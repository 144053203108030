import * as React from "react";
import { responsiveFontSizes, styled, useTheme } from "@mui/material/styles";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  Collapse,
  Drawer,
  Grid,
  Icon,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ReactComponent as Logo } from "assets/svg/Logo.svg";
import { ReactComponent as SearchIcon } from "assets/svg/search-normal.svg";
import heartIcon from "assets/svg/heart.svg";
import { ReactComponent as ShoppingCart } from "assets/svg/shopping-cart.svg";
import engageMusic from "assets/images/engageMusic.png";
// import profileAlt from "assets/images/profileAlt.png";
import {
  ArrowDownward,
  BugReportSharp,
  Close,
  ExpandMore,
  Logout,
} from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";
import { AlertContext as alertcontext } from "context/AlertContext";
import { CustomizedAlert } from "components/controls/Alert";
import {
  AuthenticatedPath,
  UnauthenticatedPath,
  getProfilePath,
} from "utility/AppRoutingPath";
import { severityType } from "model/errorSeverityTypeEnum";
import Header from "./Header";
import MainMenu from "./MainMenu";
import PopperComponents from "components/customisedComponents/PopperComponents";
import CartListing from "components/other/CartListing";
import { DarkButtonWithCustomIcon } from "components/controls/Buttons";
import { DarkInput } from "components/controls/inputFields";
import SearchPopup from "components/other/SearchPopup";
import { SearchContext } from "context/SearchContext";
import { StyledBadge } from "components/customisedComponents/StyledBadge";
import { MusicStoreContext } from "context/MusicStoreContext";
interface INavbarLayout {
  // activeMenuCaptionKey:string,
}

var drawerWidth = 290;

const CustomisedAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#08090c7d",
  color: "#fff",
  boxShadow: "none",
  padding: "0px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

let popperJsx:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
  | React.ReactPortal
  | null
  | undefined;

const NavbarLayout: React.FC<INavbarLayout> = props => {
  const [openPopper, setOpenPopper] = React.useState(false);
  const [openMusicStore, setOpenMusicStore] = React.useState(false);
  const [searchPopupFlag, setSearchPopupFlag] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const smallAndAboveScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [activeMenuCaptionKey, setActiveMenuCaptionKey] = React.useState("");
  const [AlertInfo, setAlertInfo] = React.useState<{
    Severity: severityType;
    Message: string;
    Title: string;
  }>({
    Severity: severityType.error,
    Message: "",
    Title: "",
  });
  const authcontext = React.useContext(AuthContext);
  const AlertContext = React.useContext(alertcontext);
  const searchContext = React.useContext(SearchContext);
  const store = React.useContext(MusicStoreContext)!;
  const nav = useNavigate();
  const location = useLocation();

  const userInfoString = localStorage.getItem("userInfo");
  const updatedUsername = localStorage.getItem("updatedUsername");
  const updatedUserImage = localStorage.getItem("updatedUserImage");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const profile_unique_id = updatedUsername
    ? updatedUsername
    : userInfo?.username;
  let profilePath = getProfilePath(profile_unique_id);

  const handleProfileClick = (profileUrl: any) => {
    window.location.href = profileUrl;
  };

  let profilePicVar =
    updatedUserImage !== null
      ? updatedUserImage
      : userInfo?.user_image?.file_download_url;

  if (smallAndAboveScreen) {
    drawerWidth = 280;
  } else {
    drawerWidth = 250;
  }

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    authcontext?.LogOut();
  };

  const handleOpenPopper = (
    event: React.MouseEvent<HTMLElement>,
    jsx?: any
  ) => {
    if (anchorEl == event.currentTarget) {
      setOpenPopper(!openPopper);
    } else {
      popperJsx = jsx ? jsx : <div>Coming Soon...</div>;
      setAnchorEl(event.currentTarget);
      setOpenPopper(true);
    }
  };
  const handleOpenMusicStore = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl == event.currentTarget) {
      setOpenMusicStore(!openPopper);
    } else {
      setAnchorEl(event.currentTarget);
      setOpenMusicStore(true);
    }
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const id2 = canBeOpen ? "transition-popper2" : undefined;
  const id3 = canBeOpen ? "transition-popper3" : undefined;

  const [openSearchInput, setOpenSearchInput] = React.useState(false);

  const ExpandableSearchInputJsx = (
    <>
      <DarkInput
        name="searchResult"
        id="searchPopper"
        onFocus={() => {
          setOpenSearchInput(true);
          // handleOpenSearchPopup()
          setAnchorEl(document.getElementById("SearchResults2"));
        }}
        InputProps={{
          endAdornment: (
            <Icon
              onClick={e => {
                e.stopPropagation();
                setOpenSearchInput(!openSearchInput);
                if (!openSearchInput) {
                  searchContext?.setQuery("");
                  setSearchPopupFlag(false);
                }
              }}
              sx={{
                color: "#fff",
                cursor: "pointer",
                // width: "0.5em",
                display: openSearchInput ? "inline-flex" : "none",
              }}
            >
              {searchPopupFlag ? <Close /> : <SearchIcon />}
            </Icon>
          ),
        }}
        fullWidth
        placeholder="Search"
        focused
        autoFocus
        value={searchContext?.query}
        onKeyUp={e => {
          //@ts-ignore
          if (
            e.key == "Enter" ||
            e.keyCode == 13 ||
            e.which == 13 ||
            e.code == "Enter" ||
            e.key == "NumpadEnter"
          ) {
            // searchContext?.setQuery(e.currentTarget.value)
            nav(AuthenticatedPath.SearchListing);
          }
        }}
        onChange={e => {
          searchContext?.setQuery(e.target.value);
          setSearchPopupFlag(true);
          if (e.target.value == "") {
            setSearchPopupFlag(false);
          }
        }}
        sx={{
          display: openSearchInput ? "inline-flex" : "none",
          // mr:'-14px', // this is to make the input field align with the search icon
          width: openSearchInput ? "300px" : "0%",
          "@keyframes expand": {
            "0%": {
              width: "0",
              display: "inline-flex",
            },
            "20%": {
              width: "60px",
            },
            "40%": {
              width: "120px",
            },
            "60%": {
              width: "180px",
            },
            "80%": {
              width: "240px",
            },
            "100%": {
              width: "300px",
            },
          },
          "@keyframes close": {
            "0%": {
              width: "300px",
            },
            "40%": {
              width: "240px",
            },
            "80%": {
              width: "120px",
            },
            "100%": {
              width: "0",
              display: "none",
            },
          },
          animation: !openSearchInput
            ? "close 0.2s ease-in-out"
            : "expand 0.2s ease-in-out",
          // transition: "width 0.5s ease-in-out",
          border: "unset",
          "& .MuiInputBase-root": {
            // padding: "8px 7px",
            // mr: "-14px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 7px",
            // pr: "0px",
          },
        }}
      />
      <Icon
        // onMouseEnter={(e) => setOpenSearchInput(true)}
        // onMouseLeave={(e) => setOpenSearchInput(false)}
        onClick={e => {
          // if(openSearchInput){
          //   e.currentTarget.style.display = 'inline-flex'
          // }else{
          //   e.currentTarget.style.display = 'none'
          // }
          setOpenSearchInput(!openSearchInput);
        }}
        // onTouchStart={(e) => setOpenSearchInput(!openSearchInput)}
        sx={{
          mr: "14px",
          color: "#fff",
          cursor: "pointer",
          display: openSearchInput ? "none" : "inline-flex",
        }}
      >
        <SearchIcon />
      </Icon>
    </>
  );

  const getLoggedInJsx = () => {
    return authcontext?.isAuthenticated() ? (
      <React.Fragment>
        <Grid
          id="SearchResults2"
          aria-describedby={id}
          overflow={"hidden"}
          item
          xs={"auto"}
          container
          justifyContent="flex-end"
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          {ExpandableSearchInputJsx}
        </Grid>
        <Grid
          onClick={() => handleLogout()}
          item
          xs={"auto"}
          container
          justifyContent="center"
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
        >
          {getTypography("Logout")}
        </Grid>
        <Grid
          item
          xs={"auto"}
          container
          justifyContent="center"
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => handleProfileClick(profilePath)}
        >
          <Avatar
            src={profilePicVar}
            sx={{ width: 24, height: 24, borderRadius: "8px" }}
          ></Avatar>
        </Grid>
        {/* <Grid onClick={(e)=>handleOpenPopper(e)} aria-describedby={id} item xs={'auto'} container justifyContent='center' alignItems={'center'} sx={{ cursor: 'pointer' }}><img src={heartIcon} alt='' /></Grid> */}
        <Grid
          onClick={e => handleOpenMusicStore(e)}
          aria-describedby={id2}
          item
          xs={"auto"}
          container
          justifyContent="center"
          alignItems={"center"}
          sx={{ cursor: "pointer", color: "white" }}
        >
          <StyledBadge badgeContent={store.cart.length}>
            <ShoppingCart />
          </StyledBadge>
        </Grid>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Grid
          item
          xs={"auto"}
          sx={{ cursor: "pointer" }}
          onClick={() => nav(UnauthenticatedPath.Login)}
        >
          {getTypography("Login")}
        </Grid>
        <Grid
          item
          xs={"auto"}
          sx={{ cursor: "pointer" }}
          onClick={() => nav(UnauthenticatedPath.Signup)}
        >
          {getTypography("Signup")}
        </Grid>
      </React.Fragment>
    );
  };

  let authPageNavbarjsx = (
    <React.Fragment>
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{ mr: 2 }}
          color="#FA6B05"
        >
          <Logo />
        </Typography>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{ mr: 2, "& img": { maxWidth: "250px" } }}
        >
          <img src={engageMusic} alt="" />
        </Typography>
      </Toolbar>
    </React.Fragment>
  );

  const getTypography = (text: any, isHerf?: boolean) => {
    return (
      <Typography
        sx={{
          cursor: "pointer",
          fontSize: ["0.6rem", "0.9rem", "1rem", "1rem", "1rem"],
          "& svg": {
            xs: {
              width: "2.5rem",
              height: "1.5rem",
              color: "#FA6B05",
              display: "block",
            },
            sm: {
              width: "3rem",
              height: "2rem",
            },
            md: {
              width: "4rem",
              height: "2.5rem",
            },
            lg: {
              width: "4rem",
              height: "2.5rem",
            },
          },
          color: "#fff",
          textDecoration: "none",
        }}
        component={isHerf ? "a" : "p"}
        href={isHerf && text !== "UPLOAD & SELL" ? "/" : undefined}
        onClick={
          text === "UPLOAD & SELL"
            ? (e: any) => {
                e.preventDefault();
                if (!localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
                  window.ShowMultiButtonsDialog("Please sign in to continue", [
                    {
                      captionKey: "Cancel",
                      fun: () => {},
                    },
                    {
                      captionKey: "Sign In",
                      fun: () => {
                        window.location.href = UnauthenticatedPath.Login;
                      },
                    },
                  ]);
                  return;
                }
                handleProfileClick(profilePath);
              }
            : undefined
        }
      >
        {text}
      </Typography>
    );
  };

  const navbarJsx = (
    <React.Fragment>
      <Toolbar sx={{ width: "100%", px: "3rem !important" }}>
        <Grid container justifyContent="space-between">
          <Grid
            flexWrap={"nowrap"}
            item
            container
            xs={5}
            sm={"auto"}
            textAlign="center"
            gap={["10px", 3, 4, 4, 4]}
            alignItems="center"
          >
            <Grid item xs={"auto"}>
              {getTypography(<Logo />, true)}
            </Grid>
            <Grid item xs={"auto"}>
              {getTypography("DISCOVER", true)}
            </Grid>
            <Grid item xs={"auto"}>
              {getTypography("UPLOAD & SELL", true)}
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={5}
            sm={"auto"}
            gap={["10px", 3, 4, 4, 4]}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{
              "@media (max-width: 320px)": {
                flexDirection: "column",
                alignItems: "flex-end",
              },
            }}
          >
            {getLoggedInJsx()}
          </Grid>
        </Grid>
      </Toolbar>

      {/* <PopperComponents
        popperProps={{
          sx: {
            maxWidth: "414px",
            mt: "1rem",
            zIndex: 9999,
          },
        }}
        anchorEl={anchorEl}
        id={id}
        placement="bottom-start"
        open={openPopper}
        setOpen={setOpenPopper}
        key={212}
      >
        <Typography variant="body2" color="white">
            Coming Soon
          </Typography>
      </PopperComponents> */}

      <PopperComponents
        popperProps={{
          sx: {
            // maxWidth:'200px',
            mt: "1rem",
            zIndex: 9999,
            // transform:'unset !important',
            width: anchorEl ? anchorEl.clientWidth : undefined,
            "& .MuiBox-root": {
              p: 0,
              m: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        id={id3}
        open={searchPopupFlag}
        setOpen={setSearchPopupFlag}
        placement="bottom-end"
        key={535}
        IDforClickAway={"searchPopper"}
      >
        <SearchPopup />
      </PopperComponents>

      <PopperComponents
        popperProps={{
          sx: {
            maxWidth: "414px",
            mt: "1rem",
            zIndex: 9999,
          },
        }}
        anchorEl={anchorEl}
        placement="bottom-end"
        id={id2}
        open={openMusicStore}
        setOpen={setOpenMusicStore}
      >
        <CartListing />
      </PopperComponents>
    </React.Fragment>
  );

  const getNavJsx = () => {
    let jsxResult = null;
    if (
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname === "/forgetpassword"
    ) {
      // user on login signUp page
      jsxResult = authPageNavbarjsx;
    } else if (true) {
      jsxResult = navbarJsx;
    }

    return jsxResult;
  };

  React.useEffect(() => {
    if (AlertContext?.ShowError) {
      setAlertInfo({
        Title: AlertContext?.Title,
        Message: AlertContext?.Message,
        Severity: AlertContext?.Severity,
      });
    }
  }, [AlertContext?.ShowError]);

  React.useEffect(() => {
    location.pathname === UnauthenticatedPath.Login
      ? setActiveMenuCaptionKey("Login")
      : location.pathname === UnauthenticatedPath.Signup
      ? setActiveMenuCaptionKey("Signup")
      : location.pathname === UnauthenticatedPath.Contactus
      ? setActiveMenuCaptionKey("Contact Us")
      : location.pathname === UnauthenticatedPath.Homepage
      ? setActiveMenuCaptionKey("home")
      : location.pathname === UnauthenticatedPath.Aboutus
      ? setActiveMenuCaptionKey("About Us")
      : location.pathname === "/profile"
      ? setActiveMenuCaptionKey("Profile")
      : location.pathname === "/logout"
      ? handleLogout()
      : location.pathname === "/store"
      ? setActiveMenuCaptionKey("Store")
      : setActiveMenuCaptionKey("");
  }, [location.pathname]);

  // React.useEffect(() => {
  //   if(openSearchInput){
  //     setOpenMusicStore(false)
  //     setOpenPopper(false)
  //     setAnchorEl(document.getElementById('SearchResults2'));
  //   }else{
  //     setAnchorEl(null);
  //   }
  //   handleOpenSearchPopup()
  // }, [openSearchInput])

  React.useEffect(() => {
    if (openMusicStore) {
      setOpenPopper(false);
      setSearchPopupFlag(false);
    }
  }, [openMusicStore]);

  React.useEffect(() => {
    if (openPopper) {
      setOpenMusicStore(false);
      setSearchPopupFlag(false);
    }
  }, [openPopper]);

  // React.useEffect(() => {
  //   if(anchorEl && anchorEl?.id == 'SearchResults2'){
  //     handleOpenSearchPopup()
  //   }else{
  //     openSearchInput && handleOpenSearchPopup()
  //   }
  // }, [anchorEl])

  return (
    <React.Fragment>
      {!smallAndAboveScreen ? (
        <>
          <Header
            drawerOpenHandler={handleDrawerOpen}
            // openUserProfileHandler={openUserProfileDailog}
          />
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                background: "#08090c",
                width: drawerWidth,
                color: "#FA6B05",
                borderRight: "none",
                boxSizing: "border-box",
              },
            }}
            variant="temporary"
            anchor="left"
            open={open}
            onClose={() => {
              handleDrawerClose();
            }}
          >
            <Typography
              onClick={() => nav(UnauthenticatedPath.Homepage)}
              variant="h6"
              noWrap
              sx={{
                display: "flex",
                alignItems: "center",
                p: "34px",
                px: 1,
                justifyContent: "space-evenly",
              }}
              color="#FA6B05"
            >
              <Logo />
            </Typography>
            <MainMenu
              activeMenuCaptionKey={activeMenuCaptionKey}
              closeMenuHandler={handleDrawerClose}
            />
          </Drawer>
        </>
      ) : (
        <CustomisedAppBar
          sx={{
            background: AlertContext?.ShowError ? "#08090C" : "#08090c",
            zIndex: theme => theme.zIndex.drawer + 1,
            padding: 0,
          }}
          position={AlertContext?.ShowError ? "relative" : "fixed"}
        >
          {getNavJsx()}
          <Collapse sx={{ width: "100%" }} in={AlertContext?.ShowError}>
            {
              <CustomizedAlert severity={AlertInfo.Severity} variant="filled">
                <AlertTitle>{AlertInfo.Title}</AlertTitle>
                {AlertInfo.Message}
              </CustomizedAlert>
            }
          </Collapse>
        </CustomisedAppBar>
      )}
    </React.Fragment>
  );
};

export default NavbarLayout;
