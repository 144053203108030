import {
  Avatar,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ReactComponent as Seperator } from "assets/svg/Seperator.svg";
import { ReactComponent as Download } from "assets/svg/Download.svg";
import HttpTransferService from "services/httptransfer";
import { AuthContext } from "context/AuthContext";
import { MusicPlayerContext } from "context/MusicPlayerContext";
import { playerSongType } from "model/playerSongType";
import { PlayArrow, PlayCircle, PauseCircle } from "@mui/icons-material";
import { MusicStoreContext } from "context/MusicStoreContext";

type Props = {};

const PurchasedTracksListings = (props: Props) => {
  const [selectedTrack, setSelectedTrack] = useState<any>({});
  const [allTracks, setAllTracks] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadingTrackId, setDownloadingTrackId] = useState<string | null>(
    null
  );

  const musicPlayerContext = React.useContext(MusicPlayerContext);
  const musicStoreContext = React.useContext(MusicStoreContext);
  const authContext = React.useContext(AuthContext);

  const theme = useTheme();
  const phoneScreenBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

  const styleForSvg = {
    width: phoneScreenBreakpoint ? "20px" : "24px",
    height: phoneScreenBreakpoint ? "20px" : "24px",
  };

  const httptransfer = new HttpTransferService();

  const getAllTracks = async () => {
    setIsLoading(true);
    const successCallback = (res: any) => {
      const flattenedTracks = res.data.purchased_items.flatMap(
        (item: {
          tracks: any[];
          razorpay_payment_id: string;
          razorpay_order_id: string;
          create_date: string;
        }) =>
          item.tracks.map(track => ({
            ...track,
            purchaseInfo: {
              razorpay_payment_id: item.razorpay_payment_id,
              razorpay_order_id: item.razorpay_order_id,
              purchase_date: item.create_date,
            },
          }))
      );
      setAllTracks(flattenedTracks);
      setIsLoading(false);
    };

    const errorCallback = (err: any) => {
      console.log(err);
      setIsLoading(false);
    };

    await httptransfer.purchasedTracks(successCallback, errorCallback);
  };

  const handleSelected = (track: any) => {
    if (selectedTrack._id === track._id) {
      setSelectedTrack({});
      return;
    }

    let trackToGiveToPlayer: Partial<playerSongType> = {
      id: track._id,
      trackAudioUrl: track.music_mp3_audio_file.file_path,
      trackImageUrl: track.music_cover_image.file_path,
      trackName: track.name,
      artistName: track.artist_collaborators_name[0] || "Unknown Artist",
      albumName: track.album_id || "Single",
      price: track.price,
      genres: track.genres,
      Label: track.label_collaborators_name[0] || "",
    };

    setSelectedTrack(track);
    musicPlayerContext?.openPlayer(
      trackToGiveToPlayer as playerSongType,
      allTracks
    );
  };

  const handleDownload = async (item: any) => {
    try {
      setDownloadingTrackId(item._id);
      const response = await httptransfer.getDownloadUrl(item._id);
      if (response.data && response.data.downloadUrl) {
        const fileResponse = await fetch(response.data.downloadUrl);
        const blob = await fileResponse.blob();

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = item.name || "download";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Failed to get download URL");
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    } finally {
      setDownloadingTrackId(null);
    }
  };

  const trackDetailsCardJsx = (
    track: any,
    selected: boolean,
    border: boolean
  ) => (
    <React.Fragment key={track._id}>
      <Grid
        container
        sx={{
          borderBottom: border ? "1px solid #636B82" : "",
          backgroundColor: "black",
          p: ["9px", "9.5px 20px 9.5px 20px"],
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#ffffff1f",
            "& #play-icon": {
              opacity: "1",
            },
          },
        }}
        justifyContent="space-between"
      >
        <Grid
          item
          container
          xs={10}
          justifyContent="flex-start"
          alignItems={"center"}
        >
          {track.music_cover_image && (
            <>
              <Avatar
                src={track.music_cover_image.file_path}
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "4px",
                }}
              />
              <Box
                id="play-icon"
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "4px",
                  backgroundColor: "#636b825e",
                  opacity: "0",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => handleSelected(track)}
              >
                {musicPlayerContext?.IsPlaying &&
                musicPlayerContext?.currentTrack?.id === track._id ? (
                  <PauseCircle />
                ) : (
                  <PlayCircle />
                )}
              </Box>
            </>
          )}

          <Grid
            item
            container
            xs={10}
            direction={"column"}
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{ ml: "10px" }}
            flexWrap="nowrap"
          >
            <Grid
              item
              xs={12}
              container
              gap="0.5rem"
              alignItems="center"
              flexWrap={"nowrap"}
            >
              <Typography
                noWrap
                sx={{ fontSize: ["12px", "16px"], fontWeight: "700" }}
              >
                {track.name}
              </Typography>
            </Grid>
            <Grid item xs={12} container gap="0.5rem" alignItems="center">
              <Typography
                noWrap
                sx={{
                  fontSize: ["12px", "16px"],
                  fontWeight: "700",
                  maxWidth: "40%",
                }}
              >
                {track.artist_collaborators_name[0] || "Unknown Artist"}
              </Typography>
              {/* <Seperator /> */}
              {/* <Typography
                noWrap
                sx={{ fontSize: ["12px", "16px"], maxWidth: "40%" }}
              >
                {track.album_id || "Single"}
              </Typography> */}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={2}
          gap="1rem"
          flexWrap={"nowrap"}
          justifyContent="flex-end"
          alignItems={"center"}
        >
          <Grid item container xs={"auto"} alignItems="center">
            <Typography
              sx={{
                fontSize: ["10px", "12px"],
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={e => {
                e.stopPropagation();
                handleDownload(track);
              }}
            >
              {downloadingTrackId === track._id ? (
                <>
                  Downloading
                  <CircularProgress
                    size={20}
                    sx={{
                      ml: 1,
                      color: "#FA6B05",
                    }}
                  />
                </>
              ) : (
                <>
                  Download
                  <Download style={styleForSvg} />
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  useEffect(() => {
    getAllTracks();
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <CircularProgress
          sx={{
            filter: "drop-shadow(0px 0px 6px #FA6B05)",
            width: "30px !important",
            height: "30px !important",
            color: "#FA6B05",
            m: 2,
          }}
        />
      </Grid>
    );
  }

  return (
    <>
      {allTracks.map((track: any, index: number, arr: any[]) =>
        trackDetailsCardJsx(
          track,
          track._id === selectedTrack._id,
          index !== arr.length - 1
        )
      )}
    </>
  );
};

export default PurchasedTracksListings;
