import { Box, ClickAwayListener, Fade, Grow, Popper, PopperProps } from "@mui/material";
import { DarkBlueRoundedWapper } from "components/uiCard/BoxWrapperCard";
import React from "react";

type Props = {
  children: React.ReactNode;
  open: boolean;
  anchorEl: HTMLElement | null;
  id: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  popperProps?: any;
  sx?: any;
  placement?: any;
  IDforClickAway?: string;
};

const PopperComponents = ({ sx,popperProps ,anchorEl, placement, id, open, setOpen, children,IDforClickAway }: Props) => {
  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        disableReactTree
        onClickAway={(e) => {
          //@ts-ignore
          if(e?.target?.id === IDforClickAway) return;
          if (open) setOpen(false);
        }}
      >
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          disablePortal={false}
          sx={{
            zIndex: (theme) => theme.zIndex.tooltip,
            pt: "1rem !important",
            transition: "unset",
            ...sx,
          }}
          modifiers={[
            {
              name: "flip",
              enabled: false,
              options: {
                altBoundary: false,
                rootBoundary: "document",
                padding: 8,
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                padding: 8,
              },
            },
          ]}
          {...popperProps}
        >
          <DarkBlueRoundedWapper
            color={"white"}
            sx={{
              background:
                "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
              border: "1px solid #636B82",
              backdropFilter: "blur(100px)",
              borderRadius: "24px",
            }}
          >
            {children}
          </DarkBlueRoundedWapper>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default PopperComponents;
