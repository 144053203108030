import React from 'react'
import { Divider, Fab, Grid, styled, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Add, Remove } from '@mui/icons-material';
import { ReactComponent as BurstPuckerSvg } from 'assets/svg/Burst-pucker-2.svg'
import { useLocation } from 'react-router-dom';

// import {ReactComponent as Add} from 'assets/svg/add.svg'
// import {ReactComponent as Remove} from 'assets/svg/remove.svg'

type Props = {
  ref?: React.Ref<HTMLDivElement>;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.customTheme.main}`,
  background: "transparent",
  width: "100%",
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  transition: "all 0.8s ease-in-out",
  "&.Mui-expanded .MuiButtonBase-root": {
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={props["aria-expanded"] ? <Remove sx={{ fontSize: '0.9rem' }} /> : <Add sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  background: "trasnparent",
  color: 'white',
  fontWeight: '700',
  padding: 0,
  margin: 0,
  // flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white',
  },
  '& .MuiAccordionSummary-content': {
    alignItems: 'center',
    margin: 0,
    gap: "1rem",
    [theme.breakpoints.up('xs')]: {
      "& .MuiTypography-root": {
        fontSize: '0.7rem',
      },
    },
    [theme.breakpoints.up('sm')]: {
      "& .MuiTypography-root": {
        fontSize: '1rem'
      },
    },
    [theme.breakpoints.up('md')]: {
      "& .MuiTypography-root": {
        fontSize: '1.1rem'
      },
    },
    [theme.breakpoints.up('lg')]: {
      "& .MuiTypography-root": {
        fontSize: '1.25rem'
      },
    },
    '& svg': {
      color: '#fa6b05',
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  color: 'white',
  [theme.breakpoints.up('xs')]: {
      fontSize: '0.5rem',
  },
  [theme.breakpoints.up('sm')]: {
      fontSize: '0.6rem'
  },
  [theme.breakpoints.up('md')]: {
      fontSize: '0.8rem'
  },
  [theme.breakpoints.up('lg')]: {
      fontSize: '0.9rem'
  },
  fontWeight: '400',
  paddingLeft: '2rem',
  paddingBottom: '1rem',
}));

const FaqAccordian = (props: Props) => {
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const FaqAccordianData = [
    {
      panel: "panel1",
      title: "For how long will Artists earn 100% revenue on music sales?",
      description: "Artists will earn 100% royalties for 10 years for all music uploaded on or before 31st December 2023",
    },
    {
      panel: "panel2",
      title: "How much royalties to Artists and Labels earn after 31st December 2023?",
      description: "Artists and Labels earn 90% of royalties for all music uploaded and sold on the platform after 31st December 2023."
    },
    {
      panel: "panel3",
      title: "How do I get my songs on Engage Music?",
      description: "Uploading your music on Engage is as easy as signing up and creating your profile. No intermediaries required :)."
    },
    {
      panel: "panel4",
      title: "Are you having issues uploading music?",
      description: "Feel free to write to us at support@engagemusic.io or reach out to us on our socials and we will help you at the earliest."
    },
    {
      panel: "panel5",
      title: "Can I upload DJ mixes?",
      description: "Unfortunately, we do not allow DJ mixes to be uploaded at this point. Be sure to sign up to be posted on updates regarding this."
    },
    {
      panel: "panel6",
      title: "Do Artists and Labels control 100% rights to their music?",
      description: "Our job at Engage is to connect Artists to fans. We let Artists and Labels control 100% off the rights to their art."
    },
    {
      panel: "panel7",
      title: "Are you missing an item from your purchase list?",
      description: "Do not hesitate to reach out to us on care@engagemusic.io or our socials and we will help you at the earliest."
    },
    {
      panel: "panel8",
      title: "More about our music streaming payout model",
      description: "We will be following a hybrid payout model where 30% of the payout goes to a pool to be divided among all artists based on plays, and 70% is split using the User Centric model where a users subscription only goes back to the artists they stream."
    },
    {
      panel: "panel9",
      title: "What is the BETA version?",
      description: "We are offering early BETA users free access to all the features on our platform until 31st December 2023. After that, free features will be available and for all access, a subscription will have to be purchased."
    },
  ]

  React.useEffect(() => {
    if (location.state && location.state.scrollToFaq && sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container ref={sectionRef}>
        <Grid item xs={12}>
          <Typography sx={{fontFamily: "'Darker Grotesque', sans-serif", textAlign: "left", fontWeight: "bold", fontSize: "3rem", color: "#fff", paddingTop: "2rem", paddingBottom: "2rem" }}>
          FAQ’s
          </Typography>
        </Grid>
        {FaqAccordianData.map((faqItem,key)=>(<React.Fragment key={key}>
        <Accordion expanded={expanded === faqItem.panel} onChange={handleChange(faqItem.panel)}>
          <AccordionSummary
            aria-expanded={expanded === faqItem.panel}
            aria-controls={`${faqItem.panel}-content`}
            id={`${faqItem.panel}-header`}
          >
            <BurstPuckerSvg />
            <Typography sx={{ fontSize: "1.1rem",fontWeight:'bold' }}>{faqItem.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontSize: "0.9rem" }}>
              {faqItem.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Divider sx={{ border: "1px solid #636B82", width: '100%' }} />
        </React.Fragment>))}
      </Grid>
    </React.Fragment>
  )
}

export default FaqAccordian