import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import {ReactComponent as Logo} from 'assets/svg/Logo.svg'
import heroImage from "assets/images/celebrateAboutus.png";
import LogoPng from "assets/images/LogoPng.png";

type Props = {}

const AboutUsHero = (props: Props) => {
  const responsiveImageWidth = [40, 70, 110, 110, 139];
  const responsiveImageHeight = [20, 50, 70, 70, 88];
  return (
    <Grid
      container
      justifyContent={"center"}
      alignItems="center"
      gap={4}
      color="white"
    >
      {/* <img src={heroImage} style={{width:'100%',height:'100%'}} alt=''/> */}
      <Grid
        container
        item
        alignItems="center"
        direction={"column"}
        justifyContent="center"
      >
        {/* <Grid container item xs={4} justifyContent='center' sx={{
        "& img": {
          height:responsiveImageHeight,
          width:responsiveImageWidth
        }
      }}><img src={LogoPng} alt=''/></Grid> */}
        <Grid container item xs={8} justifyContent="flex-start">
          <Typography
            // variant={"h1"}
            fontWeight={700}
            sx={{
              "@media (min-width: 1200px)": {},
              fontSize: ["2rem", "2.5rem", "3.5rem", "4rem", "5.2rem"],
              fontFamily: "'Darker Grotesque', sans-serif",
              // lineHeight: '0.8',
              // mb: "1.5rem",
            }}
          >
            <span
              style={{
                color: "#FA6B05",
                fontFamily: "'Darker Grotesque', sans-serif",
              }}
            >
              ABOUT{" "}
            </span>
            US
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          // maxWidth={[
          //   "80% !important",
          //   "70% !important",
          //   "35% !important",
          //   "35% !important",
          // ]}
          justifyContent="center"
        >
          <Typography
            textAlign={"justify"}
            sx={{
              fontSize: {
                xs: "0.8rem",
                sm: "1rem",
                md: "1.25rem",
                lg: "1.5rem",
              },
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
            fontWeight={500}
          >
            Engage Music is a platform designed to help artists thrive in the digital age.
We understand the challenges that artists and the music industry face today. For too long, the system has failed to support the creative community and left many talented musicians struggling to earn a living from their recorded work. 
            <br/><br/>
            Our Direct to Artist Digital Music Store, which offers a fair and transparent revenue model for all artists. We believe that music should be valued, and artists should be rewarded for their hard work and dedication.
The next phase is to create a social network that connects artists, labels, and event hosts, enabling them to promote their music and events, discover new fans, and build communities. <br/><br/>
Our goal is to create a platform where the art is the main focus, and where artists can thrive and be recognized for their talent and creativity.
So whether you're a music producer, label owner, event host or venue we invite you to join our movement and be part of the new music revolution. Together, let's build a community that values and supports the artists who make it all possible.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AboutUsHero