import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Chip, Grid, IconButton, InputAdornment, Typography, useTheme } from '@mui/material';
import { DarkInputWithLabel } from './inputFields';
import HttpTransferService from 'services/httptransfer';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import appConfig from "appConfig";

interface IApiReqAutoComplete {
  label: any;
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  inputName: string;
  addNewOption?: boolean;
  multipleValue?: boolean;
  //ApiReqFunc: any;
  type: any;
  setUnsavedChanges?: (value: boolean) => void;
}

const filter = createFilterOptions<string>();
const httptransfer = new HttpTransferService();

const ApiReqAutoCompleteInput: React.FC<IApiReqAutoComplete> = ({ inputName, type, label, setState, state, addNewOption, multipleValue, setUnsavedChanges }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly string[]>([]);
  const loading = open && options.length === 0;
  const theme = useTheme();


  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const successCb = (response: any) => {
        console.log("ress-", response)
      if (active) {
        const names = response.data.map((item: any) => item.name);
        setOptions(names);
      }
    }
    if(type==="TYPE" && active) {
        let typeArray = ["ARTIST", "LABEL", "USER"];
        setOptions(typeArray);
    }
    if(type==="GENRE" && active) {
        setOptions(appConfig.genresArray);
    }

    const errorCb = (error: any) => {
      console.log(error)
    }



    (async () => {
        if (type == "LOCATION"){
         httptransfer.getLocation(successCb, errorCb)
        }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      value={state}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      loading={loading}

      onChange={(event, newValue) => {
        console.log(newValue, 'newValue')
        if (newValue === null) return
        if (typeof newValue === 'string') {
          setState((prev: string | any[]) => {
            if (multipleValue) {
              return prev.concat(newValue)
            } else {
              return [newValue]
            }
          })
          return
        }
        setState([...newValue])
        setUnsavedChanges && setUnsavedChanges(true)
      }}
      multiple={multipleValue ? true : false}

      renderInput={(params) => (
        <DarkInputWithLabel
          {...params}
          inputProps={{
            ...params.inputProps,
            name: inputName,
          }}
          label={label}
          name={inputName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> :
                  <InputAdornment position="end">
                    <IconButton disableFocusRipple disableRipple disableTouchRipple>
                      {/* <Search sx={{ color: "#ffffff" }} /> */}
                    </IconButton>
                  </InputAdornment>
                }
              </React.Fragment>
            ),
          }}
          // type={"text"}
          // placeholder={""}
          sx={{
            marginBottom: "1.25rem",
            color: "#ffffff",
            maxWidth: '244px',
            [theme.breakpoints.down("md")]: {
              maxWidth: "100%",
            },
          }}
        />
      )}

      loadingText={<Typography color='white'>Loading...</Typography>}

      renderTags={(value, getTagProps) =>
        value.map((option: any, index: any) => {
          return (
            <Chip
              size="small"
              label={option}
              {...getTagProps({ index })}
              sx={{
                mr: "0.5rem",
                mb: "0.5rem",
                backgroundColor: "#ffffff1f",
                color: "white",
                height: '24px',
                "& span": {
                  px: "1rem",
                },
                [theme.breakpoints.only("xs")]: {
                  height: '17px',
                  "& span": {
                    px: "0.5rem",
                  },
                  fontSize: "10px",
                },
              }}
            />
          )
        })
      }
      renderOption={(props, option, inputState) => {
        const matches = match(option, inputState.inputValue, { insideWords: true });
        const parts = parse(option, matches);

        return (
          <React.Fragment key={props.id}>
            <li {...props} >
              <Grid container justifyContent={'space-between'}>
                <div>
                  {parts.map((part: any, index: any) => (
                    <span
                      className="optionLetter"
                      key={index}
                      style={{ color: part.highlight ? "#3276FB" : "#fff" }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </Grid>
            </li>
          </React.Fragment>
        );
      }}
      componentsProps={
        {
          popper: {
            sx: {
              transition: "unset",
              zIndex: (theme) => theme.zIndex.tooltip,
              "& .MuiPaper-root": {
                color: "#ffffff",
                background:
                  "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
                border: "1px solid #636B82",
                backdropFilter: "blur(100px)",
                borderRadius: "12px",
                // top: "388px !important",
                maxHeight: "200px",
                "& .MuiMenuItem-root": {
                  fontWeight: 600,
                  padding: "0.8rem 1.25rem",
                },
                margin: 0,
                p: 0,
                "& ul": {
                  padding: 0,
                  maxHeight: '190px',
                }
              },
              "& .optionLetter": {
                fontWeight: 600,
              },
              "& .MuiAutocomplete-noOptions": {
                color: "white"
              }
            },
            placement: "bottom-start",
          },
        }
      }
      sx={{
        "& .MuiOutlinedInput-root": {
          padding: "9px !important",
        }
      }}
    />
  );
}

export default ApiReqAutoCompleteInput;
