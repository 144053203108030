import { Grid, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { DarkButtonWithIcon } from 'components/controls/Buttons'
import DialogPopup from 'components/layout/DialogPopup'
import { AuthContext } from 'context/AuthContext'
import React from 'react'
import CreateAlbumForm from './CreateAlbumForm';
import UploadMusicForm from './UploadMusicForm';

type Props = {}

const ProfileDescription = (props: Props) => {

  const theme = useTheme()

  const [uploadMusicPopupFlag, setUploadMusicPopupFlag] = React.useState(false)
  const [fromUploadMusic, setFromUploadMusic] = React.useState(false)
  const authcontext = React.useContext(AuthContext)!;
  const [unsavedChanges, setUnsavedChanges] = React.useState(false);
  const [disableIconButton, setDisableIconButton] = React.useState(false);
  const [showFullBio, setShowFullBio] = React.useState(false);

  const toggleBio = () => {
    setShowFullBio(!showFullBio);
  };

  const openUploadMusicPopup = (from: boolean) => {
    setUploadMusicPopupFlag(true)
    setFromUploadMusic(from)
  }

  const closeUploadMusicPopup = () => {
    setUploadMusicPopupFlag(false)
  }

  const userInfoString = localStorage.getItem('userInfo');
 const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

 let bio = "";
  if (
   localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN') &&
  (
    (authcontext?.users && userInfo && authcontext?.users?._id === userInfo._id) 
  )
 ) {
  bio = (authcontext?.users ? authcontext?.users?.bio : userInfo?.bio);
 } else {
  bio = (authcontext?.users ? authcontext?.users?.bio : userInfo?.bio)
 }
 const truncatedBio = (bio !== '') ? (showFullBio ? bio : (bio.length > 300 ? `${bio.slice(0, 300)}...` : bio)) : '';
    return (
    <React.Fragment>
        <Grid container gap='1.25rem' alignItems={'center'} justifyContent='space-around'>
          <Typography 
          sx={{
            fontSize: ["12px", "14px", "14px", "14px", "14px"],
            whiteSpace: "break-spaces",
            overflowWrap: "break-word",
            width: "100%",
          }}>{truncatedBio}
          {bio.length > 300 && (
            <span
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={toggleBio}
            >
              {showFullBio ? '..Read Less' : 'Read More'}
            </span>
          )}
          </Typography>
         {localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN') && (authcontext.users ? userInfo?._id === authcontext.users?._id : userInfo?._id) &&( <Grid item container gap={'1rem'}  justifyContent='space-between' sx={{ marginTop: bio ? '1rem' : '8rem' }}>
            <DarkButtonWithIcon sx={{
              width: '45%',
              height: 'unset',
              [theme.breakpoints.down("sm")]: {
                width: '100%',
              },
            }}
              onClick={() => openUploadMusicPopup(true)}>
              UPLOAD SONG
            </DarkButtonWithIcon>
            <DarkButtonWithIcon sx={{
              width: '45%',
              height: 'unset',
              [theme.breakpoints.down("sm")]: {
                width: '100%',
              },
            }}
              onClick={() => openUploadMusicPopup(false)}>
              CREATE ALBUM
            </DarkButtonWithIcon>
          </Grid>)}
        </Grid>

      <DialogPopup
        closeDialog={closeUploadMusicPopup}
        dialogOpen={uploadMusicPopupFlag}
        dialogTitle={fromUploadMusic?"Upload Music":"Create Album"}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
        disableIconButton={disableIconButton}
      >
        {fromUploadMusic ? (
          <UploadMusicForm closeDialog={closeUploadMusicPopup} fromUploadMusic={fromUploadMusic} albumId='' labelData={[]} setUnsavedChanges={setUnsavedChanges} setDisableIconButton={setDisableIconButton}/>
        ) : (
          <CreateAlbumForm closeDialog={closeUploadMusicPopup} fromUploadMusic={fromUploadMusic} setUnsavedChanges={setUnsavedChanges}/>
        )}
      </DialogPopup>
    </React.Fragment>
  )
}

export default ProfileDescription