import React, { useState } from "react";
import { severityType } from "model/errorSeverityTypeEnum";

interface IAlertContext {
  OpenError: (data:{title: string, message: string,severity:severityType}) => void;
  CloseError: ()=>void,
  ShowError: boolean;
  Title: string;
  Message: string;
  Severity: severityType;
}

type Props = {
    children:   React.ReactNode | React.ReactNode[];
}

export const AlertContext = React.createContext<IAlertContext | null>(null);

const AlertContextProvider: React.FC<Props> = (props) => {
  const [Title, setTitle] = useState<any | null>(null);
  const [severity, setseverity] = useState<severityType>(severityType.error);
  const [message, setmessage] = useState<string>("");
  const [showError, setshowError] = useState<boolean>(false);

  const CloseError = () => {
    setshowError(false);
    setmessage("");
    setTitle(null);
  };

  const openError = (data:{title: string, message: string,severity:severityType}) => {
    setTitle(data.title);
    setmessage(data.message);
    setseverity(data.severity);
    setshowError(true);
  };

  React.useEffect(() => {
    if (showError) {
      window.scrollTo(0,0)
      setTimeout(() => {
        CloseError();
      }, 5000);
    }
  }, [showError]);

  return (
    <AlertContext.Provider
      value={{
        OpenError: openError,
        CloseError: CloseError,
        ShowError: showError,
        Title: Title,
        Message: message,
        Severity: severity, 
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
