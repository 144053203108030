import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { HTMLAttributes } from "react";
import { ReactComponent as AddSquare } from "assets/svg/add-square.svg";
import { ReactComponent as UploadIcon } from "assets/svg/uploadIcon.svg";
import { ReactComponent as BlueBurstPucker } from "assets/svg/blueBurstPucker.svg";
import { AuthContext } from "context/AuthContext";
import ImageTextFieldContainer from "components/controls/ImageTextFieldContainer";
import { DarkInputWithLabel } from "../controls/inputFields";
import { AttachMoney } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';
import {
  DarkButtonWithIcon,
  LightBlurButtonWithCustomIcon,
} from "components/controls/Buttons";
import GenresSearchInput from "components/controls/GenresSearchInput";
import CheckedIcon from "assets/Icons/CheckedIcon";
import { TrackUploadContext } from "context/TrackUploadContext";
import { TrackPublishType } from "model/trackModel";
import { AlertContext } from "context/AlertContext";
import { severityType } from "model/errorSeverityTypeEnum";
import ApiReqAutoComplete from "components/controls/ApiReqAutoComplete";
import ApiReqAutoCompleteInput from 'components/controls/AutoCompleteInputString';
import HttpTransferService from 'services/httptransfer';
import ManageSongDialog from './ManageSongDialog';
import ManageSong from './ManageSong';

type Props = {
  formDataState: {
    name: string;
    price: any;
    visibility: TrackPublishType;
    catalog_number: string;
    genres: [];
    album_id: string;
    isrc_code: any;
    release_date: string,
    artist_collaborators_id: [];
    artist_collaborators_name: [];
    label_collaborators_id: [];
    label_collaborators_name: [],
    album_cover_image: any,
    mark_all_songs_public: boolean
  },
  closeDialog: () => void;
};

const httptransfer = new HttpTransferService();

const EditMusicForm = (props: Props) => {
  const [fileData, setFileData] = React.useState<any>({});
  const [file, setFile] = React.useState<any>({});
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [artistNameData, setArtistNameData] = React.useState<any[]>([]);
  const [artistNames, setArtistNames] = React.useState<any[]>([]);
  const [labelIds, setLabelIds] = React.useState<any[]>([]);
  const [labelNames, setLabelNames] = React.useState<any[]>([]);
  const [artistState, setArtistState] = React.useState<any[]>([]);
  const [labelState, setLabelState] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [artist_collaborator_id, setArtistCollaboratorId] = React.useState<any[]>([]);
  const [label_collaborator_id, setLabelCollaboratorId] = React.useState<any[]>([]);
  const [artist_collaborator_name, setArtistCollaboratorName] = React.useState<any[]>([]);
  const [label_collaborator_name, setLabelCollaboratorName] = React.useState<any[]>([]);
  const [genreData, setGenreData] = React.useState<any[]>([]);
  const [formData, setFormData] = React.useState<{
    name: string;
    price: any;
    genres: any;
    visibility: TrackPublishType;
    catalog_number: string;
    album_id: string;
    isrc_code: string;
    release_date: any;
    artist_collaborators_id: any;
    label_collaborators_id: any;
    artist_collaborators_name: any;
    label_collaborators_name: any;
    album_cover_image?: any;
    mark_all_songs_public: boolean;
  }>({
    name: "",
    price: -1,
    genres: [],
    visibility: TrackPublishType.PRIVATE,
    catalog_number: '',
    album_id: '',
    isrc_code: '',
    release_date: '',
    artist_collaborators_id: [],
    label_collaborators_id: [],
    artist_collaborators_name: [],
    label_collaborators_name: [],
    mark_all_songs_public: false
  });

  const AlbumsContext = React.useContext(TrackUploadContext);
  const alertContext = React.useContext(AlertContext);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'release_date') {
      const targetDateString = e.target.value;
      const targetDate = new Date(targetDateString);
      const currentDate = new Date(1900, 0, 1);
      const timeDifference = targetDate.getTime() - currentDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      setFormData((prev) => ({ ...prev, [name]: daysDifference }));
    } else if (name === 'visibility') {
      const newVisibility = formData.visibility === TrackPublishType.PUBLIC
        ? TrackPublishType.PRIVATE
        : TrackPublishType.PUBLIC;
      const shouldSetMarkAllPublicToFalse = newVisibility === TrackPublishType.PRIVATE;
      setFormData((prev) => ({ ...prev, visibility: newVisibility, 
        mark_all_songs_public: shouldSetMarkAllPublicToFalse ? false : prev.mark_all_songs_public, }));
    }else if (name === 'mark_all_songs_public') {
      setFormData((prev) => ({ ...prev, [name]: !formData.mark_all_songs_public }));
    }
    else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const errorCallback = (error: any) => {
    console.log(error);
    alertContext!.OpenError({
      title: "",
      message: "Something went wrong",
      severity: severityType.error,
    });
    setLoading(false);
    props.closeDialog();
  };

  const editAlbumData = (album_cover_image: any) => {
    const formDataCopy = { ...formData };
    if (formDataCopy?.price) {
      formDataCopy.price = parseFloat(formDataCopy.price);
    }
    const { album_id, ...formDataWithoutId } = formDataCopy;
    formDataWithoutId.artist_collaborators_id = artist_collaborator_id;
    formDataWithoutId.artist_collaborators_name = artist_collaborator_name;
    formDataWithoutId.label_collaborators_id = label_collaborator_id;
    formDataWithoutId.label_collaborators_name = label_collaborator_name;
    //edit album api call
    if (album_cover_image?.file_path) {
      formDataWithoutId.album_cover_image = album_cover_image;
    }
    const successCallback = (response: any) => {
      alertContext!.OpenError({
        title: "",
        message: "Album Edited Successfully",
        severity: severityType.success,
      });
      AlbumsContext?.GetAllAlbumsByUser()
      setLoading(false)
      props.closeDialog();
    };
    httptransfer.updateAlbum(formData.album_id, formDataWithoutId, successCallback, errorCallback);
  }

  const handleEditAlbum = async () => {
    setLoading(true)
    let album_cover_image: any;
    const putSuccessCallback = (response: any) => {
      console.log(response);
      editAlbumData(album_cover_image)
    };
    const fileSuccessCallBack = async (response: any) => {
      console.log(response)
      const file_upload_url = response?.data?.responseJson?.file_upload_url;
      album_cover_image = {
        file_path: response?.data?.responseJson?.file_path,
        file_extension: fileData.trackImageFile.file_extension,
        file_name: fileData.trackImageFile.file_name,
      };
      await httptransfer.uploadFileOnAws(file, file_upload_url, putSuccessCallback, errorCallback);
    };
    if (file.name) {
      console.log(fileData)
      httptransfer.uploadAlbumArt(fileData.trackImageFile, fileSuccessCallBack, errorCallback)
    } else {
      editAlbumData(album_cover_image)
    }
  };

  const fetchArtistNamesFromIds = (ids: string[]) => {
    return ids.map((id: string) => {
      const artist = AlbumsContext.ArtistUsers.find((user: any) => user._id === id);
      return artist ? artist.name : '';
    });
  };
  const fetchLabelNamesFromIds = (ids: string[]) => {
    return ids.map((id: string) => {
      const label = AlbumsContext.LabelUsers.find((user: any) => user._id === id);
      return label ? label.name : '';
    });
  };

  React.useEffect(() => {
    if (props.formDataState) {
      const {
        name,
        price,
        visibility,
        catalog_number,
        album_id,
        isrc_code,
        release_date,
        artist_collaborators_id,
        artist_collaborators_name,
        label_collaborators_id,
        label_collaborators_name,
        genres,
        mark_all_songs_public
      } = props.formDataState;

      // Set direct properties
      setFormData((prevFormData) => ({
        ...prevFormData,
        name,
        price,
        visibility,
        catalog_number,
        album_id,
        isrc_code,
        release_date,
        mark_all_songs_public
      }));

      // Set genres
      if (genres.length > 0) {
        setGenreData(genres);
      }

      // Fetch and set artist names
      if (artist_collaborators_id && artist_collaborators_id.length > 0) {
        const artistNames = fetchArtistNamesFromIds(artist_collaborators_id);
        setArtistState((prevState) => [...prevState, ...artistNames]);
      }

      // Add directly provided artist names
      if (artist_collaborators_name && artist_collaborators_name.length > 0) {
        setArtistState((prevState) => [
          ...prevState,
          ...artist_collaborators_name,
        ]);
      }

      // Fetch and set label names
      if (label_collaborators_id && label_collaborators_id.length > 0) {
        const labelNames = fetchLabelNamesFromIds(label_collaborators_id);
        setLabelState((prevState) => [...prevState, ...labelNames]);
      }

      // Add directly provided label names
      if (label_collaborators_name && label_collaborators_name.length > 0) {
        setLabelState((prevState) => [...prevState, ...label_collaborators_name]);
      }
    }
  }, []);

  React.useEffect(() => {
    setFormData((prev) => ({ ...prev, genres: genreData }));
  }, [genreData]);

  React.useEffect(() => {
    if (artistState.length > 0) {
      const matchedIds: string[] = [];
      const unmatchedNames: string[] = [];

      artistState.forEach(artist => {
        const matchedUser = AlbumsContext.ArtistUsers.find((user: any) => user.name === artist);
        if (matchedUser) {
          matchedIds.push(matchedUser._id);
        } else {
          unmatchedNames.push(artist);
        }
      });

      setArtistCollaboratorId(matchedIds);
      setArtistCollaboratorName(unmatchedNames);
    } else {
      setArtistCollaboratorId([]);
      setArtistCollaboratorName([]);
    }
  }, [artistState]);

  React.useEffect(() => {
    if (labelState.length > 0) {
      const matchedIds: string[] = [];
      const unmatchedNames: string[] = [];

      labelState.forEach(label => {
        const matchedUser = AlbumsContext.LabelUsers.find((user: any) => user.name === label);
        if (matchedUser) {
          matchedIds.push(matchedUser._id);
        } else {
          unmatchedNames.push(label);
        }
      });

      setLabelCollaboratorId(matchedIds);
      setLabelCollaboratorName(unmatchedNames);
    } else {
      setLabelCollaboratorId([]);
      setLabelCollaboratorName([]);
    }
  }, [labelState]);

  let formattedReleaseDate;
  if (typeof formData.release_date === 'number' && !Number.isNaN(formData.release_date)) {
    const currentDate = new Date(1900, 0, 1);
    const targetDate = new Date(currentDate);
    targetDate.setDate(currentDate.getDate() + formData.release_date + 1);
    formattedReleaseDate = targetDate.toISOString().split('T')[0];
  } else if (formData.release_date) {
    formattedReleaseDate = formData.release_date;
  } else {
    formattedReleaseDate = ""
  }

  const disabledStatus =
    formData.name === "" ||
    formData.price === "" ||
    artistState.length === 0 ||
    formattedReleaseDate === '' ||
    formData.release_date === '' ||
    formData.genres.length === 0;


  return (
    <React.Fragment>
      <Grid container>
        {/* Form container */}
        <Grid container item xs={12} gap='20px' justifyContent={'center'}>
          <Grid
            item
            xs={12}
            sm={'auto'}
            md={'auto'}
            direction={['row', 'row', 'column']}
            gap={'20px'}
            container
            justifyContent={'center'}
            height='max-content'
          >
            <ImageTextFieldContainer
              data={props.formDataState.album_cover_image}
              Datakey={'trackImageFile'}
              editIcon={false}
              fileData={fileData}
              file={file}
              imagePreview={imagePreview}
              setFileData={setFileData}
              setFile={setFile}
              setImagePreview={setImagePreview}
              placeholderCondition={!imagePreview}
              placeholderNode={
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#08090C',
                    borderRadius: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <UploadIcon />
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#ffffff',
                      fontSize: '0.8rem',
                      fontWeight: 500,
                      marginLeft: '0.5rem',
                    }}
                  >
                    UPLOAD ALBUM ART
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#A3A3A3',
                      fontSize: '10px',
                      fontWeight: 400,
                      marginLeft: '0.5rem',
                    }}
                  >
                    1400X1400 pixels minimum.
                    <br />
                    .jpg .png only. Max 10Mb
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              name='visibility'
              onChange={handleChange}
              control={
                <Checkbox
                  icon={
                    <CheckedIcon
                      checked={
                        formData.visibility == TrackPublishType.PUBLIC
                      }
                    />
                  }
                  checkedIcon={
                    <CheckedIcon
                      checked={
                        formData.visibility == TrackPublishType.PUBLIC
                      }
                    />
                  }
                />
              }
              label='Public'
            />
            {formData.visibility == TrackPublishType.PUBLIC && (
            <FormControlLabel
              name='mark_all_songs_public'
              onChange={handleChange}
              control={
                <Checkbox
                  icon={
                    <CheckedIcon
                      checked={
                        formData.mark_all_songs_public == true
                      }
                    />
                  }
                  checkedIcon={
                    <CheckedIcon
                      checked={
                        formData.mark_all_songs_public == true
                      }
                    />
                  }
                />
              }
              label='Mark all as Public'
            />)}
          </Grid>

          {/* Form fields container */}
          <Grid
            item
            container
            xs={'auto'}
            direction='column'
            width={['100%', '100%', 'unset']}
          >
            <DarkInputWithLabel
              label={<span>NAME <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              value={formData.name}
              name={'name'}
              type={'text'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />

            <DarkInputWithLabel
              label={<span>PRICE <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              value={formData.price}
              placeholder={'0.00'}
              onWheel={(e) => (e.target as any).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon sx={{ color: '#C5CFDB', height: '1.05em' }}>
                      <AttachMoney />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                min: 0,
              }}
              name={'price'}
              type={'number'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />
            <DarkInputWithLabel
              label={'CATALOG NUMBER'}
              value={formData.catalog_number}
              name={'catalog_number'}
              type={'text'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />

            <ApiReqAutoCompleteInput
              multipleValue={true}
              key={'genre'}
              addNewOption={true}
              inputName={'genre'}
              setState={setGenreData}
              state={genreData}
              label={<span>EDIT GENRE / MOOD <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              type={"GENRE"}
            />
          </Grid>
          <Grid
            item
            container
            xs={'auto'}
            direction='column'
            width={['100%', '100%', 'unset']}
          >
            {/* <Grid item container> */}

            <ApiReqAutoComplete
              multipleValue={true}
              key={'label_collaborators_name'}
              addNewOption={true}
              inputName={'label_collaborators_name'}
              setSelectedIds={setLabelIds}
              selectedIds={labelIds}
              setNames={setLabelNames}
              names={labelNames}
              state={labelState}
              setState={setLabelState}
              label={"LABEL"}
              type={"LABEL"}
            />

            <div>
              <style>
                {`
                  input[type="date"]::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                  }
                `}
              </style>
              <DarkInputWithLabel
                label={<span>RELEASE DATE <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
                type={'date'}
                name={'release_date'}
                sx={{ marginBottom: '1.25rem' }}
                onChange={handleChange}
                value={formattedReleaseDate}
              /></div>

            <DarkInputWithLabel
              label={'ISRC CODE'}
              value={formData.isrc_code}
              name={'isrc_code'}
              type={'text'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />

            <ApiReqAutoComplete
              multipleValue={true}
              key={'artist_collaborators_id'}
              addNewOption={true}
              inputName={'artist_collaborators_id'}
              setSelectedIds={setArtistNameData}
              selectedIds={artistNameData}
              setNames={setArtistNames}
              names={artistNames}
              state={artistState}
              setState={setArtistState}
              label={<span>ARTISTS <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              type={"ARTIST"}
            />
          </Grid>
        </Grid>

        {/* Save button container */}
        <Grid
          item
          container
          justifyContent={'space-between'}
          xs={12}
          gap={['20px', '20px', '0px']}
          flexWrap='nowrap'
          direction={['column', 'column', 'row']}
        >
          <Grid item container>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#FFBA35',
                fontSize: '12px',
              }}
            >
              Note: 600Mb max per song, lossless .wav, .aiff, .flac
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent={'flex-end'}
            gap={['20px', '20px', '44px']}
            flexWrap='nowrap'
            direction={['column', 'column', 'row']}
          >
            <DarkButtonWithIcon
              disabled={disabledStatus}
              sx={{
                width: 'max-content',
              }}
              onClick={handleEditAlbum}
            >
              {loading ? <CircularProgress size={15} style={{ marginRight: '8px' }} color="secondary" /> : null}SAVE
            </DarkButtonWithIcon>

            <LightBlurButtonWithCustomIcon
              CustomIcon={AddSquare}
              onClick={openDialog}
            >
              MANAGE SONGS
              <ManageSongDialog
                closeDialog={props.closeDialog}
                dialogOpen={dialogOpen}
                dialogTitle="MANAGE SONGS"
              >
                <ManageSong visible={true} albumId={formData.album_id} labelData={labelIds} />
              </ManageSongDialog>
            </LightBlurButtonWithCustomIcon>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditMusicForm;
