import React from 'react'
import CssBaseline from "@mui/material/CssBaseline";
import { AppBar, Toolbar, Box, Avatar, Icon, Grid, Collapse, AlertTitle } from "@mui/material";
import HeaderIconButton from "./HeaderIconButton";
import { useTheme } from "@mui/material/styles";
import { Close, MenuRounded } from '@mui/icons-material';
import {ReactComponent as ShoppingCart} from "assets/svg/shopping-cart.svg";
import EngageMusicOnly from "assets/images/EngageMusicOnly.png";
import { CustomizedAlert } from 'components/controls/Alert';
import { AlertContext as alertContext } from 'context/AlertContext';
import { AuthContext } from 'context/AuthContext';
// import menubarIcon from "assets/svg/Menubar.svg";
import profileAlt from "assets/svg/profileIcon.svg";
import { useNavigate } from 'react-router-dom';
import { AuthenticatedPath, UnauthenticatedPath } from 'utility/AppRoutingPath';
import PopperComponents from 'components/customisedComponents/PopperComponents';
import CartListing from 'components/other/CartListing';
import { DarkInput } from 'components/controls/inputFields';
import {ReactComponent as SearchIcon} from "assets/svg/search-normal.svg";
import { SearchContext } from 'context/SearchContext';
import SearchPopup from 'components/other/SearchPopup';
import { StyledBadge } from 'components/customisedComponents/StyledBadge';
import { MusicStoreContext } from 'context/MusicStoreContext';

interface IHeader {
  drawerOpenHandler: () => void;
  // openUserProfileHandler:()=>void;
}

const Header: React.FC<IHeader> = (props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openMusicStore, setOpenMusicStore] = React.useState(false);  
  const [searchPopupFlag, setSearchPopupFlag] = React.useState(false);

  const searchContext = React.useContext(SearchContext)!;
  const AlertInfo = React.useContext(alertContext)!;
  const authcontext = React.useContext(AuthContext)!;
  const store = React.useContext(MusicStoreContext)!;

  const nav = useNavigate()

  let profilePicVar = authcontext?.User?.profilePictureUrl ? authcontext?.User?.profilePictureUrl : profileAlt

  const handleOpenMusicStore = (event: React.MouseEvent<HTMLElement>) => {
    if(anchorEl == event.currentTarget){
      setOpenMusicStore(false)
    } else {
      setAnchorEl(event.currentTarget);
      setOpenMusicStore(true)
    }
  };

  const handleOpenSearchPopup = () => {
    if(searchPopupFlag){
      setSearchPopupFlag(false)
      setAnchorEl(null)
    } else {
      setAnchorEl(
        document.getElementById('SearchResults2') as HTMLElement
      )
      setSearchPopupFlag(true)
    }
  };

  const handleImageClick = () => {
    nav(UnauthenticatedPath.Homepage);
  };

  const ExpandableSearchInputJsx = (
    <>
      <DarkInput
        name='searchResult'
        id='searchPopper'
        onFocus={()=>{
          setAnchorEl(
            document.getElementById('SearchResults2') as HTMLElement
          )
          // setSearchPopupFlag(true)
        }}
        InputProps={{
          endAdornment: (
            <Icon
            onClick={(e) => {
              e.stopPropagation();
              // setOpenSearchInput(!openSearchInput)
              handleOpenSearchPopup()
            }}
              sx={{
                color: "#fff",
                cursor: "pointer",
                // width: "0.5em",
                // display: openSearchInput ? "inline-flex" : "none",
              }}
            >
              {searchPopupFlag ? <Close /> :
                <SearchIcon />}
            </Icon>
          ),
        }}
        autoComplete='off'
        fullWidth
        placeholder='Search'
        // focused
        // autoFocus
        value={searchContext?.query}
        onKeyUp={
          (e)=>{
            console.log(e)
            if(e.key == 'Enter'){
              // searchContext?.setQuery(e.currentTarget.value)
              nav(AuthenticatedPath.SearchListing)
            }
          }
        }
        onChange={(e)=>{
          searchContext?.setQuery(e.target.value)
          setSearchPopupFlag(true)
          if(e.target.value == ''){
            setSearchPopupFlag(false)
          }
        }}
        sx={{
          // display: openSearchInput ? "inline-flex" : "none",
          // mr:'-14px', // this is to make the input field align with the search icon
          // width: openSearchInput ? "300px" : "0%",
          "@keyframes expand": {
            "0%": {
              width: "0",
              display: "inline-flex",
            },
            "20%":{
              width: "60px",
            },
            "40%":{
              width: "120px",
            },
            "60%":{
              width: "180px",
            },
            "80%":{
              width: "240px",
            },
            "100%": {
              width: "300px",
            },
          },
          "@keyframes close": {
            "0%": {
              width: "300px",
            },
            "40%":{
              width: "240px",
            },
            "80%":{
              width: "120px",
            },
            "100%": {
              width: "0",
              display: "none",
            },
          },
          // animation: !openSearchInput ? "close 0.2s ease-in-out" :"expand 0.2s ease-in-out",
          // transition: "width 0.5s ease-in-out",
          border: "unset",
          "& .MuiInputBase-root": {
            // padding: "8px 7px",
            // mr: "-14px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 7px",
            // pr: "0px",
          },
        }}
      />
    </>
  );

  const id = 'transition-popper';
  const id2 = 'transition-popper2';

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position={AlertInfo?.ShowError?"relative":"fixed"}
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: '#08090c',
        }}
      >
        <Toolbar sx={{pb:'1rem !important'}}>
          <Grid
            container
            sx={{
              width: '100%',
            }}
          >
            <Grid item xs={2} container alignItems={'center'} color="customTheme.main" sx={{ cursor: 'pointer' }}>
              <HeaderIconButton clickHandler={props.drawerOpenHandler}>
                <Icon sx={{ height: '1.05em' }}>
                  {/* <img src={menubarIcon} alt ='menubar_icon'/> */}
                  <MenuRounded sx={{ fill: '#FA6B05' }} />
                </Icon>
              </HeaderIconButton>
            </Grid>
            <Grid item xs={8} container justifyContent='center' alignItems={'center'} color="customTheme.main" sx={{ cursor: 'pointer', background: `url(${EngageMusicOnly}) no-repeat center center`, backgroundSize:'contain', transform: 'scale(0.9)' }} onClick={handleImageClick}></Grid>
            <Grid onClick={(e)=>handleOpenMusicStore(e)} aria-describedby={id2} item xs={2} container justifyContent='flex-end' alignItems={'center'} sx={{ cursor: 'pointer', color: '#FA6B05' }}>
              <StyledBadge badgeContent={store.cart.length} variant='dot'>
                <ShoppingCart />
              </StyledBadge>
              </Grid>
            <Grid mt={2} id='SearchResults2' aria-describedby={id} overflow={'hidden'} item xs={12} container justifyContent='flex-end' alignItems={'center'} sx={{ cursor: 'pointer' }}>{ExpandableSearchInputJsx}</Grid>
          </Grid>
          
        </Toolbar>
        <Collapse sx={{ width: "100%",zIndex:(theme)=>theme.zIndex.tooltip }} in={AlertInfo?.ShowError}>
          {(
            <CustomizedAlert
              severity={AlertInfo.Severity}
              variant="filled"
            >
              <AlertTitle>{AlertInfo.Title}</AlertTitle>
              {AlertInfo.Message}
            </CustomizedAlert>
          )}
        </Collapse>
      </AppBar>

      <PopperComponents
        popperProps={{
          sx: {
            maxWidth: "300px",
            mt: "1rem",
            zIndex: 9999,
          },
        }}
        anchorEl={anchorEl}
        placement="bottom-end"
        id={id2}
        open={openMusicStore}
        setOpen={setOpenMusicStore}
        key={214}
      >
        <CartListing />
      </PopperComponents>

      <PopperComponents
        popperProps={{
          sx: {
            // maxWidth:'200px',
            mt: "0.5rem !important",
            zIndex: 9999,
            // transform:'unset !important',
            width: anchorEl ? anchorEl.clientWidth : undefined,
            "& .MuiBox-root": {
              p: 0,
              m: 0,
            },
          },
        }}
        anchorEl={anchorEl}
        placement="bottom-end"
        id={id}
        open={searchPopupFlag}
        setOpen={setSearchPopupFlag}
        key={535}
        IDforClickAway={"searchPopper"}
      >
        <SearchPopup />
      </PopperComponents>
    </React.Fragment>
  );
}

export default Header