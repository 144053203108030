import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import HttpTransferService from 'services/httptransfer';
import CircularProgress from '@mui/material/CircularProgress';
import { Chip, Grid, IconButton, InputAdornment, Typography, useTheme } from '@mui/material';
import { DarkInputWithLabel } from './inputFields';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

interface IApiReqAutoComplete {
  label: any;
  selectedIds: any;
  names: any;
  state: any;
  setSelectedIds: React.Dispatch<React.SetStateAction<any>>;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setNames: React.Dispatch<React.SetStateAction<any>>;
  inputName: string;
  addNewOption?: boolean;
  multipleValue?: boolean;
  // ApiReqFunc: any;
  type: any;
  setUnsavedChanges?: (value: boolean) => void;
}

const httptransfer = new HttpTransferService();

const filter = createFilterOptions<string>();

const ApiReqAutoComplete: React.FC<IApiReqAutoComplete> = ({ inputName, label, setSelectedIds, selectedIds, setNames, names, state, setState, addNewOption, multipleValue, type, setUnsavedChanges }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly string[]>([]);
  const [isEmptyResponse, setIsEmptyResponse] = React.useState(false);
  const loading = open && options.length === 0 && !isEmptyResponse;
  const theme = useTheme();

  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;


  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const successCb = (response: any) => {
      if (type == "LABEL" || type == "ARTIST") {
        setNames(response.data.users)
        if (active) {
          const filteredArray = response.data.users
            .filter((item: any) => item.type === type)
            .map((item: any) => ({ name: item.name, value: item._id }));
          const nonSelectedOptions = filteredArray.filter((option: any) => !state.includes(option.name));
          setOptions(nonSelectedOptions);
          setIsEmptyResponse(nonSelectedOptions.length === 0);

        }
      } else if (type == "ALBUM") {
        if (active) {
          const filteredArray = response.data.albums
            .map((item: any) => ({ name: item.name, value: item._id }));
          const nonSelectedOptions = filteredArray.filter((option: any) => !state.includes(option.name));
          setOptions(nonSelectedOptions);
          setIsEmptyResponse(nonSelectedOptions.length === 0);
        }
      }
    }

    const successLocationCb = (response: any) => {
      if (active) {
        response.data.map((item: any) => setOptions((prev) => prev.concat(item.name)))
      }
    }

    const errorCb = (error: any) => {
      console.log(error)
    }

    (async () => {
      if (type == 'ARTIST' || type == "LABEL") {
        httptransfer.userQuery({ type: ["ARTIST", "LABEL"] }, successCb, errorCb)
      } else if (type == "ALBUM") {
        httptransfer.queryAlbum({ created_by: [userInfo._id] }, successCb, errorCb)
      } else if (type == "LOCATION") {
        httptransfer.getLocation(successLocationCb, errorCb)
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
  }, [state]);


  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      value={state}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      loading={loading}

      onChange={(event, newValue) => {
        if (newValue === null || newValue.length === 0) {
          setSelectedIds([]);
          setState([]);
          setUnsavedChanges && setUnsavedChanges(true)
          return;
        }

        if (multipleValue) {
          const selectedOptions = newValue.map((value: any) =>
            typeof value === 'string' ? { value, name: value } : value
          );

          const selectedIds = newValue
            .filter((selectedOption: any) => selectedOption.value)
            .map((selectedOption: any) => selectedOption.value);

          const selectedNames = selectedOptions.map((selectedOption: any) => selectedOption.name);

          setSelectedIds((prevSelectedIds: any) => [...prevSelectedIds, ...selectedIds]);
          setState(selectedNames);
          setUnsavedChanges && setUnsavedChanges(true)
        } else {
          setState(newValue.name);
          setSelectedIds([newValue.value])
          setUnsavedChanges && setUnsavedChanges(true)
        }
      }}

      multiple={multipleValue ? true : false}

      filterOptions={addNewOption ? (options, params) => {
        const inputValue = params.inputValue.toLowerCase();
        if (type === "ALBUM") {
          return options;
        } else {
          const filtered = options.filter((option) => {
            const optionName = option.name.toLowerCase();
            return optionName.includes(inputValue);
          });

          if (params.inputValue !== '') {
            const isExactMatch = options.some((option) => option.name.toLowerCase() === inputValue);
            if (!isExactMatch) {
              filtered.push({
                value: params.inputValue,
                name: params.inputValue,
              });
            }
          }

          return filtered;
        }
      } : undefined}

      renderInput={(params) => (
        <DarkInputWithLabel
          {...params}
          inputProps={{
            ...params.inputProps,
            name: inputName,
          }}
          label={label}
          name={inputName}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> :
                  <InputAdornment position="end">
                    <IconButton disableFocusRipple disableRipple disableTouchRipple>
                      {/* <Search sx={{ color: "#ffffff" }} /> */}
                    </IconButton>
                  </InputAdornment>
                }
              </React.Fragment>
            ),
          }}
          // type={"text"}
          // placeholder={""}
          sx={{
            marginBottom: "1.25rem",
            color: "#ffffff",
            maxWidth: '244px',
            [theme.breakpoints.down("md")]: {
              maxWidth: "100%",
            },
          }}
        />
      )}

      loadingText={<Typography color='white'>Loading...</Typography>}

      renderTags={(value, getTagProps) =>
        value.map((option: any, index: any) => {
          return (
            <Chip
              size="small"
              label={option}
              {...getTagProps({ index })}
              sx={{
                mr: "0.5rem",
                mb: "0.5rem",
                backgroundColor: "#ffffff1f",
                color: "white",
                height: '24px',
                "& span": {
                  px: "1rem",
                },
                [theme.breakpoints.only("xs")]: {
                  height: '17px',
                  "& span": {
                    px: "0.5rem",
                  },
                  fontSize: "10px",
                },
              }}
            />
          )
        })
      }
      renderOption={(props, option, inputState) => {
        const matches = match(option.name, inputState.inputValue, { insideWords: true });
        const parts = parse(option.name, matches);

        return (
          <React.Fragment key={props.id}>
            <li {...props} >
              <Grid container justifyContent={'space-between'}>
                <div>
                  {parts.map((part: any, index: any) => (
                    <span
                      className="optionLetter"
                      key={index}
                      style={{ color: part.highlight ? "#3276FB" : "#fff" }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </Grid>
            </li>
          </React.Fragment>
        );
      }}
      componentsProps={
        {
          popper: {
            sx: {
              transition: "unset",
              zIndex: (theme) => theme.zIndex.tooltip,
              "& .MuiPaper-root": {
                color: "#ffffff",
                background:
                  "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
                border: "1px solid #636B82",
                backdropFilter: "blur(100px)",
                borderRadius: "12px",
                // top: "388px !important",
                maxHeight: "200px",
                "& .MuiMenuItem-root": {
                  fontWeight: 600,
                  padding: "0.8rem 1.25rem",
                },
                margin: 0,
                p: 0,
                "& ul": {
                  padding: 0,
                  maxHeight: '190px',
                }
              },
              "& .optionLetter": {
                fontWeight: 600,
              },
              "& .MuiAutocomplete-noOptions": {
                color: "white"
              }
            },
            placement: "bottom-start",
          },
        }
      }
      sx={{
        "& .MuiOutlinedInput-root": {
          padding: "9px !important",
        }
      }}
    />
  );
}

export default ApiReqAutoComplete;