export default {
    LOGIN_USER: "api/user/login",
    SIGNUP_USER: "api/user/register",
    CREATE_ALBUM: "api/album",
    UPLOAD_FILE: "api/file/upload",
    USER_QUERY: "api/user/query",
    ALBUM_QUERY: "api/album/query",
    UPLOAD_MUSIC: "api/music",
    MUSIC_QUERY: "api/music/query",
    COUNTRY_QUERY: "api/country",
    SEND_OTP: "api/auth/sendOTP",
    RESET_PASSWORD: "api/auth/resetPassword",
    VERIFY_OTP: "api/auth/verifyOTP",
    CREATE_PLAYLIST: "api/playlist",
    PLAYLIST_QUERY: "api/playlist/query",
    CART_QUERY: "api/cart/query",
    CART_ADD_REMOVE: "api/cart",
    PAYMENT: "api/payment",
    PURCHASED_TRACKS: "api/user/purchasedtracks",
    GOOGLE_CLIENT_ID: "612500827406-gnl0lt8sd25g5vvp1pduigjh8bd2sb6l.apps.googleusercontent.com"  
}
