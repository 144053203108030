import { Avatar, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'

type Props = {
  statisData: any;
}

const ProfileMiscellaneousView = ({statisData}: Props) => {


  const similarInfoGridJsx = (item: any, index: any) => <React.Fragment key={index}>
    <Grid container gap={2} mb={4}>
      <Grid item xs={12}>
        <Typography fontWeight={700}>{item.title}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          {item.data.map((item: any, index: any) => (
            <Grid key={index} item xs={6} sm={3} md={3} lg={3} p={1} pl={0}>
              <Avatar
                src={item.image}
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                }}
              ></Avatar>
              {/* skeleton for avatar placeholder */}
                {/* <Skeleton
                  variant="rounded"
                  width={'100%'}
                  animation={false}
                  height={'100px'}
                  sx={{
                    bgcolor: 'grey.900',
                    borderRadius: "24px",
                  }}
                /> */}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </React.Fragment>

  return (
    <React.Fragment>
      {statisData.map((item: any, index: any) => similarInfoGridJsx(item, index))}
    </React.Fragment>
  )
}

export default ProfileMiscellaneousView