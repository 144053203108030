import { Grid } from "@mui/material";
import NavbarLayout from "components/layout/NavbarWrapper";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import { AuthContext } from "context/AuthContext";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UnauthenticatedPath } from "utility/AppRoutingPath";

type Props = {};

const AuthPage = (props: Props) => {
  const location = useLocation();
  const nav = useNavigate();

  const context = React.useContext(AuthContext);

  useEffect(() => {
    if (context?.isAuthenticated()) {
      nav(UnauthenticatedPath.Homepage);
      console.log(context?.isAuthenticated())
    } else {
      if (location.pathname === "/login") {
        document.title = "Login";
      } else {
        document.title = "Sign Up";
      }
    }

  }, []);

  return (
    <WaveBackgroundLayout twoWave={false} oneWave={false}>

      <Grid
        sx={{
          height: "auto",
          minHeight: "100vh",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          flexWrap: "wrap"
        }}
      >
        <Outlet />
      </Grid>
    </WaveBackgroundLayout>
  );
};

export default AuthPage;
