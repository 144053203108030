import { Box, Grid, Typography, Avatar } from "@mui/material";
import { DarkButtonWithIcon } from "components/controls/Buttons";
import React from "react";
import { ReactComponent as JoinTheCommunity } from "assets/svg/JoinTheCommunity.svg";
import { AuthContext } from "context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  AuthenticatedPath,
  UnauthenticatedPath,
  getProfilePath,
} from "utility/AppRoutingPath";
import { Info } from "@mui/icons-material";
import PopperComponents from "components/customisedComponents/PopperComponents";
import HttpTransferService from "services/httptransfer";
import MusicTable from "./MusicTable";
import ConcertImage from "assets/images/landing-engage.png";
import LandingLogo from "assets/images/landing-logo.png";

import Marquee from "react-fast-marquee";

import Neighbourhood from "assets/images/landing-carousel-users/Neighbourhood.png";
import Inveterate from "assets/images/landing-carousel-users/Inveterate.png";
import KrunkKulture from "assets/images/landing-carousel-users/Krunk Kulture.jpg";
import FeverAm from "assets/images/landing-carousel-users/Fever Am.jpg";
import Observant from "assets/images/landing-carousel-users/Observant.jpg";
import Ox7gen from "assets/images/landing-carousel-users/Ox7gen.jpg";
import FarhanRehman from "assets/images/landing-carousel-users/Farhan Rehman.jpeg";
import Matrixxman from "assets/images/landing-carousel-users/Matrixxman.jpeg";
import YungDoof from "assets/images/landing-carousel-users/Yung Doof.jpeg";
import Zequenx from "assets/images/landing-carousel-users/Zequenx.png";
import Dstm from "assets/images/landing-carousel-users/Dstm.png";
import KateJo from "assets/images/landing-carousel-users/Kate Jo.jpg";
import QillaRecords from "assets/images/landing-carousel-users/Qilla Records.jpeg";
import SoupherbRecords from "assets/images/landing-carousel-users/Soupherb Records.jpg";
import Asymetrik from "assets/images/landing-carousel-users/Asymetrik.png";
import Kollision from "assets/images/landing-carousel-users/Kollision.jpg";
import PssshRecords from "assets/images/landing-carousel-users/Dauwd.jpg";
import Dauwd from "assets/images/landing-carousel-users/Dauwd.jpg";
import AntarikshRecords from "assets/images/landing-carousel-users/Antariksh Records.jpg";
import KabeerArora from "assets/images/landing-carousel-users/KabeerArora.jpeg";
import PoojaB from "assets/images/landing-carousel-users/PoojaB.jpeg";

import { ReactComponent as DirectToFan } from "assets/svg/landing/direct-to-fan.svg";
import { ReactComponent as OwnToListen } from "assets/svg/landing/own-to-listen.svg";
import { ReactComponent as FanDiscovery } from "assets/svg/landing/fan-discovery.svg";
import { ReactComponent as SplitPayments } from "assets/svg/landing/split-payments.svg";
import { ReactComponent as EngageWithCommunity } from "assets/svg/landing/engage-with.svg";
import { ReactComponent as UnifiedPlatform } from "assets/svg/landing/unified-platform.svg";
import { ReactComponent as CuratedPlaylists } from "assets/svg/landing/curated-playlists.svg";
import { ReactComponent as ExclusiveMusic } from "assets/svg/landing/exclusive-music.svg";
import { ReactComponent as MainLogo } from "assets/svg/logo-main.svg";

import {
  WaveRightBox,
  WaveLeftBox,
  WaveBottomBox,
} from "components/layout/WaveBox";

type Props = {};

const httptransfer = new HttpTransferService();

const carouselData = [
  {
    name: "Neighbourhood",
    image: Neighbourhood,
    profile_unique_id: "Neighbourhood",
  },
  {
    name: "Inveterate",
    image: Inveterate,
    profile_unique_id: "Inveterate",
  },
  {
    name: "Krunk Kulture",
    image: KrunkKulture,
    profile_unique_id: "Krunk_Kulture",
  },
  {
    name: "Fever Am",
    image: FeverAm,
    profile_unique_id: "feveram",
  },
  {
    name: "Observant",
    image: Observant,
    profile_unique_id: "Observant",
  },
  {
    name: "Ox7gen",
    image: Ox7gen,
    profile_unique_id: "ox7gen",
  },
  {
    name: "Farhan Rehman",
    image: FarhanRehman,
    profile_unique_id: "FarhanRehman91",
  },
  {
    name: "Matrixxman",
    image: Matrixxman,
    profile_unique_id: "Matrixxman",
  },
  {
    name: "Yung Doof",
    image: YungDoof,
    profile_unique_id: "Yung_Doof",
  },
  {
    name: "Zequenx",
    image: Zequenx,
    profile_unique_id: "Zequenx",
  },
  {
    name: "Pooja B",
    image: PoojaB,
    profile_unique_id: "Pooja_B",
  },
  {
    name: "Dstm",
    image: Dstm,
    profile_unique_id: "DSTM",
  },
  {
    name: "Kate Jo",
    image: KateJo,
    profile_unique_id: "kejtjo77_f4c4",
  },
  {
    name: "Qilla Records",
    image: QillaRecords,
    profile_unique_id: "qillarecords",
  },
  {
    name: "Soupherb Records",
    image: SoupherbRecords,
    profile_unique_id: "Soupherb_Records",
  },
  {
    name: "Asymetrik",
    image: Asymetrik,
    profile_unique_id: "Asymetrik",
  },
  {
    name: "Kollision",
    image: Kollision,
    profile_unique_id: "kollision",
  },
  {
    name: "Psssh Records",
    image: PssshRecords,
    profile_unique_id: "Psssh__Records",
  },
  {
    name: "Dauwd",
    image: Dauwd,
    profile_unique_id: "Dauwd",
  },
  {
    name: "Antariksh Records",
    image: AntarikshRecords,
    profile_unique_id: "AntarikshRecords",
  },
  {
    name: "Kabeer Arora",
    image: KabeerArora,
    profile_unique_id: "kabeerarora",
  },
];

const shuffleArray = <T,>(array: T[]): T[] => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const HomepageHero = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [shuffledCarouselData] = React.useState(() =>
    shuffleArray(carouselData)
  );

  const handleOpenPopper = (event: any) => {
    if (anchorEl == event.currentTarget) {
      setOpenPopper(!openPopper);
    } else {
      setAnchorEl(event.currentTarget);
      setOpenPopper(true);
    }
  };

  const canBeOpen = Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;

  const authContext = React.useContext(AuthContext);
  const nav = useNavigate();

  const handleClick = () => {
    if (authContext?.isAuthenticated()) {
      const userInfoString = localStorage.getItem("userInfo");
      const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
      const profile_unique_id = userInfo?.username;
      const profilePath = getProfilePath(profile_unique_id);

      window.location.href = profilePath;
    } else {
      window.location.href = UnauthenticatedPath.Signup;
    }
  };

  const handleProfileClick = (profile_unique_id: string) => {
    const profilePath = getProfilePath(profile_unique_id);
    window.location.href = profilePath;
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        position="relative"
        height="100vh"
        style={{
          backgroundImage: `linear-gradient(rgba(4, 81, 171,1), rgba(4, 81, 171,1)), url(${ConcertImage}) `,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundBlendMode: "color",
        }}
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          xs={12}
          p="1rem"
        >
          <Box
            sx={{
              backgroundColor: "rgba(8, 38, 73, 1)",
              borderRadius: "50px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={MainLogo}
              sx={{
                width: "100%",
                height: "auto",
                maxWidth: ["350px", "480px"],
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: ["1.5rem", "2rem", "2.5rem", "3rem", "3.375em"],
              fontFamily: "'Archivo', sans-serif",
              fontWeight: "700",
              letterSpacing: "0.08em",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            FAIR PLAY, FAIR PAY
          </Typography>
          <Typography
            sx={{
              fontSize: ["0.8em", "1em", "1.2em", "1.3em", "1.375em"],
              fontFamily: "'Archivo', sans-serif",
              fontWeight: "400",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            A DIRECT-TO-FAN MARKETPLACE FOR INDIE MUSIC
          </Typography>
          {!authContext?.isAuthenticated() && (
            <DarkButtonWithIcon
              hideIcon={true}
              onClick={handleClick}
              sx={{
                width: ["150px", "160px", "180px", "190px", "200px"],
                height: ["46px", "50px", "56px", "60px", "66px"],
                marginTop: "2rem",
                padding: [".4rem", ".45rem", ".5rem", ".55rem", ".6rem"],
                borderRadius: "50px",
                backgroundColor: "#FA6B05",
                border: "1px solid #FA6B05",
                justifyContent: "center",
                fontFamily: "'Archivo', sans-serif",
                fontWeight: "700",
                fontSize: ["1rem", "1.1rem", "1.25rem", "1.35rem", "1.5rem"],
                "&:hover": {
                  backgroundColor: "#FA6B05",
                  opacity: 0.9,
                  border: "1px solid #FA6B05",
                },
              }}
            >
              Sign Up
            </DarkButtonWithIcon>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        container
        xs={12}
        sx={{
          position: "absolute",
          bottom: "1rem",
          // px: "1rem",
          overflowX: "hidden",
        }}
      >
        <Marquee
          gradient={false}
          speed={40}
          direction="left"
          pauseOnHover={true}
        >
          <Grid
            container
            spacing={2}
            wrap="nowrap"
            sx={{
              minWidth: "max-content",
              mr: 2,
            }}
          >
            {shuffledCarouselData.map((item, index) => (
              <Grid item key={index}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Box
                    component="button"
                    onClick={() => handleProfileClick(item.profile_unique_id)}
                    sx={{
                      width: ["120px", "150px", "180px", "180px", "180px"],
                      height: ["120px", "150px", "180px", "180px", "180px"],
                      borderRadius: "12px",
                      backgroundColor: "rgba(8, 38, 73, 0.8)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                    }}
                  >
                    <Avatar
                      src={item.image}
                      alt={item.name}
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                        "& img": {
                          objectFit: "cover",
                        },
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: ["0.9rem", "1rem", "1.1rem", "1.2rem"],
                      fontFamily: "'Archivo', sans-serif",
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Marquee>
      </Grid>

      {/* MUSIC TABLE */}
      <Grid item container xs={12} px="1rem">
        <Typography
          sx={{
            color: "#ffffff",
            fontSize: "1.5rem",
            fontWeight: 400,
            mt: "2rem",
            fontFamily: "'Archivo', sans-serif",
          }}
        >
          Recently added songs
        </Typography>

        <Grid
          item
          container
          xs={12}
          sx={{ overflow: "hidden", mt: "1rem", zIndex: 99 }}
        >
          <MusicTable />
        </Grid>
      </Grid>

      {/* LAST */}
      <Grid
        item
        container
        xs={12}
        direction="column"
        spacing={4}
        mt={4}
        pb={10}
        px={[2, 4, 8, 16, 22]}
        position="relative"
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <WaveBottomBox />
        </Box>
        <Grid item>
          <Typography
            sx={{
              color: "#FF6633",
              fontSize: ["1.5rem", "1.75rem", "2rem"],
              fontWeight: 700,
              fontFamily: "'Archivo', sans-serif",
              textAlign: "center",
            }}
          >
            Discover the benefits of Engage
          </Typography>
        </Grid>

        <Grid
          item
          container
          spacing={[2, 4]}
          direction={{ xs: "column", lg: "row" }}
        >
          <Grid item xs={12} lg={6}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: ["1.25rem", "1.5rem"],
                fontWeight: 600,
                fontFamily: "'Archivo', sans-serif",
                mb: 2,
                textAlign: "center",
              }}
            >
              For Artists
            </Typography>
            <Grid container direction="column" spacing={2} height="100%">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={DirectToFan}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Direct to fan music store
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={OwnToListen}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Own to Listen
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={FanDiscovery}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Fan Discovery
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={SplitPayments}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Split Payments
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography
              sx={{
                color: "#fff",
                fontSize: ["1.25rem", "1.5rem"],
                fontWeight: 600,
                fontFamily: "'Archivo', sans-serif",
                mb: 2,
                textAlign: "center",
              }}
            >
              For Fans
            </Typography>
            <Grid container direction="column" spacing={2} height="100%">
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={ExclusiveMusic}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Exclusive Music
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={UnifiedPlatform}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Unified platform - Event Listing, Music Streaming & Store
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={CuratedPlaylists}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Curated Playlists from your favorite artists
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    backgroundColor: "rgba(49, 57, 76, 0.75)",
                    borderRadius: "50px",
                    p: 2,
                    px: [2, 4, 6],
                    display: "flex",
                    alignItems: "center",
                    gap: [2, 4, 6],
                    minHeight: "124px",
                  }}
                >
                  <Box
                    component={EngageWithCommunity}
                    sx={{ width: 80, height: 80, flexShrink: 0, zIndex: 9 }}
                  />
                  <Typography
                    color="#FF6633"
                    fontSize={["1.1rem", "1.3rem", "1.5rem"]}
                    fontWeight={700}
                    fontFamily="'Archivo', sans-serif"
                    zIndex={9}
                  >
                    Engage with the Music Community
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HomepageHero;
