import { Box, Grid, LinearProgress, Typography, useTheme } from '@mui/material'
import React from 'react'

type Props = {
  progressBar: {
    trackName: string,
    percent: number
    id: string
  }[];
  waitingCondition: boolean;
}

const ProgressBarLoadingScreen = ({ progressBar, waitingCondition }: Props) => {
  const theme = useTheme()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {
        waitingCondition && (
          <>
            <Grid item container gap='40px' mb='10px' alignItems={'center'} justifyContent='center'>
              <Typography
                variant="body2"
                sx={{ color: "white", textAlign: "left" }}
              >
                Getting Things Ready
              </Typography>
              <div className='dot-pulse' />
            </Grid>
          </>)
      }
      {(progressBar.map((item, index) => item.trackName !== '' && (

        <Grid mt={waitingCondition ? '10px':''} item container sx={{ fontSize: '16px' }} key={item.id} mb='24px'>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ color: "white", marginBottom: "10px", textAlign: "left" }}
            >
              {item.trackName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body2"
              sx={{ color: "white", marginBottom: "10px", textAlign: "right", fontWeight: 700 }}
            >
              {item.percent}%
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <LinearProgress
              sx={{
                width: "100%",
                minWidth: "500px",
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100%",
                },
                height: ["16px", "32px"],
                borderRadius: "4px",
                background: "#08090C",
                "& .MuiLinearProgress-determinate": { background: "#FA6B05" },
                "& .MuiLinearProgress-barColorPrimary": { background: "#FA6B05" },
              }}
              variant="determinate"
              value={item.percent}
            /></Grid>
        </Grid>
      )))}

      <Grid item container justifyContent={"space-between"} xs={12} gap={['20px', '20px', '0px']} flexWrap='nowrap' direction={['column', 'column', 'row']}>
        {/* <Grid item container>
          <Typography
            sx={{
              textAlign: "center",
              color: "#FFBA35",
              fontSize: "12px"
            }}
          >
            Note:  600Mb max per song, lossless .wav, .aiff, .flac
          </Typography>
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default ProgressBarLoadingScreen