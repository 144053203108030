import { IconButton } from '@mui/material'
import React, { ReactNode } from 'react';

interface IHeaderIconButton{
    children: ReactNode;
    clickHandler:()=>void
}

const HeaderIconButton:React.FC<IHeaderIconButton> = (props) => {
  return (
    <IconButton
      size="small"
      aria-label="open drawer"
      onClick={props.clickHandler}
      edge="start"
      sx={{
        backgroundColor: "#inherit",
        "&:hover": {
          background: "#inherit"/*"deepPurple[700]"*/,
        },
      }}
    >
      {props.children}
    </IconButton>
  );
}

export default HeaderIconButton