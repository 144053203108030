import { Routes, Route } from "react-router-dom";
// import Home from "./pages/Authenticated/User/Home";
// import Login from "pages/Unauthenticated/Login";
import {
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Grid,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import AuthenticatedRoute from "utility/AuthenticatedRoute";
import ProcessbarStatusTracker from "utility/ProcessbarStatusTracker";
import {
  AuthenticatedPath,
  UnauthenticatedPath,
  getProfilePath,
} from "utility/AppRoutingPath";
// import DeviceProvisioning from "pages/Authenticated/Technician/DeviceProvisioning";
import "./App.css";
import LandingPage from "pages/Unauthenticated/LandingPage";
import AuthPage from "pages/Unauthenticated/AuthPage";
import Login from "components/other/Login";
import Signup from "components/other/Signup";
import AboutusPage from "pages/Unauthenticated/AboutusPage";
import NavbarLayout from "components/layout/NavbarWrapper";
import FooterLayout from "components/layout/FooterLayout";
import ContactUsPage from "pages/Unauthenticated/ContactUsPage";
import PrivacyPolicy from "pages/Unauthenticated/PrivacyPolicy";
import TermsCondition from "pages/Unauthenticated/TermsConditions";
import Copyrights from "pages/Unauthenticated/Copyright";
import ArtistProfile from "pages/Authenticated/Artist/ArtistProfile";
import ForgetPassword from "components/other/ForgetPassword";
import ProfileWrapper from "pages/Authenticated/ProfileWrapper";
import PlayListWrapper from "components/playlist/PlayListWrapper";
import WaveSurferWrapper from "components/other/WaveSurferWrapper";
import { MusicPlayerContext } from "context/MusicPlayerContext";
import CheckoutStatus from "pages/Authenticated/CheckoutStatus";
import SearchListing from "pages/Authenticated/SearchListing";
import Discover from "pages/Authenticated/Discover";

let appIsActive: boolean = true;
const App: React.FC = () => {
  // const mqttClientContext = React.useContext(MqttClientContext);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const processbarStatusTracker = ProcessbarStatusTracker.getInstance();
  window.SetProgressbarVisibility = setShowBackdrop;
  const [showOnlineAlert, setShowOnlineAlert] = React.useState(false);

  const musicPlayerState = React.useContext(MusicPlayerContext)!;

  window.CallInProgressbar = (fn: () => void) => {
    processbarStatusTracker.Increase();
    fn && fn();
    processbarStatusTracker.Decrease();
  };

  window.AsyncCallInProgressbar = async (fn: () => Promise<void>) => {
    processbarStatusTracker.Increase();
    fn && (await fn());
    processbarStatusTracker.Decrease();
  };

  window.backButtonHandler = (fn: () => void) => {
    return () => {
      if (window.ProgressbarCount == 0 && window.isMessageDialogOpen == false) {
        fn();
      }
    };
  };

  window.AsyncBackButtonHandler = async (fn: () => Promise<void>) => {
    return async () => {
      if (window.ProgressbarCount == 0 && window.isMessageDialogOpen == false) {
        await fn();
      }
    };
  };

  const url = window.location.href;
  const path = new URL(url).pathname;

  // Check if the path already contains "/profile"
  let profilePath: any;
  if (path.includes("/profile")) {
    const profileUniqueId = path.split("/")[2] || null;
    profilePath = getProfilePath(profileUniqueId);
  }

  return (
    <Box
      sx={{
        paddingBottom: musicPlayerState.openPlayerFlag ? "60px" : "0px",
      }}
    >
      <NavbarLayout />
      <Routes>
        <Route>
          {/* Authenticated Route should be here only */}
          {profilePath === "/" ? (
            <Route path="/" element={<LandingPage />} />
          ) : (
            <Route path={profilePath} element={<ProfileWrapper />} />
          )}
          <Route path="profile/:username" element={<ProfileWrapper />} />
          <Route path="playlist/:playlistId" element={<PlayListWrapper />} />
          <Route
            path={AuthenticatedPath.SearchListing}
            element={<SearchListing />}
          />
          <Route path={AuthenticatedPath.Discover} element={<Discover />} />
          <Route
            path={AuthenticatedPath.CheckoutSuccess}
            element={<CheckoutStatus status />}
          />
          <Route
            path={AuthenticatedPath.CheckoutFailure}
            element={<CheckoutStatus status={false} />}
          />

          {/* <Route
              path={AuthenticatedPath.DeviceProvisioning}
              element={<DeviceProvisioning />}
            /> */}
        </Route>
        {/* Unauthenticated Route should be here only */}
        <Route element={<AuthPage />}>
          <Route path={UnauthenticatedPath.Login} element={<Login />} />
          <Route path={UnauthenticatedPath.Signup} element={<Signup />} />
        </Route>

        <Route
          path={UnauthenticatedPath.ForgetPassword}
          element={<ForgetPassword />}
        />
        <Route path={UnauthenticatedPath.Homepage} element={<LandingPage />} />
        <Route path={UnauthenticatedPath.Aboutus} element={<AboutusPage />} />
        <Route
          path={UnauthenticatedPath.Contactus}
          element={<ContactUsPage />}
        />
        <Route
          path={UnauthenticatedPath.PrivacyPolicy}
          element={<PrivacyPolicy />}
        />
        <Route
          path={UnauthenticatedPath.TermsConditions}
          element={<TermsCondition />}
        />
        <Route path={UnauthenticatedPath.Copyright} element={<Copyrights />} />
      </Routes>

      <Fade in={musicPlayerState.openPlayerFlag}>
        <Box>
          <WaveSurferWrapper />
        </Box>
      </Fade>

      {!musicPlayerState.openPlayerFlag && <FooterLayout />}
    </Box>
  );
};

export default App;
