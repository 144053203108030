export const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const generateId = () => `${performance.now()}${Math.random().toString(16).slice(5)}`.replace(".", "");

export const runFuncOnEnter = (e: any, cb: Function, disableCondition: boolean) => {
    if (disableCondition && (e.key === 'Enter' || e.key === 'NumpadEnter' || e.code === 'Enter' || e.code === 'NumpadEnter' || e.keyCode === 13)) {
        cb()
    } else {
        // console.log(mobileNumber, e)
    }
}

export const getMatchingIds = (inputArray: string[], referenceArray: any[]) => {
    const matchingIds: string[] = [];
    inputArray.forEach(item => {
        const matchingObject = referenceArray.find(obj => obj._id === item);
        if (matchingObject) {
            matchingIds.push(matchingObject._id);
        }
    });
    return matchingIds;
};

export const getNamesNotInDatabase = (inputArray: string[], referenceArray: any[]) => {
    const namesNotInDatabase: string[] = [];
    inputArray.forEach(item => {
        const matchingObject = referenceArray.find(obj => obj._id === item);
        if (!matchingObject) {
            namesNotInDatabase.push(item);
        }
    });
    return namesNotInDatabase;
};
