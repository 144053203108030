import { Box, styled } from "@mui/system";

export const TransparentRoundedWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  // color: "#fff",
  overflow: "hidden",
  position: "relative",
  borderRadius: "30px",
  height: "max-content",
  width: '100%',
  padding: '2rem 2rem',
  border: '2px #54a4ff solid'
}));
export const DarkGlassRoundedWapper = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
  border: "1px solid #636B82",
  backdropFilter: "blur(100px)",
  borderRadius: "24px",
  padding: "32px 20px",
}));

export const DarkBlueRoundedWapper = styled(Box)(({ theme }) => ({
  background: "#121520", 
  borderRadius: "12px",
  backdropFilter: "blur(100px)",
  padding: "1rem",
}));