export default class ProcessbarStatusTracker {
  private static instance: ProcessbarStatusTracker;

  public processbarRequestCount: number = 0;

  private constructor() {}

  private showProgressbar(){
      if(this.processbarRequestCount >0)
      {
          window.SetProgressbarVisibility(true)
      }
      else{
          window.SetProgressbarVisibility(false);
      }
      
    window.ProgressbarCount = this.processbarRequestCount;
  }

  public static getInstance(): ProcessbarStatusTracker {
    if (!ProcessbarStatusTracker.instance) {
      ProcessbarStatusTracker.instance = new ProcessbarStatusTracker();
    }

    return ProcessbarStatusTracker.instance;
  }

  public Increase(): void {
    this.processbarRequestCount++;
    this.showProgressbar();
  }

  public Decrease(): void {
      if (this.processbarRequestCount > 0)
      { 
          this.processbarRequestCount--;
      }
      this.showProgressbar();
  }
}