export enum AlbumPublishType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE"
  };
  
  export type AlbumModel = {
    name: string;
    price: any;
    genres: any;
    artist_collaborators_id: [],
    artist_collaborators_name: [],
    label_collaborators_id: [],
    label_collaborators_name: [],
    catalog_number: string,
    visibility: AlbumPublishType;
    isrc_code: string;
    mark_all_songs_public: boolean;
  };
  