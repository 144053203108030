import { Grid, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import Logo from 'assets/svg/LogoWhite.svg';
import { ReactComponent as Facebook } from 'assets/svg/facebook.svg';
import { ReactComponent as Whatsapp } from 'assets/svg/whatsapp.svg';
import { ReactComponent as YouTube } from 'assets/svg/youtube.svg';
import { ReactComponent as Apple } from 'assets/svg/apple.svg';
import { ReactComponent as GooglePlay } from 'assets/svg/google-play.svg';
import engageMusic from 'assets/images/engageMusic.png';
import engageMusicTM from 'assets/images/engageMusicTM.png';
import { DarkInput } from 'components/controls/inputFields';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import { useNavigate, useLocation } from 'react-router-dom';
import { UnauthenticatedPath } from 'utility/AppRoutingPath';
import { Instagram, Send, Twitter } from '@mui/icons-material';
import { useTheme } from "@mui/material/styles";
import {
  DarkButtonWithIcon,
  LightBlurButtonWithCustomIcon,
} from 'components/controls/Buttons';

type Props = {};

const FooterLayout = (props: Props) => {
  const location = useLocation();
  const nav = useNavigate();
  const theme = useTheme();

  const BelowSMBreakpoint = useMediaQuery(theme.breakpoints.down('sm')) as boolean;


  const footerItemsData = [
    {
      title: 'COMPANY',
      items: [
        {
          name: 'About Company',
          onClick: () => nav(UnauthenticatedPath.Aboutus),
        },
        // { name: "Customer Support", onClick: () => nav(UnauthenticatedPath.Homepage) },
        {
          name: 'Contact Us',
          onClick: () =>
            nav(UnauthenticatedPath.Contactus, {
              state: { scrollToFaq: false },
            }),
        },
        {
          name: "FAQ's",
          onClick: () =>
            nav(UnauthenticatedPath.Contactus, {
              state: { scrollToFaq: true },
            }),
        },
      ],
    },
    {
      title: 'LEGAL',
      items: [
        // {
        //   name: 'Terms & Conditions',
        //   onClick: () => nav(UnauthenticatedPath.Homepage),
        // },

        // { name: "Privacy Policy", onClick: () => {
        //   window.open("/assets/privacyPolicyPdf/PrivacyPolicy-engage.pdf","_blank")
        // } },
        {
          name: 'Terms & Conditions',
          onClick: () => nav(UnauthenticatedPath.TermsConditions),
        },
        {
          name: 'Privacy Policy',
          onClick: () => nav(UnauthenticatedPath.PrivacyPolicy),
        },
        {
          name: 'Content Guidelines',
          onClick: () => nav(UnauthenticatedPath.Copyright),
        },
        // {
        //   name: 'Report Copyright Issues',
        //   onClick: () => nav(UnauthenticatedPath.Homepage),
        // },
      ],
    },
    // {
    //   title: "ENGAGE OFFERINGS",
    //   items: [
    //     { name: "Music Store", onClick: () => nav(UnauthenticatedPath.Homepage) },
    //     { name: "Event Store", onClick: () => nav(UnauthenticatedPath.Homepage) },
    //     { name: "Purchases", onClick: () => nav(UnauthenticatedPath.Homepage) },
    //   ],
    // },
  ];

  if (location.pathname === '/login' || location.pathname === '/signup') {
    return <></>;
  }

  return (
    <Box
      sx={{
        padding: ['1.5rem 1rem', '1.5rem 3rem', '2.5rem 5rem'],
        backgroundColor: '#1E2332',
      }}
    >
      <Grid container gap={5}>
        <Grid item xs={12} container alignItems={'center'}>
          <Typography variant='h6' noWrap component='a' href='/' sx={{ mr: 2 }}>
            <img src={Logo} alt='' />
          </Typography>
          <Typography
            width={'calc(245px - 16.51px)'}
            display={['none', 'block']}
            variant='h6'
            noWrap
            component='a'
            href='/'
            sx={{ mr: 2, borderTopRightRadius: '10%' }}
          >
            <img src={engageMusicTM} alt='' />
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          justifyContent='space-between'
          gap={[4, 4, null]}
        >
          <Grid item xs={5} sm={5} md={'auto'}>
            <Typography
              variant='body1'
              sx={{ color: '#A8B3C1', fontWeight: 700 }}
            >
              STAY UP TO DATE
            </Typography>
            <Grid
              item
              xs={12}
              container
              justifyContent={'flex-start'}
              alignItems={'center'}
              sx={{
                mt: 2,
                width: '100%',
                mb: 4,
                color: '#FFFFFF',
              }}
            >
              <DarkInput
                focused={false}
                placeholder={BelowSMBreakpoint ? 'Enter Email' : 'Enter Email Id'}
                // value={userInfo.username}
                // onChange={(e: any) =>
                //   setUserInfo({ ...userInfo, username: e.target.value })
                // }
                sx={{
                  color: '#FFFFFF',
                  width: '80%',
                }}
                size='small'
              />
              <Send sx={{ ml: [0.5, 2] }} onClick={() => console.log('send')} />
            </Grid>
            <Typography
              variant='body1'
              sx={{ color: '#A8B3C1', fontWeight: 700 }}
            >
              GET IN TOUCH
            </Typography>
            <Grid container color='white' mt={2} gap={1} justifyContent=''>
              <Grid item xs={2} container alignItems={'center'}>
                <Facebook
                  onClick={() => {
                    window.open(
                      'https://www.facebook.com/Engagemusic.io',
                      '_blank'
                    );
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
              <Grid item xs={2} container alignItems={'center'}>
                <Twitter
                  onClick={() => {
                    window.open('https://twitter.com/engagemusic_io', '_blank');
                  }}
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>
              <Grid
                onClick={() => {
                  window.open(
                    'https://www.instagram.com/engagemusic_io/',
                    '_blank'
                  );
                }}
                sx={{ cursor: 'pointer' }}
                item
                xs={2}
                container
                alignItems={'center'}
              >
                <Instagram
                  onClick={() => {
                    window.open(
                      'https://www.instagram.com/engagemusic_io/',
                      '_blank'
                    );
                  }}
                  sx={{ cursor: 'pointer' }}
                />
              </Grid>

              {/* <Grid item xs={2} ><Whatsapp /></Grid> */}
              {/* <Grid item xs={2} ><YouTube /></Grid> */}
              {/* <Grid item xs={2} ><Apple /></Grid> */}
              {/* <Grid item xs={2} ><GooglePlay /></Grid> */}
            </Grid>
          </Grid>
          {footerItemsData.map((gridItem, key) => (
            <React.Fragment key={key}>
              <Grid item xs={5} sm={5} md={'auto'}>
                <Box sx={{ fontSize: '14px', color: '#A8B3C1' }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {gridItem.title}
                  </Typography>
                  {gridItem.items.map((item, key) => (
                    <Typography
                      key={key}
                      sx={{ mt: 1, cursor: 'pointer' }}
                      onClick={item.onClick}
                    >
                      {item.name}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterLayout;
