import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import HttpTransferService from "services/httptransfer";
import {
  GetAccessTokenKey,
  RemoveAccessTokenKey,
  SetAccessTokenKey,
  setKeyInLocalStorage,
} from "utility/AccessTokenManagement";
import { userType } from "model/userType";
import { AlertContext } from "./AlertContext";
import { severityType } from "model/errorSeverityTypeEnum";
import { UnauthenticatedPath, getProfilePath } from "utility/AppRoutingPath";
import { userModel } from "model/userModel";
import { GoogleLogin, useGoogleLogin, googleLogout } from "@react-oauth/google";

const httptransfer = new HttpTransferService();

interface IAuthContext {
  User: userModel | null;
  LogIn: (email: string, password: string) => void;
  SignUp: (
    name: string,
    username: string,
    email: string,
    password: string,
    type: string,
    payType: string,
    payValue: string,
    setFlowStep: (step: { uiStep: number }) => void
  ) => void;
  onGoogleSignUpSuccess: (
    googleCredentialResponse: string,
    ype: string
  ) => void;
  LogOut: () => void;
  SendEmailForOtp: (
    email: string,
    fnSuccessCallback: (res: any) => void,
    fnErrorCallback: (error: any) => void
  ) => void;
  VerifyOtp: (
    email: string,
    otp: string,
    fnSuccessCallback: (res: any) => void,
    fnErrorCallback: (error: any) => void
  ) => void;
  ResetPassword: (
    email: string,
    password: string,
    fnSuccessCallback: (res: any) => void,
    fnErrorCallback: (error: any) => void
  ) => void;
  isAuthenticated: () => boolean;
  UpdateUser: (
    id: any,
    data: any,
    username: string,
    fnSuccesHandler: () => void,
    fnErrorHandler: (error: any) => void
  ) => void;
  onGoogleLoginSuccess: (res: any) => void;
  onGoogleLoginFailure: (res: any) => void;
  onGoogleSignUpFailure: (res: any) => void;
  ShowCompleteSignup: {
    show: boolean;
    token: string;
  };
  users: any;
  setUsers: (user: any) => void;
  userTypeFromAPI: any;
  loading: boolean;
  Playlist: any;
  getUserProfile: () => Promise<void>;
  UpdatePayment: (
    id: string,
    data: any,
    username: string,
    fnSuccessHandler: () => void,
    fnErrorHandler: (error: any) => void
  ) => void;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const AuthContext = React.createContext<IAuthContext | null>(null);

const AuthContextProvider: React.FC<Props> = props => {
  const [user, setUser] = useState<userModel | null>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [playlist, setPlaylist] = useState<any[]>([]);
  const [userTypeFromAPI, setUserTypeFromAPI] = useState(null);
  const [signuploading, setSignupLoading] = useState<boolean>(false);

  const [showCompleteSignup, setShowCompleteSignup] = useState({
    show: false,
    token: "",
  });

  const alertContext = React.useContext(AlertContext);

  const nav = useNavigate();

  const signUp = async (
    name: string,
    username: string,
    email: string,
    password: string,
    type: string,
    payType: string,
    payValue: string,
    setFlowStep: (step: { uiStep: number }) => void
  ) => {
    const data = {
      name,
      username,
      email,
      password,
      type,
    };
    setSignupLoading(true);
    const signUpSuccessHandler = (res: any) => {
      setUser(res.data.user);
      setSignupLoading(false);
      let userData = res.data.user;
      localStorage.setItem("userInfo", JSON.stringify(userData));
      SetAccessTokenKey(res.data.token);
      nav(UnauthenticatedPath.Homepage);
      window.location.reload();
    };

    const signUpErrorHandler = (error: any) => {
      console.log(error);
      setSignupLoading(false);
      alertContext?.OpenError({
        title: "Signup Error",
        message: error.response.data.message,
        severity: severityType.error,
      });
      setFlowStep({ uiStep: 0 });
    };

    window.AsyncCallInProgressbar(() =>
      httptransfer.signupUser(data, signUpSuccessHandler, signUpErrorHandler)
    );
  };

  const LogIn = async (email: string, password: string) => {
    const LogInSuccessHandler = (res: any) => {
      setUser(res.data.user);
      let userData = res.data.user;
      localStorage.setItem("userInfo", JSON.stringify(userData));
      SetAccessTokenKey(res.data.token);
      nav(UnauthenticatedPath.Homepage);
      window.location.reload();
    };
    const LogInErrorHandler = (error: any) => {
      alertContext?.OpenError({
        title: "login Error",
        message: error.response.data.message,
        severity: severityType.error,
      });
    };

    window.AsyncCallInProgressbar(() =>
      httptransfer.userLogin(
        { email, password },
        LogInSuccessHandler,
        LogInErrorHandler
      )
    );
  };

  const signout = async () => {
    const signoutSuccessHandler = () => {
      setUser(null);
      RemoveAccessTokenKey();
      nav(UnauthenticatedPath.Homepage);
      googleLogout();
      window.location.reload();
    };

    const signoutErrorHandler = (error: any) => {};

    window.AsyncCallInProgressbar(
      async () =>
        await httptransfer.LogoutApi(signoutSuccessHandler, signoutErrorHandler)
    );
  };

  const sendEmailForOtp = async (
    email: string,
    fnSuccessCallback: (res: any) => void,
    fnErrorCallback: (error: any) => void
  ) => {
    //debugger;
    const sendEmailForOtpSuccessHandler = (res: any) => {
      console.log(res);
      fnSuccessCallback && fnSuccessCallback(res);
    };
    const sendEmailForOtpErrorHandler = (error: any) => {
      console.log(error);
      fnErrorCallback && fnErrorCallback(error);
    };

    window.AsyncCallInProgressbar(() =>
      httptransfer.sendOTP(
        { email: email },
        sendEmailForOtpSuccessHandler,
        sendEmailForOtpErrorHandler
      )
    );
  };

  const verifyOtp = async (
    email: string,
    otp: string,
    fnSuccessCallback: (res: any) => void,
    fnErrorCallback: (error: any) => void
  ) => {
    const verifyOtpSuccessHandler = (res: any) => {
      console.log(res);
      fnSuccessCallback && fnSuccessCallback(res);
    };
    const verifyOtpErrorHandler = (error: any) => {
      console.log(error);
      fnErrorCallback && fnErrorCallback(error);
    };
    const otpNumber = parseInt(otp, 10);
    let data = { email: email, OTP: otpNumber };
    window.AsyncCallInProgressbar(() =>
      httptransfer.verifyOTP(
        data,
        verifyOtpSuccessHandler,
        verifyOtpErrorHandler
      )
    );
  };

  const resetPassword = async (
    email: string,
    password: string,
    fnSuccessCallback: (res: any) => void,
    fnErrorCallback: (error: any) => void
  ) => {
    const resetPasswordSuccessHandler = (res: any) => {
      setUser(res.data.user);
      let userData = res.data.user;
      localStorage.setItem("userInfo", JSON.stringify(userData));
      SetAccessTokenKey(res.data.token);
      nav(UnauthenticatedPath.Homepage);
      fnSuccessCallback && fnSuccessCallback(res);
    };
    const resetPasswordErrorHandler = (error: any) => {
      console.log(error);
      fnErrorCallback && fnErrorCallback(error);
    };
    const data = {
      email: email,
      password: password,
    };

    window.AsyncCallInProgressbar(() =>
      httptransfer.resetPassword(
        data,
        resetPasswordSuccessHandler,
        resetPasswordErrorHandler
      )
    );
  };

  const updateUser = (
    id: string,
    data: any,
    username: string,
    fnSuccesHandler: () => void,
    fnErrorHandler: (err: any) => void
  ) => {
    localStorage.setItem("updatedUsername", username);
    const userSuccessHandler = (response: any) => {
      localStorage.setItem(
        "updatedUserImage",
        response.data.users[0].user_image.file_download_url
      );
      const userProfileUrl = getProfilePath(username);
      window.location.href = userProfileUrl;
    };
    const userErrorHandler = (error: any) => {
      console.log(error);
    };
    const updateUserSuccessHandler = (response: any) => {
      //let user: userModel;
      let user = response.data;
      // localStorage.setItem("userType", response.data.type);
      // setUser(user);
      fnSuccesHandler && fnSuccesHandler();
      alertContext?.OpenError({
        title: "PROFILE SUCCESSFULLY UPDATED!",
        message: "Your changes were successfully saved and updated",
        severity: severityType.success,
      });
      httptransfer.userQuery(
        { user_id: [id] },
        userSuccessHandler,
        userErrorHandler
      );
    };

    const updateUserErrorHandler = (error: any) => {
      console.log(error);
      fnErrorHandler && fnErrorHandler(error);
      alertContext?.OpenError({
        title: "SOMETHING WENT WRONG!",
        message: "Please try again later",
        severity: severityType.error,
      });
    };
    httptransfer.updateUser(
      id,
      data,
      updateUserSuccessHandler,
      updateUserErrorHandler
    );

    // UpdateUserProfileApi(
    //   data,
    //   updateUserSuccessHandler,
    //   updateUserErrorHandler
    // );
  };

  const getUserProfile = async () => {
    const getUserSuccessHandler = (response: any) => {
      setUser(response.data.users[0]);
    };

    const getUserErrorHandler = (error: any) => {
      console.log(error);
    };

    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

    if (userInfo?._id) {
      window.AsyncCallInProgressbar(async () => {
        await httptransfer.userQuery(
          { user_id: [userInfo._id] },
          getUserSuccessHandler,
          getUserErrorHandler
        );
      });
    }
  };

  const googleSignUpSuccessHandler = (codeResponse: any, type: string) => {
    console.log(codeResponse);
    setSignupLoading(true);
    const successCallback = (res: any) => {
      setSignupLoading(false);
      setUser(res.data.user);
      let userData = res.data.user;
      localStorage.setItem("userInfo", JSON.stringify(userData));
      SetAccessTokenKey(res.data.token);
      window.location.reload();
      nav(UnauthenticatedPath.Homepage);
    };

    const errorCallback = (error: any) => {
      setSignupLoading(false);
      alertContext?.OpenError({
        title: "Signup Error",
        message: error.response.data.message,
        severity: severityType.error,
      });
    };
    const google_access_token = codeResponse.credential;
    httptransfer
      .googleSignUpApi(
        { google_access_token: google_access_token, type: type },
        successCallback,
        errorCallback
      )
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err.message);
      });
  };

  const googleLoginSuccessHandler = (codeResponse: any) => {
    console.log(codeResponse);

    const successCallback = (res: any) => {
      console.log(res);
      setUser(res.data.user);
      let userData = res.data.user;
      localStorage.setItem("userInfo", JSON.stringify(userData));
      SetAccessTokenKey(res.data.token);
      nav(UnauthenticatedPath.Homepage);
      window.location.reload();
    };

    const errorCallback = (error: any) => {
      alertContext?.OpenError({
        title: "login Error",
        message: error.response.data.message,
        severity: severityType.error,
      });
    };

    const google_access_token = codeResponse.credential;
    httptransfer
      .googleLoginApi(
        { google_access_token: google_access_token },
        successCallback,
        errorCallback
      )
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err.message);
      });
  };

  const googleLoginFailureHandler = (error: any) => {
    console.log(error);
  };

  const isAuthenticated = () => {
    if (GetAccessTokenKey() == null) {
      return false;
    } else {
      return true;
    }
  };

  const getUserInfoFromAPI = async () => {
    try {
      const userSuccessCallback = (response: any) => {
        setUsers(response.data.users[0]);
        setUserTypeFromAPI(response.data.users[0]?.type);
      };
      const userErrorCallback = (error: any) => {
        console.log(error);
      };

      const currentPathname = window.location.pathname;
      if (currentPathname.includes("profile")) {
        const parts = currentPathname.split("/");
        const username = parts[2];
        await httptransfer.userQuery(
          { username: [username] },
          userSuccessCallback,
          userErrorCallback
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPlaylistAndUserInfoFromUrl = async () => {
    const userInfoString = localStorage.getItem("userInfo");
    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
    try {
      const userSuccessCallback = (response: any) => {
        setUsers(response.data.users[0]);
      };
      const playlistErrorCallback = (error: any) => {
        console.log(error);
      };
      const playlistSuccessCallback = (response: any) => {
        setPlaylist(response.data.playlists[0]);
        httptransfer.userQuery(
          { user_id: [response.data.playlists[0].created_by] },
          userSuccessCallback,
          playlistErrorCallback
        );
      };

      const currentPathname = window.location.pathname;
      if (currentPathname.includes("playlist")) {
        const parts = currentPathname.split("/");
        const playListId = parts[2];
        if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
          await httptransfer.playlistQuery(
            { playlist_id: [playListId], user_id: [userInfo?._id] },
            playlistSuccessCallback,
            playlistErrorCallback
          );
        } else {
          await httptransfer.playlistQuery(
            { playlist_id: [playListId] },
            playlistSuccessCallback,
            playlistErrorCallback
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updatePayment = (
    id: string,
    data: any,
    username: string,
    fnSuccessHandler: () => void,
    fnErrorHandler: (error: any) => void
  ) => {
    const userSuccessHandler = (response: any) => {
      setUsers(response.data.users[0]);
      fnSuccessHandler && fnSuccessHandler();
    };

    const userErrorHandler = (error: any) => {
      console.log(error);
    };

    const updatePaymentSuccessHandler = (response: any) => {
      fnSuccessHandler && fnSuccessHandler();
      alertContext?.OpenError({
        title: "PAYMENT DETAILS SUCCESSFULLY UPDATED!",
        message: "Your changes were successfully saved and updated",
        severity: severityType.success,
      });
      httptransfer.userQuery(
        { user_id: [id] },
        userSuccessHandler,
        userErrorHandler
      );
    };

    const updatePaymentErrorHandler = (error: any) => {
      console.log(error);
      fnErrorHandler && fnErrorHandler(error);
      alertContext?.OpenError({
        title: "SOMETHING WENT WRONG!",
        message: "Please try again later",
        severity: severityType.error,
      });
    };

    const paymentData = {
      bank_account_name: data.bank_account_name,
      bank_account_number: data.bank_account_number,
      ifsc_code: data.ifsc_code,
      paypal_email: data.paypal_email,
    };

    httptransfer.updatePayment(
      id,
      paymentData,
      updatePaymentSuccessHandler,
      updatePaymentErrorHandler
    );
  };

  React.useEffect(() => {
    if (isAuthenticated()) {
      getUserProfile();
    }

    if (localStorage.getItem("googleSignUp")) {
      console.log(localStorage.getItem("googleSignUp"));
      setUser(JSON.parse(localStorage.getItem("googleSignUpUser")!));
      setShowCompleteSignup({
        show: true,
        token: localStorage.getItem("googleSignUp")
          ? localStorage.getItem("googleSignUp")!
          : "",
      });
    }

    getUserInfoFromAPI();
    getPlaylistAndUserInfoFromUrl();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        User: user,
        SignUp: signUp,
        LogOut: signout,
        LogIn: LogIn,
        isAuthenticated: isAuthenticated,
        UpdateUser: updateUser,
        SendEmailForOtp: sendEmailForOtp,
        VerifyOtp: verifyOtp,
        ResetPassword: resetPassword,
        onGoogleLoginFailure: googleLoginFailureHandler,
        onGoogleLoginSuccess: googleLoginSuccessHandler,
        onGoogleSignUpSuccess: googleSignUpSuccessHandler,
        onGoogleSignUpFailure: googleLoginFailureHandler,
        ShowCompleteSignup: showCompleteSignup,
        users: users,
        setUsers: setUsers,
        userTypeFromAPI: userTypeFromAPI,
        loading: signuploading,
        Playlist: playlist,
        getUserProfile: getUserProfile,
        UpdatePayment: updatePayment,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
