export enum TrackPublishType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE"
  };
  
  export type TrackModel = {
    name: string;
    price: any;
    visibility: any;
    catalog_number: string;
    genres: any;
  };
  