import {
  TextField,
  TextFieldProps,
  InputLabel,
  Box,
  SelectProps,
  Select,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { runFuncOnEnter } from "utility/Common";

type Props = SelectProps & {
  children: React.ReactNode | React.ReactNode[] | null;
};

type CustomisedInputProps = TextFieldProps & {
  funcOnEnterSubmit?: () => any | undefined;
  disabledButtonStatus?: boolean | undefined;
};

export const RoundedInput = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  borderRadius: "30px",
  color: theme.palette.info.main,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    color: "#4a83c3",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    textAlign: "right",
  },
  "& .MuiOutlinedInput-notchedOutline>legend>span": { display: "none" },
  "& .MuiInputLabel-root": {
    right: "8px",
    left: "auto",
    padding: "0 6px",
    background: "white",
    color: "black !important",
  },
}));

export const RoundedInputTopLabel = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  borderRadius: "30px",
  color: theme.palette.info.main,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    color: "#1e88ff",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    textAlign: "right",
    borderColor: "#3b97ff !important",
  },
  "& .MuiOutlinedInput-notchedOutline>legend>span": { display: "none" },
  "& .MuiInputLabel-root": {
    top: "-11px",
    left: "10px",
    fontStyle: "italic",
    fontSize: "1.1rem",
    padding: "0 6px",
    background: "white",
    width: "max-content",
    color: "#1e88ff !important",
  },
}));
export const RoundedInputLeftLabel = styled((props: TextFieldProps) => (
  <TextField {...props} />
))(({ theme }) => ({
  borderRadius: "30px",
  color: theme.palette.info.main,
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "30px",
    color: "#1e88ff",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    textAlign: "right",
    borderColor: "#3b97ff !important",
  },
  "& .MuiOutlinedInput-notchedOutline>legend>span": { display: "none" },
  "& .MuiInputLabel-root": {
    left: "0",
    padding: "0 6px",
    background: "white",
    width: "max-content",
    color: "#1e88ff !important",
  },
}));

export const DarkInput = styled((props: CustomisedInputProps) => {
  const { funcOnEnterSubmit, disabledButtonStatus, ...rest } = props;
  return (
    <TextField
      {...rest}
      onKeyDown={(e: any): any =>
        funcOnEnterSubmit !== undefined &&
        disabledButtonStatus !== undefined &&
        runFuncOnEnter(e, funcOnEnterSubmit, disabledButtonStatus)
      }
    />
  );
})(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.12)",
  backdropFilter: "blur(100px)",
  borderRadius: "8px",
  color: "#fff",
  "& .MuiOutlinedInput-root": {
    color: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline": { display: "none" },
}));

export const DarkInputWithLabel = styled((props: CustomisedInputProps) => {
  const { funcOnEnterSubmit, disabledButtonStatus, error,helperText,...rest } = props;

  return (
    <Box
      sx={{
        width: ["100%", "100%", "unset"],
      }}
    >
      <InputLabel
        sx={{
          fontSize: "12px",
          mb: "8px",
          color:props.error?'#f44336':'#C5CFDB',
          display:'flex',
          alignItems:'center',
        }}
      >
        {props.label} <><FormHelperText sx={{
          m: 0,
          ml: 0.5,
          color: '#f44336',
          // css to slowly fade in the error message
          opacity: props.error ? 1 : 0,
          transition: 'opacity 0.5s',
        }}>-  {props?.helperText}</FormHelperText>
        </>
      </InputLabel>
      <TextField
        {...rest}
        sx={{
          "& .Mui-error":{
            color:'#f44336'
          }
        }}
        error={error}
        onKeyDown={(e: any): any =>
          funcOnEnterSubmit !== undefined &&
          disabledButtonStatus !== undefined &&
          runFuncOnEnter(e, funcOnEnterSubmit, disabledButtonStatus)
        }
      />
    </Box>
  );
})(({ theme }) => ({
  width: "100%",
  "& .MuiInputLabel-root": {
    display: "none",
  },
  background: "rgba(255, 255, 255, 0.12)",
  // backdropFilter: "blur(100px)",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    color: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline": { display: "none" },
}));

export const DarkDropdown = styled((props: Props) => (
  <Select
    {...props}
    MenuProps={{
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      sx: {
        "& .MuiPaper-root": {
          color: "#ffffff",
          background:
            "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
          border: "1px solid #636B82",
          backdropFilter: "blur(100px)",
          borderRadius: "12px",
          top: "388px !important",
          maxHeight: "200px",
          "& .MuiMenuItem-root": {
            fontWeight: 600,
            padding: "0.8rem 1.25rem",
          },
        },
      },
    }}
  >
    {props.children}
  </Select>
))(({ theme }) => ({
  width: "100%",
  "& .MuiInputLabel-root": {
    display: "none",
  },
  background: "rgba(255, 255, 255, 0.12)",
  backdropFilter: "blur(100px)",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    color: "#fff",
  },
  "& .MuiOutlinedInput-notchedOutline": { display: "none" },
  "&	.MuiSelect-icon": {
    display: "none",
  },
}));
