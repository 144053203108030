import { Avatar, Chip, Grid, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import artistProfileAlt from "assets/images/Profile-Avatar-Alt-2.png";
import { ReactComponent as Seperator } from "assets/svg/Seperator.svg";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import { Box } from '@mui/system';
import { AuthContext } from 'context/AuthContext';
import DialogPopup from 'components/layout/DialogPopup';
import { userType } from 'model/userType';
import EditUserForm from './EditUserForm';
import HttpTransferService from 'services/httptransfer';

const httptransfer = new HttpTransferService();

type Props = {}

const ProfileHero = (props: Props) => {
  const responsiveImageSize = [100, 100, 180, 200, 200]

  const [updateUserDialogPopupFlag, setupdateUserDialogPopupFlag] = useState(false)

  const theme = useTheme();
  const authcontext = React.useContext(AuthContext)!;


  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const openUpdateUserDialog = () => {
    setupdateUserDialogPopupFlag(true)
  }

  const closeUpdateUserDialog = () => {
    setupdateUserDialogPopupFlag(false)
  }

  const geners = [
    "TECHNO",
    "ELECTRONIC",
    "INDUSTRIAL TECHNO",
  ]

  const userTypeToDisplay = authcontext?.users ? authcontext?.users.type : userInfo?.type;
  const locationToDisplay = authcontext?.users ? authcontext?.users.location : userInfo?.location;
  const genresToDisplay = authcontext?.users ? authcontext?.users.genres : userInfo?.genres;

  return (
    <React.Fragment>
      <Grid
        container
        item
        height={"fit-content"}
        gap={["0.5rem", "1.5rem", "1.5rem"]}
      >
        {/* <Grid item xs={6} container alignItems={'center'}>
      </Grid> */}
        <Avatar
          src={authcontext?.users ? authcontext?.users?.user_image?.file_download_url : userInfo?.user_image?.file_download_url}
          sx={{
            width: responsiveImageSize,
            height: responsiveImageSize,
            borderRadius: "8px",
          }}
        ></Avatar>
        <Grid container item xs={8} sm={6.5} gap={['0.5rem',null]} alignContent="space-around">
          <Grid item xs={12}>
            <Typography
              fontWeight={700}
              sx={{
                "@media (min-width: 1200px)": {},
                fontSize: ["1.5rem", "2.5rem", "3rem", "4rem", "4.7129rem"],
                fontFamily: "'Darker Grotesque', sans-serif",
                lineHeight: "80%",
                letterSpacing: "-0.03rem",
                // mb: "1.5rem",
              }}
            >
              {authcontext?.users ? authcontext?.users?.name : userInfo.name}
            </Typography>
          </Grid>
          <Grid
            flexWrap={'nowrap'}
            item
            xs={12}
            container
            gap="0.5rem"
            alignItems="center"
            sx={{
              [theme.breakpoints.only("xs")]: {
                fontSize: "10px",
              },
            }}
          >
          {/* Conditionally render user type */}
         {userTypeToDisplay !== userType.USER ? (
          <React.Fragment>
            {userTypeToDisplay?.charAt(0).toUpperCase() + userTypeToDisplay?.slice(1).toLowerCase()}{" "}
            {locationToDisplay ? <Seperator /> : ""}
          </React.Fragment>
        ) : null}  {locationToDisplay}
          {localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN') && (authcontext.users ? userInfo?._id === authcontext.users?._id : userInfo?._id) && (<Box
            sx={{
              borderRadius: "22px",
              backgroundColor: "#ffffff1f",
              p: ["8px", "8px",'10px'],
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              ml: "1rem",
              "& svg": {
                width: "1rem",
                height: "1rem",
              },
            }}
            onClick={openUpdateUserDialog}
          >
            <Edit />
          </Box>)}
            
          </Grid>
          <Grid item xs={12} gap="0.5rem" container>
          {Boolean(genresToDisplay?.length) && genresToDisplay.map((item: any) => item !== '' && (
          <Chip
            key={item}
            size="small"
            label={item}
            sx={{
              backgroundColor: "#ffffff1f",
              color: "white",
              height: '24px',
              "& span": {
                px: "1rem",
              },
              [theme.breakpoints.only("xs")]: {
                height: '17px',
                "& span": {
                  px: "0.5rem",
                },
                fontSize: "10px",
              },
            }}
          />
        ))}
          </Grid>
        </Grid>
      </Grid>

      <DialogPopup
        closeDialog={closeUpdateUserDialog}
        dialogOpen={updateUserDialogPopupFlag}
        dialogTitle={userInfo?.type == userType.ARTIST ? "Edit Artist Profile" : "Edit User Profile"}
      >
        <EditUserForm
          closeDialog={closeUpdateUserDialog} userData={userInfo}
        />
      </DialogPopup>
    </React.Fragment>
  );
}

export default ProfileHero