import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import React, { HTMLAttributes } from 'react';
import { ReactComponent as AddSquare } from 'assets/svg/add-square.svg';
import { ReactComponent as UploadIcon } from 'assets/svg/uploadIcon.svg';
import ImageTextFieldContainer from 'components/controls/ImageTextFieldContainer';
import { DarkInputWithLabel } from '../controls/inputFields';
import { Done, AttachMoney, FamilyRestroomRounded } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DarkButtonWithIcon,
  LightBlurButtonWithCustomIcon,
} from 'components/controls/Buttons';
import GenresSearchInput from 'components/controls/GenresSearchInput';
import MusicUploadTextFieldContainer from 'components/controls/MusicUploadTextFieldContainer';
import { getMatchingIds, getNamesNotInDatabase } from 'utility/Common';
import CheckedIcon from 'assets/Icons/CheckedIcon';
import { TrackUploadContext } from 'context/TrackUploadContext';
import { TrackModel, TrackPublishType } from 'model/trackModel';
import { AlbumModel, AlbumPublishType } from 'model/albumModel';
import ManageSongDialog from './ManageSongDialog';
import HttpTransferService from 'services/httptransfer';
import { AlertContext } from 'context/AlertContext';
import { severityType } from 'model/errorSeverityTypeEnum';
import ApiReqAutoComplete from 'components/controls/ApiReqAutoComplete';
import ApiReqAutoCompleteInput from 'components/controls/AutoCompleteInputString';
import ManageSong from './ManageSong';
import { userType } from 'model/userType';

type Props = {
  closeDialog: () => void;
  fromUploadMusic: boolean;
  setUnsavedChanges: (value: boolean) => void;
};

const httptransfer = new HttpTransferService();

const CreateAlbumForm = (props: Props) => {
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [fileData, setFileData] = React.useState<any>({});
  const [file, setFile] = React.useState<File | null>(null);
  const [genreData, setGenreData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const tracksContext = React.useContext(TrackUploadContext);

  const [albumData, setAlbumData] = React.useState<
    AlbumModel & { release_date: null }
  >({
    name: '',
    price: '',
    visibility: AlbumPublishType.PRIVATE,
    catalog_number: '',
    genres: [],
    artist_collaborators_id: [],
    artist_collaborators_name: [],
    label_collaborators_id: [],
    label_collaborators_name: [],
    release_date: null,
    isrc_code: '',
    mark_all_songs_public: false
  });

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const [artistNameData, setArtistNameData] = React.useState<any[]>([]);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = React.useState(false);
  const [artistNames, setArtistNames] = React.useState<any[]>([]);
  const [labelIds, setLabelIds] = React.useState<any[]>([]);
  const [labelNames, setLabelNames] = React.useState<any[]>([]);
  const [albumId, setAlbumId] = React.useState<string | null>(null);
  const [doesAlbumexist, setDoesAlbumexist] = React.useState<boolean>(true);
  const [artistState, setArtistState] = React.useState<any[]>([]);
  const [labelState, setLabelState] = React.useState<any[]>([]);
  const [progressBar, setProgressBar] = React.useState<
    { percent: number; trackName: string; id: string }[]
  >([{ percent: 0, trackName: '', id: '' }]);

  const theme = useTheme();
  const alertContext = React.useContext(AlertContext);

  React.useEffect(() => {
    setAlbumData((prev) => ({ ...prev, genres: genreData }));
  }, [genreData]);


  const albumhandleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'visibility') {
      const newVisibility = albumData.visibility === AlbumPublishType.PUBLIC
        ? AlbumPublishType.PRIVATE
        : AlbumPublishType.PUBLIC;

      setAlbumData((prev) => ({ ...prev, visibility: newVisibility }));
    }else if (name === 'mark_all_songs_public') {
      setAlbumData((prev) => ({ ...prev, [name]: !albumData.mark_all_songs_public }));
    } else if (name === 'release_date') {
      const targetDateString = e.target.value;
      const targetDate = new Date(targetDateString);
      const currentDate = new Date(1900, 0, 1);
      const timeDifference = targetDate.getTime() - currentDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      setAlbumData((prev) => ({ ...prev, [name]: daysDifference }));
    } else {
      setAlbumData((prev) => ({ ...prev, [name]: value }));
    }
    props.setUnsavedChanges(true)
  };

  const createAlbumSuccessCallback = async (createResponse: any) => {
    setAlbumId(createResponse.data.album_id);
    setLoading(false)
    window.ShowInfoDialog(
      "Album created successfully. Now you can start uploading songs by clicking on manage songs"
    );
    tracksContext?.GetAllAlbumsByUser();
    setIsCreateButtonDisabled(true);
  };

  const handleAlbumCreateError = (error: any) => {
    props.closeDialog();
    alertContext?.OpenError({
      title: 'SOMETHING WENT WRONG!',
      message: 'We couldn’t create your album. Please try again later',
      severity: severityType.error,
    });
  };

  const createCommonAlbumData = () => ({
    ...albumData,
    price: parseFloat(albumData.price),
    artist_collaborators_id: getMatchingIds(artistNameData, artistNames),
    artist_collaborators_name: getNamesNotInDatabase(artistNameData, artistNames),
    label_collaborators_id: getMatchingIds(labelIds, labelNames),
    label_collaborators_name: getNamesNotInDatabase(labelIds, labelNames),
  });

  const albumCreateCall = async (album_cover_image: any) => {
    setDoesAlbumexist(false);
    const updatedAlbumData = {
      ...(album_cover_image.file_path
        ? { ...createCommonAlbumData(), album_cover_image }
        : createCommonAlbumData()),
    };

    await httptransfer.createAlbum(updatedAlbumData, createAlbumSuccessCallback, handleAlbumCreateError);
  };

  const handleCreateAlbum = async () => {
    setLoading(true)
    const putSuccessCallback = async (response: any) => {
      console.log(response);
    };

    const uploadAlbumArtSuccessCallback = async (fileResponse: any) => {
      const file_upload_url = fileResponse?.data?.responseJson?.file_upload_url;
      const album_cover_image = {
        file_path: fileResponse?.data?.responseJson?.file_path,
        file_extension: fileData.file_path.file_extension,
        file_name: fileData.file_path.file_name,
      };

      await httptransfer.uploadFileOnAws(file, file_upload_url, putSuccessCallback, handleAlbumCreateError);
      albumCreateCall(album_cover_image);
    };

    if (fileData.file_path) {
      await httptransfer.uploadAlbumArt(fileData.file_path, uploadAlbumArtSuccessCallback, handleAlbumCreateError);
    } else {
      albumCreateCall({});
    }
  };

  const disabledStatus =
    albumData.price === '' ||
    albumData.name === '' ||
    albumData.release_date === null ||
    albumData.genres.length === 0 ||
    artistNameData.length === 0

  return (
    <React.Fragment>
      <Grid container>
        {/* Form container */}
        <Grid container item xs={12} gap='20px' justifyContent={'center'}>
          <Grid
            item
            xs={12}
            sm={'auto'}
            md={'auto'}
            direction={['row', 'row', 'column']}
            gap={'20px'}
            container
            justifyContent={'center'}
            height='max-content'
          >
            {/* Profile picture container */}
            <ImageTextFieldContainer
              setUnsavedChanges={props.setUnsavedChanges}
              data={[]}
              Datakey={'file_path'}
              editIcon={false}
              fileData={fileData}
              file={file}
              imagePreview={imagePreview}
              setFileData={setFileData}
              setFile={setFile}
              setImagePreview={setImagePreview}
              placeholderCondition={!imagePreview}
              placeholderNode={
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#08090C',
                    borderRadius: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <UploadIcon />
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#ffffff',
                      fontSize: '0.8rem',
                      fontWeight: 500,
                      marginLeft: '0.5rem',
                    }}
                  >
                    UPLOAD ALBUM ART
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#A3A3A3',
                      fontSize: '10px',
                      fontWeight: 400,
                      marginLeft: '0.5rem',
                    }}
                  >
                    1400X1400 pixels minimum.
                    <br />
                    .jpg .png only. Max 10Mb
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              name='visibility'
              onChange={albumhandleChange}
              control={
                <Checkbox
                  icon={
                    <CheckedIcon
                      checked={
                        albumData.visibility == AlbumPublishType.PUBLIC
                      }
                    />
                  }
                  checkedIcon={
                    <CheckedIcon
                      checked={
                        albumData.visibility == AlbumPublishType.PUBLIC
                      }
                    />
                  }
                />
              }
              label='Public'
            />
            {albumData.visibility === AlbumPublishType.PUBLIC && (
            <FormControlLabel
              name='mark_all_songs_public'
              onChange={albumhandleChange}
              control={
                <Checkbox
                  icon={
                    <CheckedIcon
                      checked={
                        albumData.mark_all_songs_public == true
                      }
                    />
                  }
                  checkedIcon={
                    <CheckedIcon
                      checked={
                        albumData.mark_all_songs_public == true
                      }
                    />
                  }
                />
              }
              label='Mark all as Public'
            />)}
          </Grid>

          {/* Form fields container */}
          <Grid
            item
            container
            xs={'auto'}
            direction='column'
            width={['100%', '100%', 'unset']}
          >

            {!props.fromUploadMusic && (
              <DarkInputWithLabel
                label={<span>NAME <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
                value={albumData.name}
                name={'name'}
                type={'text'}
                onChange={albumhandleChange}
                sx={{ marginBottom: '1.25rem' }}
              />
            )}

            {!props.fromUploadMusic && (
              <DarkInputWithLabel
                label={<span>PRICE <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
                value={albumData.price}
                placeholder={'0.00'}
                onWheel={(e: any) => (e.target as any).blur()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Icon sx={{ color: '#C5CFDB', height: '1.05em' }}>
                        <AttachMoney />
                      </Icon>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 0,
                }}
                name={'price'}
                type={'number'}
                onChange={albumhandleChange}
                sx={{ marginBottom: '1.25rem' }}
              />
            )}

            {/* Catelog Number */}
            {!props.fromUploadMusic && (
              <DarkInputWithLabel
                label={'CATALOG NUMBER'}
                value={albumData.catalog_number}
                name={'catalog_number'}
                type={'text'}
                onChange={albumhandleChange}
                sx={{ marginBottom: '1.25rem' }}
              />
            )}

            <ApiReqAutoCompleteInput
              setUnsavedChanges={props.setUnsavedChanges}
              multipleValue={true}
              key={'genre'}
              addNewOption={true}
              inputName={'genre'}
              setState={setGenreData}
              state={genreData}
              label={<span>EDIT GENRE / MOOD <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              type={"GENRE"}
            />

          </Grid>
          <Grid
            item
            container
            xs={'auto'}
            direction='column'
            width={['100%', '100%', 'unset']}
          >
            {/* <Grid item container> */}

            {/* album label */}
            {!props.fromUploadMusic && (
              <ApiReqAutoComplete
                setUnsavedChanges={props.setUnsavedChanges}
                multipleValue={true}
                key={'label'}
                addNewOption={true}
                inputName={'label_collaborators_name'}
                setSelectedIds={setLabelIds}
                selectedIds={labelIds}
                setNames={setLabelNames}
                names={labelNames}
                state={labelState}
                setState={setLabelState}
                label={"LABEL"}
                type={"LABEL"}
              />
            )}

            {!props.fromUploadMusic && (
              <div>
                <style>
                  {`
                  input[type="date"]::-webkit-calendar-picker-indicator {
                    filter: invert(1);
                  }
                `}
                </style>
                <DarkInputWithLabel
                  label={<span>RELEASE DATE <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
                  type={'date'}
                  name={'release_date'}
                  sx={{ marginBottom: '1.25rem' }}
                  onChange={albumhandleChange}
                  value={
                    albumData.release_date
                      ? new Date(1900, 0, 1 + albumData.release_date + 1).toISOString().split('T')[0]
                      : null
                  }
                /></div>)}

            {!props.fromUploadMusic && (
              <DarkInputWithLabel
                label={'ISRC CODE'}
                value={albumData.isrc_code}
                name={'isrc_code'}
                type={'text'}
                onChange={albumhandleChange}
                sx={{ marginBottom: '1.25rem' }}
              />
            )}

            {/* album collaborators */}
            {!props.fromUploadMusic && (
              <ApiReqAutoComplete
                setUnsavedChanges={props.setUnsavedChanges}
                multipleValue={true}
                key={'artist_collaborators_id'}
                addNewOption={true}
                inputName={'artist_collaborators_id'}
                setSelectedIds={setArtistNameData}
                selectedIds={artistNameData}
                setNames={setArtistNames}
                names={artistNames}
                state={artistState}
                setState={setArtistState}
                label={<span>ARTISTS <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
                type={"ARTIST"}
              />)}
          </Grid>
        </Grid>

        {/* Save button container */}
        <Grid
          item
          container
          justifyContent={'space-between'}
          xs={12}
          gap={['20px', '20px', '0px']}
          flexWrap='nowrap'
          direction={['column', 'column', 'row']}
        >
          <Grid item container>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#FFBA35',
                fontSize: '12px',
              }}
            >
              Note: 600Mb max per song, lossless .wav, .aif, .aiff, .flac
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent={'flex-end'}
            gap={['20px', '20px', '44px']}
            flexWrap='nowrap'
            direction={['column', 'column', 'row']}
          >
            {!props.fromUploadMusic &&
              <DarkButtonWithIcon
                disabled={disabledStatus || isCreateButtonDisabled}
                onClick={
                  handleCreateAlbum
                }
                sx={{
                  width: 'max-content',
                }}
              >
                {loading ? <CircularProgress size={15} style={{ marginRight: '8px' }} color="secondary" /> : null}CREATE
              </DarkButtonWithIcon>}

            <LightBlurButtonWithCustomIcon
              disabled={doesAlbumexist}
              CustomIcon={AddSquare}
              onClick={openDialog}
            >
              MANAGE SONGS
              <ManageSongDialog
                closeDialog={props.closeDialog}
                dialogOpen={dialogOpen}
                dialogTitle="MANAGE SONGS"
              >
                <ManageSong visible={true} albumId={albumId} labelData={labelIds} />
              </ManageSongDialog>
            </LightBlurButtonWithCustomIcon>

          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateAlbumForm;
