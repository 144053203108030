import { PlayArrow } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Skeleton,
  Typography,
} from "@mui/material";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import { WaveLeftBox } from "components/layout/WaveBox";
import ProfileMiscellaneousView from "components/other/ProfileMiscellaneousView";
import React from "react";

import Artboard1 from "assets/images/recommendation place holder/Artboard 1.png";
import Artboard2 from "assets/images/recommendation place holder/Artboard 2.png";
import Artboard3 from "assets/images/recommendation place holder/Artboard 3.png";
import Artboard4 from "assets/images/recommendation place holder/Artboard 4.png";
import Artboard5 from "assets/images/recommendation place holder/Artboard 5.png";
import Artboard6 from "assets/images/recommendation place holder/Artboard 6.png";
import Artboard7 from "assets/images/recommendation place holder/Artboard 7.png";
import Artboard8 from "assets/images/recommendation place holder/Artboard 8.png";
import { DarkBlueRoundedWapper } from "components/uiCard/BoxWrapperCard";
import ExpandingGridMusicListing from "components/other/ExpandingGridMusicListing";
//import { searchMusicApi } from "services/Apirepository";

type Props = {};

const recommendationData = [
  {
    title: "ENGAGE TOP CHARTS",
    data: [
      {
        name: "Artist 1",
        image: Artboard1,
      },
      {
        name: "Artist 2",
        image: Artboard2,
      },
      {
        name: "Artist 3",
        image: Artboard3,
      },
      {
        name: "Artist 4",
        image: Artboard4,
      },
      {
        name: "Artist 5",
        image: Artboard5,
      },
      {
        name: "Artist 6",
        image: Artboard6,
      },
      {
        name: "Artist 7",
        image: Artboard7,
      },
      {
        name: "Artist 8",
        image: Artboard8,
      },
      {
        name: "Artist 9",
        image: Artboard8,
      },
      {
        name: "Artist 10",
        image: Artboard8,
      },
    ],
  },
];

const Discover = (props: Props) => {
  const [top10Songs, setTop10Songs] = React.useState<any[]>([]);

  React.useEffect(() => {
    const successCB = (response: any) => {
      console.log(response);
      setTop10Songs(response.data.data);
    };
    const errorCB = (error: any) => {
      console.log(error);
    };
    //(async()=>await searchMusicApi('Songs','',successCB,errorCB,10))()
  }, []);

  return (
    <React.Fragment>
      <WaveLeftBox sx={{ zIndex: 0 }} />
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box
          p={["1rem", "2rem", "4rem", "5rem"]}
          pt={["7rem", "6rem", null, null, null]}
          // zIndex={(theme) => (theme.zIndex.drawer + 100) as number}
          position={"relative"}
        >
          <Grid container alignItems="flex-start" color={"white"}>
            <Grid item container xs={12} mb="3rem">
              <Typography
                fontWeight={700}
                sx={{
                  fontSize: ["2rem", "2.5rem", "3rem"],
                  fontFamily: "'Darker Grotesque', sans-serif",
                }}
              >
                Discover{" "}
                <span
                  style={{
                    color: "#FA6B05",
                    fontFamily: "'Darker Grotesque', sans-serif",
                    position: "relative",
                  }}
                >
                  music - Coming Soon
                </span>
              </Typography>
            </Grid>
            <Grid pr={2} item container sm={12} md={7}>
              <Grid item xs={12} container>
                <ExpandingGridMusicListing
                  statisData={[{ title: "Recently Added", data: top10Songs }]}
                />
              </Grid>
              {/* <Grid item xs={12} container>
                <ExpandingGridMusicListing statisData={[{title:"Bestsellers",data:top10Songs}]} />
              </Grid> */}
            </Grid>
            <Grid item sm={12} md={5}>
              <Grid container gap={2} mb={4}>
                <Grid item xs={12}>
                  <Typography fontWeight={700}>Events - coming soon</Typography>
                </Grid>
                <Grid item xs={12}>
                  <DarkBlueRoundedWapper
                    sx={{
                      background:
                        "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
                      border: "1px solid #636B82",
                      borderRadius: "24px",
                    }}
                  >
                    <Grid container alignItems="center" gap={2}>
                      <Grid item xs={12}>
                        <Skeleton
                          variant="rounded"
                          width={"100%"}
                          animation={false}
                          height={"324px"}
                          sx={{
                            bgcolor: "grey.900",
                            borderRadius: "24px",
                          }}
                        />
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Typography fontWeight={700}>
                            FarOutLeft 24
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          container
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                        >
                          <Pagination
                            count={5}
                            // page={2}
                            hidePrevButton
                            hideNextButton
                            renderItem={item => (
                              <Box
                                sx={{
                                  mx: "2px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: "8px",
                                  bgcolor: "grey.900",
                                  color: "white",
                                  cursor: "pointer",
                                  backgroundColor: item.selected
                                    ? "#FA6B05"
                                    : "grey.900",
                                  width: item.selected ? "16px" : "8px",
                                  borderRadius: item.selected ? "4px" : "50%",
                                }}
                                {...item}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item xs={12}>
                        <Grid item xs={6}>
                          <Typography
                            variant="caption"
                            sx={{ color: "#C5CFDB" }}
                          >
                            10.02.24 - 11.02.24
                          </Typography>
                          <br />
                          <Typography
                            variant="caption"
                            sx={{ color: "#C5CFDB" }}
                          >
                            1:00PM onwards
                          </Typography>
                          <br />
                          <Typography
                            variant="caption"
                            sx={{ color: "#C5CFDB" }}
                          >
                            Drome Arena, Pune
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DarkBlueRoundedWapper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default Discover;
