import { PlayArrow, PlayArrowRounded } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";

type Props = {
  statisData: any;
  fromArtistShowcase?: boolean;
};

// const data = [
//   { _id : "64538bf891f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf891f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8918f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf89178f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf891c78f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c78f6", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c78f36", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c78f136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c78f4136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "6438bf8917c78f574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c78f74136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c78f574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538bf8917c787f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "6bf8917c78f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "68bf8917c78f7f74136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "6538bf8917c78f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "68bf8917c78f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "6453f8917c78f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
//   { _id : "64538b8917c78f7f1574136", name : "dipansh.saxenavivo", email : "dipansh.saxenavivo@gmail.com" ,password : "$2a$10$Xv4pWGOTK0qmXzmeX8D3VOuGqpgqLOc.n0aUJLHrWeVpYzAPW9C3i" ,type : "ARTIST", profilePictureUrl : "https://engage-music-dev.s3.ap-northeast-1.amazonaws.com/64538bf8917c78f7f1574136profilePictureFU0niO0gRLiYYLclo6pM6Qq22RZ9193T" ,bio : "hi!", profession : "Music Producer/DJ"  ,genres : ["Dreamy", "Happy", "Relaxing"] , relatedArtist : [""] ,location : "DELHI" ,createdAt : "2023-05-04T10:42:00.309Z", updatedAt : "2023-05-04T10:42:31.617Z" ,__v :0},
// ]

const ExpandingGridMusicListing = ({ statisData,fromArtistShowcase }: Props) => {
  const [selected, setSelected] = React.useState({
    _id: "",
  });

  const gridArea = [
    {
      index: 0,
      notSelected: "1 / 1 / 2 / 2",
      selected: "1 / 1 / 3 / 3",
    },
    {
      index: 1,
      notSelected: "1 / 2 / 2 / 3",
      selected: "1 / 2 / 3 / 4",
    },
    {
      index: 2,
      notSelected: "1 / 3 / 2 / 4",
      selected: "1 / 3 / 3 / 5",
    },
    {
      index: 3,
      notSelected: "1 / 4 / 2 / 5",
      selected: "1 / 3 / 3 / 5",
    },
    {
      index: 4,
      notSelected: "2 / 1 / 3 / 2",
      selected: "1 / 1 / 3 / 3",
    },
    {
      index: 5,
      notSelected: "2 / 2 / 3 / 3",
      selected: "1 / 2 / 3 / 4",
    },
    {
      index: 6,
      notSelected: "2 / 3 / 3 / 4",
      selected: "1 / 3 / 3 / 5",
    },
    {
      index: 7,
      notSelected: "2 / 4 / 3 / 5",
      selected: "1 / 3 / 3 / 5",
    },
    {
      index: 8,
      notSelected: "3 / 1 / 4 / 2",
      selected: "3 / 1 / 5 / 3",
    },
    {
      index: 9,
      notSelected: "3 / 2 / 4 / 3",
      selected: "3 / 2 / 5 / 4",
    },
    {
      index: 10,
      notSelected: "3 / 3 / 4 / 4",
      selected: "3 / 3 / 5 / 5",
    },
    {
      index: 11,
      notSelected: "3 / 4 / 4 / 5",
      selected: "3 / 3 / 5 / 5",
    },
    {
      index: 12,
      notSelected: "4 / 1 / 5 / 2",
      selected: "3 / 1 / 5 / 3",
    },
    {
      index: 13,
      notSelected: "4 / 2 / 5 / 3",
      selected: "3 / 2 / 5 / 4",
    },
    {
      index: 14,
      notSelected: "4 / 3 / 5 / 4",
      selected: "3 / 3 / 5 / 5",
    },
    {
      index: 15,
      notSelected: "4 / 4 / 5 / 5",
      selected: "3 / 3 / 5 / 5",
    },
    {
      index: 16,
      notSelected: "5 / 1 / 6 / 2",
      selected: "5 / 1 / 7 / 3",
    },
    {
      index: 17,
      notSelected: "5 / 2 / 6 / 3",
      selected: "5 / 2 / 7 / 4",
    },
    {
      index: 18,
      notSelected: "5 / 3 / 6 / 4",
      selected: "5 / 3 / 7 / 5",
    },
    {
      index: 19,
      notSelected: "5 / 4 / 6 / 5",
      selected: "5 / 3 / 7 / 5",
    },
  ];

  const theme = useTheme();

  const similarInfoGridJsx = (item: any, index: any) => (
    <React.Fragment key={index}>
      <Grid container gap={2} mb={4}>
        <Grid item xs={12} display={item.title? "flex":"none"}>
          <Typography fontWeight={700}>{item.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              gridColumnGap: "20px",
              gridRowGap: "20px",
            }}
          >
            {item.data && item.data.slice(0,8).map((item: any, index: any) => (
              <Box
                onMouseEnter={() => {
                  if (item._id === selected._id) {
                    setSelected({ _id: "" });
                    return;
                  }
                  setSelected({ _id: item._id });
                }}
                onMouseLeave={() => {
                  setSelected({ _id: "" });
                }}
                sx={{
                  transition: "unset",
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  cursor: "pointer",
                  zIndex: item._id === selected._id ? 2 : 1,
                  // transform: item._id === selected._id ? "scale(1.1)" : "scale(1)",
                  position: "relative",
                  //calculate grid area according to index
                  gridArea:
                    gridArea[index].index === index
                      ? item._id === selected._id
                        ? gridArea[index].selected
                        : gridArea[index].notSelected
                      : "",
                }}
              >
                <Avatar
                  src={item["albumCover"]}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                ></Avatar>
                {/* a box with play icon only visible when selected */}
                <Box
                  sx={{
                    display: item._id === selected._id ? "flex" : "none",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(0deg, #1E2332 13.88%, rgba(30, 35, 50, 0.12) 100%)",
                    // transform: "rotate(-180deg)",
                    zIndex: 3,
                    top: 0,
                    alignItems: "flex-end"
                  }}
                >
                  <Grid container justifyContent="center" alignItems="center" p={["8px",'12px']}>
                    <Grid item xs={12}>
                      <Typography variant="h6" fontWeight={'700'}>
                        {item.name}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} flexWrap={'nowrap'} alignItems={'center'} justifyContent={'space-between'}>
                      <Grid item xs={'auto'} container direction={'column'} >
                        <Typography variant="caption" sx={{}}>{item.albumName}</Typography>
                        <Typography variant="caption" sx={{}}>{item.userName}</Typography>
                      </Grid>
                      <Grid item xs={'auto'} >
                        <PlayArrowRounded sx={{ color:"#FA6B05" }} />
                      </Grid>
                    </Grid>
                  </Grid>

                </Box>
                {/* skeleton for avatar placeholder */}
                {/* <Skeleton
                  variant="rounded"
                  width={'100%'}
                  animation={false}
                  height={'100px'}
                  sx={{
                    bgcolor: 'grey.900',
                    borderRadius: "24px",
                  }}
                /> */}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  const artistShowcaseGridJsx = (item: any, index: any) => (
    <React.Fragment key={index}>
      <Grid container my={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(4, 1fr)",
              gridTemplateRows: "repeat(2, 1fr)",
              gridColumnGap: "20px",
              gridRowGap: "20px",
            }}
          >
            {item.data && item.data.map((item: any, index: any) => (
              <Box
                onMouseEnter={() => {
                  if (item._id === selected._id) {
                    setSelected({ _id: "" });
                    return;
                  }
                  setSelected({ _id: item._id });
                }}
                onMouseLeave={() => {
                  setSelected({ _id: "" });
                }}
                sx={{
                  transition: "unset",
                  width: "100%",
                  height: "100%",
                  borderRadius: "8px",
                  cursor: "pointer",
                  zIndex: item._id === selected._id ? 2 : 1,
                  // transform: item._id === selected._id ? "scale(1.1)" : "scale(1)",
                  position: "relative",
                  //calculate grid area according to index
                  gridArea:
                    gridArea[index].index === index
                      ? item._id === selected._id
                        ? gridArea[index].selected
                        : gridArea[index].notSelected
                      : "",
                }}
              >
                <Avatar
                  src={item["profilePictureUrl"]}
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "8px",
                  }}
                ></Avatar>
                {/* a box with play icon only visible when selected */}
                <Box
                  sx={{
                    display: item._id === selected._id ? "flex" : "none",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "4px",
                    background:
                      "linear-gradient(0deg, #1E2332 13.88%, rgba(30, 35, 50, 0.12) 100%)",
                    // transform: "rotate(-180deg)",
                    zIndex: 3,
                    top: 0,
                    alignItems: "flex-end"
                  }}
                >
                  <Grid container justifyContent="center" alignItems="center" p={["8px",'12px']}>
                    <Grid item xs={12}>
                      <Typography variant="body1" fontWeight={'500'}>
                        {item.name}
                      </Typography>
                    </Grid>
                  </Grid>

                </Box>
                {/* skeleton for avatar placeholder */}
                {/* <Skeleton
                  variant="rounded"
                  width={'100%'}
                  animation={false}
                  height={'100px'}
                  sx={{
                    bgcolor: 'grey.900',
                    borderRadius: "24px",
                  }}
                /> */}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {statisData.map((item: any, index: any) =>
        fromArtistShowcase
          ? artistShowcaseGridJsx(item, index)
          : similarInfoGridJsx(item, index)
      )}
    </React.Fragment>
  );
};

export default ExpandingGridMusicListing;
