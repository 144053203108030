import constant from "constants/constant";
import { loginModel, signupModel } from "model/userModel";
import {
  GetAccessTokenKey,
  RemoveAccessTokenKey,
} from "utility/AccessTokenManagement";
import { environment } from "environments/environment.staging";

import axios from "axios";
export default class HttpTransferService {
  baseUrl = environment.baseUrl;
  loginApi = constant.LOGIN_USER;
  signupApi = constant.SIGNUP_USER;
  createAlbumApi = constant.CREATE_ALBUM;
  uploadFile = constant.UPLOAD_FILE;
  userQueryApi = constant.USER_QUERY;
  albumQueryApi = constant.ALBUM_QUERY;
  uploadMusicApi = constant.UPLOAD_MUSIC;
  queryMusicApi = constant.MUSIC_QUERY;
  countryApi = constant.COUNTRY_QUERY;
  sendOtpApi = constant.SEND_OTP;
  resetPasswordApi = constant.RESET_PASSWORD;
  verifyOTPApi = constant.VERIFY_OTP;
  playlistQueryApi = constant.PLAYLIST_QUERY;
  addPlaylist = constant.CREATE_PLAYLIST;
  queryCartData = constant.CART_QUERY;
  addRemoveCart = constant.CART_ADD_REMOVE;
  payment = constant.PAYMENT;
  purchasedQuery = constant.PURCHASED_TRACKS;

  userLogin(
    inputJson: loginModel,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.loginApi;
    return this.loginAndsignupCall(
      inputJson,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }

  signupUser(
    inputJson: signupModel,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.signupApi;
    return this.loginAndsignupCall(
      inputJson,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }

  createAlbum(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.createAlbumApi;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  queryAlbum(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.albumQueryApi;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  musicQuery(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.queryMusicApi;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  uploadMusic(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.uploadMusicApi;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  uploadAlbumArt(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.uploadFile;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  uploadFileOnAws(
    file: any,
    url: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void,
    setUploadProgress?: (percent: number) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "put",
        data: file,
        headers: {
          "Content-Type": file.type,
          Accept: "image/*,application/pdf",
          "x-auth-token": accessToken,
        },
        onUploadProgress: progressEvent => {
          if (
            progressEvent.total !== null &&
            progressEvent.total !== undefined
          ) {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadProgress && setUploadProgress(percent);
          }
        },
      })
      .then(function (response) {
        fnSuccessCallBack && fnSuccessCallBack(response);
      })
      .catch(function (error) {
        fnErrorCallBack && fnErrorCallBack(error);
      });
  }

  userQuery(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.userQueryApi;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  changePassword(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/user/" + id + "/changepassword";
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  updateUser(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/user/" + id;
    return this.putCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  updateMusic(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/music/" + id;
    return this.patchCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  deleteMusic(
    id: string,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/music/" + id;
    return this.deleteCall(url, fnSuccessCallBack, fnErrorCallBack);
  }
  updateAlbum(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/album/" + id;
    return this.patchCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  deleteAlbum(
    id: string,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/album/" + id;
    return this.deleteCall(url, fnSuccessCallBack, fnErrorCallBack);
  }
  googleLoginApi(
    googleAccessToken: object,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.loginApi;
    return this.loginAndsignupCall(
      googleAccessToken,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }
  googleSignUpApi(
    googleAccessToken: object,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.signupApi;
    return this.loginAndsignupCall(
      googleAccessToken,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }

  getLocation(
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.countryApi;
    return this.getCall(url, fnSuccessCallBack, fnErrorCallBack);
  }

  sendOTP(
    email: object,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.sendOtpApi;
    return this.loginAndsignupCall(
      email,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }

  resetPassword(
    inputJson: object,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.resetPasswordApi;
    return this.loginAndsignupCall(
      inputJson,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }

  verifyOTP(
    inputJson: object,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.verifyOTPApi;
    return this.loginAndsignupCall(
      inputJson,
      url,
      fnSuccessCallBack,
      fnErrorCallBack
    );
  }

  createNewPlaylist(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.addPlaylist;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  playlistQuery(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.playlistQueryApi;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  addInPlaylist(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/playlist/" + id;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  deletePlaylist(
    id: string,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/playlist/" + id;
    return this.deleteCall(url, fnSuccessCallBack, fnErrorCallBack);
  }
  updatePlaylist(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/playlist/" + id;
    return this.patchCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  deleteSongFromPlaylist(
    playlistId: string,
    playlistMusicId: string,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url =
      this.baseUrl + "api/playlist/" + playlistId + "/music/" + playlistMusicId;
    return this.deleteCall(url, fnSuccessCallBack, fnErrorCallBack);
  }
  cartQuery(
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.queryCartData;
    return this.getCall(url, fnSuccessCallBack, fnErrorCallBack);
  }
  addIntoCart(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.addRemoveCart;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  removeFromCart(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.addRemoveCart;
    return this.patchCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  paymentCheckout(
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.payment;
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
  purchasedTracks(
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + this.purchasedQuery;
    return this.getCall(url, fnSuccessCallBack, fnErrorCallBack);
  }

  LogoutApi = async (
    fnSuccessCallBack: () => void,
    fnErrorCallBack: (error: any) => void
  ) => {
    try {
      RemoveAccessTokenKey();
      localStorage.removeItem("userInfo");
      localStorage.removeItem("specificUser");
      localStorage.removeItem("updatedUsername");
      localStorage.removeItem("updatedUserImage");
      fnSuccessCallBack && fnSuccessCallBack();
    } catch (error) {
      console.log(JSON.stringify(error));
      fnErrorCallBack && fnErrorCallBack(error);
    }
  };

  loginAndsignupCall(
    inputJson: any,
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    return axios
      .request({
        url: url,
        method: "post",
        data: inputJson,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(function (response) {
        success && success(response);
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  postCall(
    inputJson: any,
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "post",
        data: inputJson,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-auth-token": accessToken,
        },
      })
      .then(function (response) {
        success && success(response);
        return response;
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  getCall(
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "x-auth-token": accessToken,
        },
      })
      .then(function (response) {
        success && success(response);
        return response;
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  putCallForFile(
    inputJson: any,
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "put",
        data: inputJson,
        headers: {
          "Content-Type": inputJson.type,
          Accept: "image/*,application/pdf",
          "x-auth-token": accessToken,
        },
      })
      .then(function (response) {
        success && success(response);
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  patchCall(
    inputJson: any,
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "patch",
        data: inputJson,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": accessToken,
        },
      })
      .then(function (response) {
        success && success(response);
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  putCall(
    inputJson: any,
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "put",
        data: inputJson,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": accessToken,
        },
      })
      .then(function (response) {
        success && success(response);
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  deleteCall(
    url: string,
    success: (res: any) => void,
    errors: (error: any) => void
  ) {
    const accessToken = GetAccessTokenKey();
    return axios
      .request({
        url: url,
        method: "delete",
        headers: {
          "Content-Type": "application/octet-stream",
          Accept: "image/*,application/pdf",
          "x-auth-token": accessToken,
        },
      })
      .then(function (response) {
        success && success(response);
      })
      .catch(function (error) {
        errors && errors(error);
      });
  }

  createRazorpayOrder(
    amount: number,
    currency: string,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    const url = `${this.baseUrl}api/payment/razorpay/order`;
    const inputJson = { amount, currency };
    return this.postCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }

  verifyRazorpayPayment(
    data: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    const url = `${this.baseUrl}api/payment/razorpay/verify`;
    return this.postCall(data, url, fnSuccessCallBack, fnErrorCallBack);
  }

  getDownloadUrl(musicId: string) {
    const url = `${this.baseUrl}api/music/${musicId}/download-url`;
    return axios.get(url, {
      headers: {
        "x-auth-token": GetAccessTokenKey(),
      },
    });
  }

  updatePayment(
    id: string,
    inputJson: any,
    fnSuccessCallBack: (res: any) => void,
    fnErrorCallBack: (error: any) => void
  ) {
    var url = this.baseUrl + "api/user/" + id + "/payment";
    return this.putCall(inputJson, url, fnSuccessCallBack, fnErrorCallBack);
  }
}
