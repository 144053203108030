import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import appConfig from "appConfig";
import { SearchContext } from "context/SearchContext";
import React from "react";
import { Link } from 'react-router-dom';
import { getProfilePath } from "utility/AppRoutingPath";

type Props = {};

const SearchPopup = (props: Props) => {
  const search = React.useContext(SearchContext);

  const GeneresList = () => (
    <List>
      {appConfig.genresArray.map((option: string, index: number) => {
        return (
          <React.Fragment key={index}>
            <ListItem onClick={() => {
              search?.setQuery(option);
            }}>
              <ListItemText
                sx={{
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
                primary={option}
              />
            </ListItem>
            {appConfig.genresArray.length - 1 !== index && (
              <Divider
                sx={{
                  borderBottom: "1px solid #636B82",
                  mx: "1.25rem",
                  ml: "0.8rem",
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </List>
  );

  const handleLinkClick = (newUrl: any) => {
    window.location.href = newUrl;
  };

  const TracksView = () => {
    return (
      <Grid container py={1} px={2}>
        {search?.searchResult.map((option: any, index: number, arr: any) => {
          const userProfileUrl = getProfilePath(option?.username);
          const linkStyles = {
            textDecoration: 'none',
            color: 'white',
          };
          return (
            <React.Fragment key={index}>
              <Grid item flexWrap={'nowrap'} container gap='1rem' alignItems={"center"}>
                <Grid item>
                  <Link to={userProfileUrl} onClick={() => handleLinkClick(userProfileUrl)} style={linkStyles}>
                    <Avatar src={option?.user_image.file_download_url} alt="Remy Sharp" sx={{ borderRadius: '4px' }} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={userProfileUrl} onClick={() => handleLinkClick(userProfileUrl)} style={linkStyles}>
                    <Typography fontWeight={'700'}>{option?.name}</Typography>
                  </Link>
                  {/* <Typography variant="caption" fontWeight={"400"}>{option?.albumName}</Typography> */}
                </Grid>
              </Grid>
              {arr.length - 1 !== index && (
                <Divider
                  sx={{
                    borderBottom: "1px solid #636B82",
                    m: "auto",
                    my: "8px",
                    width: "100%",
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    );
  };

  const tabs = [
    {
      viewName: "Artists/Labels",
      view: TracksView,
    },
  ];
  const [selectedView, setselectedView] = React.useState<
    "Songs" | "Albums" | "Artists" | "Genres"
  >(tabs[0].viewName as any);

  React.useEffect(() => {
    console.log("selectedView", selectedView);
    search?.setType(selectedView);
    search?.searchImplicitly(selectedView, search?.query);
  }, [selectedView]);
  return (
    <>
      <Grid
        container
        borderRadius={"24px"}
        maxHeight={"250px"}
        overflow={"auto"}
      >
        <Grid
          item
          xs={12}
          container
          sx={{
            background: "linear-gradient(1deg, #10151C 0%,#171A25 100%)",
            backdropFilter: "unset",
          }}
        >
          {tabs.map((tab, index) => (
            <Grid
              key={index}
              p="12px 0px 10px 0px"
              textAlign={"center"}
              onClick={() => setselectedView(tab.viewName as any)}
              item
              xs={12 / tabs.length}
              sx={{
                borderBottom:
                  selectedView == tab.viewName
                    ? "2px solid #FA6B05"
                    : "2px solid #1E2332",
                color: selectedView == tab.viewName ? "#fff" : "#636B82",
                transition: "unset",
              }}
            >
              <Typography>{tab.viewName}</Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            background:
              "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
            height: "inherit",
            overflow: "scroll",
            maxHeight: "267px",
          }}
        >
          {tabs[tabs.findIndex((tab) => tab.viewName == selectedView)].view()}
        </Grid>
      </Grid>
    </>
  );
};

export default SearchPopup;
