export const getProfilePath = (username: string | null): string => {
  if (username !== undefined && username !== null) {
    return `/profile/${username}`;
  } else {
    const url = window.location.href;
    const path = new URL(url).pathname;
    return path;
  }
};

export enum AuthenticatedPath {
  Logout = '/logout',
  SearchListing = '/search',
  CheckoutSuccess = '/checkout-success',
  CheckoutFailure = '/checkout-failure',
  Discover = '/discover',
}

export enum UnauthenticatedPath {
  Login = '/login',
  Signup = '/signup',
  Homepage = '/',
  Aboutus = '/aboutus',
  Contactus = '/contactus',
  ForgetPassword = '/forgetpassword',
  PrivacyPolicy = '/privacy-policy',
  TermsConditions = '/terms-and-condition',
  Copyright = '/copyright',
}
