import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import NavbarLayout from "components/layout/NavbarWrapper";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import AboutUsHero from "components/other/AboutUsHero";
import MadeForCreators from "components/other/MadeForCreators";
import MeetTheTeam from "components/other/MeetTheTeam";
import Roadmap from "components/other/Roadmap";
import { BlueCardWrapper } from "components/uiCard/Cards";
import React from "react";

type Props = {};

const AboutusPage = (props: Props) => {
  React.useEffect(() => {
    // scroll to the top of the page smoothly
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <React.Fragment>
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box p={["1rem", "2rem", "4rem", "5rem"]} pt={["7rem","6rem",null,null,null]}>
        <AboutUsHero/>
        <Box p={['40px','60px','70px','90px','90px']}/>
      <MeetTheTeam/>
        <Box p={['40px','60px','70px','90px','90px']}/>
      {/* <Roadmap/> */}
        {/* <Box p={['20px','40px','50px','70px','70px']}/> */}
        {/* <MadeForCreators/> */}
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default AboutusPage;
