import React from "react";
import {
  List,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  listItemButtonClasses,
  listItemTextClasses,
  Divider,
  Avatar,
} from "@mui/material";
import { useNavigate } from 'react-router-dom'
import { ContactMail, Favorite, FavoriteOutlined, InfoOutlined, LocalGroceryStoreOutlined, Login, Logout, MailOutline, PersonAddAltOutlined, PersonOutline, SignpostRounded } from "@mui/icons-material";
import { AuthContext } from "context/AuthContext";
import { AuthenticatedPath, UnauthenticatedPath, getProfilePath } from "utility/AppRoutingPath";
// import profileAlt from "assets/images/profileAlt.png";
import profileAlt from "assets/svg/profileIcon.svg";

interface IMainMenuItem {
  activeMenuCaptionKey: string;
  closeMenuHandler: () => void;
}

const MainMenu: React.FC<IMainMenuItem> = (props) => {
  const navigate = useNavigate();

  const authcontext = React.useContext(AuthContext);

  let profilePicVar = authcontext?.User?.profilePictureUrl ? authcontext?.User?.profilePictureUrl : profileAlt

  const MainMenuItem = [
    {
      GroupCaptionKey: "Contact",
      Menus: [{
        CaptionKey: "Contact Us",
        Icon: <MailOutline/>,
        Path: UnauthenticatedPath.Contactus,
      }],
    },
    {
      GroupCaptionKey: "About Us",
      Menus: [{
        CaptionKey: "About Us",
        Icon: <InfoOutlined/>,
        Path: UnauthenticatedPath.Aboutus,
      }],
    },
  ];

  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const profile_unique_id = userInfo?.username;
  const profilePath = getProfilePath(profile_unique_id);

  const loggedInMenu: any = [
    {
      GroupCaptionKey: "Profile",
      Menus: [
        {
          CaptionKey: "Profile",
          Icon: <Avatar src={profilePicVar} sx={{ width: 24, height: 24, borderRadius: '8px' }}/>,
          Path: profilePath,
        },
      ],
    },
    // {
    //   GroupCaptionKey: "Liked Songs",
    //   Menus: [
    //     {
    //       CaptionKey: "Liked Songs",
    //       Icon: <FavoriteOutlined/>,
    //       Path: UnauthenticatedPath.Homepage,
    //     },
    //   ],
    // },
    // {
    //   GroupCaptionKey: "Store",
    //   Menus: [
    //     {
    //       CaptionKey: "Store",
    //       Icon: <LocalGroceryStoreOutlined/>,
    //       Path: UnauthenticatedPath.Homepage,
    //     },
    //   ],
    // },
    {
      GroupCaptionKey: "Logout",
      Menus: [{
        CaptionKey: "Logout",
        Icon: <Logout/>,
        Path: AuthenticatedPath.Logout,
      }],
    },
  ]

  const loggedOutMenu = [
    {
      GroupCaptionKey: "Login",
      Menus: [{
        CaptionKey: "Login",
        Icon: <Login/>,
        Path: UnauthenticatedPath.Login,
      }],
    },
    {
      GroupCaptionKey: "Signup",
      Menus: [{
        CaptionKey: "Signup",
        Icon: <PersonAddAltOutlined/>,
        Path: UnauthenticatedPath.Signup,
      }],
    },

  ];

  if (authcontext?.isAuthenticated()) {
    MainMenuItem.unshift(...loggedInMenu);

  } else {
    MainMenuItem.push(...loggedOutMenu)
  }

  console.log({ MainMenuItem, s: authcontext?.isAuthenticated() })


  return (
    <React.Fragment>
      {MainMenuItem.map((item, index) => (
        <List
          key={index}
          sx={{
            width: "100%",
            maxWidth: 360,
            color: "#FA6B05",

            "& .Mui-selected": {
              backgroundColor: "#fa6b051c !important",
              color: "#FA6B05",
              borderRadius: "0",
            },
          }}
        >
          {item?.Menus.map((menu, menuIndex) => (
            <React.Fragment key={menuIndex + menu.CaptionKey}>
              <ListItemButton
                sx={{
                  pl: "16px",
                  gap: "1rem",
                  borderRadius: "8px",
                  color: 'white',
                  "& .MuiListItemIcon-root": {
                    color: "inherit",
                  },
                }}
                key={menu.CaptionKey}
                selected={menu.CaptionKey == props.activeMenuCaptionKey}
                onClick={() => {
                  props.closeMenuHandler();
                  navigate(menu.Path);
                }}
              >
                <ListItemIcon sx={{
                  minWidth: "0", m: "auto",
                  "& .MuiSvgIcon-root": {
                    fontSize: "1rem", fill: "currentColor"
                  }
                }}>
                  {menu.Icon}
                </ListItemIcon>
                <ListItemText sx={{ m: "auto" }} primary={menu.CaptionKey} />
              </ListItemButton>
              {/* <Divider color={'#FA6B05'} sx={{marginTop:'8px'}}/> */}
            </React.Fragment>
          ))}
        </List>
      ))}
    </React.Fragment>
  );
};

export default MainMenu;
