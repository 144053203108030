import {
  Box,
  Grid,
  Typography,
  useTheme,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as UploadIcon } from "assets/svg/uploadIcon.svg";
import { AuthContext } from "context/AuthContext";
import ImageTextFieldContainer from "components/controls/ImageTextFieldContainer";
import { DarkInputWithLabel } from "../controls/inputFields";
import { DarkButtonWithIcon } from "components/controls/Buttons";
import GenresSearchInput from "components/controls/GenresSearchInput";
import { AlertContext } from "context/AlertContext";
import { severityType } from "model/errorSeverityTypeEnum";
import ApiReqAutoCompleteInput from "components/controls/AutoCompleteInputString";
import CircularProgress from "@mui/material/CircularProgress";
import { DarkInput } from "components/controls/inputFields";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import HttpTransferService from "services/httptransfer";

type Props = {
  closeDialog: () => void;
  fromSignup?: boolean;
  userData?: any;
};
const httptransfer = new HttpTransferService();

const EditUserForm = (props: Props) => {
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [formData, setFormData] = React.useState<{
    name: string;
    username: string;
    bio: string;
    location: string;
    genres: any;
    user_image?: any;
    type: string;
  }>({
    name: "",
    username: "",
    bio: "",
    location: "",
    type: "",
    genres: [],
  });

  const [old_password, setOldPassword] = React.useState<string>("");
  const [new_password, setNewPassword] = React.useState<string>("");
  const [confirm_password, setConfirmPassword] = React.useState<string>("");
  const [id, setId] = React.useState<string>("");
  const [fileData, setFileData] = React.useState<any>({});
  const [file, setFile] = React.useState<File | null>(null);
  const [locationNameData, setLocationNameData] = React.useState<any[]>([]);
  const [type, setType] = React.useState<any[]>([]);
  const [genreData, setGenreData] = React.useState<any[]>([]);
  const [editLoading, setEditLoading] = React.useState<boolean>(false);
  const authContext = React.useContext(AuthContext);
  const alertContext = React.useContext(AlertContext);
  const [menuSelection, setMenuSelection] = useState<"BASIC" | "PASSWORD">(
    "BASIC"
  );
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [userNameError, setUsernameError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [bioError, setBioError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const handleMenuClick = (menu: "BASIC" | "PASSWORD") => {
    setMenuSelection(menu);

    // If the user clicks "CHANGE PASSWORD," show the password change form
    if (menu === "PASSWORD") {
      setShowPasswordForm(true);
    } else {
      setShowPasswordForm(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "name" && value.length > 30) {
      setNameError("Name should be 30 characters or less");
    } else {
      setNameError("");
    }
    if (name === "bio" && value.length > 600) {
      setBioError("Bio should be 600 characters or less");
    } else {
      setBioError("");
    }
    if (name === "old_password") {
      setOldPassword(value);
      return;
    } else if (name === "new_password") {
      setNewPassword(value);
      return;
    } else if (name === "confirm_password") {
      setConfirmPassword(value);
      if (value !== new_password) {
        setConfirmPasswordError("Passwords must match");
      } else {
        setConfirmPasswordError("");
      }
    } else if (name === "username") {
      const usernameRegex = /^[a-zA-Z0-9_]+$/;
      const isValidUsername = usernameRegex.test(value);

      if (isValidUsername) {
        setUsernameError("");
      } else {
        setUsernameError("Username is invalid");
      }
    }
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const errorHandler = (error: any) => {
    setEditLoading(false);
    window.ShowInfoDialog(error.response.data.message);
  };

  const updateUserJson = (user_image: any) => {
    let data = formData;
    if (user_image?.file_path) {
      data.user_image = user_image;
    }
    const successHandler = () => {
      setEditLoading(false);
      props.closeDialog();
      alertContext?.OpenError({
        title: "User Updated",
        message: "User updated successfully",
        severity: severityType.success,
      });
    };

    authContext?.UpdateUser(
      id,
      data,
      formData.username,
      successHandler,
      errorHandler
    );
  };

  const handleUpdateUser = () => {
    setEditLoading(true);
    let user_image: any;
    const putSuccessCallback = (response: any) => {
      updateUserJson(user_image);
    };
    const fileSuccessCallBack = async (response: any) => {
      const file_upload_url = response?.data?.responseJson?.file_upload_url;
      user_image = {
        file_path: response?.data?.responseJson?.file_path,
        file_extension: fileData.userImagePath.file_extension,
        file_name: fileData.userImagePath.file_name,
      };
      await httptransfer.uploadFileOnAws(
        file,
        file_upload_url,
        putSuccessCallback,
        errorHandler
      );
    };
    if (file?.name) {
      httptransfer.uploadAlbumArt(
        fileData.userImagePath,
        fileSuccessCallBack,
        errorHandler
      );
    } else {
      updateUserJson(user_image);
    }
  };

  const handleChangePassword = () => {
    const passwordSuccessCallback = (response: any) => {
      setEditLoading(false);
      props.closeDialog();
      alertContext?.OpenError({
        title: "Password Changed",
        message: "Password Changed Successfully.",
        severity: severityType.success,
      });
    };
    if (old_password && new_password) {
      setEditLoading(true);
      const data = {
        old_password: old_password,
        new_password: new_password,
      };
      httptransfer.changePassword(
        id,
        data,
        passwordSuccessCallback,
        errorHandler
      );
    }
  };

  let userInfo = authContext?.users ? authContext?.users : props.userData;

  useEffect(() => {
    setFormData({
      name: userInfo.name,
      username: userInfo.username,
      bio: userInfo.bio,
      location: userInfo.location,
      genres: userInfo.genres,
      type: userInfo.type,
    });
    if (userInfo.location !== "") {
      setLocationNameData([userInfo.location]);
    }
    if (userInfo.type !== "") {
      setType([userInfo.type]);
    }
    if (userInfo.genres.length > 0) {
      setGenreData(userInfo.genres);
    }
    if (userInfo._id !== "") {
      setId(userInfo._id);
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (locationNameData.length > 0) {
      setFormData(prev => ({
        ...prev,
        location: locationNameData.join(","),
      }));
    } else {
      setFormData(prev => ({ ...prev, location: "" }));
    }
  }, [locationNameData]);

  React.useEffect(() => {
    if (type.length > 0) {
      setFormData(prev => ({
        ...prev,
        type: type.join(","),
      }));
    } else {
      setFormData(prev => ({ ...prev, type: "" }));
    }
  }, [type]);

  React.useEffect(() => {
    setFormData(prev => ({ ...prev, genres: genreData }));
  }, [genreData]);

  return (
    <React.Fragment>
      <Grid container>
        {/* Form container */}
        <Grid item xs={12} sm={12} md={3} justifyContent={"center"}>
          <List>
            <ListItemButton
              selected={menuSelection === "BASIC"}
              onClick={() => handleMenuClick("BASIC")}
            >
              <ListItemText primary="BASIC INFO" />
            </ListItemButton>
            <ListItemButton
              selected={menuSelection === "PASSWORD"}
              onClick={() => handleMenuClick("PASSWORD")}
            >
              <ListItemText primary="CHANGE PASSWORD" />
            </ListItemButton>
          </List>
        </Grid>
        <Grid container item xs={9} gap="20px" justifyContent={"center"}>
          {menuSelection === "BASIC" && !showPasswordForm && (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                container
                justifyContent={"center"}
                height="max-content"
              >
                {/* Profile picture container */}
                <ImageTextFieldContainer
                  data={userInfo.user_image}
                  Datakey={"userImagePath"}
                  editIcon={true}
                  fileData={fileData}
                  file={file}
                  imagePreview={imagePreview}
                  setFileData={setFileData}
                  setFile={setFile}
                  setImagePreview={setImagePreview}
                  placeholderCondition={!imagePreview}
                  fallBackProfilePic
                  placeholderNode={
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#08090C",
                        borderRadius: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <UploadIcon />
                      <Typography
                        sx={{
                          textAlign: "center",
                          color: "#ffffff",
                          fontSize: "0.8rem",
                          fontWeight: 500,
                          marginLeft: "0.5rem",
                        }}
                      >
                        Upload Profile Picture
                      </Typography>
                    </Box>
                  }
                />
              </Grid>

              {/* Form fields container */}
              <Grid
                item
                container
                xs={"auto"}
                direction="column"
                width={["100%", "100%", "unset"]}
              >
                <DarkInputWithLabel
                  label={
                    props.fromSignup
                      ? "USER/ARTIST/LABEL NAME"
                      : userInfo.type === "LABEL"
                      ? "LABEL NAME"
                      : "ARTIST NAME"
                  }
                  value={formData.name}
                  name={"name"}
                  type={"text"}
                  onChange={handleChange}
                  sx={{ marginBottom: "1.25rem" }}
                  disabledButtonStatus={true}
                />
                {nameError && (
                  <Typography variant="caption" color="error">
                    {nameError}
                  </Typography>
                )}

                <ApiReqAutoCompleteInput
                  multipleValue={false}
                  key={"Location"}
                  addNewOption={true}
                  inputName={"location"}
                  setState={setLocationNameData}
                  state={locationNameData}
                  label={"LOCATION"}
                  type={"LOCATION"}
                />
                <DarkInputWithLabel
                  label={props.fromSignup ? "USER ID" : "USER ID"}
                  value={formData.username}
                  name={"username"}
                  type={"text"}
                  onChange={handleChange}
                  sx={{ marginBottom: "1.25rem" }}
                  disabledButtonStatus={true}
                />
                {userNameError && (
                  <Typography variant="caption" color="error">
                    {userNameError}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={"auto"}
                direction="column"
                width={["100%", "100%", "unset"]}
              >
                {userInfo.type === "USER" ? (
                  <ApiReqAutoCompleteInput
                    multipleValue={false}
                    key={"type"}
                    addNewOption={true}
                    inputName={"type"}
                    setState={setType}
                    state={type}
                    label={"TYPE"}
                    type={"TYPE"}
                  />
                ) : (
                  <DarkInputWithLabel
                    label={"TYPE"}
                    value={formData.type}
                    name={"type"}
                    type={"text"}
                    inputProps={{
                      readOnly: true,
                    }}
                    rows={5.4}
                    sx={{ marginBottom: "1.25rem" }}
                  />
                )}

                <DarkInputWithLabel
                  label={props.fromSignup ? "BIO" : "BIO"}
                  value={formData.bio}
                  name={"bio"}
                  type={"text"}
                  rows={5.4}
                  onChange={handleChange}
                  multiline
                  sx={{ marginBottom: "1.25rem" }}
                />
                {bioError && (
                  <Typography variant="caption" color="error">
                    {bioError}
                  </Typography>
                )}

                <ApiReqAutoCompleteInput
                  multipleValue={true}
                  key={"genre"}
                  addNewOption={true}
                  inputName={"genre"}
                  setState={setGenreData}
                  state={genreData}
                  label={"EDIT GENRE / MOOD"}
                  type={"GENRE"}
                />
              </Grid>
            </>
          )}
          {showPasswordForm && (
            <Grid justifyContent={"flex-start"} xs={12} sm={12} md={7}>
              <DarkInput
                value={old_password}
                name="old_password"
                onChange={handleChange}
                placeholder={"Old Password"}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    WebkitTextSecurity: showPassword ? "" : "disc",
                  },
                  marginBottom: "2rem",
                  marginTop: "1rem",
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle OTP visibility"
                        onClick={() => setShowPassword(prev => !prev)}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <DarkInput
                value={new_password}
                name="new_password"
                onChange={handleChange}
                placeholder={"New Password"}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    WebkitTextSecurity: showNewPassword ? "" : "disc",
                  },
                  marginBottom: "2rem",
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle OTP visibility"
                        onClick={() => setShowNewPassword(prev => !prev)}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <DarkInput
                value={confirm_password}
                name="confirm_password"
                onChange={handleChange}
                placeholder={"Confirm Password"}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    WebkitTextSecurity: showConfirmPassword ? "" : "disc",
                  },
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle OTP visibility"
                        onClick={() => setShowConfirmPassword(prev => !prev)}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {confirmPasswordError && (
                <Typography variant="caption" color="error">
                  {confirmPasswordError}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
        {/* Save button container */}
        <Grid
          item
          container
          justifyContent={"flex-end"}
          sx={{ marginTop: "1.25rem" }}
        >
          <DarkButtonWithIcon
            onClick={
              menuSelection === "BASIC"
                ? handleUpdateUser
                : handleChangePassword
            }
            sx={{
              width: "max-content",
            }}
            disabled={
              menuSelection === "PASSWORD" &&
              (!old_password || !new_password || !confirm_password)
            }
          >
            {editLoading ? (
              <CircularProgress
                size={15}
                style={{ marginRight: "8px" }}
                color="secondary"
              />
            ) : null}
            SAVE CHANGES
          </DarkButtonWithIcon>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditUserForm;
