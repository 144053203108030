import { Avatar, Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import { ReactComponent as UploadIcon } from "assets/svg/uploadIcon.svg";
import { AuthContext } from "context/AuthContext";

type Props = {
  editIcon: boolean;
  imagePreview: string | null;
  setImagePreview: React.Dispatch<React.SetStateAction<string | null>>;
  fileData: any;
  file: any;
  data: any;
  setFileData: React.Dispatch<React.SetStateAction<any>>;
  setFile: React.Dispatch<React.SetStateAction<any>>;
  placeholderCondition: boolean;
  placeholderNode: JSX.Element;
  Datakey: String;
  fallBackProfilePic?: boolean;
  setUnsavedChanges?: (value: boolean) => void;
};

const ImageTextFieldContainer = ({
  fallBackProfilePic,
  data,
  Datakey,
  editIcon,
  fileData,
  file,
  imagePreview,
  setFileData,
  setFile,
  setImagePreview,
  placeholderCondition,
  placeholderNode,
  setUnsavedChanges,
}: Props) => {
  const [isHovered, setIsHovered] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const userInfo = React.useContext(AuthContext)!.User!;
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <React.Fragment>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          width: Datakey === "userImagePath" ? 150 : 200,
          height: Datakey === "userImagePath" ? 150 : 200,
          background: imagePreview
            ? `url(${imagePreview}) no-repeat center center`
            : data
            ? `url(${data?.file_download_url}) no-repeat center center`
            : "none",
          backgroundSize: "cover",
          borderRadius: "24px",
          position: "relative",
        }}
      >
        <TextField
          ref={inputRef}
          type={"file"}
          inputProps={{ accept: "image/*" }}
          onChange={e => {
            const file = (e.target as any).files![0];
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);

            console.log(file);
            const fileExtension = file.name.split(".").pop();
            const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, "");
            const fileInfo = {
              file_type: file.type.split("/")[0],
              file_extension: fileExtension,
              file_name: fileNameWithoutExtension,
            };

            console.log("fileInfo", fileInfo);

            setFileData({
              [Datakey as string]: fileInfo,
            });
            setFile(file);
            setUnsavedChanges && setUnsavedChanges(true);
          }}
          sx={{
            opacity: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            "& .MuiOutlinedInput-input": {
              opacity: 0,
              width: "100%",
              height: "100%",
            },
            "& .MuiOutlinedInput-root": {
              opacity: 0,
              width: "100%",
              height: "100%",
            },
            userSelect: "unset",
            pointerEvents: "unset",
          }}
        />

        {/* if there is no profile picture and no image preview tell user to upload a profile picture using this input */}
        {!data?.file_download_url && placeholderCondition && placeholderNode}

        {editIcon && isHovered && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: isHovered ? 2 : 0,
              "& svg": {
                width: "3rem",
                height: "3rem",
                // fill: '#fff',
              },
              "@media (max-width: 600px)": {
                display: "none",
              },
            }}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              const input = document.querySelector(
                "input[type='file']"
              ) as HTMLInputElement;
              input.click();
              console.log("first");
            }}
          >
            {/* <Edit /> */}
            <UploadIcon />
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ImageTextFieldContainer;
