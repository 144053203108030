import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import NavbarLayout from 'components/layout/NavbarWrapper';
import WaveBackgroundLayout from 'components/layout/WaveBackgroundLayout';
import AboutUsHero from 'components/other/AboutUsHero';
import MadeForCreators from 'components/other/MadeForCreators';
import MeetTheTeam from 'components/other/MeetTheTeam';
import Roadmap from 'components/other/Roadmap';
import { BlueCardWrapper } from 'components/uiCard/Cards';
import React from 'react';

type Props = {};

const AboutusPage = (props: Props) => {
  React.useEffect(() => {
    // scroll to the top of the page smoothly
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box
          p={['1rem', '2rem', '4rem', '5rem']}
          pt={['7rem', '6rem', null, null, null]}
        >
          <Grid
            container
            justifyContent={'center'}
            alignItems='center'
            gap={4}
            color='white'
          >
            {/* <img src={heroImage} style={{width:'100%',height:'100%'}} alt=''/> */}
            <Grid
              container
              item
              alignItems='center'
              direction={'column'}
              justifyContent='center'
            >
              {/* <Grid container item xs={4} justifyContent='center' sx={{
        "& img": {
          height:responsiveImageHeight,
          width:responsiveImageWidth
        }
      }}><img src={LogoPng} alt=''/></Grid> */}
              <Grid container item xs={8} justifyContent='flex-start'>
                <Typography
                  // variant={"h1"}
                  fontWeight={700}
                  sx={{
                    '@media (min-width: 1200px)': {},
                    fontSize: ['2rem', '2.5rem', '3.5rem', '4rem', '5.2rem'],
                    fontFamily: "'Darker Grotesque', sans-serif",
                    // lineHeight: '0.8',
                    // mb: "1.5rem",
                  }}
                >
                  <span
                    style={{
                      color: '#FA6B05',
                      fontFamily: "'Darker Grotesque', sans-serif",
                    }}
                  >
                    Copyright
                  </span>{' '}
                  Guidlines
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  textAlign={'justify'}
                  sx={{
                    fontSize: {
                      xs: '0.8rem',
                      sm: '1rem',
                      md: '1.25rem',
                      lg: '1.5rem',
                    },
                    overflowWrap: 'break-word',
                    whiteSpace: 'break-spaces',
                  }}
                  fontWeight={500}
                >
                  A Client using any aspect of Engage Music is bound to adhere
                  to the content guidelines set out herein (“Content
                  Guidelines”). These Content Guidelines form an integral part
                  of the Agreement between you as a user of the Platform and us.{' '}
                  <br />
                  <br />
                  Where you believe that any information made available on the
                  Platform, including Digital Content violates these Content
                  Guidelines, you may report such content by writing to us at
                  support@engagemusic.io. We will endeavour to resolve all such
                  grievances within a reasonable time period, as mandated under
                  laws applicable within the territory of India. Any decision
                  taken by us relating to any grievance will be binding on the
                  user. <br />
                  <br />
                  You may contact Himay, Grievance Officer at Email:
                  support@engagemusic.io. <br />
                  <br />
                  Where we become aware of any violation of these Content
                  Guidelines, we may remove/sever such content from the
                  Platform, and take such other actions in accordance with the
                  Agreement and Platform Policies applicable to you. <br />
                  <br />
                  Where any content is removed or de-listed from the Platform,
                  or a user’s access to the Platform is disabled owing to a
                  violation of these Content Guidelines, we will provide notice
                  to the user/affected party of the actions taken as soon as
                  practicable, which may be before or after the implementation
                  of such actions, at our sole discretion. Affected parties may
                  appeal against such decision/action in accordance with
                  applicable law. <br />
                  <br />
                  Where any content is taken down on account of a request from
                  competent law enforcement or government agency, or upon
                  receipt of an order/direction from a court of competent
                  jurisdiction, we will notify the user who makes available such
                  content on the Platform of the details of such
                  request/order/direction and in such a case, the user may
                  appeal the said decision as per applicable law. <br />
                  <br />
                  Any undefined term in these Content Guidelines shall have the
                  meaning ascribed to it in the Terms of Service and Platform
                  Policies as may be applicable to you. <br />
                  <br />
                  Content which is prohibited <br />
                  <br />
                  No person may publish, list, or make available any information
                  or content that qualifies under any one or more of the
                  following categories. <br />
                  <br />
                  <strong>
                    Promotion of online falsehood and manipulation:
                  </strong>
                  <ol type='1'>
                    <li>
                      Content that is patently false and untrue, and is written
                      or published in any form, with the intent to mislead or
                      harass a person, entity or agency for financial gain or to
                      cause any injury to any person.
                    </li>
                    <br />
                    <li>
                      {' '}
                      Content that deceives or misleads the user about its
                      origin or knowingly and intentionally communicates any
                      information which is patently false or misleading but may
                      reasonably be perceived as a fact.
                    </li>
                    <br />
                    <li>
                      {' '}
                      Content that falsifies or denies well-documented and
                      violent events that have taken or are taking place.
                    </li>
                    <br />
                    <li>
                      {' '}
                      Content that patently false and untrue or misleading in
                      nature or false or misleading by such fact check unit of
                      the Central Government in India.
                    </li>
                  </ol>
                  <br />
                  <ol type='1'>
                    <li>
                      <strong>Harmful to Minors</strong>
                      <ol type='i'>
                        <li>
                          Content that is harmful to a minor in any manner.
                        </li>
                        <br />
                        <li>
                          Content that contains or normalizes the trade or
                          distribution of child sexual abuse material or child
                          pornography.
                        </li>
                        <br />
                        <li>
                          Content that depicts, solicits, normalizes, glorifies,
                          or encourages child abuse or sexual activity with
                          minors or nudity amongst minors.
                        </li>
                        <br />
                        <li>
                          Content that solicits, normalizes, glorifies, or
                          encourages minors to connect with an adult on another
                          website, mobile application, or any other digital
                          resource.
                        </li>
                        <br />
                        <li>
                          Content that solicits, depicts, promotes, normalizes,
                          or glorifies grooming behaviour.
                        </li>
                        <br />
                        <li>
                          Content that solicits, depicts, imitates, or promotes
                          the consumption or trade of alcohol, tobacco, or drugs
                          by a minor.
                        </li>
                        <br />
                        <li>
                          Content that solicits nude imagery or sexual contact
                          through coercion.
                        </li>
                        <br />
                        <li>
                          Content that incentivizes, exploits, or takes
                          advantage of any child victim of any abuse, sexual or
                          otherwise, or their story/confessions/information
                          relating to such abuse.
                        </li>
                        <br />
                        <li>
                          Content that depicts, normalizes or encourages a minor
                          to engage in nudity or sexual activity.
                        </li>
                        <br />
                        <li>
                          Content that depicts, normalizes or encourages
                          physical abuse, neglect, endangerment, or
                          psychological disparagement of minor.
                        </li>
                        <br />
                        <li>
                          Content that depicts, normalizes or encourages minors
                          to use dangerous tools or weapons and/or participate
                          in dangerous, harmful, or life-threatening activities.
                        </li>
                        <br />
                        <li>
                          Content that depicts, glorifies, normalizes or
                          encourages minors to engage in self-harm or suicide.
                        </li>
                        <br />
                        <li>
                          Content that depicts, glorifies, normalizes or
                          encourages minors to indulge in disordered eating or
                          dangerous weight loss behaviour associated with
                          disordered eating.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Obscene content</strong>
                      <ol type='i'>
                        <li>
                          Content that is obscene or has been adjudicated by a
                          court of competent jurisdiction to be obscene.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises users
                          to engage in obscene or sexual activity publicly.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises users
                          to engage in pedophilic activity.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises any
                          person to engage in non-consensual sexual activity.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises any
                          person to engage in sexual abuse or sexual violence.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises any
                          person to transmit, publish, or share any material
                          depicting another person in full or partial nudity
                          without their consent.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises any
                          person to solicit sexual favours or indulge in sexual
                          harassment or sexual assault.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that contains, normalizes, or encourages the
                          use of sexually explicit language depicting or
                          describing a minor or any vulnerable groups.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that modifies an image of another individual
                          to depict, whether directly or indirectly, involvement
                          in sexual activity or nudity.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that depicts or threatens to reveal the
                          details of person’s sexual life or sexual orientation
                          without their consent.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Other unlawful content</strong>
                      <ol type='i'>
                        <li>
                          Content that depicts, promotes, normalises, or
                          encourages others to indulge in illegal activities.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that depicts, promotes, normalizes, or
                          encourages an online game that causes user harm.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that is in the nature of an online game that
                          is not verified as a permissible online game;
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that is in the nature of advertisement or
                          surrogate advertisement promotion of an online game
                          that is not a permissible online game in India, or of
                          any online gaming intermediary offering such an online
                          game.
                        </li>
                        <br />
                        <li> Content that is defamatory.</li>
                        <br />
                        <li>
                          {' '}
                          Content that is invasive of another’s privacy,
                          including bodily privacy.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that is discriminatory, insulting or harassing
                          on the basis of gender, race, ethnicity, caste, sexual
                          orientation, nationality, appearance, personality
                          traits, hygiene, intellect, medical condition, etc.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that includes the use of non-self-referential
                          insults.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that solicits, normalises, glorifies, or
                          promotes enmity or inflicting any violence or
                          hostility against any person or group of persons on
                          the difference in political ideologies or opinions,
                          gender, sexual orientation, religion, caste, race,
                          ethnicity, medical condition, or nationality.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, supports, or advertises
                          conversion therapy or other such programs or
                          initiatives.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, supports violent
                          acts or extremist organizations or individuals or
                          encourages users to participate in such activities in
                          any manner, including solicitation to volunteer or
                          become a part of such extremist organizations.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content with details that represent violent extremist
                          organizations or individuals.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that solicits, depicts, imitates, or promotes
                          the consumption or trade of alcohol, tobacco, or
                          drugs.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies or normalises
                          physical, mental and/or emotional abuse or violence.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises
                          inflicting self-harm in any manner, including
                          committing suicide.
                        </li>
                        <br />
                        <li> Content that impersonates another person.</li>
                        <br />
                        <li>
                          {' '}
                          Content that contains software virus or any other
                          computer code, file or program designed to interrupt,
                          destroy or limit the functionality of any computer
                          resource.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that ridicules victims of tragedies or events
                          or encourages, normalizes persons to ridicule such
                          victims.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises cyber
                          bullying, cyber stalking, trolling.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that ridicules another person’s life choices
                          or activities.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that promotes, glorifies, or normalises the
                          use of personal information belonging to another
                          person without their consent.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that threatens to blackmail an individual or
                          to hack an individual&#39;s account with a bank, or
                          another platform.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that depicts, promotes, normalises, or
                          encourages others to possess, trade in, or be involved
                          in the use or trade of illegal firearms, weapons,
                          ammunition, drugs, alcohol, controlled substances, or
                          other restricted or prohibited tools or products.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that solicits, normalises, or encourages
                          others to promote phishing, betting, gambling,
                          lottery, Ponzi schemes, multi-level marketing schemes,
                          and other similar services or activities.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that encourages money laundering or gambling.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that depicts, solicits, normalises, or
                          encourages others to abuse or inflict harm of animals.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that is intended to interfere with elections.
                        </li>
                        <br />
                        <li>
                          {' '}
                          Content that violates any law for the time being in
                          force.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>
                        Infringement of Intellectual Property Rights
                      </strong>
                      <ol type='i'>
                        <li>
                          Content that belongs to another person and the user
                          does not have any right to it.
                        </li>
                        <br />
                        <li>
                          Content that infringes any patent, trademark,
                          copyright, or other proprietary rights of any other
                          person.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Security of state</strong>
                      <ol type='i'>
                        <li>
                          Content that threatens defence, security or
                          sovereignty of any country.
                        </li>
                        <br />
                        <li>
                          Content that causes incitement to the commission of
                          any offence or prevents investigation of any offence
                          or is insulting to any nation.
                        </li>
                        <br />
                        <li>
                          Content that promotes, glorifies, normalizes waging
                          war against another nation.
                        </li>
                      </ol>
                    </li>
                  </ol>
                  
                  <p>Last Updated: 1st May 2023</p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default AboutusPage;
