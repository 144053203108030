import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { MusicStoreContext } from "context/MusicStoreContext";
import React, { useEffect } from "react";
import { TrackUploadContext } from "context/TrackUploadContext";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import { DarkButtonWithIcon } from "components/controls/Buttons";
import RazorpayCheckout from "components/customisedComponents/RazorpayCheckout";
import { useContext } from "react";
type Props = {};

const CartListing = (props: Props) => {
  const musicStoreCtx = useContext(MusicStoreContext);

  // useEffect(() => {
  //   console.log("Music store context in CartListing:", musicStoreCtx);
  // }, [musicStoreCtx]);

  const handleRemoveFromCart = (params: any) => {
    musicStoreCtx && musicStoreCtx.removeFromCart(params);
  };

  const handlePayment = async () => {
    musicStoreCtx && (await musicStoreCtx.Payment());
  };

  const musicCartDetailCardJsx = (item: any) => {
    if (!item) return null;
    return (
      <React.Fragment>
        <ListItem
          alignItems="flex-start"
          secondaryAction={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ color: "white" }}
                variant="body2"
                color="text.primary"
              >
                ${item.price || "N/A"}
              </Typography>
              <IconButton
                onClick={() => handleRemoveFromCart({ music_id: item._id })}
                sx={{ color: "white" }}
                edge="end"
                aria-label="delete"
              >
                <IndeterminateCheckBoxOutlinedIcon />
              </IconButton>
            </Box>
          }
        >
          <ListItemAvatar>
            <Avatar
              alt={item.name || "Unknown"}
              variant="rounded"
              src={item?.music_cover_image?.file_download_url || ""}
            />
          </ListItemAvatar>
          <ListItemText
            secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
            sx={{ mt: "0.6rem" }}
            primary={item.name || "Unknown Track"}
            secondary={
              item.artist_collaborators_name?.join(", ") || "Unknown Artist"
            }
          />
        </ListItem>
        <Divider
          variant="inset"
          component="div"
          sx={{ borderColor: "#636B82", m: 0, mt: "0.5rem" }}
        />
      </React.Fragment>
    );
  };

  const albumCartDetailCardJsx = (item: any) => {
    if (!item) return null;
    return (
      <React.Fragment>
        <ListItem
          alignItems="flex-start"
          secondaryAction={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography
                sx={{ color: "white" }}
                variant="body2"
                color="text.primary"
              >
                ${item.price || "N/A"}
              </Typography>
              <IconButton
                onClick={() =>
                  handleRemoveFromCart({ album_id: item._id || "" })
                }
                sx={{ color: "white" }}
                edge="end"
                aria-label="delete"
              >
                <IndeterminateCheckBoxOutlinedIcon />
              </IconButton>
            </Box>
          }
        >
          <ListItemAvatar>
            <Avatar
              alt={item.name || "Unknown"}
              variant="rounded"
              src={item.album_cover_image?.file_download_url || ""}
            />
          </ListItemAvatar>
          <ListItemText
            secondaryTypographyProps={{ color: "white", fontSize: "0.8rem" }}
            sx={{ mt: "0.6rem" }}
            primary={item.name || "Unknown Album"}
            secondary={
              item.artist_collaborators_name?.join(", ") || "Unknown Artist"
            }
          />
        </ListItem>
        <Divider
          variant="inset"
          component="div"
          sx={{ borderColor: "#636B82", m: 0, mt: "0.5rem" }}
        />
      </React.Fragment>
    );
  };

  //elements for Razorpay
  const currency = "INR"; // Assuming INR, modify if needed

  return (
    <React.Fragment>
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xs={12} textAlign={"center"}>
          <Typography variant="h6">My Cart</Typography>
        </Grid>

        <Grid item xs={12} textAlign={"center"}>
          <List sx={{ width: "100%", color: "white" }}>
            {musicStoreCtx?.musics?.musicData.map((song: any) => (
              <React.Fragment key={song._id}>
                {musicCartDetailCardJsx(song)}
              </React.Fragment>
            ))}

            {musicStoreCtx?.Albums?.albumData.map((album: any) => (
              <React.Fragment key={album._id}>
                {albumCartDetailCardJsx(album)}
              </React.Fragment>
            ))}
          </List>
        </Grid>

        {/* Added the RazorpayCheckout with the dynamic ammount and currency set to India's current currency */}
        <Grid item xs={12}>
          <RazorpayCheckout
            items={[
              ...musicStoreCtx?.musics?.musicData,
              ...musicStoreCtx?.Albums?.albumData,
            ]}
            currency={currency}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <DarkButtonWithIcon disabled={true} sx={{ width: "100%", mt: 1 }}>
            CHECKOUT
          </DarkButtonWithIcon>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export default CartListing;
