import React, { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import HttpTransferService from 'services/httptransfer';

const httptransfer = new HttpTransferService();

type Props = {
  children: React.ReactNode | React.ReactNode[];
}

export const PlayListContext = React.createContext<any>(null);
const userInfoString = localStorage.getItem('userInfo');
const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

const PlaylistContextProvider: React.FC<Props> = (props) => {
  const authcontext = React.useContext(AuthContext)!;
  const [playListData, setPlayListData] = useState<any[]>([]);
  const [likedPlaylistData, setLikedPlaylistData] = useState<any[]>([]);
  const [getListLoading, setGetListLoading] = useState<boolean>(false);

  const getAllPlayList = async () => {
    //setGetListLoading(true);
    const successCallback = (res: any) => {
      setPlayListData(res.data.playlists);
      const likedMusicPlaylist = res.data.playlists.find((playlist: any) => playlist.type === 'LIKED_MUSIC_PLAYLIST');
      if (likedMusicPlaylist) {
        setLikedPlaylistData(likedMusicPlaylist);
      }
      //setGetListLoading(false);
    };
    const errorCallback = (err: any) => {
      console.log("err", err);
      //setGetListLoading(false);
    };
    await httptransfer.playlistQuery({ user_id: [userInfo?._id] }, successCallback, errorCallback)
  };

  useEffect(() => {
    if (userInfo?._id) {
      getAllPlayList();
    }
  }, [userInfo?._id]);


  return (
    <PlayListContext.Provider
      value={{
        GetAllPlayList: getAllPlayList,
        GetListLoading: getListLoading,
        PlayListData: playListData,
        LikedPlaylistData: likedPlaylistData,
      }}
    >
      {props.children}
    </PlayListContext.Provider>
  );
};

export default PlaylistContextProvider;
