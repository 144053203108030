import React from 'react';
import ManageSongListing from './ManageSongListing';
import ProfileListWrapper from './ProfileListWrapper';
import { Grid, Box } from '@mui/material';

type Props = {
    albumId:any,
    visible: any,
    labelData: any
};
const ManageSong = (props: Props) => {
    const tabListingData = 
  [
    {
      viewName: "MANAGE SONGS",
      view: (
        <Box overflow="auto" maxHeight="500px">
          <ManageSongListing visible={props.visible} albumId={props.albumId} labelData={props.labelData}/>
        </Box>
      )
    },
]
    return (
        <React.Fragment>
            <>
            <Grid container justifyContent={'center'}>
            <Grid height='fit-content' item container xs={12} sm={12} md={7} lg={7}><ProfileListWrapper tabs={tabListingData} /></Grid>
            </Grid>
            </>
        </React.Fragment>
    )
}

export default ManageSong;
