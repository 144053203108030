import { Backdrop, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ReactComponent as CloseSquare } from "assets/svg/closeSquare.svg";
import { ReactComponent as CloseSquare2 } from "assets/svg/closeSquare2.svg";
import { color } from '@mui/system';

type Props = {
  children: React.ReactNode
  dialogOpen: boolean
  closeDialog: () => void
  dialogTitle: string
  unsavedChanges?: boolean;
  setUnsavedChanges?: (value: boolean) => void;
  disableIconButton?: boolean;
  editPlayList?: boolean;
}

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100000,
  background:
    "linear-gradient(180deg, #1E2332 0%, rgba(30, 35, 50, 0.12) 100%)",
  backdropFilter: "blur(100px)"
}))

const CustomisedDialogContent = styled(DialogContent)(({ theme }) => ({
  background:
    "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
  border: "1px solid #636B82",
  backdropFilter: "blur(100px)",
  borderRadius: "24px",
  color: "white"
}))

const DialogPopup = (props: Props) => {
  const theme = useTheme();

  const closeDialog = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      if (props.unsavedChanges) {
        window.ShowConfirmationDialog(
          'The changes are not saved yet. Do you want to discard the changes?',
          () => {
            props.closeDialog();
            props.setUnsavedChanges && props.setUnsavedChanges(false);
          },
          () => {
          },
          null,
          'Confirm',
          'Cancel' 
        );
      } else {
        // No unsaved changes, close the dialog
        props.closeDialog();
      }
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.dialogOpen}
        onClose={closeDialog}
        closeAfterTransition
        BackdropComponent={CustomBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            boxShadow: "none",
            borderRadius: "unset",
            ...(props.editPlayList
              ? {}
              : {
                  maxWidth: "1000px",
                  [theme.breakpoints.down("sm")]: {
                    minWidth: "90%",
                  },
                }),
          }
        }}
      >
        <Grid mb='1.5rem' width='100%' container item justifyContent={'space-between'} >
          <Typography
            variant='h5'
            sx={{
              color: "white",
              fontWeight: "bold",
            }}
          >
            {props.dialogTitle}
          </Typography>
          <IconButton
            onClick={(event) => closeDialog(event, 'closeButton')}
            sx={{
              padding: '0px',
            }}
            disabled={props.disableIconButton}
          >
            {props.disableIconButton ? <CloseSquare2 /> : <CloseSquare />}
          </IconButton>
        </Grid>
        <CustomisedDialogContent>
          {props.children}
        </CustomisedDialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default DialogPopup