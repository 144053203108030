import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import React from "react";

type Props = {};

const AboutusPage = (props: Props) => {
  React.useEffect(() => {
    // scroll to the top of the page smoothly
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <React.Fragment>
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box
          p={["1rem", "2rem", "4rem", "5rem"]}
          pt={["7rem", "6rem", null, null, null]}
        >
          <Grid
            container
            justifyContent={"center"}
            alignItems="center"
            gap={4}
            color="white"
          >
            {/* <img src={heroImage} style={{width:'100%',height:'100%'}} alt=''/> */}
            <Grid
              container
              item
              alignItems="center"
              direction={"column"}
              justifyContent="center"
            >
              {/* <Grid container item xs={4} justifyContent='center' sx={{
        "& img": {
          height:responsiveImageHeight,
          width:responsiveImageWidth
        }
      }}><img src={LogoPng} alt=''/></Grid> */}
              <Grid container item xs={8} justifyContent="flex-start">
                <Typography
                  // variant={"h1"}
                  fontWeight={700}
                  sx={{
                    "@media (min-width: 1200px)": {},
                    fontSize: ["2rem", "2.5rem", "3.5rem", "4rem", "5.2rem"],
                    fontFamily: "'Darker Grotesque', sans-serif",
                    // lineHeight: '0.8',
                    // mb: "1.5rem",
                  }}
                >
                  <span
                    style={{
                      color: "#FA6B05",
                      fontFamily: "'Darker Grotesque', sans-serif",
                    }}
                  >
                    User Terms &
                  </span>{" "}
                  Conditions
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                <Typography
                  textAlign={"justify"}
                  sx={{
                    fontSize: {
                      xs: "0.8rem",
                      sm: "1rem",
                      md: "1.25rem",
                      lg: "1.5rem",
                    },
                    overflowWrap: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                  fontWeight={500}
                >
                  Engage Music is an online marketplace offered by Engage Music
                  Pvt. Ltd. (hereinafter referred to as “Engage” “we” or “us” or
                  “our”) for the purchase of audio products made available on
                  the online marketplace by third-party sellers (“Products”).
                  <br />
                  <br />
                  These Terms of Use (“Terms”), Privacay Policy, and the
                  Intellectual Property Right’s Policy (“IPR Policy”)
                  (hereinafter, the Terms, Privacy Policy and the IPR Policy
                  shall together be referred to as “Platform Policies”) govern
                  your use of Engage Music through the constituent mobile app
                  and/or website (“Platform”), and forms a binding legal
                  agreement between you, as a user and buyer of Products through
                  the Platform (“Buyer” or “User” or “you” and its grammatical
                  variations) and us. <br />
                  <br />
                  By using the Platform, you consent to be bound by the Platform
                  Policies. The effective Platform Policies contain all the
                  terms agreed between you and us regarding the access to and
                  use of the Platform and supersedes and excludes any prior
                  terms and conditions made between you and us. Accordingly, you
                  are advised to read each of the Platform Policies carefully
                  before using the Platform. <br />
                  <br /> We reserve the right to periodically review, update, or
                  otherwise modify any part of the Platform and/or Platform
                  Policies at our sole and absolute discretion, and accordingly,
                  you are advised to keep yourself updated on the latest
                  Platform Policies made available on the Platform. We will
                  provide you with notice of any modification to the Terms, and
                  your continued access and use of the Platform after such
                  notification shall constitute consent to the modified Platform
                  Policies. If you do not accept any part of the Platform
                  Policies or any subsequent modification therein, you must stop
                  accessing or using our Platform. <br />
                  <br />
                  In the event that one or more of the terms hereunder or part
                  thereof is determined to be invalid or unlawful for any reason
                  by a competent judicial or quasi-judicial authority in India,
                  the validity or enforceability of the remaining terms will not
                  be affected and the same will remain valid and binding on you.{" "}
                  <br />
                  <br />
                  This document constitutes an electronic record under the
                  provisions of the Information Technology Act, 2000 and the
                  rules thereunder, and does not require any physical or digital
                  signatures. <br />
                  <br />
                  <ol type="1">
                    <li>
                      <strong>Your Account</strong>
                      <ol type="A">
                        <li>
                          Our Platform is available only to persons who can form
                          a legally binding contract under the Indian Contract
                          Act, 1872, and is not intended for or offered to any
                          person below the age of eighteen (18) years.
                        </li>
                        <br />
                        <li>
                          You are required to register and create an account
                          with us (“User Account”), through the registration
                          process available here, in order to access and use the
                          functions of the Platform and make purchases of
                          Products.
                        </li>
                        <br />
                        <li>
                          You are responsible for maintaining the
                          confidentiality of your User Account login credentials
                          and for taking appropriate measures to prevent
                          unauthorized access to your User Account.
                        </li>
                        <br />
                        <li>
                          You agree to accept complete responsibility for all
                          activities that occur on the Platform through your
                          User Account, and/or using the email address and phone
                          number registered with it. You should inform us
                          immediately if you have any reason to believe that
                          your User Account login credentials have become known
                          to anyone else, or if the password is being, or is
                          likely to be, used in an unauthorized manner.
                        </li>
                        <br />
                        <li>
                          You are required to provide us with accurate and
                          complete information relating to your identity, such
                          as your name, location, and email address at the time
                          of registering on the Platform, and to subsequently
                          inform us of any changes to the information that you
                          provided when registering. You can access and update
                          the information you provided to us through the
                          Platform.
                        </li>
                        <br />
                        <li>
                          Where you use the Products purchased on the Platform
                          for commercial purposes and not for your personal use,
                          you agree and acknowledge that you are not a
                          ‘consumer’ as defined under the Consumer Protection
                          Act, 2019.
                        </li>
                        <br />
                        <li>
                          We reserve the right to deny access to the Platform,
                          or terminate your User Account at any time, without
                          prior notice to you, for violation of any of the
                          provisions of these Terms or the IPR Policy.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Privacy</strong>
                      <br />
                      <ol type="a">
                        <li>
                          When you access our Platform and/or create a User
                          Account, we may collect, use, share, store, or
                          otherwise process your information, which may include
                          your personal information and sensitive personal
                          information. The processing of such information by us
                          is governed by the Privacy Policy, which forms an
                          integral part of the Platform Policies. You may access
                          the Privacy Policy here.
                        </li>
                        <br />
                        <li>
                          From time to time, we may recommend/broadcast certain
                          Products for sale on the Platform, which may be
                          specific or personalised to you. You acknowledge that
                          any interaction with such Products or any transaction
                          conducted on the Platform is entirely based on your
                          choice and free will.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>E-platform for communication </strong>
                      <br />
                      <ol type="a">
                        <li>
                          You agree, understand, and acknowledge that the
                          Platform is an online marketplace that enables you to
                          purchase Products from third-party sellers, at any
                          time and from any location, and accordingly, the
                          Platform is an intermediary service merely
                          facilitating such transactions that you choose to
                          enter into with such third-party sellers.
                        </li>
                        <br />
                        <li>
                          From time to time, we may recommend/broadcast certain
                          Products for sale on the Platform, which may be
                          specific or personalised to you. You acknowledge that
                          any interaction with such Products or any transaction
                          conducted on the Platform is entirely based on your
                          choice and free will.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Purchase of Products </strong>
                      <br />
                      <ol type="a">
                        <li>
                          Third-party sellers upload Products on the Platform
                          for purchase by Users at the price mentioned on the
                          Platform by such third-party sellers.
                        </li>
                        <br />
                        <li>
                          You may choose to purchase one or more Products from
                          one or more third-party sellers by adding the Product
                          listed on the Platform by using the “add to cart”
                          option available on the Platform. Thereafter, you will
                          be directed to the payment page for making online
                          payment for purchase of the chosen Product(s) from the
                          list of available options for making the payment.
                          Please note that all payments can only be made online.
                        </li>
                        <br />
                        <li>
                          You acknowledge and understand that all descriptions
                          and particulars of the Product(s), the price of the
                          Product, and the purchase terms are determined by the
                          third-party seller and the Platform/we have no control
                          over any aspect thereof, other than the facilitation
                          of the purchase of the Product as an intermediary.
                        </li>
                        <br />
                        <li>
                          Once you have purchased a Product, you will not be
                          entitled to a refund of the amount paid for the
                          purchased Product(s).
                        </li>
                        <br />
                        <li>
                          You understand, acknowledge, and agree that we and our
                          affiliates are merely intermediaries facilitating
                          certain aspects of the transaction between you and
                          third-party sellers on the Platform, and accordingly,
                          we are in no way liable or responsible for any aspect
                          of the transaction, or your interaction with any
                          third-party seller on the Platform.
                        </li>
                        <br />
                        <li>
                          All payments you make on the Platform shall be in
                          Indian Rupees (INR).
                        </li>
                        <br />
                        <li>
                          In case of the Product being damaged in a manner that
                          the Product cannot be used by you or dispute relating
                          thereto, you agree and acknowledge that your sole
                          remedy would be to redownload the purchased Product on
                          the Platform. You understand and acknowledge that we
                          are not responsible for the quality of the Product
                          made available to you on the Platform and your sole
                          remedy in case of issues relating to the quality of
                          the Product shall be against the third-party seller.
                        </li>
                        <br />
                        <li>
                          You may make payments via various payment methods,
                          including through credit cards, debit cards, Unified
                          Payment Interface, and internet banking payments,
                          which are processed through third-party payment
                          gateways. You understand and agree that we shall not
                          be responsible for delays or denials at their end. The
                          processing of payments will be solely in terms of the
                          policies and procedures of such third-party payment
                          gateway without any responsibility or risk at our end.
                        </li>
                        <br />
                        <li>
                          We do not control and are not liable to or responsible
                          for the quality of the Products or the description
                          thereof offered for sale on the Platform, or your
                          ability to complete a transaction on the Platform.
                        </li>
                        <br />
                        <li>
                          You acknowledge and agree to assume all the risks of
                          conducting any transaction in connection with using
                          the Platform, and for any subsequent activity of any
                          kind relating to offerings made available through the
                          Platform that are the subject of transactions using
                          the Platform.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Your use of the Platform </strong>
                      <br />
                      <ol type="a">
                        <li>
                          Subject to your compliance with these Terms, we grant
                          you a limited license to access and make use of the
                          Platform, which does not extend to the following:
                          <br />
                          <ol type="i">
                            <li>
                              downloading (other than page caching) or modifying
                              the Platform, or any portion of it;
                            </li>
                            <br />
                            <li>
                              decompiling, disassembling, reverse engineering or
                              attempting to deconstruct, identify or discover
                              any source code, underlying ideas or other
                              technology, user interface techniques or
                              algorithms of the Platform;
                            </li>
                            <br />
                            <li>
                              encumbering, transferring, distributing, selling,
                              disclosing, sublicensing, assigning, providing,
                              leasing, lending, or using for the benefit of any
                              third party, any part of the Platform or its
                              components;
                            </li>
                            <br />
                            <li>
                              copying, modifying, adapting, translating,
                              incorporating into or with other software, or
                              create a derivative work of the Platform or any
                              part thereof, or any similar or competitive
                              product to the Platform;
                            </li>
                            <br />
                            <li>
                              using any means to gain unauthorized access to the
                              Platform, or engaging in any scraping,
                              data-mining, harvesting, data aggregating or
                              indexing of the Platform or the data therein;
                            </li>
                            <br />
                            <li>
                              using the Platform in any manner that causes, or
                              is likely to cause, interruption of access to the
                              Platform, or disruption of the integrity or
                              performance of the Platform and/or its related
                              systems, servers or networks, or any form of
                              damage or impairment in any other manner; and
                            </li>
                            <br />
                            <li>
                              using the Platform in any harmful or unlawful way.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          You understand that you are solely responsible for all
                          electronic communications and content sent from your
                          computer resource using the Platform, and you must use
                          the Platform for lawful purposes only. Further, your
                          continued use of the Platform implies your consent to
                          receive communications (including transactional,
                          promotional and/or commercial messages), from us with
                          respect to your use of the Platform, any Products made
                          available for sale on the Platform, and/or
                          transactions facilitated through the Platform.
                        </li>
                        <br />
                        <li>
                          In case there is any software bug or technical glitch
                          (“Software Error”) in the Platform, you shall inform
                          us about the same at the earliest and will not make
                          any unlawful or fraudulent gains because of such
                          Software Error. At all times, we will have the right
                          to initiate any action against you as it may deem
                          necessary for such unlawful and fraudulent gains
                          including clawback, blocking of your User Account,
                          termination of all agreements with you, adjusting such
                          gains against any previous dues, and other legal
                          actions available under applicable law.
                        </li>
                        <br />
                        <li>
                          You must not use the Platform for any of the
                          following:
                          <br />
                          <ol type="i">
                            <li>
                              for fraudulent purposes, or in connection with a
                              criminal offense or other unlawful activity;
                            </li>
                            <br />
                            <li>
                              to host, display, upload, modify, publish,
                              transmit, store, update or share any information
                              that:
                              <br />
                              <li>
                                a. belongs to another person and to which you
                                have no right;
                              </li>
                              <br />
                              <li>
                                is obscene, pornographic, paedophilic, invasive
                                of another’s privacy, including bodily privacy,
                                insulting or harassing on the basis of gender,
                                racially or ethnically objectionable, relating
                                or encouraging money laundering or gambling, or
                                promoting enmity between different groups on the
                                grounds of religion or caste with the intent to
                                incite violence;
                              </li>
                              <br />
                              <li> is harmful to children;</li>
                              <br />
                              <li>
                                infringes any patent, trademark, copyright or
                                other proprietary rights;
                              </li>
                              <br />
                              <li>
                                deceives or misleads the addressee about the
                                origin of the message or knowingly and
                                intentionally communicates any misinformation or
                                information which is patently false or untrue or
                                misleading in nature;
                              </li>
                              <br />
                              <li> impersonates another person;</li>
                              <br />
                              <li>
                                threatens the unity, integrity, defence,
                                security or sovereignty of India, friendly
                                relations with foreign States, public order or
                                causes incitement to the commission of any
                                cognizable offence or prevents investigation of
                                any offence or is insulting other nations;
                              </li>
                              <br />
                              <li>
                                contains software viruses or any other computer
                                code, file or program designed to interrupt,
                                destroy or limit the functionality of any
                                computer resource;
                              </li>
                              <br />
                              <li>
                                violates any law for the time being in force.
                              </li>
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          Where you are in violation of these Terms, we, at our
                          sole discretion, reserve the right to:
                          <br />
                          <ol type="i">
                            <li>
                              a non-exclusive, royalty-free, irrevocable,
                              perpetual and fully sub-licensable right to use,
                              reproduce, modify, adapt, publish, translate,
                              create derivative works from, distribute, and
                              display such content throughout the world in any
                              media; and
                            </li>{" "}
                            <br />
                            <li>
                              and sub-licensees the right to use the name that
                              you submit in connection with such content at
                              their discretion.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          You may post reviews, comments, and other content on
                          the Platform, so long as the content is not in
                          violation of Clause 5.4. You are solely responsible
                          for the content that you transmit, upload, or
                          otherwise make available on the Platform. By
                          publishing any content on the Platform, you grant us
                          and our affiliates,
                          <br />
                          <ol type="i">
                            <li>
                              (i) a non-exclusive, royalty-free, irrevocable,
                              perpetual and fully sub-licensable right to use,
                              reproduce, modify, adapt, publish, translate,
                              create derivative works from, distribute, and
                              display such content throughout the world in any
                              media; and
                            </li>
                            <li>
                              and sub-licensees the right to use the name that
                              you submit in connection with such content at
                              their discretion.
                            </li>
                          </ol>
                        </li>
                        <br />
                        <li>
                          You agree that the rights you grant above are
                          irrevocable during the entire period of protection of
                          your intellectual property rights associated with such
                          content and material. You agree to waive your right to
                          be identified as the author of such content and your
                          right to object to derogatory treatment of such
                          content. You agree to perform all further acts
                          necessary to perfect any of the above rights granted
                          by you to us, including the execution of deeds and
                          documents, at our request.
                        </li>
                        <br />
                        <li>
                          You represent and warrant that you own or otherwise
                          control all of the rights to the content that you post
                          or that you otherwise provide on or through the
                          Platform; that, as at the date that the content or
                          material is submitted to us: (i) the content and
                          material is accurate; (ii) use of the content and
                          material you supply does not breach any of our
                          applicable policies or guidelines and will not cause
                          injury to any person or entity (including that the
                          content or material is not defamatory); (iii) the
                          content is lawful. You agree to indemnify us and our
                          affiliates for all claims brought by a third party
                          against us or our affiliates arising out of or in
                          connection with a breach of any of these warranties.
                        </li>
                        <br />
                        <li>
                          You represent and warrant that you are not on any
                          denied-party list or located in an embargoed country,
                          under the laws of India, and you agree to prevent any
                          usage of the Platform that is in violation of any
                          Indian law or regulation.{" "}
                        </li>
                        <br />
                        <li>
                          Any form of fraudulent activity, including attempting
                          to use or using any other person’s credit card(s),
                          debit cards, net-banking usernames, passwords,
                          authorization codes, accessing or attempting to access
                          the Services through someone else’s User Account is
                          strictly prohibited. Where we become aware of or are
                          of the opinion, at our sole discretion, that you are
                          indulging in such fraudulent activity, we may
                          terminate your User Account and notify the appropriate
                          authorities of the same, where relevant.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Access to Platform </strong>
                      <br />
                      <ol type="a">
                        <li>
                          We will seek to ensure that the availability of our
                          Platform is uninterrupted and that transmissions are
                          error-free. However, due to the nature of the
                          Internet, this cannot be guaranteed. We make no
                          warranties as to the availability of completely secure
                          or virus-free services as the platform is dependent on
                          third-party service providers.
                        </li>
                        <br />
                        <li>
                          {" "}
                          Your access to the Platform may also be occasionally
                          suspended or restricted to allow for repairs,
                          maintenance, or the introduction of new facilities or
                          services at any time without prior notice.
                          Additionally, we shall not be liable for any delay,
                          loss of data/information, or other failures of
                          performance during the scheduled downtime that is
                          beyond our control.
                        </li>
                        <br />
                        <li>
                          {" "}
                          This Platform or any portion thereof, including but
                          not limited to any copyrighted material, trademarks,
                          or other proprietary information belonging to us, may
                          not be reproduced, duplicated, copied, sold, resold,
                          visited, distributed, or otherwise exploited for any
                          purpose without express written consent from us and/or
                          our affiliates, as may be applicable.
                        </li>
                        <br />
                        <li>
                          {" "}
                          You may not frame or use framing techniques to enclose
                          any of our or our affiliates’ trademark, logo, or
                          other proprietary information (including images, text,
                          page layout, or form) without express written consent
                          from us. You may not use any meta tags or any other
                          "hidden text" utilising our or our affiliates' names
                          or trademarks without the express written consent from
                          us and/or our affiliates, as applicable. Any
                          unauthorised use terminates the permission or license
                          granted by us and/or our affiliates, as applicable.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Property Rights </strong>
                      <br />
                      <ol type="a">
                        <li>
                          All content included on the Platform, such as text,
                          graphics, logos, page headers, button icons, images,
                          audio clips, digital downloads, data compilations,
                          scripts, service names and software, including any
                          compilation thereof, other than the Products listed on
                          the Platform (“Engage Intellectual Property”) is the
                          property belonging to us or our affiliates or
                          suppliers and is protected by Indian and international
                          intellectual property laws, and you are prohibited
                          from any use of the Engage Intellectual Property, or
                          part thereof, which is not expressly authorised in
                          these Terms.
                        </li>
                        <br />
                        <li>
                          {" "}
                          ENGAGE MUSIC and all other marks indicated on our
                          Platform, including graphics, logos, page headers,
                          button icons, scripts, and service names, are
                          trademarks or registered trademarks of Engage or its
                          affiliates or their trade dress. Our trademarks and
                          trade dress may not be used in connection with any
                          product or service that is not ours, in any manner
                          that is likely to cause confusion among users of the
                          Platform, or that disparages or discredits us. All
                          other trademarks not owned by us that appear on this
                          Platform are the property of their respective owners,
                          who may or may not be affiliated with, connected to,
                          or sponsored by us.
                        </li>
                        <br />
                        <li>
                          {" "}
                          The intellectual property rights in any Product listed
                          on the Platform vests in third-party sellers and we or
                          any of our affiliates do not own any rights in
                          relation to the same. When you purchase a Product
                          listed on the Platform, you are granted a
                          non-exclusive, royalty-free, and perpetual license to
                          use the Product.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>International Use </strong>
                      <br />
                      <ol type="a">
                        <li>
                          We make no representation that the Product listed on
                          the Platform or any information made available on the
                          Platform is appropriate or available for use in
                          locations outside of India, and accessing the Platform
                          is prohibited from territories where such Product or
                          information is illegal. If you access the Platform
                          from other locations, you do so at your own initiative
                          and are responsible for compliance with local laws.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Disclaimer </strong>
                      <br />
                      <ol type="a">
                        <li>
                          You acknowledge and undertake that you are accessing
                          the Platform and purchasing Products listed therein at
                          your own risk and are using your best and prudent
                          judgment before entering into any transactions through
                          the Platform. We shall neither be liable nor
                          responsible for any actions or inactions of
                          third-party sellers nor any breach of conditions,
                          representations or warranties by the third-party
                          sellers of the Products and hereby expressly disclaim
                          any and all responsibility and liability in that
                          regard. We shall not mediate or resolve any dispute or
                          disagreement between you and third-party sellers, and
                          our sole involvement in case of any dispute or claim
                          arising out of a completed transaction for which
                          payment has been received from you will be the
                          facilitation of communication between you and the
                          third party seller.
                        </li>
                        <br />
                        <li>
                          {" "}
                          We may place certain promoted or sponsored content on
                          the Platform (directly or via links to other sites or
                          resources) in conjunction with third parties. We make
                          no warranties with respect to such content and neither
                          do we endorse any information contained therein. We
                          shall not be liable to you for such promoted or
                          sponsored content, and your interaction with such
                          content or third-party resources shall be solely at
                          your own risk. These Platform Policies will not govern
                          your interaction with such content or third-party
                          resources or platform.
                        </li>
                        <br />
                        <li>
                          {" "}
                          We expressly disclaim any warranties or
                          representations (express or implied) in respect of
                          quality, suitability, accuracy, reliability, or
                          completeness, for a particular purpose, or legality of
                          the Products listed or displayed or transacted or the
                          content (including Product or pricing information
                          and/or specifications) on the Platform. While we have
                          taken precautions to avoid inaccuracies in content on
                          this Platform, all content, information (including the
                          price of Products), software, Products, and related
                          graphics are provided on an “as is” basis, without
                          warranty of any kind. We do not implicitly or
                          explicitly support or endorse the sale or purchase of
                          any Product on the Platform. At no time shall any
                          right, title or interest in the Products sold through
                          or displayed on the Platform vest with us nor shall we
                          have any obligations or liabilities in respect of any
                          transactions on the Platform.
                        </li>
                        <br />
                        <li>
                          {" "}
                          We do not provide any warranty as to the content on
                          the Platform. The Platform content is distributed on
                          an "as is, as available" basis.
                        </li>
                        <br />
                        <li>
                          {" "}
                          Any material accessed, downloaded, or otherwise
                          obtained through our Platform is done at your
                          discretion, competence, acceptance, and risk, and you
                          will be solely responsible for any potential damage to
                          your computer system or loss of data that results from
                          your access to or download of any such material.
                        </li>
                        <br />
                        <li>
                          {" "}
                          We shall make commercially reasonable endeavours to
                          ensure that the Platform and experience through it, is
                          error-free and secure, however, neither we nor any of
                          our partners, licensors, or associates makes any
                          warranty that:
                          <br />
                          <ol type="i">
                            <li>
                              {" "}
                              the Platform will meet your requirements at all
                              times;
                              <li>
                                <br />
                              </li>{" "}
                              the Platform will be uninterrupted, timely,
                              secure, or error-free at all times;
                              <li>
                                <br />
                              </li>{" "}
                              the listings on the Platform will be accurate or
                              reliable; and
                              <li>
                                <br />
                              </li>
                              the quality of any Products, Platform,
                              information, or other material that you purchase
                              or obtain through the Platform will meet your
                              expectations.
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Limitation of Liability </strong>
                      <br />
                      <ol type="a">
                        <li>
                          NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS
                          AGREEMENT, TO THE MAXIMUM EXTENT PERMISSIBLE BY LAW,
                          UNDER NO CIRCUMSTANCE SHALL WE BE LIABLE TO YOU, IN
                          ANY MANNER, FOR ANY (I) DAMAGES OF ANY KIND, (II) LOST
                          PROFITS; (III) LOSS OF BUSINESS; (IV) LOSS OF
                          GOODWILL, OPPORTUNITY, OR REVENUE; OR (V) ANY
                          INDIRECT, CONSEQUENTIAL, EXEMPLARY, SPECIAL, PUNITIVE
                          OR INCIDENTAL DAMAGES ARISING OUT OF OR RELATED TO
                          THIS AGREEMENT OR YOUR USAGE OF THE PLATFORM, WHETHER
                          FORESEEABLE OR UNFORESEEABLE, INCLUDING, BUT NOT
                          LIMITED TO, CLAIMS FOR THE USE OF THE PLATFORM,
                          INTERRUPTION IN USE, OR AVAILABILITY OF DATA, STOPPAGE
                          OF OTHER WORK OR IMPAIRMENT OF OTHER ASSETS, PRIVACY,
                          ACCESS TO OR USE OF ANY ADDRESSES, EXECUTABLES OR
                          FILES THAT SHOULD HAVE BEEN LOCATED OR BLOCKED,
                          NEGLIGENCE, BREACH OF CONTRACT, TORT OR OTHERWISE AND
                          THIRD PARTY CLAIMS, EVEN IF ADVISED OF THE POSSIBILITY
                          OF SUCH DAMAGES.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Indemnity and Release </strong>
                      <br />
                      <ol type="a">
                        <li>
                          You shall indemnify and hold harmless us, our
                          subsidiaries, affiliates and respective officers,
                          directors, agents and employees, from any claim or
                          demand, or actions including reasonable attorney's
                          fees, made by any third party or penalty imposed due
                          to or arising out of your breach of the Platform
                          Policies or any document incorporated by reference, or
                          your violation of applicable law or the rights of a
                          third party. Further, we reserve the right to assume
                          the sole control of the defence and settlement of any
                          such claim, action, proceeding, or suit for which you
                          are obliged to indemnify us, at your sole cost, and
                          you agree to cooperate with us with respect to such
                          defence and settlement.
                        </li>
                        <br />
                        <li>
                          In the event that you have a dispute with any
                          third-party seller, you agree to release and indemnify
                          Engage and its subsidiaries, affiliates, agents,
                          affiliates, directors, officers and employees, from
                          all claims, demands, actions, proceedings, costs,
                          expenses and damages (including without limitation any
                          actual, special, incidental or consequential damages)
                          or other consequence arising out of or in connection
                          with the transaction with third-party sellers.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Waiver </strong>
                      <br />
                      <ol type="a">
                        <li>
                          If you breach any of these conditions and we take no
                          action, we will still be entitled to use our rights
                          and remedies in any other situation where you breach
                          any of these conditions. The failure on by either
                          party to insist upon strict performance of any of the
                          provisions herein or to exercise any option, right or
                          remedy contained herein, shall not be construed as
                          waiver or relinquishment of such provisions, option,
                          right or remedy but the same shall continue and remain
                          in full force and effect. No waiver by you or us of
                          any provision hereof shall be deemed to have been made
                          unless expressed in writing and signed by you or us,
                          as the case may be.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Complaints and grievances </strong>
                      <br />
                      <ol type="a">
                        <li>
                          If you have any complaint or grievance relating to any
                          aspect of the Platform, you may approach our customer
                          care and grievance redressal team for resolution of
                          such issues by writing to support@engagemusic.io.
                        </li>{" "}
                        <br />
                        <li>
                          If you believe that any content on the Platform is
                          illegal or in violation of the Platform Policies,
                          please notify our Grievance Officer immediately in
                          writing or email. You agree that all complaints and
                          disputes relating to us are to be kept confidential.
                          We will endeavour to resolve all grievances within a
                          reasonable time period, as mandated under applicable
                          law. Any decision taken by us relating to any
                          complaint will be binding on the user.
                        </li>{" "}
                        <br />
                        <li>
                          The contact details of our Grievance Officer are as
                          follows: <br /> Name: Himay Email:
                          <br /> support@engagemusic.io
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>
                        Governing law, dispute resolution, and Jurisdiction{" "}
                      </strong>
                      <br />
                      <ol type="a">
                        <li>
                          The Platform Policies are governed by and construed in
                          accordance with the laws of India.
                        </li>{" "}
                        <br />
                        <li>
                          Any dispute, controversy, or claim against us shall be
                          subject to the exclusive jurisdiction of the civil
                          courts at Mumbai, Maharashtra, India.
                        </li>{" "}
                        <br />
                        <li>
                          The contact details of our Grievance Officer are as
                          follows: <br /> Name: Himay Email:
                          <br /> support@engagemusic.io
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <Typography
                    // variant={"h1"}
                    fontWeight={700}
                    sx={{
                      "@media (min-width: 1200px)": {},
                      fontSize: ["2rem", "2.5rem", "3.5rem", "4rem", "5.2rem"],
                      fontFamily: "'Darker Grotesque', sans-serif",
                      // lineHeight: '0.8',
                      // mb: "1.5rem",
                    }}
                  >
                    <span
                      style={{
                        color: "#FA6B05",
                        fontFamily: "'Darker Grotesque', sans-serif",
                      }}
                    >
                      Artists Terms &
                    </span>{" "}
                    Conditions
                  </Typography>
                  <ul style={{ listStyleType: "none" }}>
                    <li> </li>
                    <li>
                      Engage Music Pvt. Ltd (“Engage” or “us” or “we” or “our”)
                      operates a marketplace platform by the name of Engage
                      Music (the mobile application and the website are
                      hereinafter together referred to as the “Platform”),
                      through which we facilitate the sale of audio content
                      listed by third-party sellers (“Artists”) on the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      These terms of service (“Terms of Service”) form an
                      integral and binding part of the contractual agreement
                      entered into by you (alternatively the “Artist”) with
                      Engage, for the provision of the services that are
                      described in detail below.{" "}
                    </li>
                    <li> </li>
                    <li>
                      By registering with us, you have consented to these Terms
                      of Service which contain the terms and conditions for the
                      provision of our service to the Artist, or any of the
                      Artist’s affiliates.{" "}
                    </li>
                    <li> </li>
                    <li>1. Definitions. </li>
                    <li> </li>
                    <li>
                      In these Terms of Service, capitalized terms used but not
                      defined in the clause in which first used will have the
                      meaning ascribed to them below:{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.1. “Agreement” means these Terms of Service, and any
                      accompanying Platform Policies and/or online agreement
                      entered into between the Artist and Engage upon completion
                      of the Online Sign-up Process and through online
                      acceptance of these Terms of Service.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.2. “Artist Account” means an account created by an
                      Artist with us to list and offer for sale Digital Content
                      on the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.3. “Buyer(s)” means internet Buyer(s) who initiate Unit
                      Payment.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.4. “Card Association” means Visa, MasterCard, AMEX,
                      Discover or any other card issuers that provide cards
                      accepted by agreement with the Payment Service Provider.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.5. “Commission” means the amount payable to Engage by
                      the Artist for each sale transaction concluded via the
                      Platform for the purchase of each Digital Content listed
                      by the Artist for sale on the Platform, which amount shall
                      be calculated in accordance with the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.6. “Confidential Information” means any data, material,
                      or information of a nature that could reasonably be
                      presumed confidential, and includes all non-public,
                      proprietary, or confidential information, data, or
                      material in oral, written, electronic, or any other form,
                      including without limitation, information pertaining to
                      either Party’s business affairs, operations, proprietary
                      information and technology, intellectual property, trade
                      secrets and details of its products and services, as well
                      as research and development data, pricing policies, market
                      studies and forecasts, competitive analyses, internal
                      policies and procedures, financial information,
                      capitalization information, dashboard data, the details of
                      and substance of agreements with investors, employees,
                      suppliers, customers, and prospective customers, business
                      and product development plans or strategies, Buyer PII,
                      and marketing information.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.7. “Denied Persons List” means the U.S. Department of
                      Commerce, Bureau of Industry and Security’s Denied Persons
                      List.{" "}
                    </li>
                    <li> </li>
                    <li> </li>
                    <li>
                      1.8. “Digital Content” means the audio files that are
                      listed on the Platform for purchase.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.9. “Embargoed Countries” means the U.S. Department of
                      Commerce, Bureau of Industry and Security’s specified
                      Embargoed Countries.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.10. “Engage Content Guidelines” means the guidelines
                      published by Engage and made available at
                      www.engagemusic.io/contentguidlines.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.11. “Engage Intellectual Property” means all
                      intellectual property rights, including, but not limited
                      to, patents, trademarks, copyrights, and trade secrets of
                      Engage in and to the Platform and the content thereof.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.12. “Exclusive Digital Content” means Digital Content
                      which the Artist agrees to list for purchase or make
                      available in any manner only on the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.13. “Force Majeure Event” means an event or occurrence
                      beyond the control or remedy of the Party, including,
                      without limitation, Acts of God, natural disasters, acts
                      of civil or military authority, government priorities,
                      fire, floods, epidemics, pandemics, quarantine, energy
                      crises, strikes, wars, riots, terrorist acts or accidents.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.14. “Loss” means all fines, penalties, claims, damages,
                      costs, settlement payments, expenses, liabilities, or fees
                      of any nature whatsoever, including attorneys’ fees and
                      costs in relation to a specific event.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.15. “Non-exclusive Digital Content” means Digital
                      Content which the Artist may list for purchase on
                      platforms other than on the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.16. “Online Sign-up Process” means the automated
                      registration facility available on the Engage website
                      and/or mobile applications, which may be used by any
                      person to register for the use of the Services, and which
                      includes acceptance of these Terms of Service.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.17. “Party” or “Parties” means Engage and the Artist,
                      individually or collectively, as the case may be.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.18. “Payment Service Provider” means a payment
                      processor, such as a bank or credit card processor.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.19. “PII” means any information of an Artist or
                      Buyer(s), as the case may be, that can personally identify
                      such Artist or Buyer(s), including their name, email
                      address, phone number, and location.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.20. “Platform Policies” means the policies that govern
                      the use of and access to the Platform and any of the
                      features offered by Engage.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.21. “Service” means, collectively and individually, the
                      website, software, API, and related applications developed
                      by Engage for the purposes described in these Terms of
                      Service.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.22. “Tax Claim” has the meaning ascribed to it in Clause
                      10.2 of these Terms of Service.{" "}
                    </li>
                    <li> </li>
                    <li>
                      1.23. “Unit Payment(s)” means a payment made by a Buyer
                      entitling them to access a single piece of Digital Content
                      purchased by the Buyer and includes all applicable taxes.
                      It is clarified that for a payment transaction by a Buyer
                      to be considered a Unit Payment, the entire transaction
                      amount should have been received by Engage.{" "}
                    </li>
                    <li> </li>
                    <li>2. Registration Process </li>
                    <li> </li>
                    <li>
                      2.1. An Artist may register for the use of the Services by
                      completing the Online Sign-up Process and providing all
                      details in the corresponding fields provided during the
                      Online Sign-up Process.{" "}
                    </li>
                    <li> </li>
                    <li>
                      2.2. Commission fee is waived on music sales for life for
                      all music uploaded before January 1, 2025. Music uploaded
                      on or after January 1, 2025 will be subject to a ten per
                      cent (10%) commission ("Commission") of the Unit Payments.
                      We reserve the right to revise the quantum of the
                      Commission, at any time, with thirty (30) days prior
                      written notice to you via electronic mail prior to the
                      effective date of such revision. If you do not accept the
                      revised Commission, you may discontinue using the Platform
                      and delete your Artist Account. Your continued use of the
                      Platform after such notification shall constitute consent
                      to the revised Commission.{" "}
                    </li>
                    <li> </li>
                    <li>
                      2.3. It is clarified that the Commission is exclusive of
                      applicable Goods and Services Tax. Engage will charge
                      applicable GST over and above the Commission amount and
                      the same will be payable by the Artist.{" "}
                    </li>
                    <li> </li>
                    <li>
                      2.4. Engage may, at any time after the Online Sign-up
                      Process, require the Artist to provide additional details
                      pertaining to their legal status and operations, including
                      their bank account details, tax ID or accept such further
                      terms which will form part of the Agreement. The Artist
                      shall be bound by any details shared with Engage, or any
                      terms accepted, during this process, each of which will
                      form an integral part of the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>3. The Service. </li>
                    <li> </li>
                    <li>
                      3.1. Engage is an internet intermediary offering an online
                      marketplace to Artists, which, inter alia, enables Artists
                      to list Digital Content on the Platform for sale at a
                      price determined by the Artist. It is clarified that the
                      sale price is determined solely by the Artist without any
                      involvement of Engage, however, Engage may display the
                      amount in currency other than the currency in which the
                      sale price of the Digital Content is determined by the
                      Artist as per the currency applicable in the jurisdiction
                      in which the User resides.{" "}
                    </li>
                    <li> </li>
                    <li>
                      3.2. It is clarified that Engage is not involved in the
                      creation, selection, publication or modification of any
                      Digital Content listed on the Platform and Engage does not
                      play any role in the selection of Buyer(s).{" "}
                    </li>
                    <li> </li>
                    <li>
                      3.3. We support the facilitation of purchase of Digital
                      Content on the Platform and for this purpose may utilize
                      the services of a third-party Payment Service Provider(s).
                      Engage reserves the right in its sole and exclusive
                      discretion to engage with or substitute the Payment
                      Service Provider(s), or add additional Payment Service
                      Provider(s) for processing any payment transaction
                      initiated by a Buyer using the Service.{" "}
                    </li>
                    <li> </li>
                    <li>
                      3.4. The Artist agrees and understands that it may have
                      certain obligations under applicable law to the operators
                      of the relevant payment infrastructure, including Payment
                      Service Providers since such Payment Service Providers are
                      involved in the processing of Unit Payments and transfer
                      thereof to the Artist as per the terms of this Agreement,
                      and Artists undertakes to promptly and duly comply with
                      such obligations where it has knowledge of such obligation
                      or upon receipt of knowledge from Engage or its affiliates
                      in this regard.{" "}
                    </li>
                    <li> </li>
                    <li>
                      3.5. Our Services are not intended to be offered to
                      individuals on the Denied Persons List and/or located in
                      Embargoed Countries. Accordingly, if you are an individual
                      on the Denied Person’s List or located in Embargoed
                      Countries, you are prohibited from using our Services. If
                      you are on the Denied Persons List and/or located in
                      Embargoed Countries and availing of our Services, you
                      agree to defend, indemnify and hold Engage, its owners,
                      and representatives, harmless against any Loss to the
                      extent such Loss is based upon an allegation concerning
                      your usage of the Platform and our Services.{" "}
                    </li>
                    <li> </li>
                    <li>
                      3.6. You specifically authorise us to undertake the
                      following actions on your behalf, either directly or
                      through our affiliates:{" "}
                    </li>
                    <li>
                      i. to facilitate transactions between you and Buyers;{" "}
                    </li>
                    <li>
                      ii. to generate invoices and any other relevant
                      documentation on your behalf upon the purchase of Digital
                      Content;{" "}
                    </li>
                    <li>
                      iii. to facilitate the sharing of the Digital Content with
                      the Buyer who makes the purchase upon such Buyer making a
                      payment;{" "}
                    </li>
                    <li>
                      iv. to provide transaction support and communicate with
                      the Buyer on your behalf in relation to a transaction
                      concluded on the Platform for the purchase of Digital
                      Content listed by you;{" "}
                    </li>
                    <li>
                      v. to process payments on your behalf in accordance with
                      the Agreement;{" "}
                    </li>
                    <li>
                      vi. to facilitate dispute related discussions and
                      conversations between you and the Buyer in relation to the
                      purchase transaction between you and the Buyer who has
                      purchased Digital Content listed by you.{" "}
                    </li>
                    <li> </li>
                    <li>
                      3.7. All risk and responsibility in relation to the
                      quality and regulatory compliance of the Digital Content
                      listed by you for sale on the Platform remain solely with
                      you and Engage shall have no liability in this regard.{" "}
                    </li>
                    <li> </li>
                    <li>4. Payments and Settlement. </li>
                    <li> </li>
                    <li>
                      4.1. We shall, on your behalf, receive and hold the Unit
                      Payments made to you by the Buyers in a specifically
                      designated nodal account that may be operated by us or our
                      authorized representatives.{" "}
                    </li>
                    <li> </li>
                    <li> </li>
                    <li>
                      4.2. We shall raise an invoice for Commission upon the
                      conclusion of transaction of Unit Payment or on a monthly
                      basis at the end of each calendar month for all Unit
                      Payments for the previous calendar month. .{" "}
                    </li>
                    <li> </li>
                    <li>
                      4.3. Upon receipt of Unit Payment from a Buyer for the
                      purchase of Digital Content listed for sale by you, we
                      shall have the right to:{" "}
                    </li>
                    <li>
                      i. transfer the Commission due to us to our bank account;
                      ii. deduct applicable taxes, and any bank or transfer
                      related fees; and{" "}
                    </li>
                    <li>
                      thereafter, transfer the remaining amount after all
                      applicable deductions and withholdings, to your designated
                      bank account in accordance with this Agreement.{" "}
                    </li>
                    <li>
                      4.4. You are required to provide us with all necessary
                      regulatory and tax information pertaining to you, and such
                      other information that we may require to make payment of
                      the Net Payable Amount to you.{" "}
                    </li>
                    <li>
                      4.5. In case you are a resident of a jurisdiction outside
                      India, all applicable laws and obligations of such other
                      jurisdiction will apply to you, for such cross-border
                      transactions.{" "}
                    </li>
                    <li> </li>
                    <li>
                      4.6. You understand and agree that we have the right to
                      withhold any amount due to us from Net Payable Amount.
                      Further, if there are reasonable grounds to suspect that a
                      transaction has been conducted in breach of any applicable
                      laws or is a fraudulent transaction, or if there is a
                      chargeback or a dispute raised by the Buyer, we shall
                      withhold connected payments until the issue is resolved in
                      accordance with applicable laws, or to our satisfaction.{" "}
                    </li>
                    <li> </li>
                    <li>
                      4.7. We shall have the right to withhold settlement of
                      payments to you, including the release of the Net Payable
                      Amount, in the following circumstances:{" "}
                    </li>
                    <li>
                      i. if we, in our sole discretion, determine that the
                      transaction being undertaken by you is not genuine or
                      suspicious or fraudulent; or{" "}
                    </li>
                    <li>
                      ii. where we are required to do so to comply with a lawful
                      request from the government or its agencies, or an order
                      of a court of competent jurisdiction; or{" "}
                    </li>
                    <li>
                      iii. where you list Digital Content for sale on the
                      Platform in which:{" "}
                    </li>
                    <li>a. you do not own intellectual property rights; or </li>
                    <li>
                      b. you do not have valid written authorization for sale.{" "}
                    </li>
                    <li>
                      iv. where you default in paying applicable taxes impacts
                      us in any manner, we reserve the right to withhold
                      payments and settlements due to you till such time the
                      default is remedied.{" "}
                    </li>
                    <li> </li>
                    <li>
                      4.8. Process for payment flow when a Buyer purchases
                      Digital Content on the Platform and the for remittance of
                      the aggregate of all Unit Payments received by us for the
                      purchase of Digital Content listed by you pertaining to a
                      Calendar month by Engage to the Artist, shall be as
                      follows:{" "}
                    </li>
                    <li> </li>
                    <li> (a) For Artists from India or based out of India: </li>
                    <li>
                      i. The Payment Service Provider shall deduct and withhold
                      the total Commission due from the Artist to Engage, from
                      the total amount (aggregate) of the Unit Payments due to
                      the Artist for that Calendar month, and thereafter make
                      payment of the remaining amount (“Net Payable Amount”) to
                      the Designated Bank Account of the Artist within thirty
                      (30) days of the last day of that month;{" "}
                    </li>
                    <li> </li>
                    <li>
                      ii. any taxes and bank or transfer related charges that
                      may be applicable on the Net Payable Amount shall be borne
                      by the Artist, without any liability on Engage.
                    </li>
                    <li> </li>
                    <li> (b) For Artists from outside India: </li>
                    <li>
                      i. All Unit Payments collected on behalf of the Artist by
                      a Payment Service Provider shall be remitted to Engage.
                      Thereafter, Engage shall deduct and withhold the total
                      Commission due from the Artist to Engage, from the total
                      amount (aggregate) of the Unit Payments due to the Artist
                      for that Calendar month, and thereafter make payment of
                      the Net Payable{" "}
                    </li>
                    <li>
                      Amount within thirty (30) days of the last day of that
                      month;{" "}
                    </li>
                    <li> </li>
                    <li>
                      ii. any taxes, currency conversion charges, and bank or
                      transfer related charges that may be applicable on the Net
                      Payable Amount shall be borne by the Artist, without any
                      liability on Engage.{" "}
                    </li>
                    <li> </li>
                    <li>
                      4.9. The Artist is aware that it may be possible for a
                      variety of reasons that the Payment Service Provider does
                      not receive or does not fully receive payment(s) from a
                      respective Buyer. This is, for example, the case if the
                      Buyer refuses the necessary registration on the Platform
                      or if the Payment Service Provider does not receive
                      payment from the Buyer for any other reason. The Artist is
                      aware of the associated risk of non-payment and
                      understands and agrees that it may not receive payment in
                      such cases from the Buyer, Payment Service Provider, or
                      Engage.{" "}
                    </li>
                    <li> </li>
                    <li>5. Use of the Service. </li>
                    <li> </li>
                    <li>
                      5.1. During the term of the Agreement, Engage grants a
                      non-exclusive, non-assignable, and nontransferable right
                      to the Artist to access Service and to use the features
                      connected with the Platform as specified in the Agreement.
                      The Artist has and receives no other rights in and to the
                      Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      5.2. In connection with the right of use granted in Clause
                      5.1 above, the Artist shall be able to track various
                      details captured by the Platform pertaining to the Digital
                      Content listed and those purchased on the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      5.3. The Artist has no right to and will not use the
                      Platform beyond the use permitted in accordance with the
                      Agreement, or allow third parties to use it, or make the
                      Platform available to third parties. The Artist has no
                      right to reverse engineer, revise, decompile, reproduce,
                      sell, permit the temporary use, or to let or lend the
                      Platform or parts of it to third parties. During the term
                      of the Agreement and for two (2) years thereafter, the
                      Artist will not develop or offer any service that provides
                      similar Service in India.{" "}
                    </li>
                    <li> </li>
                    <li>6. Marketing Activities </li>
                    <li> </li>
                    <li>
                      6.1. The Artist understands and agrees that Engage may, at
                      its sole discretion, create and publish relevant content
                      designed to market and popularise the Artist and/or their
                      business and content to potential Buyers (“Marketing
                      Content”), on any third party platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      6.2. Engage shall ensure that the Marketing Content does
                      not violate applicable law, and does not disparage the
                      Artist.{" "}
                    </li>
                    <li> </li>
                    <li>
                      6.3. It is clarified that Engage may undertake the
                      publication of Marketing Content through authorised third
                      parties, and that no Marketing Content shall be published
                      in the name of the Artist.{" "}
                    </li>
                    <li> </li>
                    <li>7. Obligations of the Parties </li>
                    <li>
                      7.1. The Artist shall have the following obligations:{" "}
                    </li>
                    <li> </li>
                    <li>
                      (i) to provide and ensure, at its own expense, that the
                      Digital Content is of good quality and that the Digital
                      Content is error free;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) to ensure that the Digital Content does not violate
                      any Intellectual Property of any third party.{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iii) to resolve, at its own expense, any errors or
                      quality-related issues that occur in the Digital Content
                      listed on the Platform, whether purchased or not;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iv) to establish a data link to the Engage data centre;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (v) to provide Engage with all necessary regulatory and
                      tax information pertaining to the Artist’s business that
                      may be necessary for the performance of the Agreement by
                      Engage and its compliance with applicable law;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vi) to transmit to Engage the names of all designated
                      administrators for communication with regard to the
                      Service;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vii) to use the Service exclusively for the facilitation
                      of payments for the Digital Content listed on the Platform
                      and interact with Buyers and other users of the Platform,
                      and not for any other purpose that is not sanctioned under
                      the Agreement;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (viii) to pay all applicable taxes within the statutorily
                      prescribed timelines. Where failure on part of the Artist
                      to do so affects us in any manner whatsoever, we reserve
                      the right to withhold payments and settlements due to you
                      till such time the default is remedied;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ix) to ensure reporting and filing of their GST returns,
                      and the timely fulfilment of any other regulatory
                      obligation. Engage shall not be responsible for any
                      consequence of non-compliance by the Artist, including the
                      loss of GST input credits or levy of penalties, etc.;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (x) to inform Engage, without undue delay, about any
                      defects or errors in the Digital Content listed on the
                      Platform for sale and any other Digital Content which may
                      have been previously listed on the Platform but has been
                      removed subsequently that could affect the safety or
                      operation of the Platform, and to cooperate with Engage
                      and take all reasonable measures to enable Engage to
                      diagnose any defect or error. It is clarified that in the
                      event of any significant error, incorrect reporting,
                      failure, or defect in the Artist’s Digital Content, which
                      is required to be remedied by Engage, the Artist will pay
                      the requisite expenses for such remediation;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xi) to ensure that all information provided to Engage
                      under this Agreement is accurate and complete, and to
                      notify Engage about any changes with regard to the
                      following information: the Artist’s company name,
                      registered address, bank information;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xii) to ensure that the Digital Content complies with the
                      requirements of applicable laws. It is clarified that the
                      Artist acknowledges sole and exclusive responsibility for
                      such compliance, without any liability on Engage;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xiii) to review all payments made and invoices issued by
                      Engage without undue delay, and to raise any objections no
                      later than two (2) weeks from the respective date of
                      receipt. It is clarified that no objections may be raised
                      beyond the aforesaid stipulated period, and the
                      corresponding settlements shall be deemed final and
                      binding;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xiv) to provide all relevant information in relation to a
                      Digital Content listed or offered for sale on the
                      Platform, including the price and the breakup of price.
                      Transactions on the Platform, and corresponding invoices
                      and other documentation, will be raised on the basis of
                      the information provided by the Artist, and accordingly,
                      the Artist assumes full responsibility for all
                      consequences arising out of any error or misrepresentation
                      in the classification and taxation of Digital Content It
                      is clarified that Engage shall bear no liability in
                      relation to any description of the Digital Content. In
                      case of any dispute relating to the description of Digital
                      Content being inaccurate or misleading, Engage
                      acknowledges and agrees that it shall be solely liable to
                      the person raising the dispute.{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xv) to provide any KYC- related information that may be
                      required by the Payment Service Providers that Engage
                      uses, or as may be required for regulatory purposes in
                      either the jurisdiction of the Artist or Engage.{" "}
                    </li>
                    <li> </li>
                    <li> </li>
                    <li>7.2. Engage shall have the following obligations: </li>
                    <li> </li>
                    <li>
                      (i) to process all successful Unit Payments from Buyers
                      pertaining to the Artist’s Digital Content;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) to issue timely instructions to the Payment Service
                      Provider for the credit of the successful Unit Payments to
                      the Artist’s bank account, in accordance with these Terms
                      of Service;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iii) to inform the Artist of any downtime or maintenance
                      of the technology offered by Engage which may disrupt the
                      Services;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iv) to comply with all applicable laws in relation to
                      offering the Services; and{" "}
                    </li>
                    <li> </li>
                    <li>
                      (v) to provide the Artist relevant details about the use
                      of the Services by Buyers including the number of Buyer
                      visits and Unit Payments for Digital Content, through the
                      Artist Account.{" "}
                    </li>
                    <li> </li>
                    <li>8. Representations and Warranties of the Parties </li>
                    <li> </li>
                    <li>
                      8.1. Each Party hereto represents, warrants, and
                      undertakes that:{" "}
                    </li>
                    <li> </li>
                    <li>
                      (i) it has the full capacity, power and authority to enter
                      into this Agreement;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) neither Party is subject to any restriction or
                      limitation that would prevent them from performing their
                      duties and obligations hereunder;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iii) it is an entity that is duly constituted and validly
                      existing under applicable law. It is clarified that in
                      relation to Artists, this clause is only applicable when
                      Digital Content is listed through a juristic entity;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iv) it has the requisite power and authority to execute,
                      deliver and perform its obligations under the Agreement,
                      and that the entire Agreement shall constitute a legally
                      binding obligation upon registering with Engage using the
                      Online Sign-up Process;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (v) its obligations hereunder constitute legal, valid,
                      binding, and enforceable obligations;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vi) the entering into and performance of the Agreement
                      does not breach any applicable law, the provisions of any
                      contract or order of court or tribunal applicable to it,
                      or require any governmental approval;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vii) the person entering into this Agreement is duly
                      authorized to do so for and on its behalf, and shall have
                      the authority to create binding legal obligations on it;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (viii) it is and shall remain compliant with all legal,
                      statutory, and regulatory compliances under applicable law
                      during the pendency and performance of the Agreement;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ix) its services and offerings do not violate or infringe
                      any intellectual property rights of any third party; and{" "}
                    </li>
                    <li> </li>
                    <li>
                      (x) all information provided to the other Party under the
                      Agreement will be up to date, accurate, and complete.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.2. Engage represents, warrants, and undertakes that:{" "}
                    </li>
                    <li> </li>
                    <li>
                      (i) it has all necessary rights, permissions, and
                      authorizations, including requisite statutory and
                      regulatory licenses and authorisations, to provide the
                      Services under the Agreement, and shall maintain the same
                      during the Term;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) it has entered into the required contractual
                      agreements with Payment Service Providers and other
                      partner banks that are necessary for the provision of the
                      Services;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iii) the Services will be provided in a professional
                      manner in accordance with the terms of the Agreement;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iv) it shall comply with applicable data protection and
                      privacy laws, in relation to the collection, accessing,
                      transferring, sharing, controlling, and/ or processing of
                      a Buyer’s{" "}
                    </li>
                    <li>PII; </li>
                    <li> </li>
                    <li>
                      (v) it shall immediately notify the Artist upon receiving
                      actual knowledge of any unauthorized access to the
                      Artist’s account on the Service, and shall cooperate with
                      the Artist in the investigation of such breach;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vi) it shall take commercially reasonable steps to remedy
                      any error or defect in the Services upon such fact coming
                      to its knowledge;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vii) it will promptly respond to any requests or
                      complaints of Buyers in connection with any aspect of the
                      Services or any other services offered by Engage to the
                      Buyer;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (viii) it has robust cybersecurity infrastructure and
                      protocols in place to protect a Buyer’s and the Artist’s
                      information, and is compliant with applicable law in this
                      regard; and{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ix) it has implemented the necessary technical measures
                      to ensure the protection, confidentiality, and security of
                      the facilitation system for payments.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.3. The Artist represents, warrants, and undertakes that
                      it:{" "}
                    </li>
                    <li> </li>
                    <li>
                      (i) shall ensure that it provides all information required
                      to be provided as part of the Online Sign-up Process,
                      including the name of the Artist, the legal entity through
                      which the{" "}
                    </li>
                    <li>
                      Artist operates, applicable statutory licenses, Permanent
                      Account Number, Goods and Services Tax Certificate,
                      contact details, and bank account details. The Artist
                      shall ensure that all such information provided is correct
                      and valid, and we shall not be liable in any manner for
                      any act or omission which is occasioned by incorrect
                      information provided by the Artist. We don’t have this
                      process on our platform, however the same will be
                      collected by the payment gateway. Stripe for Indian
                      artists and Paypal for International.{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) shall ensure that the Digital Content does not
                      infringe the intellectual property rights of any third
                      party and that it has all necessary licenses and
                      permissions to offer the Digital Content for purchase to
                      Buyers through the Services;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iii) shall ensure that Engage is the sole and exclusive
                      provider for any Exclusive Digital Content and undertakes
                      to not allow sale of Exclusive Digital Content via any
                      other medium, whether online or offline{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iv) is solely responsible for any aspect related to the
                      Digital Content, including legal actions, claims,
                      disputes, or violations of law, and in this regard shall
                      promptly address and resolve any disputes or grievances
                      raised by any person, including a Buyer, pertaining to
                      such Digital Content;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (v) shall comply with all obligations under applicable
                      laws, and obtain all necessary licenses, authorizations,
                      and permits in order to conduct its business and offer
                      Digital Content through the Platform;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vi) shall promptly address and resolve any disputes or
                      grievances raised by Buyers in relation to the Digital
                      Content, and that it shall reimburse any expenses incurred
                      by Engage in connection with any claims, actions, or
                      disputes arising in relation to the Digital Content,
                      including a breach of third-party intellectual property
                      rights or its unlawfulness;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (vii) shall accept the chargeback of any transaction
                      relating to which the Buyer disputes validity, and shall
                      comply in this regard with the prevailing Card Association
                      regulations, or decisions communicated by the Payments
                      Service Provider or Engage;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (viii) shall not accept or submit a fraudulent transaction
                      to Engage or Payment Service Provider, and shall not,
                      under any circumstances, present for processing or credit,
                      directly or indirectly, a transaction which originated
                      with any merchant or source other than the transactions
                      arising from bona fide transactions from Artist relating
                      to the Digital Content or activities for which the Artist
                      has been approved under the Agreement. If the Artist
                      breaches its obligations under this Section, Engage may:{" "}
                    </li>
                    <li> </li>
                    <li>(a) immediately terminate the Agreement; </li>
                    <li>
                      (b) have the Payment Service Provider withhold payments
                      and demand an escrow;{" "}
                    </li>
                    <li>or </li>
                    <li>
                      (c) report the Artist to the relevant Card Association
                      members or other concerned parties and regulatory
                      authorities (it is clarified that the Artist’s employees’
                      or agents’ actions are chargeable to the Artist);{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ix) shall not make a claim against or hold liable Engage
                      or the Payment Service Provider for the acts or omissions
                      of other merchants, service providers, Card Associations,
                      financial institutions, or other persons that do not have
                      a written contractual relationship with Engage, or over
                      which Engage has no control. These provisions supplement,
                      augment, and are in addition to obligations of
                      indemnification, audit, confidentiality, and other similar
                      provisions contained in the Agreement;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (x) shall comply with any legal or contractual
                      requirements mandated by the Payment Services Provider,
                      which may be necessary under applicable law to ensure
                      continued receipt of the services from the Payment Service
                      Provider, as and when communicated to it by Engage; and{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xi) shall not post reviews and comments on the Platform
                      for themselves. An Artist may only post reviews and
                      comments on the Platform if they have purchased Digital
                      Content listed by another Artist on the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>
                      (xii) shall, where relevant, required, or requested by
                      Engage, communicate to its Buyers, that Engage is an
                      intermediary that merely provides an online marketplace
                      for the purchase of Digital Content by Buyers, and is in
                      no way responsible or liable for any aspect of the Offered
                      Materials.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.4. The Artist acknowledges and agrees that Engage will
                      have no responsibility or liability to any person with
                      regard to any claims related to the Digital Content listed
                      by the Artist for sale on the Platform, including any
                      violations of the intellectual property rights of third
                      parties or content regulations applicable in any
                      jurisdiction in which the Digital Content is available for
                      purchase by Buyers.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.5. The Artist shall retain control and discretion over
                      the Digital Content that may be listed on the Platform for
                      purchase and ensure that the Digital Content does not
                      violate the applicable Platform Policies.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.6. Where any Digital Content is in violation of the
                      Platform Policies, Engage reserves the right to disable
                      the facilitation of Unit Payments, at its sole discretion
                      for such Digital Content.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.7. When listing Digital Content for sale on the Platform
                      or anytime thereafter, the Artist may be required to
                      furnish documents or information about such Digital
                      Content in order verify their authenticity and demonstrate
                      that they do not infringe intellectual property rights of
                      any third party. The Artist agrees to promptly provide
                      such additional documents and information when requested,
                      failing which we reserve the right to take appropriate
                      measures as set out the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      8.8. Where the Artist is in violation of the terms of the
                      Agreement, we reserve the right to immediately terminate
                      the Artist’s access to the Platform.{" "}
                    </li>
                    <li> </li>
                    <li>9. Intellectual Property Rights </li>
                    <li> </li>
                    <li>
                      9.1. Engage and/or its affiliates, as the case may be,
                      exclusively owns all patents, trademarks, domain names,
                      trade names, service marks, copyrights, software, trade
                      secrets, or know-how (“Intellectual Property Rights”) in
                      the Platform, underlying technology, and content made
                      available by Engage on the Platform, excluding the
                      description of the Digital Content and the Digital Content
                      (“Engage Intellectual Property”). The Artist acknowledges
                      and agrees that it is granted only a limited right to use
                      the Services in accordance with the Agreement, and has no
                      other right, ownership, title or interest in the Engage
                      Intellectual Property.{" "}
                    </li>
                    <li> </li>
                    <li>
                      9.2. The Artist and/or its affiliates, as the case may be,
                      exclusively owns all Intellectual Property Rights in the
                      Exclusive Digital Content (“Artist’s Intellectual
                      Property”), and the Artist either owns or has the
                      necessary rights to use and distribute the Non-exclusive
                      Digital Content, along with its associated Intellectual
                      Property Rights. Engage acknowledges and agrees that it
                      has no other right, ownership, title, or interest in the
                      Artist’s Intellectual Property, or the Digital Content.{" "}
                    </li>
                    <li> </li>
                    <li>
                      9.3. The Artist hereby provides Engage with the necessary
                      consent and a non-exclusive, nontransferable, royalty-free
                      license to use the Artist’s names, trademarks, and logos
                      on the Engage’s websites, in furtherance of the Services,
                      or for any other marketing purposes that Engage may
                      reasonably undertake.{" "}
                    </li>
                    <li> </li>
                    <li>10. Limitation of Liability and Disclaimers </li>
                    <li> </li>
                    <li>
                      10.1. Engage’s sole obligation to the Artist under the
                      Agreement is to ensure that the Services accurately
                      records and provides the Unit Payments of the Artist to
                      the Payment Service Provider, and that the Payment Service
                      Provider thereafter processes the payments, and related
                      periodic payouts, to the Artist, subject to the Agreement.
                      Engage’s sole liability to the Artist is limited to
                      instances of willful misconduct and gross negligence in
                      relation to this obligation.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.2. Engage hereby specifically disclaims any liability
                      that may arise for the Artist from a tax authority or
                      otherwise, based on the determination, collection, and
                      payment of any applicable sales or other taxes. The Artist
                      will defend, at its expense, any tax-related action, suit,
                      or proceeding against Engage (“Tax Claim”) on account of a
                      default by the Artist in relation to its tax obligations.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.3. The Service is provided to the Artist on an as-is
                      basis and Engage makes no implied warranties, with respect
                      to the same. All implied warranties of merchantability and
                      fitness for a particular purpose or use, are disclaimed.
                      No one is authorized to make any warranty on Engage’s
                      behalf, and the Artist may not rely on any other statement
                      of warranty. However, if there is an error, directly
                      attributable to Engage, in the Services, then Engage shall
                      take prompt steps to rectify the same.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.4. Neither Party shall be liable for any Losses that
                      the other Party may incur due to any event that is not
                      within its control, as well as any interruption of
                      internet access, or any Force Majeure Events for which
                      such Party is not responsible.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.5. Neither Party (including their respective affiliates
                      and agents) shall be liable to the other for any indirect,
                      incidental, special, punitive, or consequential losses
                      arising out of or in connection with the performance of
                      its obligations under the agreement, or losses related to
                      the loss of data, profit or revenue or the interruption of
                      activities or any other financial loss. However this
                      clause shall not be applicable for any Losses arising out
                      of infringement of any Intellectual Property right by the
                      Artist.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.6. In no circumstance will Engage’s liability to the
                      Artist for any claim arising under the Agreement exceed
                      the total Commissions received for transactions conducted
                      for the Artist via Engage over the 3 (three) month period
                      prior to the date on which the claim arose.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.7. The Artist agrees that it shall make no claim
                      against Engage, nor hold Engage liable for the acts or
                      omissions of other merchants, service providers, Card
                      Associations, financial institutions, or others that do
                      not have a written contractual relationship with Engage,
                      or over which Engage has no control. These provisions
                      supplement, augment, and are in addition to obligations of
                      indemnification, confidentiality, and other similar
                      provisions contained in the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      10.8. Engage may supply all or a portion of the Service
                      from within India or elsewhere as applicable law permits,
                      using its own or third-party computer systems.{" "}
                    </li>
                    <li> </li>
                    <li>11. Indemnification </li>
                    <li> </li>
                    <li>
                      11.1. The Artist agrees to indemnify, defend and hold
                      Engage and its parents, subsidiaries, and affiliated
                      companies, including its officers, directors, employees,
                      agents, shareholders, investors, and other representatives
                      (“Engage Parties”) harmless from and against all Losses,
                      and third-party claims (together “Claims”) arising as a
                      result of or in connection with:{" "}
                    </li>
                    <li> </li>
                    <li>
                      (i) any allegation, action, suit, investigation, inquiry,
                      or proceeding claiming that the Digital Content made
                      available by the Artist through the Services violate
                      applicable laws;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) any violation of applicable law in relation to the
                      Digital Content offered for purchase by the Artist through
                      the Service;{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iii) the negligence, gross negligence, or wilful
                      misconduct of the Artist in the performance of its
                      obligations under the Agreement, including, but not
                      limited to, data security obligations and publisher
                      obligations under applicable law; and{" "}
                    </li>
                    <li> </li>
                    <li>
                      (iv) any violation of applicable law by the Artist, breach
                      by the Artist of its representations, warranties, and
                      obligations under the Agreement, or infringement of
                      third-party intellectual property rights by the Artist.{" "}
                    </li>
                    <li> </li>
                    <li>
                      11.2. Engage hereby agrees to protect, defend, indemnify
                      and hold harmless the Artist, its employees, officers,
                      directors, agents, or representatives from and against all
                      claims, liabilities, damages, fines, penalties, and costs
                      arising from or relating to:{" "}
                    </li>
                    <li>
                      (i) Services or any other materials provided by it
                      infringing any patent, trademark or copyright, or any
                      other intellectual property right of a third party;{" "}
                    </li>
                    <li>
                      (ii) any gross negligence or willful misconduct by Engage
                      in offering the Services under this Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      11.3. The indemnified Party shall give the indemnifying
                      Party prompt notice of any Claim and reasonably cooperate
                      with the defense and any settlement of the Claim, with any
                      participation being at its own expense. The indemnifying
                      Party shall control the defense of the claim and bear its
                      own expenses using counsel reasonably approved by the
                      indemnified Party. Neither Party shall enter into the
                      settlement or compromise of any claim without the prior
                      written consent of the other Party, which shall not be
                      unreasonably delayed or withheld.{" "}
                    </li>
                    <li> </li>
                    <li>12. Term and Termination </li>
                    <li> </li>
                    <li>
                      12.1. The Agreement shall come into effect from the date
                      of the completion of the registration process as set out
                      in Clause 2 and shall continue to be in force till such
                      time the Artist uses its Artist Account for sale of
                      Digital Content.{" "}
                    </li>
                    <li> </li>
                    <li>
                      12.2. Where the Artist is in violation of any of the terms
                      of the Agreement, Engage reserves the right to terminate
                      the Agreement and all Services being provided to the
                      Artist thereunder, temporarily or permanently disable
                      access to the Artist Account, at its sole discretion and
                      to withhold payments due to the Artist under the
                      Agreement, in part or full, against any potential
                      liabilities that may arise thereof.{" "}
                    </li>
                    <li> </li>
                    <li>
                      12.3. Upon termination of the Agreement, the Artist shall
                      cease using any aspect or component of the Services, and
                      Engage shall be entitled to delete or otherwise process
                      all data retained by it in relation to the services
                      rendered under the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      12.4. Where the Artist Account is disabled under Clause
                      12.3 and Buyers have purchased the Digital Content of the
                      Artist thereafter, Engage shall have no obligation to
                      remit any such Unit Payments to the Artist.{" "}
                    </li>
                    <li> </li>
                    <li>13. Confidentiality and Data Protection. </li>
                    <li> </li>
                    <li>
                      13.1. The Parties mutually commit to maintaining complete
                      confidentiality regarding all business processes and other
                      Confidential Information of the other Party that become
                      known to such Party in the performance of the Agreement.
                      The Parties will obtain corresponding confidentiality
                      commitments from their employees and representatives, and
                      each Party will be responsible for any breach of the
                      confidentiality obligations set out herein, caused by its
                      employees and representatives.{" "}
                    </li>
                    <li> </li>
                    <li>
                      13.2. Each Party must only use the other Party’s
                      Confidential Information for the purposes envisaged by
                      this Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      13.3. Each Party will ensure that consent is obtained from
                      Buyers, where mandated in accordance with applicable laws,
                      prior to disclosing any Buyer information to the other
                      Party, which may request a copy of records of such consent
                      or information in this regard.{" "}
                    </li>
                    <li> </li>
                    <li>
                      13.4. The Artist understands that Engage will not share
                      any Buyer information (including, but not limited to,
                      Buyer’s identity or billing address), except such
                      information that may be necessary for the sole purpose of
                      determining applicable tax and jurisdiction of the Buyer
                      (“Regulatory Information”), where required. The Artist is
                      strictly prohibited from using such Regulatory Information
                      for any purpose other than the determination of the
                      applicable taxes to the relevant transaction.
                      Notwithstanding the foregoing, Engage may, in its sole and
                      exclusive discretion, additionally provide the Artist with
                      Buyer information as may be required under applicable law,
                      to the extent that consent is provided by the Buyers in
                      this regard.{" "}
                    </li>
                    <li> </li>
                    <li>
                      13.5. The contents and terms of the Agreement are
                      confidential to the Parties. Neither Party may publish or
                      disclose to any third party any terms or conditions of the
                      Agreement without the prior written consent of the other
                      Party.{" "}
                    </li>
                    <li> </li>
                    <li>
                      13.6. The Artist agrees and understands that any
                      Confidential Information shared with it by Engage is to be
                      kept confidential and is not to be shared further without
                      prior written authorization from Engage.{" "}
                    </li>
                    <li> </li>
                    <li>14. Miscellaneous </li>
                    <li> </li>
                    <li>
                      14.1. Amendment: Engage may, in its sole discretion, amend
                      these Terms of Service at any time and such amendments
                      will become effective fourteen (14) days after the notice
                      of the amendment is posted on the Platform. Where the
                      Artist has objections to the amendments so made, it may
                      discontinue using the Services and remove the listing of
                      all Digital Content for purchase.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.2. Governing Law and Jurisdiction: The Agreement will
                      be construed, and the rights and obligations of the
                      Parties determined, exclusively in accordance with the
                      laws of India, excluding its choice-of-law provisions. The
                      exclusive place of jurisdiction for all disputes arising
                      out of or in connection with this Agreement will be
                      Mumbai, Maharashtra, India.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.3. Arbitration: Any dispute, controversy, difference or
                      claim, whether contractual or not, arising out of or
                      relating to this Agreement, including the existence,
                      validity, interpretation, performance, breach or
                      termination thereof or any dispute regarding
                      non-contractual obligations arising out of or relating to
                      it shall be referred to and finally resolved by
                      arbitration administered under the Arbitration and
                      Conciliation Act, 1996 in force when the Notice of
                      Arbitration is submitted. The arbitration proceedings
                      shall be held in Mumbai, Maharashtra, India. The
                      arbitration tribunal shall consist of one (1) arbitrator,
                      to be appointed mutually by the Parties, or failing
                      agreement within fourteen (14) days after either Party has
                      given to the other Party a written request to concur in
                      the appointment of the arbitrator, to be appointed as per
                      the (“Arbitration Act”). The language of the arbitration
                      shall be English and each Party shall have the right to
                      have its legal advisers present throughout the
                      arbitration. Any judgment, decision, or award of the
                      arbitration tribunal (the “Award”) shall be set forth in a
                      reasoned award stating the basis for the Award. The
                      Parties agree that the Award shall be final, binding, and
                      conclusive upon each of them without appeal. Any costs,
                      fees, or taxes incident to enforcement of the Award shall,
                      to the extent permitted by law, be charged against the
                      Party resisting enforcement. In addition, a damage Award
                      shall include interest, as determined by the arbitration
                      tribunal, from the date of injury, up to and including the
                      date of payment of the Award.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.4. Severability: In the event that any part or clause
                      of the Terms of Service is determined to be unenforceable,
                      such determination will not affect the remaining clauses
                      of the Terms of Service. The Parties hereby agree that to
                      the extent possible, if any clause of the Terms of Service
                      is determined to be unenforceable, such clause will be
                      cancelled and replaced with terms and conditions that are
                      enforceable and which come as close as possible to the
                      economic intent of the Parties.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.5. Waiver: Any failure by either Party to enforce at
                      any time any term or condition under the Agreement will
                      not be considered a waiver of that Party’s right
                      thereafter to enforce each and every term and condition of
                      the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.6. Entire Agreement: The Agreement constitutes the
                      entire agreement and understanding between the Parties
                      with respect to the subject matter hereof and supersedes
                      all prior agreements, oral and written, made with respect
                      to the subject matter hereof. Any modification or addition
                      to this Agreement must be in writing and signed by
                      authorized representatives of both parties.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.7. Assignment: Neither Party shall, without the prior
                      written consent of the other Party (which consent shall
                      not be unreasonably withheld), assign the Agreement by
                      operation of law or otherwise.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.8. Notice: Any notice, notification, or other
                      communication to be given by one Party to the other Party
                      under, or in connection with, the Agreement, shall be made
                      in writing and served by letter or email to the designated
                      address(es) of the Parties.{" "}
                    </li>
                    <li> </li>
                    <li>
                      (i) The designated address(es) of the Artist for the
                      purpose of notices shall be as specified during the Online
                      Sign-up Process.{" "}
                    </li>
                    <li> </li>
                    <li>
                      (ii) The designated address(es) of Engage are: 314, unique
                      industrial estate, Prabhadevi Mumbai 400025, India.{" "}
                    </li>
                    <li> </li>
                    <li>
                      14.9. Force Majeure Event: Except for payment obligations,
                      neither Party shall be deemed to be in material breach of
                      the Agreement, or otherwise be liable to the other, by
                      reason of any delay in performance or non-performance of
                      any of its obligations, under the Agreement, caused by a
                      Force Majeure Event. Immediately upon discovery of a Force
                      Majeure Event, the affected Party shall immediately notify
                      the other Party and the date for performance of any
                      obligation under the Agreement which is delayed by a Force
                      Majeure Event, shall be suspended for a period equal to
                      the delay caused by such event, but in no event shall such
                      suspension extend the term of the Agreement.{" "}
                    </li>
                    <li> </li>
                    <li> </li>
                  </ul>
                  <p>Last Updated: 1st May 2023</p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default AboutusPage;
