import { Box } from "@mui/system";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import { WaveLeftBox, WaveRightBox } from "components/layout/WaveBox";
import ContactUsHero from "components/other/ContactUsHero";
import FaqAccordian from "components/other/FaqAccordian";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

type Props = {};

const ContactUsPage = (props: Props) => {
  const location = useLocation();

  useEffect(() => {
    if (!location.state?.scrollToFaq) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  return (
    <React.Fragment>
      <WaveRightBox
      //  sx={{bottom:'-230%',top:'unset'}}
       />
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box p={["1rem", "2rem", "4rem", "5rem"]} pt={["7rem", "6rem", null, null, null]}>
          <ContactUsHero />
          <Box p={['80px', '80px', '80px', '80px', '80px']} />
          <FaqAccordian />
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default ContactUsPage;
