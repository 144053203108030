import { Google, Visibility, VisibilityOff } from '@mui/icons-material'
import { Button, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { DarkInput } from 'components/controls/inputFields'
import { DarkGlassRoundedWapper } from 'components/uiCard/BoxWrapperCard'
import React from 'react'
import burstPuckerSvg from 'assets/svg/Burst-pucker-2.svg'
import { useNavigate } from 'react-router-dom'
import { DarkButtonWithCustomIcon, DarkButtonWithIcon } from 'components/controls/Buttons'
import { AuthContext } from 'context/AuthContext'
import { UnauthenticatedPath } from 'utility/AppRoutingPath'
import { runFuncOnEnter } from 'utility/Common'
import { GoogleLogin } from '@react-oauth/google'

type Props = {}

const Login = (props: Props) => {


  const nav = useNavigate()

  const [showPassword, setShowPassword] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState({
    username: '',
    password: '',
  })
  const authContext = React.useContext(AuthContext)

  const loginHandler = async () => {
    await authContext?.LogIn(userInfo.username, userInfo.password)
  }


  return (
    <React.Fragment>
      <Grid container justifyContent={"center"} gap={"2.5rem"}>
        <Grid xs={12} item textAlign={"center"}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "100%",
              color: "#FFFFFF",
            }}
          >
            LOGIN WITH ENGAGE
          </Typography>
        </Grid>

        <Grid
          xs={12}
          item
          container
          justifyContent={"center"}
          padding={[2, 2, 0, 0]}
        >
          <DarkGlassRoundedWapper maxWidth={"414px"}>
            <Grid container>
              <Grid item xs={12}>
                <DarkInput
                  placeholder={"Enter Email id"}
                  value={userInfo.username}
                  onChange={(e: any) =>
                    setUserInfo({ ...userInfo, username: e.target.value })
                  }
                  sx={{
                    width: "100%",
                    color: "#FFFFFF",
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <DarkInput
                  funcOnEnterSubmit={loginHandler}
                  disabledButtonStatus={Boolean(
                    userInfo.username && userInfo.password
                  )}
                  value={userInfo.password}
                  onChange={(e: any) =>
                    setUserInfo({ ...userInfo, password: e.target.value })
                  }
                  placeholder={"Enter password"}
                  sx={{
                    mt: "2rem",
                    width: "100%",
                    "& .MuiOutlinedInput-input": {
                      WebkitTextSecurity: showPassword ? "" : "disc",
                    },
                  }}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle OTP visibility"
                          onClick={() => setShowPassword((prev) => !prev)}
                          edge="end"
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} alignItems="center" mt={"2rem"} container>
                <Grid item xs={5} container alignItems={"center"}>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "100%",
                      color: "#FA6B05",
                    }}
                    onClick={() => nav(UnauthenticatedPath.ForgetPassword)}
                  >
                    Forgot Password
                  </Typography>
                </Grid>
                <Grid item xs={5} textAlign={"right"}>
                  <DarkButtonWithIcon
                    disabled={!userInfo.username || !userInfo.password}
                    onClick={() => loginHandler()}
                  >
                    Log In
                  </DarkButtonWithIcon>
                </Grid>
                <Grid item xs={2} mt={"0rem"} alignItems="center" container justifyContent={"flex-end"}>
                  {/* <Grid item xs={5} textAlign={"right"}> */}
                  <GoogleLogin
                    itp_support
                    cancel_on_tap_outside={false}
                    containerProps={{
                      style: {
                        scale: "0.8",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "100%",
                        // height: "calc(100% - 15%)",
                        // width: "calc(100% - 90%)",
                      },
                    }}
                    context={"signin"}
                    logo_alignment={"center"}
                    shape={"circle"}
                    size={"large"}
                    theme={"outline"}
                    text={"signin"}
                    type={"icon"}
                    ux_mode={"popup"}
                    intermediate_iframe_close_callback={() => {
                      console.log("intermediate_iframe_close_callback");
                    }}
                    onSuccess={(credentialResponse) => {
                      console.log(credentialResponse);
                      authContext?.onGoogleLoginSuccess(credentialResponse);
                    }}
                    onError={() => {
                      console.log('Login Failed');
                      authContext?.onGoogleLoginFailure("error");
                    }}
                    useOneTap
                  />
                  {/* </Grid> */}
                </Grid>
              </Grid>
              {/* "Don't have an account? Sign Up" link */}
              <Grid item xs={12} mt={2} textAlign="center">
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '100%',
                    cursor: 'pointer',
                    color: '#FFFFFF',
                  }}
                >
                  Don't have an account?
                  <span
                    onClick={() => {
                      nav(UnauthenticatedPath.Signup);
                    }}
                    style={{ color: '#FA6B05' }}
                  >
                    {' '}
                    Sign Up
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </DarkGlassRoundedWapper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Login