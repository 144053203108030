import { Delete, Download, Edit, PauseCircle, PlayCircle, PlayForWorkOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { ReactComponent as Heart } from "assets/svg/heart.svg";
import { ReactComponent as MusicFilter } from "assets/svg/music-filter.svg";
import { SearchContext } from "context/SearchContext";
import { MusicStoreContext } from "context/MusicStoreContext";
import { MusicPlayerContext } from "context/MusicPlayerContext";

type Props = {};

function createData(
  albumCover: string,
name: string,
albumName: string,
userName: string,
label: string,
genres: string[],
price: number,
createdAt: string
) {
  return { albumCover,
    name,
    albumName,
    userName,
    label,
    genres,
    price,
    createdAt, };
}

const rows = [
  createData(
    "https://i.scdn.co/image/ab67616d0000b273e4b4b5b2b2b2b2b2b2b2b2b2",
    "The Weeknd",
    "After Hours",
    "The Weeknd",
    "Republic Records",
    ["R&B", "Pop"],
    10,
    "2021-09-20T14:48:00.000Z"
  ),
  createData(
    "https://i.scdn.co/image/ab67616d0000b273e4b4b5b2b2b2b2b2b2b2b2b2",
    "Queens",
    "Bohemian Rhapsody",
    "Sony Music",
    "Kanye West",
    [
      "R&B",
      "Pop",
      "Hip Hop",
      "Rap",
      "Rock",
      "Jazz",
    ],
    10,
    "2021-09-20T14:48:00.000Z"
  ),
  createData(
    "https://i.scdn.co/image/ab67616d0000b273e4b4b5b2b2b2b2b2b2b2b2b2",
    "The Weeknd",
    "After Hours",
    "The Weeknd",
    "Republic Records",
    ["R&B", "Pop"],
    10,
    "2021-09-20T14:48:00.000Z"
  ),

];

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  border: "1px solid #636B82",
  borderRadius: "16px",
  background: "transparent",
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #636B82",
    color: "#fff",
  },
  // zIndex: (theme.zIndex.drawer + 100) as number,
  position: "relative",
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  background:
    "linear-gradient(134.34deg, rgb(30 35 50 / 69%) 0%, rgb(12 13 19) 100%)",
  backdropFilter: "blur(100px)",
  color: "#fff",
}));

const tHeadData = [
  {
    name: "NAME",
    align: "left",
  },
  // {
  //   name: "ARTIST",
  //   align: "center",
  // },
  // {
  //   name: "LABEL",
  //   align: "center",
  // },
  // {
  //   name: "GENRE",
  //   align: "center",
  // },
  // {
  //   name: "PRICE",
  //   align: "center",
  // },
  // {
  //   name: "RELEASED",
  //   align: "center",
  // },
  // {
  //   name: "ACTIONS",
  //   align: "right",
  // },
];

const SearchResultTable = (props: Props) => {

  const [selectedRow , setSelectedRow] = React.useState<any>(null);

  const search = React.useContext(SearchContext);
  const store = React.useContext(MusicStoreContext);
  const musicPlayerContext = React.useContext(MusicPlayerContext);

  const addToCart = (row:any) => {
    if(!row) return;
    store?.addToCart({
      belongsTo: row.userName ? row.userName : '',
      cover: row.albumCover,
      name: row.name,
      price: row.price,
      itemType: "song",
      id: row._id,
    });
  }

  const handlePlayMusic = (item:any) => {
    let trackToGiveToPlayer = {
      albumCredits: item.credits,
      albumName: item.albumName,
      albumPrice: item.price,
      artistName: item.artistName,
      genres: item.label,
      id: item._id,
      Label: item.label,
      price: item.price,
      trackAudioUrl: item.s3data.Location,
      trackImageUrl: item.albumCover,
      trackName: item.name,
    }
    //musicPlayerContext!.openPlayer(trackToGiveToPlayer)
  }


  return (
    <React.Fragment>
      <StyledTableContainer>
        <Table sx={{ minWidth: 775 }} size="small" aria-label="simple table">
          <StyledTableHeader>
            <TableRow>
              {tHeadData.map((item, index) => (
                <TableCell
                  key={index}
                  align={item.align as any}
                  sx={{ fontWeight: "bold" }}
                >
                  {item.name}
                </TableCell>
              ))}
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {search?.searchResult.length !== 0 && search?.searchResult.map((row) => (
              <TableRow
                key={row?._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
                background: selectedRow?._id === row?._id ? "#FA6B05" : "transparent",
              }}
                onClick={() => {
                  if(selectedRow?._id === row?._id) {
                    setSelectedRow(null)
                    return;
                  }
                  setSelectedRow(row)
                }}
              >
                <TableCell align="left" component="th" scope="row">
                  <Grid flexWrap={'nowrap'} container spacing={2} alignItems={"center"}>
                    {/* <Grid item>
                      <Avatar src={row?.albumCover} sx={{ borderRadius: "4px" }}/>
                    </Grid> */}
                    <Grid item>
                      <Typography fontWeight={"700"}>{row?.name}</Typography>
                      <Typography variant="caption" fontWeight={"400"}>{row?.albumName}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                {/* <TableCell align="center">{row?.credits.join(', ')}</TableCell>
                <TableCell align="center">{row?.label}</TableCell>
                <TableCell align="center">
                  {row?.genres.join(", ")}
                </TableCell>
                <TableCell align="center">${row.price}</TableCell>
                <TableCell align="center">
                  {new Date(row?.createdAt).toDateString()}
                </TableCell>
                <TableCell align="right">
                  <IconButton sx={{color:"white"}}>
                    <PlayForWorkOutlined onClick={()=>addToCart(row)} />
                  </IconButton>
                  <IconButton sx={{color:'white'}}>
                    {musicPlayerContext?.IsPlaying && musicPlayerContext?.currentTrack?.id == row._id ? (
                      <PauseCircle onClick={musicPlayerContext?.TogglePlay} />
                    ) : (
                      <PlayCircle onClick={()=>handlePlayMusic(row)} />
                    )}
                  </IconButton>
                </TableCell> */}
              </TableRow>
            ))}

            {search?.searchResult.length == 0 ? (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell align="center" colSpan={7}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        py: 2,
                      }}
                    >
                      <Typography variant="h5" fontWeight="700">
                        No results found
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ):<></>
            }
          </TableBody>
        </Table>
      </StyledTableContainer>
    </React.Fragment>
  );
};

export default SearchResultTable;
