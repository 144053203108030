import { Avatar, Chip, Grid, Typography, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { ReactComponent as Seperator } from "assets/svg/Seperator.svg";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import { Box } from '@mui/system';
import { AuthContext } from 'context/AuthContext';
import { TrackUploadContext } from 'context/TrackUploadContext';
import EditPlayListForm from './EditPlayListForm';
import { MusicPlayerContext } from 'context/MusicPlayerContext';
import HttpTransferService from 'services/httptransfer';
import DialogPopup from 'components/layout/DialogPopup';
import { useParams } from 'react-router-dom';
import { PauseCircle, PlayArrow, PlayCircle} from '@mui/icons-material';

type Props = {}

const httptransfer = new HttpTransferService();

const PlaylistHero = (props: Props) => {
  const responsiveImageSize = [100, 100, 180, 200, 200]

  const [updatePlaylistDialogPopupFlag, setupdatePlaylistDialogPopupFlag] = useState(false)
  const [playlistSongs, setPlaylistSongs] = useState<any[]>([]);
  const [currentPlayingItemId, setCurrentPlayingItemId] = useState<string | null>(null);
  const theme = useTheme();
  const { playlistId } = useParams();
  const authcontext = React.useContext(AuthContext)!;
  const trackContext = React.useContext(TrackUploadContext);
  const musicPlayerContext = React.useContext(MusicPlayerContext);

  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const handleSelected = (item: any) => {
    console.log("currentPlayingItemId", currentPlayingItemId);
    //const isLastItem = playlistSongs[0]._id === item._id;;
    if (currentPlayingItemId === item._id) {
      if (musicPlayerContext) {
        if (musicPlayerContext.IsPlaying) {
          musicPlayerContext.Pause();
        } else {
          musicPlayerContext.Play();
        }
      }
    } else {
    const successCallback = (res: any) => {
    const trackToGiveToPlayer = {
      id: item._id,
      trackAudioUrl: res.data.musics[0].music_mp3_audio_file.file_download_url,
      trackImageUrl: item.music_cover_image.file_download_url,
      trackName: item.name,
    };
    musicPlayerContext!.openPlayer(trackToGiveToPlayer, playlistSongs);
    // setCurrentPlayingItemId(isLastItem ? null : item._id);
    setCurrentPlayingItemId(item._id);
  };

  const errorCallback = (err: any) => {
    console.log('err', err);
  };

  if (localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN')) {
    const inputJson = {
      user_id: authcontext?.users ? [authcontext?.users._id] : [userInfo._id],
      mp3_play: true,
      music_id: [item._id],
    };

    httptransfer.musicQuery(inputJson, successCallback, errorCallback);
  }else {
    showConfirmationDialog("play");
  }
}
}
const showConfirmationDialog = (condition: string) => {
  window.ShowInfoDialog(
    `Please login from the top right corner to ${condition}`
  );
};
  const openUpdatePlaylistDialog = () => {
    setupdatePlaylistDialogPopupFlag(true)
  }

  const closeUpdatePlaylistDialog = () => {
    setupdatePlaylistDialogPopupFlag(false)
  }

  useEffect(() => {
    const musicSuccessCallback = (res: any) => {
      if (playlistMusicData && res.data && res.data.musics) {
        const musicIdOrder = playlistMusicData.musics.map((music: any) => music.music_id);
        const orderedSongs = musicIdOrder.map((musicId: string) => {
          const foundMusic = res.data.musics.find((music: any) => music._id === musicId);
          return foundMusic ? foundMusic : null;
        });
        setPlaylistSongs(orderedSongs.filter((music: any) => music !== null));
      } else {
        console.error('Invalid data received in musicSuccessCallback');
      }
    };

    const errorCallback = (err: any) => {
      console.log("err", err);
    };

    let playlistMusicData = authcontext?.Playlist?._id ? authcontext?.Playlist : trackContext?.PlayListData.find((playlist: any) => playlist._id === playlistId);

    let musicIds = playlistMusicData?.musics.map((selectedMusic: any) => selectedMusic.music_id);
    httptransfer.musicQuery({ music_id: musicIds }, musicSuccessCallback, errorCallback);
  }, [authcontext?.Playlist]);

  
  const playListData = trackContext?.SpecificPlayListData;
  const selectedPlaylist = playListData.find((playlist: any) => playlist._id === playlistId);
  const playlistName = authcontext?.Playlist?.name ? authcontext?.Playlist?.name : selectedPlaylist?.name;
  const createdByUserName = authcontext?.users ? authcontext?.users?.name : '';
  const playlistCreatedYear = authcontext?.Playlist?.create_date ? new Date(authcontext?.Playlist?.create_date).getFullYear() : new Date(selectedPlaylist?.create_date).getFullYear();

  return (
    <React.Fragment>
      <Grid
        container
        item
        height={"fit-content"}
        gap={["0.5rem", "1.5rem", "1.5rem"]}
      >
        <Avatar
          src={authcontext?.Playlist?.playlist_cover_image ? authcontext?.Playlist?.playlist_cover_image?.file_download_url : selectedPlaylist?.playlist_cover_image?.file_download_url}
          sx={{
            width: responsiveImageSize,
            height: responsiveImageSize,
            borderRadius: "8px",
          }}
        ></Avatar>
        <Grid container item xs={8} sm={6.5} gap={['0.5rem',null]} alignContent="space-around">
          <Grid item xs={12}>
            <Typography
              fontWeight={700}
              sx={{
                "@media (min-width: 1200px)": {},
                fontSize: ["1.5rem", "2.5rem", "3rem", "4rem", "4.7129rem"],
                fontFamily: "'Darker Grotesque', sans-serif",
                lineHeight: "80%",
                letterSpacing: "-0.03rem",
              }}
            >
              {playlistName && playlistName}
            </Typography>
          </Grid>
          <Grid
            flexWrap={'nowrap'}
            item
            xs={12}
            container
            gap="0.5rem"
            alignItems="center"
            sx={{
              [theme.breakpoints.only("xs")]: {
                fontSize: "10px",
              },
            }}
          >
          <React.Fragment>
            {createdByUserName && createdByUserName.charAt(0).toUpperCase() + createdByUserName?.slice(1).toLowerCase()}{" "}
            {playlistCreatedYear ? <Seperator /> : ""}
          </React.Fragment>{playlistCreatedYear}
          {localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN') && (selectedPlaylist?.created_by === userInfo?._id) && (selectedPlaylist?.type !== "LIKED_MUSIC_PLAYLIST")  && (<Box
            sx={{
              borderRadius: "22px",
              backgroundColor: "#ffffff1f",
              p: ["8px", "8px",'10px'],
              width: "fit-content",
              height: "fit-content",
              display: "flex",
              ml: "1rem",
              "& svg": {
                width: "1rem",
                height: "1rem",
              },
            }}
            onClick={openUpdatePlaylistDialog}
          >
            <Edit />
          </Box>)}
            
          </Grid>
          <Grid item xs={12} gap="0.5rem" container onClick={() => handleSelected(playlistSongs[0])}>
            {localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN') && musicPlayerContext?.IsPlaying  ? (
                      <PauseCircle onClick={musicPlayerContext?.TogglePlay} sx={{fontSize: '2rem', cursor: "pointer"}}/>
                    ) : (
                      <PlayCircle onClick={musicPlayerContext?.TogglePlay} sx={{fontSize: '2rem', cursor: "pointer"}}/>
                    )}
          </Grid>
          <Grid item xs={12} gap="0.5rem" container>
          </Grid>
        </Grid>
      </Grid>
      <DialogPopup
        editPlayList = {true}
        closeDialog={closeUpdatePlaylistDialog}
        dialogOpen={updatePlaylistDialogPopupFlag}
        dialogTitle={"Edit Playlist"}
      >
        <EditPlayListForm
          closeDialog={closeUpdatePlaylistDialog} playlistData={selectedPlaylist}
        />
      </DialogPopup>
    </React.Fragment>
  );
}

export default PlaylistHero