import {
    Avatar,
    CircularProgress,
    Grid,
    Typography,
  } from '@mui/material';
  import React, { useEffect, useState } from 'react';
  import { ReactComponent as CloseSquare } from 'assets/svg/closeSquare.svg';
  import { AuthContext } from 'context/AuthContext';
  import HttpTransferService from 'services/httptransfer';
  import { TrackUploadContext } from 'context/TrackUploadContext';
  import { Link } from 'react-router-dom';
  
  type Props = {
  };
  
  const httptransfer = new HttpTransferService();
  const PlayListListing = (props: Props) => {
  //const PlayList = React.useContext(PlayListContext);
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  const trackContext = React.useContext(TrackUploadContext);

  const authcontext = React.useContext(AuthContext)!;

  const handleSelected = (item: any) => {
  }

  const deletePlaylistHandler = (id: string) => {

    const successCallback = (res: any) => {
      trackContext?.GetAllAlbumsByUser();
    };

    const errorCallback = (err: any) => {
      console.log('err', err);
    };

    httptransfer.deletePlaylist(id, successCallback, errorCallback);
  };
  
    const playlistDetailsCardJsx = (
      item: any
    ) => {
      const songCount = item.musics.length;
      const isDeleteIconVisible = item.type !== "LIKED_MUSIC_PLAYLIST";
      return (
        <React.Fragment key={item._id}>
          <Grid
            container
            onClick={() => handleSelected(item)}
            sx={{
              minWidth: '550px',
              borderBottom: '1px solid #636B82',
              backgroundColor: 'black',
              p: ['9px', '9.5px 20px 9.5px 20px'],
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#ffffff1f',
              },
            }}
            justifyContent='space-between'
          >
            <Grid
              item
              container
              
              xs={10}
              justifyContent='flex-start'
              alignItems={'center'}
            >
              {item.playlist_cover_image &&
              <>
               <Avatar
                    src={item.playlist_cover_image.file_download_url}
                    sx={{
                      width: '60px',
                      height: '60px',
                      borderRadius: '4px',
                      position: 'relative'
                    }}
                  ></Avatar></>}
              <Grid
                item
                container
                xs={8}
                direction={'column'}
                justifyContent='flex-start'
                alignItems='flex-start'
                flexWrap='nowrap'
              >
                <Grid
                  item
                  xs={12}
                  container
                  gap='0.5rem'
                  alignItems='center'
                  flexWrap={'nowrap'}
                  sx={{ ml: '10px' }}
                >
                  <Link to={`/playlist/${item._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography
                    sx={{ fontSize: ['12px', '16px'], fontWeight: '700', alignItems: "center",'&:hover': {
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={() => handleSelected(item)}
                  >
                    {item.name} 
                  </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} container gap='0.5rem' alignItems='center' sx={{ ml: '10px' }}>
                <Typography sx={{ fontSize: ['12px', '16px'] }}>
                  {songCount === 0 ? 'No Songs' : `${songCount} Song${songCount > 1 ? 's' : ''}`}
                </Typography>
              </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={2}
              gap='1rem'
              flexWrap={'nowrap'}
              justifyContent='flex-end'
              alignItems={'center'}
            >  
            {(item.created_by === userInfo?._id && (localStorage.getItem('APP_GATEWAY_ACCESS_TOKEN'))) &&
            <>
              <Grid item container xs={4}>
              {isDeleteIconVisible && (
                <CloseSquare
                  onClick={(e) => {
                    e.stopPropagation();
                    window.ShowConfirmationDialog(
                      'Do you want to delete this playlist?',
                      () => deletePlaylistHandler(item._id)
                    );
                  }}
                />)}
              </Grid>
              </>}
            </Grid>
          </Grid>
        </React.Fragment>
      )
    };
  
    useEffect(() => {
      (async () => await trackContext?.GetAllAlbumsByUser())();
    }, []);
  
    return (
      <>
      {trackContext?.GetAlbumLoading ? (
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          sx={{ height: '100%' }}
        >
          <CircularProgress
            sx={{
              filter: 'drop-shadow(0px 0px 6px #FA6B05)',
              width: '30px !important',
              height: '30px !important',
              color: '#FA6B05',
              m: 2,
            }}
          />
        </Grid>
      ) : (
        trackContext?.SpecificPlayListData && trackContext?.SpecificPlayListData
          .map((item: any, index: any) => {
            return (
              <React.Fragment key={index}>
                {playlistDetailsCardJsx(
                  item
                )}
              </React.Fragment>
            );
          })
      )}
      </>
    );
  };
  
  export default PlayListListing;
  