import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer, Icon, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import wave1svg from "assets/svg/wave1.svg";
import wave2svg from "assets/svg/wave2.svg";
import wave3svg from "assets/svg/wave3.svg";
import { WaveRightBox } from "./WaveBox";

interface IWaveBackgroundLayout {
  children: React.ReactNode | React.ReactNode[];
  oneWave: boolean;
  twoWave: boolean;
  //   activeMenuCaptionKey:string,
}



const WaveBackgroundLayout: React.FC<IWaveBackgroundLayout> = (props) => {
  const theme = useTheme();
  const smallAndAboveScreen = useMediaQuery(theme.breakpoints.up("sm"));
  //   const context = React.useContext(AuthContext);
  const nav = useNavigate();

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "auto",
          minHeight: "100vh",
          width: "100%",
          // position: "absolute",
          zIndex: 0,

          // background: props.twoWave
          //   ? `url(${wave1svg}) no-repeat top left, url(${wave2svg}) no-repeat top right`
          //   : props.oneWave
          //   ? `url(${wave3svg}) no-repeat top left`
          //   : "",
          // backgroundSize: props.twoWave
          // ? "50% 100%"
          // : props.oneWave
          // ? "50% 100%"
          // : "",
          backgroundColor: "#08090C",
        }}
      >
        {/* <img src={wave1svg} alt='' style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  width: "50%",
                  height: "auto",
                  objectFit: "cover",
                  zIndex: -1,
            }}/>
              <img src={wave2svg} alt='' style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  width: "50%",
                  height: "auto",
                  objectFit: "cover",
                  zIndex: -1,
            }}/> */}
        {props.children}
      </Box>
    </React.Fragment>
  );
};

export default WaveBackgroundLayout;
