import { Backdrop, Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import { ReactComponent as CloseSquare } from "assets/svg/closeSquare.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { color } from '@mui/system';

type Props = {
  children: React.ReactNode
  dialogOpen: boolean
  closeDialog: () => void
  dialogTitle: string
}

const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 100000,
  background:
    "linear-gradient(180deg, #1E2332 0%, rgba(30, 35, 50, 0.12) 100%)",
  backdropFilter: "blur(100px)"
}))

const CustomisedDialogContent = styled(DialogContent)(({ theme }) => ({
//   background:
//     "linear-gradient(134.34deg, rgba(30, 35, 50, 0.8) 0%, rgba(30, 35, 50, 0) 100%)",
//   border: "1px solid #636B82",
  backdropFilter: "blur(100px)",
  //borderRadius: "24px",
  color: "white",
  overflow: "hidden"
}))

const ManageSongDialog = (props: Props) => {
  const theme = useTheme();

  const closeDialog = (event: any, reason: string) => {
    console.log("clicked!!")
    if (reason !== 'backdropClick') {
      props.closeDialog();
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={props.dialogOpen}
        onClose={closeDialog}
        closeAfterTransition
        BackdropComponent={CustomBackdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            background: "transparent",
            boxShadow: "none",
            borderRadius: "unset",
            maxWidth: "1000px",
            [theme.breakpoints.down("sm")]: {
              minWidth: "90%",
            },
          }
        }}
      >
        <Grid width='100%' container item justifyContent={'flex-end'}>
        <IconButton
            onClick={props.closeDialog}
            sx={{
              padding: '0px',
            }}
          >
            < ArrowBackIosIcon sx={{color: '#fff', marginRight: '14.5rem'}}/>
          </IconButton>
        </Grid>
        <CustomisedDialogContent>
          {props.children}
        </CustomisedDialogContent>
      </Dialog>
    </React.Fragment>
  )
}

export default ManageSongDialog