import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  styled,
} from "@mui/material";
// import { useTranslation } from "react-i18next";
// import ErrorCodeMessage from "utility/ErrorCodeMessage";
import { CSSProperties } from '@mui/styled-engine';
import { IMessageDialogButtons } from 'model/TypeDef';

export const CustomDialog = styled((props:any)=>(
  <Dialog {...props}>
    {props.children}
  </Dialog>
))(({theme})=>({
  "& .MuiDialog-paper":{
    borderRadius:'15px',
    background: "#121520",
    backdropFilter: "blur(100px)",
    // padding: "1rem",
    // background:'linear-gradient(0deg, rgba(185,226,244,1) 0%, rgba(240,249,254,1) 100%)'
  },
  "& .MuiDialogContentText-root":{
    fontSize:'15px',
    fontWeight:'400',
    color:"#fff",
  }
}))

const t = (key:string)=>key;

const MessageDialog = () => {
  const [dialogOpenFlag, setDialogOpenFlag] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState<string | null>(null);
  const [dialogMessage, setDialogMessage] = React.useState<string>("");
  const [dialogActionButtons, setDialogActionButtons] =
    React.useState<React.ReactNode[]>();
  // const { t } = useTranslation();

 window.ShowInfoDialog = (
   message: string,
   title: string | null = null,
   buttonCaptionKey: string | null = null,
   buttonClickHandler ?: ()=>void
 ) => {
   //Buttons Action section start.
   const okClickHandler = () => {
     setDialogOpenFlag(false);
     buttonClickHandler && buttonClickHandler();
   };
   //Buttons Action section end.

   let actionButtons = [
     <Button key="ShowDialogOk" onClick={okClickHandler}>
       {buttonCaptionKey !== null ? t(buttonCaptionKey) : t("Ok")}
     </Button>,
   ];
   title && setDialogTitle(title);
   setDialogMessage(message);
   setDialogActionButtons(actionButtons);
   setDialogOpenFlag(true);
 };

 window.ShowErrorDialog = (
   message: string,
   title: string | null = null,
   buttonCaptionKey: string | null = null,
   buttonClickHandler?: () => void
 ) => {
   //Buttons Action section start.
   const okClickHandler = () => {
     setDialogOpenFlag(false);
     buttonClickHandler && buttonClickHandler(); 
   };
   //Buttons Action section end.

   let actionButtons = [
     <Button key="ShowDialogOk" onClick={okClickHandler}>
       {buttonCaptionKey !== null ? t(buttonCaptionKey) : t("Ok")}
     </Button>,
   ];
   title && setDialogTitle(title);
   setDialogMessage(message);
   setDialogActionButtons(actionButtons);
   setDialogOpenFlag(true);
 };

 window.ShowMultiButtonsDialog = (
   message: string,
   buttones: IMessageDialogButtons[],
   title?: string
 ) => {
   //  const buttonesTemplate = buttonesCaptionKey.map((item, index) => {
   //    let dialogBoxButton = (
   //      <Button
   //        key={item}
   //        onClick={() => {
   //          if (buttonesAction.length >= index) {
   //            let fn = buttonesAction[index];
   //            fn && fn();
   //            setDialogOpenFlag(false);
   //          } else {
   //            setDialogOpenFlag(false);
   //          }
   //        }}
   //      >
   //        {t(item)}
   //      </Button>
   //    );

   //    return dialogBoxButton;
   //  });

   const buttonesTemplate = buttones.map((item, index) => {
     let dialogBoxButton = (
       <Button
         key={item.captionKey}
         sx={item.cssProps}
         onClick={() => {
           item.fun && item.fun();
           setDialogOpenFlag(false);
         }}
       >
         {t(item.captionKey)}
       </Button>
     );
     return dialogBoxButton;
   });
   title && setDialogTitle(title);
   setDialogMessage(message);
   setDialogActionButtons(buttonesTemplate);
   setDialogOpenFlag(true);
 };

 window.ShowConfirmationDialog = (
   message: string,
   confirmAcceptClickHandlr: () => void,
   confirmNotAccetClickHandlr:  () => void  = ()=>{},
   title: string | null = null,
   buttonConfirmAcceptCaptionKey: string | null = null,
   buttonConfirmNotAcceptCationKey: string | null = null
 ) => {
   //Buttons Action section start.
   const cancelButtonClickHandler = ()=>{
       confirmNotAccetClickHandlr && confirmNotAccetClickHandlr();
        setDialogOpenFlag(false);
   }

   const acceptButtonClickHandlr = ()=>{
       confirmAcceptClickHandlr && confirmAcceptClickHandlr();
        setDialogOpenFlag(false);
   }
   //Buttons Action section end.

   let actionButtons = [
     <Button key="confirmDialogCancel" onClick={cancelButtonClickHandler}>
       {buttonConfirmNotAcceptCationKey !== null
         ? t(buttonConfirmNotAcceptCationKey)
         : t("Cancel")}
     </Button>,
     <Button key="confirmDialogOk" onClick={acceptButtonClickHandlr}>
       {buttonConfirmAcceptCaptionKey !== null
         ? t(buttonConfirmAcceptCaptionKey)
         : t("Ok")}
     </Button>,
   ];

   title && setDialogTitle(title);
   setDialogMessage(message);
   setDialogActionButtons(actionButtons);
   setDialogOpenFlag(true);
 };

 window.AsynShowConfirmationDialog = async (
   message: string,
   asyncConfirmAcceptClickHandlr: () => Promise<void>,
   asyncConfirmNotAccetClickHandlr: () => Promise<void> = async () => {},
   title: string | null = null,
   buttonConfirmAcceptCaptionKey: string | null = null,
   buttonConfirmNotAcceptCationKey: string | null = null
 ) => {
   //Buttons Action section start.
   const cancelButtonClickHandler = async () => {
     asyncConfirmNotAccetClickHandlr && asyncConfirmNotAccetClickHandlr();
     setDialogOpenFlag(false);
   };

   const acceptButtonClickHandlr = async () => {
     asyncConfirmAcceptClickHandlr && asyncConfirmAcceptClickHandlr();
     setDialogOpenFlag(false);
   };
   //Buttons Action section end.

   let actionButtons = [
     <Button key="confirmDialogCancel" onClick={cancelButtonClickHandler}>
       {buttonConfirmNotAcceptCationKey !== null
         ? t(buttonConfirmNotAcceptCationKey)
         : t("Cancel")}
     </Button>,
     <Button key="confirmDialogOk" onClick={acceptButtonClickHandlr}>
       {buttonConfirmAcceptCaptionKey !== null
         ? t(buttonConfirmAcceptCaptionKey)
         : t("Ok")}
     </Button>,
   ];

   title && setDialogTitle(title);
   setDialogMessage(message);
   setDialogActionButtons(actionButtons);
   setDialogOpenFlag(true);
 };

//  window.ShowApiErrorDailog = (error:any,fn?:()=>void)=>{
//   if (fn)
//   {
//    window.ShowErrorDialog(t(ErrorCodeMessage(error)),null,null,fn);
//   }
//   else
//   {
//    window.ShowErrorDialog(t(ErrorCodeMessage(error)));
//   }
//  }

 React.useEffect(() => {
  window.isMessageDialogOpen = dialogOpenFlag;
 }, [dialogOpenFlag]);
  return (
    <React.Fragment>
      <CustomDialog open={dialogOpenFlag} disableEscapeKeyDown={true} maxWidth="xs" fullWidth={true}>
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        <DialogContent sx={{padding:'15px 24px', paddingBottom:'0',whiteSpace:'break-spaces'}}>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>{dialogActionButtons}</DialogActions>
      </CustomDialog>
    </React.Fragment>
  );
}

export default MessageDialog