import { Box, Typography } from "@mui/material";
import NavbarLayout from "components/layout/NavbarWrapper";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import { WaveRightBox, WaveLeftBox } from "components/layout/WaveBox";
import HomepageHero from "components/other/HomepageHero";
import MadeForCreators from "components/other/MadeForCreators";
import Roadmap from "components/other/Roadmap";
import VibeBuilderBox from "components/other/VibeBuilderBox";
import { BlueCardWrapper } from "components/uiCard/Cards";
import React from "react";
import { Grid } from "@mui/material";
import MusicTable from "components/other/MusicTable";

type Props = {};

const LandingPage = (props: Props) => {
  return (
    <React.Fragment>
      {/* <WaveRightBox /> */}
      <WaveBackgroundLayout twoWave oneWave={false}>
        {/* <Box
          p={["1rem", "2rem", "4rem", "5rem"]}
          pt={["7rem", "6rem", null, null, null]}
        > */}
        <HomepageHero />

        {/* <Grid item container xs={12} sx={{ overflow: "hidden", mt: "1rem" }}>
          <MusicTable />
        </Grid> */}

        {/* <Box p={['20px', '40px', '50px', '70px', '70px']}  />
         */}
        {/* <VibeBuilderBox /> */}
        {/* <Roadmap /> */}
        {/* <Box p={["20px", "40px", "50px", "70px", "70px"]} /> */}
        {/* <MadeForCreators /> */}
        {/* <Box p={["20px", "40px", "50px", "70px", "70px"]} /> */}
        {/* <WaveLeftBox /> */}
        {/**/}
        {/* </Box> */}
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default LandingPage;
