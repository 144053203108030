import { SvgIconComponent } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as BurstPuckerSvg } from "assets/svg/Burst-pucker-2.svg";

type Props = ButtonProps & {
  CustomIcon: SvgIconComponent | React.ComponentType<any>;
};

export const DarkButtonWithIcon = styled(
  (props: ButtonProps & { hideIcon?: boolean }) => (
    <Button
      variant="outlined"
      endIcon={!props.hideIcon && <BurstPuckerSvg />}
      {...props}
    >
      {props.children}
    </Button>
  )
)(({ theme }) => ({
  justifyContent: "space-between",
  background: "#08090C",
  border: "1px solid #FA6B05",
  borderRadius: "8px",
  width: "100%",
  height: "100%",
  color: "#Fff",
  "& span": {
    color: "#FA6B05",
  },
  "&:hover": {
    background: "none",
    border: "1px solid #FA6B05",
    "& span": {
      color: "#ff7f24",
    },
  },
  "&:disabled": {
    background: "#08090c7a",
    border: "1px solid #fa6b057a",
    color: "#ffffff7b",
    "& span": {
      color: "#fa6b057a",
    },
  },
}));

export const LightBlurButtonWithCustomIcon = styled((props: Props) => {
  let { CustomIcon, ...rest } = props;
  return (
    <Button variant="outlined" {...rest} endIcon={<CustomIcon />}>
      {props.children}
    </Button>
  );
})(({ theme }) => ({
  height: "100%",
  justifyContent: "space-between",
  background: "#08090C",
  border: "1px solid #FA6B05",
  borderRadius: "8px",
  // backdropFilter: "blur(20px)",
  color: "#Fff",
  "& span": {
    color: "#FA6B05",
  },
  "&:hover": {
    background: "none",
    "& span": {
      color: "#white",
    },
  },
  "&:disabled": {
    background: "rgba(255, 255, 255, 0.12)",
    color: "#ffffff7b",
    "& span": {
      color: "#ffffff7b",
      border: "1px solid transparent",
    },
  },
}));
export const DarkButtonWithCustomIcon = styled((props: Props) => {
  let { CustomIcon, ...rest } = props;
  return (
    <Button variant="outlined" {...rest} endIcon={<CustomIcon />}>
      {props.children}
    </Button>
  );
})(({ theme }) => ({
  justifyContent: "space-between",
  background: "#08090C",
  border: "1px solid #FA6B05",
  borderRadius: "8px",
  width: "100%",
  height: "100%",
  color: "#Fff",
  "& span": {
    color: "#FA6B05",
  },
  "&:hover": {
    background: "none",
    border: "1px solid #FA6B05",
    "& span": {
      color: "#ff7f24",
    },
  },
  "&:disabled": {
    background: "#08090c7a",
    border: "1px solid #fa6b057a",
    color: "#ffffff7b",
    "& span": {
      color: "#fa6b057a",
    },
  },
}));
