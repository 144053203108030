import {
    Box,
    Grid,
    Checkbox,
    Typography,
    FormControlLabel
} from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { ReactComponent as UploadIcon } from 'assets/svg/uploadIcon.svg';
import ImageTextFieldContainer from 'components/controls/ImageTextFieldContainer';
import { DarkInputWithLabel } from '../controls/inputFields';
import { DarkButtonWithIcon } from 'components/controls/Buttons';
import { AlertContext } from 'context/AlertContext';
import { severityType } from 'model/errorSeverityTypeEnum';
import CircularProgress from '@mui/material/CircularProgress';
import CheckedIcon from 'assets/Icons/CheckedIcon';
import { TrackPublishType } from 'model/musicModel';
import { TrackUploadContext } from 'context/TrackUploadContext';
import HttpTransferService from 'services/httptransfer';

type Props = {
    closeDialog: () => void;
    playlistData?: any;
};
const httptransfer = new HttpTransferService();

const EditPlayListForm = (props: Props) => {
    const [imagePreview, setImagePreview] = React.useState<string | null>(null);
    const [playlistFormData, setPlaylistFormData] = React.useState<{
        name: string,
        playlist_cover_image?: any,
        visibility: TrackPublishType
    }>({
        name: "",
        visibility: TrackPublishType.PRIVATE
    });


    const [id, setId] = useState<string>('');
    const [fileData, setFileData] = useState<any>({});
    const [file, setFile] = useState<File | null>(null);
    const [editLoading, setEditLoading] = useState<boolean>(false);
    
    const alertContext = useContext(AlertContext);
    const trackContext = useContext(TrackUploadContext);

    const errorHandler = (error: any) => {
        setEditLoading(false);
        window.ShowInfoDialog(
            error.response.data.message
        );
    };

    const updatePlayListJson = (playlist_cover_image: any) => {
        let data = playlistFormData;
        if (playlist_cover_image?.file_path) {
            data.playlist_cover_image = playlist_cover_image;
        }
        const successHandler = () => {
            setEditLoading(false)
            props.closeDialog();
            alertContext?.OpenError({
                title: 'Playlist Updated',
                message: 'Playlist updated successfully',
                severity: severityType.success,
            });
            trackContext.GetAllAlbumsByUser()
        };

        httptransfer?.updatePlaylist(id, data, successHandler, errorHandler);
    }

    const playlistData = props.playlistData;

    useEffect(() => {
        setPlaylistFormData({
            name: playlistData?.name,
            visibility: playlistData?.visibility,
        });
        setId(playlistData?._id)
    }, []);

    const handleUpdatePlaylist = () => {
        setEditLoading(true)
        let playlist_cover_image: any;
        const putSuccessCallback = (response: any) => {
            updatePlayListJson(playlist_cover_image)
        };
        const fileSuccessCallBack = async (response: any) => {
            const file_upload_url = response?.data?.responseJson?.file_upload_url;
            playlist_cover_image = {
                file_path: response?.data?.responseJson?.file_path,
                file_extension: fileData.userImagePath.file_extension,
                file_name: fileData.userImagePath.file_name,
            };
            await httptransfer.uploadFileOnAws(file, file_upload_url, putSuccessCallback, errorHandler);
        };
        if (file?.name) {
            httptransfer.uploadAlbumArt(fileData.userImagePath, fileSuccessCallBack, errorHandler)
        } else {
            updatePlayListJson(playlist_cover_image)
        }

    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        if (name === 'visibility') {
            const newVisibility = playlistFormData.visibility === TrackPublishType.PUBLIC
                ? TrackPublishType.PRIVATE
                : TrackPublishType.PUBLIC;
            setPlaylistFormData((prev) => ({ ...prev, visibility: newVisibility }));
        } else {
            setPlaylistFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid container item xs={12} gap='50px' justifyContent={'center'}>
                    <Grid
                        item
                        xs={12}
                        sm={'auto'}
                        md={'auto'}
                        direction={['row', 'row', 'column']}
                        gap={'20px'}
                        container
                        justifyContent={'center'}
                        height='max-content'
                    >
                        <ImageTextFieldContainer
                            data={playlistData?.playlist_cover_image}
                            Datakey={'userImagePath'}
                            editIcon={true}
                            fileData={fileData}
                            file={file}
                            imagePreview={imagePreview}
                            setFileData={setFileData}
                            setFile={setFile}
                            setImagePreview={setImagePreview}
                            placeholderCondition={!imagePreview}
                            fallBackProfilePic
                            placeholderNode={
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: '#08090C',
                                        borderRadius: '24px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <UploadIcon />
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            color: '#ffffff',
                                            fontSize: '0.8rem',
                                            fontWeight: 500,
                                            marginLeft: '0.5rem',
                                        }}
                                    >
                                        Upload Profile Picture
                                    </Typography>
                                </Box>
                            }
                        />
                    </Grid>

                    <Grid
                        item
                        container
                        xs={'auto'}
                        direction='column'
                        width={['100%', '100%', 'unset']}
                    >
                        <DarkInputWithLabel
                            label={"NAME"}
                            value={playlistFormData.name}
                            name={'name'}
                            type={'text'}
                            rows={5.4}
                            onChange={handleChange}
                            sx={{ marginBottom: '1.25rem' }}
                        />

                        <FormControlLabel
                            name='visibility'
                            onChange={handleChange}
                            control={
                                <Checkbox
                                    icon={
                                        <CheckedIcon
                                            checked={
                                                playlistFormData.visibility == TrackPublishType.PUBLIC
                                            }
                                        />
                                    }
                                    checkedIcon={
                                        <CheckedIcon
                                            checked={
                                                playlistFormData.visibility == TrackPublishType.PUBLIC
                                            }
                                        />
                                    }
                                />
                            }
                            label='Public'
                        />
                    </Grid>

                </Grid>

                {/* Save button container */}
                <Grid
                    item
                    container
                    justifyContent={'flex-end'}
                >
                    <DarkButtonWithIcon
                        onClick={handleUpdatePlaylist}
                        sx={{
                            width: 'max-content',
                        }}
                    >
                        {editLoading ? <CircularProgress size={15} style={{ marginRight: '8px' }} color="secondary" /> : null}
                        SAVE CHANGES
                    </DarkButtonWithIcon>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default EditPlayListForm;
