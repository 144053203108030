import { Avatar, Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Edit } from "assets/svg/edit.svg";
import { ReactComponent as UploadIcon } from "assets/svg/uploadIcon.svg";
import { AuthContext } from "context/AuthContext";

type Props = {
  editIcon: boolean;
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  placeholderCondition?: boolean;
  placeholderNode: JSX.Element;
  selectedFileNode: JSX.Element;
  Datakey:string
  setUnsavedChanges?: (value: boolean) => void;
}

const MusicUploadTextFieldContainer = ({ selectedFileNode,Datakey,editIcon, formData, setFormData, placeholderCondition, placeholderNode, setUnsavedChanges }: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const userInfo = React.useContext(AuthContext)!.User!;
  return (
    <React.Fragment>
      <Box
        // onClick={() => {
        //   inputRef.current?.click();
        //   console.log(inputRef)
        // }}
        sx={{
          width: 200, height: 200,
          // background: imagePreview ?
          //   `url(${imagePreview}) no-repeat center center`
          //   : userInfo.profilePictureUrl ?
          //     `url(${userInfo.profilePictureUrl}) no-repeat center center`
          //     : "none",
          backgroundSize: "cover",
          borderRadius: '24px',
          position: "relative",


        }}
      >
        <TextField
          ref={inputRef}
          type={'file'}
          inputProps={{ accept: ".flac,.aiff,.wav,.aif" }}
          onChange={(e) => {
            const file = (e.target as any).files![0];
            const audio = new Audio(URL.createObjectURL(file));
            setFormData(file);
            setUnsavedChanges && setUnsavedChanges(true)
          }}
          sx={{
            opacity: 0,
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1,
            "& .MuiOutlinedInput-input": {
              opacity: 0,
              width: "100%",
              height: "100%",
            },
            "& .MuiOutlinedInput-root": {
              opacity: 0,
              width: "100%",
              height: "100%",
            },
            userSelect: "unset",
            pointerEvents: "unset",
          }}
        />
        {/* if there is no profile picture and no image preview tell user to upload a profile picture using this input */}
        {Datakey === "musicUrl" && selectedFileNode}
        {placeholderCondition !== undefined && placeholderCondition && placeholderNode}
        {placeholderCondition !== undefined && !placeholderCondition && selectedFileNode}


        {editIcon && <Box
          sx={{
            borderRadius: "22px",
            backgroundColor: "#ffffff1f",
            p: ["8px", "10px"],
            width: "fit-content",
            height: "fit-content",
            display: "flex",
            position: "absolute",
            bottom: 15,
            right: 15,
            zIndex: 2,
            "& svg": {
              width: "1rem",
              height: "1rem",
            },
          }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            const input = document.querySelector("input[type='file']") as HTMLInputElement;
            input.click();
            console.log('first')
          }}
        >
          <Edit />
        </Box>}
      </Box>
    </React.Fragment>
  )
}

export default MusicUploadTextFieldContainer