export enum TrackPublishType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE"
};

export type TrackModel = {
  trackAudioFile: File | null;
  trackImageFile: File | null;
  trackName: string;
  price: string | number;
  publishTrack: TrackPublishType;
  artistName: string;
  albumName: string;
  albumPrice: string | number;
  albumCredits: string;
  Label: string;
  catalogNumber: string;
  genres: [];
  id: string;
};
