import { Google, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  RadioGroup,
  SvgIcon,
  Typography,
} from "@mui/material";
import { DarkInput, DarkDropdown } from "components/controls/inputFields";
import { DarkGlassRoundedWapper } from "components/uiCard/BoxWrapperCard";
import React from "react";
import burstPuckerSvg from "assets/svg/Burst-pucker-2.svg";
import { Box } from "@mui/system";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import CropSquareRoundedIcon from "@mui/icons-material/CropSquareRounded";
import CheckedIcon from "assets/Icons/CheckedIcon";
import { useNavigate } from "react-router-dom";
import {
  DarkButtonWithCustomIcon,
  DarkButtonWithIcon,
} from "components/controls/Buttons";
import { AuthContext } from "context/AuthContext";
import { userType } from "model/userType";
import { UnauthenticatedPath } from "utility/AppRoutingPath";
import { runFuncOnEnter } from "utility/Common";
import DialogPopup from "components/layout/DialogPopup";
import EditUserForm from "./EditUserForm";
import { SetAccessTokenKey } from "utility/AccessTokenManagement";
import { GoogleLogin } from "@react-oauth/google";
import CircularProgress from "@mui/material/CircularProgress";
import TypeSelect from "components/controls/TypeSelect";
import artistIcon from "../../assets/images/artistIcon.png";
import labelIcon from "../../assets/images/labelIcon.png";
import userIcon from "../../assets/images/userIcon.png";
import { AlertContext } from "context/AlertContext";
import { severityType } from "model/errorSeverityTypeEnum";
import HttpTransferService from "services/httptransfer";

const httptransfer = new HttpTransferService();

type Props = {};

const Signup = (props: Props) => {
  const nav = useNavigate();
  const [showOTP, setShowOTP] = React.useState(false);
  const [showStrip, setshowStrip] = React.useState(0);
  const [acceptTermsConditions, setAcceptTermsConditions] =
    React.useState(false);
  const [userInfo, setUserInfo] = React.useState({
    name: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    type: userType.USER,
  });
  const [usernameError, setUsernameError] = React.useState("");
  const [nameLengthError, setNameLengthError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState("ARTIST");
  const [FlowStep, setFlowStep] = React.useState({ uiStep: 0 });
  const [googleCredentialResponse, setGoogleCredentialResponse] =
    React.useState(null);

  const context = React.useContext(AuthContext);
  const alertContext = React.useContext(AlertContext);

  const signupHandler = () => {
    setFlowStep(prev => ({ uiStep: prev.uiStep + 1 }));
  };

  const finishSignup = () => {
    if (googleCredentialResponse) {
      context?.onGoogleSignUpSuccess(googleCredentialResponse, selectedOption);
    } else {
      context?.SignUp(
        userInfo.name,
        userInfo.username,
        userInfo.email,
        userInfo.password,
        selectedOption,
        "",
        "",
        setFlowStep
      );
    }
  };

  const googlesignupHandler = (credentialResponse: any) => {
    setGoogleCredentialResponse(credentialResponse);
    if (credentialResponse && credentialResponse.credential) {
      const idToken = credentialResponse.credential;
      const parts = idToken.split(".");

      if (parts.length === 3) {
        const decodedToken = JSON.parse(atob(parts[1]));
        if (decodedToken && decodedToken.email) {
          const userEmail = decodedToken.email;
          checkEmailAvailability(userEmail, true);
        }
      }
    }
  };

  const handleSelectClick = (option: any) => {
    setSelectedOption(option);
  };

  const errorCallback = (err: any) => {
    console.log("err", err);
  };

  const checkUsernameAvailability = async (username: any) => {
    const successCallback = (res: any) => {
      if (res?.data?.users && res.data.users[0].username === username) {
        setUsernameError("Username already exists!");
      } else {
        setUsernameError("");
      }
    };
    await httptransfer.userQuery(
      { username: [username] },
      successCallback,
      errorCallback
    );
  };

  const checkEmailAvailability = async (
    email: string,
    isGoogleSignup: Boolean
  ) => {
    const successCallback = (res: any) => {
      const userExists =
        res?.data?.users[0] && res.data.users[0].email === email;

      if (isGoogleSignup) {
        if (userExists) {
          alertContext?.OpenError({
            title: "Signup Error",
            message: "Email already exists!",
            severity: severityType.error,
          });
        } else {
          setFlowStep(prev => ({ uiStep: prev.uiStep + 1 }));
        }
      } else {
        if (userExists) {
          setEmailError("Email already exists!");
        } else {
          setEmailError("");
        }
      }
    };

    await httptransfer.userQuery(
      { email_id: [email] },
      successCallback,
      errorCallback
    );
  };

  const validateName = async () => {
    if (userInfo.name.length > 30) {
      setNameLengthError("Name should be 30 characters or less");
    } else {
      setNameLengthError("");
    }
  };

  const validateUsername = async () => {
    setUsernameError("");
    const usernamePattern = /^[a-zA-Z0-9_]+$/;
    if (!userInfo.username.match(usernamePattern)) {
      setUsernameError(
        "Username must contain only letters, numbers, and underscores"
      );
    } else {
      await checkUsernameAvailability(userInfo.username);
    }
  };
  const validateEmail = async () => {
    setEmailError("");
    const usernamePattern =
      /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/gi;
    if (!userInfo.email.match(usernamePattern)) {
      setEmailError("email is not valid");
    } else {
      await checkEmailAvailability(userInfo.email, false);
    }
  };

  const disabledStatus =
    !acceptTermsConditions ||
    userInfo.password !== userInfo.confirmPassword ||
    userInfo.password === "" ||
    userInfo.username === "" ||
    userInfo.email === "";

  const typeJsx = (
    <Grid
      xs={12}
      item
      container
      justifyContent={"center"}
      padding={[2, 2, 0, 0]}
    >
      <DarkGlassRoundedWapper maxWidth={"414px"}>
        <Grid container>
          <Grid item xs={12}>
            <TypeSelect
              imageSrc={artistIcon}
              label="ARTIST"
              selected={selectedOption === "ARTIST"}
              onclick={() => handleSelectClick("ARTIST")}
              imageWidth="22px"
              imageHeight="25px"
              sx={{
                mt: "2rem",
                color: "#FFFFFF",
                width: "100%",
              }}
            />

            <TypeSelect
              imageSrc={labelIcon}
              label="LABEL"
              selected={selectedOption === "LABEL"}
              onclick={() => handleSelectClick("LABEL")}
              imageWidth="25px"
              imageHeight="25px"
              sx={{
                mt: "2rem",
                color: "#FFFFFF",
                width: "100%",
              }}
            />

            <TypeSelect
              imageSrc={userIcon}
              label="USER"
              selected={selectedOption === "USER"}
              onclick={() => handleSelectClick("USER")}
              imageWidth="25px"
              imageHeight="25px"
              sx={{
                mt: "2rem",
                color: "#FFFFFF",
                width: "100%",
              }}
            />
            <Grid item xs={12} mt={"2rem"} container alignItems={"center"}>
              <Grid item xs={5} container alignItems={"center"}></Grid>
              <Grid item xs={7} textAlign={"right"}>
                <DarkButtonWithIcon onClick={() => finishSignup()}>
                  {context?.loading ? (
                    <CircularProgress
                      size={15}
                      style={{ marginRight: "8px" }}
                      color="secondary"
                    />
                  ) : null}
                  Finish Sign Up
                </DarkButtonWithIcon>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DarkGlassRoundedWapper>
    </Grid>
  );

  const signupJsx = (
    <>
      <Grid xs={12} item textAlign={"center"}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "100%",
            color: "#FFFFFF",
          }}
        >
          SIGN UP WITH ENGAGE
        </Typography>
      </Grid>

      <Grid
        xs={12}
        item
        container
        justifyContent={"center"}
        padding={[2, 2, 0, 0]}
      >
        <DarkGlassRoundedWapper maxWidth={"414px"}>
          <Grid container>
            <Grid item xs={12}>
              <DarkInput
                placeholder={"Enter user/artist/label name"}
                value={userInfo.name}
                onChange={(e: any) =>
                  setUserInfo({ ...userInfo, name: e.target.value })
                }
                onBlur={validateName}
                sx={{
                  width: "100%",
                  color: "#FFFFFF",
                }}
                size="small"
              />
              {nameLengthError && (
                <Typography variant="caption" color="error">
                  {nameLengthError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <DarkInput
                placeholder={"Enter user id"}
                value={userInfo.username}
                onChange={(e: any) =>
                  setUserInfo({ ...userInfo, username: e.target.value })
                }
                onBlur={validateUsername}
                sx={{
                  mt: "2rem",
                  width: "100%",
                  color: "#FFFFFF",
                }}
                size="small"
              />
              {usernameError && (
                <Typography variant="caption" color="error">
                  {usernameError}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <DarkInput
                value={userInfo.email}
                onChange={(e: any) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
                placeholder={"Enter Email id"}
                type={"email"}
                onBlur={validateEmail}
                sx={{
                  mt: "2rem",
                  width: "100%",
                }}
                size="small"
              />
              {emailError && (
                <Typography variant="caption" color="error">
                  {emailError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <DarkInput
                funcOnEnterSubmit={signupHandler}
                disabledButtonStatus={!disabledStatus}
                value={userInfo.password}
                onChange={(e: any) =>
                  setUserInfo({ ...userInfo, password: e.target.value })
                }
                placeholder={"Enter password"}
                sx={{
                  mt: "2rem",
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    WebkitTextSecurity: showOTP ? "" : "disc",
                  },
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle OTP visibility"
                        onClick={() => setShowOTP(prev => !prev)}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        {showOTP ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DarkInput
                funcOnEnterSubmit={signupHandler}
                disabledButtonStatus={!disabledStatus}
                value={userInfo.confirmPassword}
                onChange={(e: any) =>
                  setUserInfo({
                    ...userInfo,
                    confirmPassword: e.target.value,
                  })
                }
                placeholder={"Confirm password"}
                sx={{
                  mt: "2rem",
                  width: "100%",
                  "& .MuiOutlinedInput-input": {
                    WebkitTextSecurity: showOTP ? "" : "disc",
                  },
                }}
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle OTP visibility"
                        onClick={() => setShowOTP(prev => !prev)}
                        edge="end"
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        {showOTP ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                mt={"2rem"}
                pl={2}
                sx={{
                  background: "rgba(255, 255, 255, 0.12)",
                  backdropFilter: "blur(100px)",
                  borderRadius: "8px",
                  py: "1px",
                }}
              >
                <FormGroup
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "135%",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={acceptTermsConditions}
                        onChange={(e: any) =>
                          setAcceptTermsConditions(e.target.checked)
                        }
                        icon={<CheckedIcon checked={acceptTermsConditions} />}
                        checkedIcon={
                          <CheckedIcon checked={acceptTermsConditions} />
                        }
                      />
                    }
                    label={
                      <>
                        <p>
                          You agree to the terms of use and acknowledge the{" "}
                          <a
                            href="/assets/privacyPolicyPdf/PrivacyPolicy-engage.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            privacy policy.
                          </a>
                        </p>
                      </>
                    }
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} mt={"2rem"} container alignItems={"center"}>
              <Grid item xs={5} container alignItems={"center"}>
                <Typography
                  sx={{
                    cursor: "pointer",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "100%",
                    color: "#FA6B05",
                  }}
                  onClick={() => {
                    nav(UnauthenticatedPath.Login);
                  }}
                >
                  Back To Login
                </Typography>
              </Grid>
              <Grid item xs={5} textAlign={"right"}>
                <DarkButtonWithIcon
                  onClick={() => signupHandler()}
                  disabled={
                    disabledStatus ||
                    !!usernameError ||
                    !!emailError ||
                    !!nameLengthError
                  }
                >
                  {context?.loading ? (
                    <CircularProgress
                      size={15}
                      style={{ marginRight: "8px" }}
                      color="secondary"
                    />
                  ) : null}
                  Continue
                </DarkButtonWithIcon>
              </Grid>
              <Grid
                item
                xs={2}
                mt={"0rem"}
                alignItems="center"
                container
                justifyContent={"flex-end"}
              >
                {/* <Grid item xs={5} textAlign={"right"}> */}
                <GoogleLogin
                  itp_support
                  cancel_on_tap_outside={false}
                  containerProps={{
                    style: {
                      scale: "0.8",
                      overflow: "hidden",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "100%",
                      // height: "calc(100% - 15%)",
                      // width: "calc(100% - 90%)",
                    },
                  }}
                  context={"signup"}
                  logo_alignment={"center"}
                  shape={"circle"}
                  size={"large"}
                  theme={"outline"}
                  text={"signup_with"}
                  type={"icon"}
                  ux_mode={"popup"}
                  intermediate_iframe_close_callback={() => {
                    console.log("intermediate_iframe_close_callback");
                  }}
                  // onSuccess={(credentialResponse) => {
                  //   context?.onGoogleSignUpSuccess(credentialResponse);
                  // }}
                  // onError={() => {
                  //   context?.onGoogleLoginFailure('error');
                  // }}
                  onSuccess={credentialResponse => {
                    googlesignupHandler(credentialResponse);
                  }}
                  useOneTap
                />
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </DarkGlassRoundedWapper>
      </Grid>
    </>
  );

  return (
    <React.Fragment>
      <Grid container justifyContent={"center"} gap={"5rem"} className="mt-4">
        {FlowStep.uiStep == 0 ? signupJsx : typeJsx}
      </Grid>
    </React.Fragment>
  );
};

export default Signup;
