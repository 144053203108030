import { styled, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import wave1svg from "assets/svg/wave1.svg";
import wave2svg from "assets/svg/wave2.svg";
import wave3svg from "assets/svg/wave3.svg";

export const WaveLeftBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${wave1svg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
  backgroundSize: "contain",
  position: "absolute",
  width: "100vw",
  height: "100vh",
  bottom: 0,
  left: 0,
  opacity: 0.6,
  mixBlendMode: "soft-light",
  pointerEvents: "none",
  zIndex: 0,
}));

export const WaveRightBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${wave2svg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "right",
  backgroundSize: "contain",
  position: "absolute",
  width: "100vw",
  height: "100vh",
  top: "10%",
  right: "0px",
  opacity: 0.6,
  mixBlendMode: "soft-light",
  pointerEvents: "none",
  zIndex: 0,
}));

export const WaveBottomBox = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${wave3svg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left bottom",
  backgroundSize: "50% auto",
  position: "absolute",
  width: "100vw",
  height: "100vh",
  bottom: 0,
  left: 0,
  opacity: 0.4,
  pointerEvents: "none",
  zIndex: 0,
}));
