import { AuthContext } from 'context/AuthContext';
import { userType } from 'model/userType';
import constant from 'constants/constant';
import React, { useContext, useState, useEffect } from 'react';
import PlayListProfile from './PlayListProfile';
import HttpTransferService from 'services/httptransfer';

const httptransfer = new HttpTransferService();

const PlayListWrapper = () => {
  return <PlayListProfile />;
};

export default PlayListWrapper;
