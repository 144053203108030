import React, { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { Button } from "@mui/material";
import { ReactComponent as AddSquare } from "assets/svg/add-square.svg";
import HttpTransferService from "services/httptransfer";
import { environment } from "environments/environment.staging";
import { AlertContext } from "context/AlertContext";
import { MusicStoreContext } from "context/MusicStoreContext";
import { severityType } from "model/errorSeverityTypeEnum";
import { DarkButtonWithIcon } from "components/controls/Buttons";
interface RazorpayCheckoutProps {
  items: { name: string; price: number; type: string; id: string }[];
  currency: string;
}

// Add this declaration at the top of the file
declare global {
  interface Window {
    Razorpay: any;
  }
}

const RazorpayCheckout: React.FC<RazorpayCheckoutProps> = ({
  items,
  currency,
}) => {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);
  const musicStoreContext = useContext(MusicStoreContext);
  const user = authContext?.User;
  const httpTransferService = new HttpTransferService();

  const handlePayment = async () => {
    try {
      const amount = items.reduce((total, item) => total + item.price * 82, 0);
      const successCB = (response: any) => {
        const order = response.data;
        const options = {
          key:
            environment.razorpayMode === "test"
              ? process.env.REACT_APP_RAZORPAY_TEST_KEY_ID
              : process.env.REACT_APP_RAZORPAY_LIVE_KEY_ID,
          amount: order.amount,
          currency: order.currency,
          name: "Engage Music",
          description: "Payment for your order",
          order_id: order.id,
          handler: async (response: any) => {
            const data = {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };
            const successCB = (response: any) => {
              if (response.data.success) {
                alertContext?.OpenError({
                  title: "Payment Successful",
                  message: "Your payment was processed successfully.",
                  severity: severityType.success,
                });
                musicStoreContext?.clearCart();
              } else {
                alertContext?.OpenError({
                  title: "Payment Verification Failed",
                  message: "There was an issue verifying your payment.",
                  severity: severityType.error,
                });
              }
            };
            const errorCB = (error: any) => {
              console.error("Error verifying Razorpay payment:", error);
              alertContext?.OpenError({
                title: "Payment Verification Error",
                message: "An error occurred while verifying your payment.",
                severity: severityType.error,
              });
            };
            httpTransferService.verifyRazorpayPayment(data, successCB, errorCB);
          },
          prefill: {
            name: user?.name,
            email: user?.email,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
        const rzp = new window.Razorpay(options);
        rzp.open();
      };
      const errorCB = (error: any) => {
        console.error("Error creating Razorpay order:", error);
        alertContext?.OpenError({
          title: "Order Creation Error",
          message: "An error occurred while creating your order.",
          severity: severityType.error,
        });
      };
      httpTransferService.createRazorpayOrder(
        amount,
        currency,
        successCB,
        errorCB
      );
    } catch (error) {
      console.error("Error initiating payment:", error);
      alertContext?.OpenError({
        title: "Payment Initiation Error",
        message: "An error occurred while initiating the payment.",
        severity: severityType.error,
      });
    }
  };

  return (
    <DarkButtonWithIcon sx={{ width: "100%", mt: 1 }} onClick={handlePayment}>
      CHECKOUT
    </DarkButtonWithIcon>
  );
};

export default RazorpayCheckout;
