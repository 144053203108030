import { Divider, Grid, LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DarkGlassRoundedWapper } from "components/uiCard/BoxWrapperCard";
import { AuthContext } from 'context/AuthContext';
import React from "react";
import map from 'assets/images/worldMap.png';


interface IArtistAnalytics { }
interface ITopMusicAnalytics {
  songData: any,
  heading: string,
  data?: string
}

const songData = [
  {
    id: 1,
    title: "Drifting ft. Amna (Original Mix)",
    plays: 78,
    completedListens: 43,
    partailListens: 20,
    skips: 15,
  },
  {
    id: 2,
    title: "Bombay Dreams (Sentient remix)",
    plays: 54,
    completedListens: 34,
    partailListens: 15,
    skips: 5,
  },
  {
    id: 3,
    title: "Vishwaas ft. Amna",
    plays: 14,
    completedListens: 10,
    partailListens: 3,
    skips: 1,
  },
];

const songSales = [
  {
    id: 1,
    title: "Drifting ft. Amna (Original Mix)",
    plays: 78,
    sales: "$274.34",
  },
  {
    id: 2,
    title: "Bombay Dreams (Sentient remix)",
    plays: 54,
    sales: "$141.52",
  },
  {
    id: 3,
    title: "Vishwaas ft. Amna",
    plays: 14,
    sales: "$96.36",
  },
];

const TopMusicAnalytics: React.FC<ITopMusicAnalytics> = (props) => {
  return (
    <DarkGlassRoundedWapper p={'1rem 12px !important'}>
      <Grid container>
        <Grid item xs={12} container justifyContent={'space-between'} mb={2}>
          <Typography variant="body1" color="white" fontWeight={'700'}>
            {props.heading}
          </Typography>
          <Typography variant="body1" color="white" fontWeight={'700'}>
            {props?.data}
          </Typography>
        </Grid>
        {props.songData[0].hasOwnProperty('skips') && props.songData.map((song: { id: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; completedListens: number; plays: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined; partailListens: number; skips: number; }, index: any, arr: any) => (
          <>
            <Grid item xs={12} container my={2} justifyContent="space-between">
              <Grid container item xs={5} flexWrap='nowrap'>
                <Grid item xs={2.5}>
                  <Typography variant="body1" color="white" fontWeight={'700'}>
                    #{song.id}
                  </Typography>
                </Grid>
                <Grid item xs={9.5}>
                  <Typography noWrap variant="body1" color="white">
                    {song.title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                container
                mr={2}
                borderRadius={"8px"}
                overflow="hidden"
              >
                {/*
                  completed plays skip plays and partial plays all in one progress bar linear progress bar with height 28px 
                 */}
                <Box
                  sx={{
                    display: "block",
                    height: "28px",
                    width: (song.completedListens / (song!.plays! as number)) * 100 + "%",
                    backgroundColor: "#3276FB",
                  }}
                />
                <Box
                  sx={{
                    display: "block",
                    height: "28px",
                    width: (song.partailListens / (song!.plays! as number)) * 100 + "%",
                    backgroundColor: "#FFBA35",
                  }}
                />
                <Box
                  sx={{
                    display: "block",
                    height: "28px",
                    width: (song.skips / (song!.plays! as number)) * 100 + "%",
                    backgroundColor: "#FA6B05",
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" color="white" fontWeight={'700'}>
                  {song.plays}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                backgroundColor: "#636B82",
                width: "100%",
              }}
            />
          </>
        ))}


        {props.songData[0].hasOwnProperty('sales') && props.songData.map((song: { id: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; completedListens: number; plays: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | null | undefined; partailListens: number; skips: number; }, index: any, arr: any) => (
          <>
            <Grid item xs={12} container my={2} justifyContent="space-between">
              <Grid item xs={1}>
                <Typography variant="body1" color="white" fontWeight={'700'}>
                  #{song.id}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography noWrap variant="body1" color="white">
                  {song.title}
                </Typography>
              </Grid>
              <Grid item xs={5} container justifyContent={'flex-end'}>
                <Typography variant="body1" color="white" fontWeight={'700'}>
                  {/* @ts-ignore */}
                  {song.sales}
                </Typography>
              </Grid>
            </Grid>
            {index !== arr.length - 1 && <Divider
              sx={{
                backgroundColor: "#636B82",
                width: "100%",
              }}
            />}
          </>
        ))}

        {props.songData[0].hasOwnProperty('skips') && <Grid item xs={12} container my={2} justifyContent="space-between">
          <Grid
            item
            xs={4}
            container
            alignItems={"center"}
            justifyContent={"flex-start"}
          >
            <Box
              sx={{
                display: "block",
                width: "12px",
                height: "8px",
                backgroundColor: "#3276FB",
                borderRadius: "2px",
                mr: 1,
              }}
            />
            <Typography variant="caption" color="white">
              Completed
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box
              sx={{
                display: "block",
                width: "12px",
                height: "8px",
                backgroundColor: "#FFBA35",
                borderRadius: "2px",
                mr: 1,
              }}
            />
            <Typography variant="caption" color="white">
              Partial
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Box
              sx={{
                display: "block",
                width: "12px",
                height: "8px",
                backgroundColor: "#FA6B05",
                borderRadius: "2px",
                mr: 1,
              }}
            />
            <Typography variant="caption" color="white">
              Skipped
            </Typography>
          </Grid>
        </Grid>}
      </Grid>
    </DarkGlassRoundedWapper>
  );
};

const ArtistAnalytics: React.FC<IArtistAnalytics> = (props) => {
  const authcontext = React.useContext(AuthContext)!;
  const userInfoString = localStorage.getItem('userInfo');
  const specificUserId = localStorage.getItem('specificUser');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
  
  return (
    <Grid container gap={2} textTransform='uppercase'>
      {(authcontext?.users ? authcontext?.users.type === "LABEL" : userInfo?.type === "LABEL") && (
        ((authcontext?.users ? authcontext?.users?._id === userInfo?._id : userInfo?.type === "LABEL")) ? (
          <TopMusicAnalytics heading="Analytics - Coming Soon" songData={songData} />
        ) : (
          <TopMusicAnalytics heading="Recommendations - Coming Soon" songData={songData} />
        ))}
      {(authcontext?.users ? authcontext?.users?.type === "ARTIST" : userInfo?.type === "ARTIST") && (
        ((authcontext?.users ? authcontext?.users?._id === userInfo?._id : userInfo?.type === "ARTIST")) ? (
          <TopMusicAnalytics heading="Top Plays - Coming Soon" songData={songData} />
        ) : (
          <TopMusicAnalytics heading="Recommendations - Coming Soon" songData={songData} />
        )
      )}

      {/* <TopMusicAnalytics heading="Total Sales" data={'$518.22'} songData={songSales} /> */}

      {/* <DarkGlassRoundedWapper width={'100%'} p={'1rem 12px !important'}>
      <Grid container>
        <Grid item xs={12} container justifyContent={'space-between'} mb='24px'>
          <Typography variant="body1" color="white" fontWeight={'700'}>
            MAP
          </Typography>
          <Typography variant="body1" color="white" fontWeight={'700'}>
          31,220 Buyers
          </Typography>
        </Grid>
        <Box
          sx={{
            width: "100%",
            height: "200px",
            background: `url(${map}) no-repeat center center`,
            backgroundSize: "contain",
          }}
        />
      </Grid>
    </DarkGlassRoundedWapper> */}
    </Grid>
  );
};

export default ArtistAnalytics;
