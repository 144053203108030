
const appConfig = {
  accessTokenName: "APP_GATEWAY_ACCESS_TOKEN",
  genresArray: [
    "Happy",
    "Dreamy",
    "Euphoric",
    "Relaxing",
    "Dark",
    "Energetic",
    "Aggresive",
    "Romantic",
    "Melancholy",
    "Sunrise",
    "Sunset",
    "Chill",
    "Trippy",
    "Deep",
    "Afro House",
    "Bass/Club",
    "Bass/house",
    "Breaks/Breakbeat/Uk bass",
    "Deep House",
    "Dj Tools",
    "Drum n Bass",
    "Dubstep",
    "Electro",
    "Electronica",
    "Funk",
    "Hard dance/hardcore",
    "Industrial Techno",
    "House",
    "Indie Dance",
    "Indie pop",
    "Indie Rock",
    "Instrumental",
    "Melodic house/techno",
    "Minimal house",
    "Minimal techno",
    "Deep Tech",
    "Nu Disco",
    "New wave",
    "Disco",
    "Downtempo",
    "Prog House",
    "Psy Trance",
    "Tech House",
    "Techno (peak time/driving)",
    "Techno (deep/hypnotic)",
    "Trance",
    "Trap",
    "Uk garage",
    "Hip hop",
    "Classic rock",
    "Punk rock",
    "Synth pop",
    "Progressive rock",
    "Alternative rock",
    "Rock",
    "Metal",
    "Jazz",
    "RnB",
    "Swing",
    "IDM",
    "Pop",
    "Country",
    "303",
    "Acid house",
    "Breaks",
    "Techno",
    "Alternative",
    "Experimental",
    "Punk",
    "Folk",
    "Ambient",
    "World music",
    "Acoustic",
    "Devotional",
    "Classical",
    "Reggae",
    "Spoken word",
    "Blues",
    "Latin",
    "Afro beat",
    "Tribal", 
    "Percussive",
  ],
  hideWaveInPlayer: true,
};

export default appConfig;