import { TrackModel } from "model/trackModel";
import React, { useState, useEffect } from "react";
import { AuthContext } from "./AuthContext";
import HttpTransferService from 'services/httptransfer';

const httptransfer = new HttpTransferService();

type Props = {
  children: React.ReactNode | React.ReactNode[];
}

export const TrackUploadContext = React.createContext<any>(null);
const userInfoString = localStorage.getItem('userInfo');
const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

const TrackUploadContextProvider: React.FC<Props> = (props) => {
  const authcontext = React.useContext(AuthContext)!;
  const [users, setUsers] = useState<any[]>([]);
  const [labelUsers, setLabelUsers] = useState<any[]>([]);
  const [artistUsers, setArtistUsers] = useState<any[]>([]);
  const [Albums, setAlbums] = useState<any>({albumData:[]});
  const [musics, setMusics] = useState<any>({musicData:[]});
  const [playListData, setPlayListData] = useState<any[]>([]);
  const [likedPlaylistData, setLikedPlaylistData] = useState<any[]>([]);
  const [specificPlayListData, setSpecificPlayListData] = useState<any[]>([]);
  const [defaultlikedMusicPlaylistId, setDefaultLikedMusicPlaylistId] = useState('');
  const [getAlbumLoading, setGetAlbumLoading] = useState<boolean>(false);
  let specificUserId = localStorage.getItem("specificUser");
  const userInfoString = localStorage.getItem('userInfo');
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
   
  const getAllAlbumsByUser = async () => {
    setGetAlbumLoading(true);

    const musicSuccessCallback = (res: any) => {
      setMusics({ musicData: res.data.musics });
    };

    const albumSuccessCallback = (res: any) => {
      setAlbums({ albumData: res.data.albums });
      setGetAlbumLoading(false);
    };

    const playlistSuccessCallback = (res: any) => {
      setPlayListData(res.data.playlists);
      const likedMusicPlaylist = res.data.playlists.find((playlist: any) => playlist.type === 'LIKED_MUSIC_PLAYLIST');
      if (likedMusicPlaylist) {
        setLikedPlaylistData(likedMusicPlaylist);
        const { _id } = likedMusicPlaylist;
        setDefaultLikedMusicPlaylistId(_id); 
        localStorage.setItem("defaultLikedId", _id)
      }
      setGetAlbumLoading(false);
    };
    const specificPlaylistSuccessCallback = (res: any) => {
      setSpecificPlayListData(res.data.playlists);
    };

    const errorCallback = (err: any) => {
      console.log("err", err);
      setGetAlbumLoading(false);
    };

    await httptransfer.musicQuery({
      user_id : authcontext?.users?._id ? [authcontext.users._id]: [userInfo?._id]
    }, musicSuccessCallback, errorCallback);

    await httptransfer.queryAlbum({
      user_id: authcontext?.users?._id ? [authcontext.users._id]: [userInfo?._id]
    }, albumSuccessCallback, errorCallback);
    if(authcontext?.users && authcontext?.users?._id) {
    await httptransfer.playlistQuery({ 
      user_id: authcontext?.users?._id ? [authcontext.users._id]: [userInfo?._id]
    }, specificPlaylistSuccessCallback, errorCallback);
    }

    await httptransfer.playlistQuery({ 
      user_id: [userInfo?._id] 
    }, playlistSuccessCallback, errorCallback);

    let userErrorCallback = (error: any) => {
      console.log(error)
    };
    if (users.length === 0) {
      let userSuccessCallback = (response: any) => {
        setUsers(response.data.users)
      };
      await httptransfer.userQuery({}, userSuccessCallback, userErrorCallback);        
    }
    let labelUserSuccessCallback = (response: any) => {
        setLabelUsers(response.data.users)
    };

    await httptransfer.userQuery({type: ["LABEL"]}, labelUserSuccessCallback, userErrorCallback);
    let artistUserSuccessCallback = (response: any) => {
        setArtistUsers(response.data.users)
    };
    await httptransfer.userQuery({type: ["ARTIST"]}, artistUserSuccessCallback, userErrorCallback);
  };

  useEffect(() => {
    if(specificUserId || (authcontext?.users && authcontext.users._id)) {
    getAllAlbumsByUser();
    }
  }, [specificUserId, authcontext?.users?._id]);


  return (
    <TrackUploadContext.Provider
      value={{
        GetAllAlbumsByUser: getAllAlbumsByUser,
        Albums:Albums,
        Musics: musics,
        GetAlbumLoading:getAlbumLoading,
        Users: users,
        LabelUsers: labelUsers,
        ArtistUsers: artistUsers,
        PlayListData: playListData,
        SpecificPlayListData: specificPlayListData,
        DefaultPlayListId: defaultlikedMusicPlaylistId,
        LikedPlaylistData: likedPlaylistData,
      }}
    >
      {props.children}
    </TrackUploadContext.Provider>
  );
};

export default TrackUploadContextProvider;
