import React, { useEffect, useState } from "react";
import { cartListingType } from "model/cartListingType";
import { AuthContext } from "./AuthContext";
import HttpTransferService from 'services/httptransfer';

const httptransfer = new HttpTransferService();
//import { MusicStoreCheckout, searchMusicApi } from "services/Apirepository";

interface ISearchContext {
  type: "Songs" | "Albums" | "Artists" | "Genres";
  query: string;
  searchResult: any[];
  setQuery: (data: string) => void;
  setType: (data: "Songs" | "Albums" | "Artists" | "Genres") => void;
  searchApi: () => void;
  searchImplicitly: (typeP:"Songs" | "Albums" | "Artists" | "Genres",queryP:string) => void;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const SearchContext = React.createContext<ISearchContext | null>(null);

let searchTimerVar: string | number | NodeJS.Timeout | undefined 
const SearchContextProvider: React.FC<Props> = (props) => {

  const [type, setType] = useState<"Songs" | "Albums" | "Artists" | "Genres">("Songs");
  const [query, setQuery] = useState<string>("");
  const [searchResult, setSearchResult] = useState([]);
  const authcontext = React.useContext(AuthContext);

  const searchApi = async () => {
    if (query.trim() === "") {
      setSearchResult([]);
      return;
    }
    if(searchTimerVar){
      clearTimeout(searchTimerVar);
    }
    searchTimerVar = setTimeout(async() => {
      
    const successCB = (res: any) => {
      setSearchResult(res.data.users);
      console.log("successCB", res.data.users);
    };

    const errorCB = (err: any) => {
      console.log("errorCB", err);
    };
    const searchParams = {
      "type": ["ARTIST", "LABEL"],
      "search_params": {
        "search_text": query
      },
      "pagination_details": {
        "page_size": 5,
        "page_number": 1
      }
    }

    await httptransfer.userQuery(searchParams, successCB, errorCB);
    }, 500);
  };

  const searchImplicitly = async (typeP:"Songs" | "Albums" | "Artists" | "Genres",queryP:string) => {
    // debugger
    console.log("searchImplicitly",typeP,queryP);
    const successCB = (res: any) => {
      setSearchResult(res.data.users);
      console.log("successCB", res.data.users);
    };

    const errorCB = (err: any) => {
      console.log("errorCB", err);
    };
    let type = authcontext?.users.type
    const searchParams = {
      "type": [type],
      "search_params": {
        "search_text": query
      },
    }
     //await httptransfer.userQuery(searchParams, successCB, errorCB);
  };

  React.useEffect(() => {
    searchApi();
  }, [type, query]);

  return (
    <SearchContext.Provider
      value={{
        type,
        query,
        searchResult,
        setQuery,
        setType,
        searchApi,
        searchImplicitly,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchContextProvider;
