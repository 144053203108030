import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import { DarkButtonWithIcon } from 'components/controls/Buttons'
import { DarkInput } from 'components/controls/inputFields'
import WaveBackgroundLayout from 'components/layout/WaveBackgroundLayout'
import { DarkGlassRoundedWapper } from 'components/uiCard/BoxWrapperCard'
import { AuthContext } from 'context/AuthContext'
import { AlertContext } from 'context/AlertContext'
import { userModel } from 'model/userModel'
import { userType } from 'model/userType'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { UnauthenticatedPath } from 'utility/AppRoutingPath'
import { severityType } from 'model/errorSeverityTypeEnum'

type Props = {}

const ForgetPassword = (props: Props) => {
  const [FlowStep, setFlowStep] = React.useState({ uiStep: 0 })

  const nav = useNavigate()

  const [showPassword, setShowPassword] = React.useState(false)
  const [userInfo, setUserInfo] = React.useState({
    email: '',
    confirmPassword: '',
    password: '',
  })

  const [otp , setOtp] = React.useState('')

  const authContext = React.useContext(AuthContext)
  const alertContext = React.useContext(AlertContext)

  const handleResetPassword = async () => {

    if(FlowStep.uiStep === 0){
      // send email
      let successHandler = (res:any) => {
        console.log(res)
        alertContext?.OpenError({
          title: "OTP SENT SUCCESSFULLY",
          message: res.data.msg,
          severity: severityType.success,
        });
        setFlowStep((prev)=>({ uiStep: prev.uiStep + 1 }))
      }

      let errorHandler = (error: any) => {
        // console.log(error)
        alertContext?.OpenError({
          title: "OTP SENT FAILED",
          message: error.response.data.errors[0].msg,
          severity: severityType.error,
        });
      }

      authContext?.SendEmailForOtp(userInfo.email,successHandler,errorHandler)
    }else if(FlowStep.uiStep === 1){
      // verify otp
      let successHandler = (res:any) => {
        console.log(res)
        alertContext?.OpenError({
          title: "OTP VERIFIED SUCCESSFULLY",
          message: res.data.msg,
          severity: severityType.success,
        });
        setFlowStep((prev)=>({ uiStep: prev.uiStep + 1 }))
      }

      let errorHandler = () => {
        // console.log(error)
        alertContext?.OpenError({
          title: "OTP VERIFIED FAILED",
          message: "Otp verified failed, please try again!",
          severity: severityType.error,
        });
      }

      authContext?.VerifyOtp(userInfo.email,otp,successHandler,errorHandler)
    }else if(FlowStep.uiStep === 2){
      // change password

      let successHandler = () => {
        // console.log(res)
        alertContext?.OpenError({
          title: "PASSWORD RESET SUCCESSFULLY",
          message: "Password reset successfully!",
          severity: severityType.success,
        });
        setFlowStep((prev)=>({ uiStep: prev.uiStep + 1 }))
      }

      let errorHandler = () => {
        // console.log(error)
        alertContext?.OpenError({
          title: "PASSWORD RESET FAILED",
          message: "Password reset failed, please try again!",
          severity: severityType.error,
        });
      }

      authContext?.ResetPassword(userInfo.email,userInfo.password,successHandler,errorHandler)
    }
  }

  const sendEmailJsx = (
    
    <Grid container justifyContent={"center"} gap={"2.5rem"}>
    <Grid xs={12} item textAlign={"center"}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "100%",
          color: "#FFFFFF",
        }}>
          ENTER EMAIL ID TO RESET PASSWORD
      </Typography>
    </Grid>

    <Grid xs={12} item container justifyContent={"center"} padding={[2, 2, 0, 0]}>
      <DarkGlassRoundedWapper maxWidth={"414px"}>
        <Grid container>
          <Grid item xs={12}>
            <DarkInput
              placeholder={"Enter Email id"}
              value={userInfo.email}
              onChange={(e: any) =>
                setUserInfo({ ...userInfo, email: e.target.value })
              }
              sx={{
                width: "100%",
                color: "#FFFFFF",
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} mt={"2rem"} container>
            <Grid item xs={7} container alignItems={"center"}>
              <Typography
                sx={{
                  cursor: "pointer",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "100%",
                  color: "#FA6B05",
                }}
                onClick={() => nav(UnauthenticatedPath.Login)}
              >
                Back to Login
              </Typography>
            </Grid>
            <Grid item xs={5} textAlign={"right"}>
              <DarkButtonWithIcon
                disabled={!userInfo.email}
                onClick={() => handleResetPassword()}>
                SEND EMAIL
              </DarkButtonWithIcon>
            </Grid>
          </Grid>
        </Grid>
      </DarkGlassRoundedWapper>
    </Grid>
  </Grid>
  )

  const verifyOtpJsx = (
    
    <Grid container justifyContent={"center"} gap={"2.5rem"}>
    <Grid xs={12} item textAlign={"center"}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "100%",
          color: "#FFFFFF",
        }}>
          VERIFY OTP TO RESET PASSWORD
      </Typography>
    </Grid>

    <Grid xs={12} item container justifyContent={"center"} padding={[2, 2, 0, 0]}>
      <DarkGlassRoundedWapper maxWidth={"414px"}>
        <Grid container>
          <Grid item xs={12}>
            <DarkInput
              placeholder={"Enter OTP"}
              value={otp}
              type={"number"}
              onChange={(e: any) =>
                setOtp(e.target.value)
              }
              sx={{
                width: "100%",
                color: "#FFFFFF",
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} mt={"2rem"} container>
            <Grid item xs={7} container alignItems={"center"}>
              
            </Grid>
            <Grid item xs={5} textAlign={"right"}>
              <DarkButtonWithIcon
                disabled={!otp}
                onClick={() => handleResetPassword()}>
                VERIFY OTP
              </DarkButtonWithIcon>
            </Grid>
          </Grid>
        </Grid>
      </DarkGlassRoundedWapper>
    </Grid>
  </Grid>
  )

  const changePasswordJsx = (
    
    <Grid container justifyContent={"center"} gap={"2.5rem"}>
    <Grid xs={12} item textAlign={"center"}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "100%",
          color: "#FFFFFF",
        }}>
          RESET PASSWORD
      </Typography>
    </Grid>

    <Grid xs={12} item container justifyContent={"center"} padding={[2, 2, 0, 0]}>
      <DarkGlassRoundedWapper maxWidth={"414px"}>
        <Grid container>
          <Grid item xs={12}>
          <DarkInput
              value={userInfo.password}
              onChange={(e: any) =>
                setUserInfo({ ...userInfo, password: e.target.value })
              }
              placeholder={"New password"}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-input": {
                  WebkitTextSecurity: showPassword ? "" : "disc",
                },
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle OTP visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DarkInput
              value={userInfo.confirmPassword}
              onChange={(e: any) =>
                setUserInfo({ ...userInfo, confirmPassword: e.target.value })
              }
              placeholder={"Re-type New Password"}
              sx={{
                mt: "2rem",
                width: "100%",
                "& .MuiOutlinedInput-input": {
                  WebkitTextSecurity: showPassword ? "" : "disc",
                },
              }}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle OTP visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} mt={"2rem"} container>
            <Grid item xs={7} container alignItems={"center"}>
              
            </Grid>
            <Grid item xs={5} textAlign={"right"}>
              <DarkButtonWithIcon
                disabled={userInfo.password === '' || userInfo.password !== userInfo.confirmPassword}
                onClick={() => handleResetPassword()}>
                Log In
              </DarkButtonWithIcon>
            </Grid>
          </Grid>
        </Grid>
      </DarkGlassRoundedWapper>
    </Grid>
  </Grid>
  )

  return (
    <React.Fragment>
      <WaveBackgroundLayout twoWave={false} oneWave={false}>
        <Grid
          sx={{
            height: "auto",
            minHeight: "100vh",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            flexWrap: "wrap"
          }}
        >
          {
            FlowStep.uiStep == 0 ? sendEmailJsx 
              : FlowStep.uiStep == 1 ? verifyOtpJsx
                : changePasswordJsx
          }
        </Grid>
      </WaveBackgroundLayout>
    </React.Fragment>
  )
}

export default ForgetPassword