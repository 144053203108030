import { Alert, AlertProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import {ReactComponent as EmojiSad} from 'assets/svg/emoji-sad.svg'
import {ReactComponent as EmojiHappy} from 'assets/svg/emoji-normal.svg'

export const CustomizedAlert = styled((props: AlertProps) => (
    <Alert  {...props} iconMapping={{
        success: <EmojiHappy />,
        error: <EmojiSad />,
    }} >{props.children}</Alert>
))(({ theme }) => ({
    width: "100%",
    color: "#fff",
    textAlign: "unset",
    justifyContent: "center",
    alignItems: "center",
    borderRadius:0,
    "& .MuiAlert-icon":{
        padding:0
    },
    "& .MuiAlert-action":{
        marginLeft:'unset'
    }
}));
