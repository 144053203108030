import { Grid, Box } from '@mui/material';
import WaveBackgroundLayout from 'components/layout/WaveBackgroundLayout';
import PlaylistHero from './PlayListHero';
import ProfileListWrapper from 'components/other/ProfileListWrapper';
import PlaylistSongsListing from './PlaylistSongsListing';
import React from 'react';

type Props = {}

const PlayListProfile = (props: Props) => {

  const tabListingData = 
  [
    {
      viewName: "Music",
      view: (
        <Box overflow="auto" maxHeight="500px">
          <PlaylistSongsListing/>
        </Box>
      )
    }
  ]


  return (
    <WaveBackgroundLayout oneWave={false} twoWave={false}>
      <Grid container color='white' p={["1rem", "2rem", "4rem", "5rem"]} pt={["7rem", "6rem", null, null, null]}>
        <Grid height='fit-content' item container xs={12} sm={12} md={7} lg={7}><PlaylistHero /></Grid>
        {/* <Grid pl={[0, 4]} height='fit-content' item container xs={12} sm={12} md={5} lg={5}><ProfileDescription /></Grid> */}
        <Grid mt={['1rem', '3.4rem']} height='fit-content' item container xs={12} sm={12} md={7} lg={7}><ProfileListWrapper tabs={tabListingData} /></Grid>
      </Grid>
    </WaveBackgroundLayout>
  )
}

export default PlayListProfile;