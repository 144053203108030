import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import NavbarLayout from 'components/layout/NavbarWrapper';
import WaveBackgroundLayout from 'components/layout/WaveBackgroundLayout';
import AboutUsHero from 'components/other/AboutUsHero';
import MadeForCreators from 'components/other/MadeForCreators';
import MeetTheTeam from 'components/other/MeetTheTeam';
import Roadmap from 'components/other/Roadmap';
import { BlueCardWrapper } from 'components/uiCard/Cards';
import React from 'react';

type Props = {};

const AboutusPage = (props: Props) => {
  React.useEffect(() => {
    // scroll to the top of the page smoothly
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);
  return (
    <React.Fragment>
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box
          p={['1rem', '2rem', '4rem', '5rem']}
          pt={['7rem', '6rem', null, null, null]}
        >
          <Grid
            container
            justifyContent={'center'}
            alignItems='center'
            gap={4}
            color='white'
          >
            {/* <img src={heroImage} style={{width:'100%',height:'100%'}} alt=''/> */}
            <Grid
              container
              item
              alignItems='center'
              direction={'column'}
              justifyContent='center'
            >
              {/* <Grid container item xs={4} justifyContent='center' sx={{
        "& img": {
          height:responsiveImageHeight,
          width:responsiveImageWidth
        }
      }}><img src={LogoPng} alt=''/></Grid> */}
              <Grid container item xs={8} justifyContent='flex-start'>
                <Typography
                  // variant={"h1"}
                  fontWeight={700}
                  sx={{
                    '@media (min-width: 1200px)': {},
                    fontSize: ['2rem', '2.5rem', '3.5rem', '4rem', '5.2rem'],
                    fontFamily: "'Darker Grotesque', sans-serif",
                    // lineHeight: '0.8',
                    // mb: "1.5rem",
                  }}
                >
                  <span
                    style={{
                      color: '#FA6B05',
                      fontFamily: "'Darker Grotesque', sans-serif",
                    }}
                  >
                    Privacy
                  </span>
                  Policy
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent='center'>
                <Typography
                  textAlign={'justify'}
                  sx={{
                    fontSize: {
                      xs: '0.8rem',
                      sm: '1rem',
                      md: '1.25rem',
                      lg: '1.5rem',
                    },
                    overflowWrap: 'break-word',
                    whiteSpace: 'break-spaces',
                  }}
                  fontWeight={500}
                >
                  Engage Music Private Limited (“Engage”, or “we” or “our” or
                  “us”) operates the Engage Music platform (“Platform”), which
                  enables users of the Platform (“Users”, or “you” and its
                  grammatical variations) to purchase audio content listed by
                  third party sellers on the Platform (“Products”).
                  <br />
                  <br />
                  This policy (“Privacy Policy”) forms an integral part of the
                  Terms of Service between Engage and Users, and governs how
                  Engage collects, uses, shares, and protects the personal
                  information disclosed by users in the course of their usage of
                  the Platform. By using this Platform and availing our
                  services, you indicate that you understand, agree and consent
                  to this Privacy Policy Accordingly, you are advised to read
                  this Privacy Policy carefully before accessing the Platform.
                  <ol type='1'>
                    <li>
                      <strong>Types of information collected</strong>
                      <ol type='A'>
                        <li>
                          We may collect the following types of information:
                          <ul>
                            <li>
                              <u>Information provided by you</u>: When you
                              register on the Platform and create youraccount
                              with us, you will be required to provide us with
                              your name, email address,location, mobile number,
                              date of birth, social media account ID (where
                              submitted by you)
                            </li>

                            <li>
                              <u>Transaction data</u>: When you purchase
                              Product(s) on the Platform, we will collect
                              information about the purchase(s) initiated and/or
                              concluded by you. Please note that we do not
                              collect your financial details such as your bank
                              account details, debit/credit card details, or any
                              other detail about the payment instrument used by
                              you. However, third party service providers such
                              as payment service providers may collect your
                              financial information and such collection will be
                              governed by their terms of use and privacy policy.
                            </li>
                            <li>
                              <u>
                                Information collected when you interact with us
                              </u>
                              : When you interact with our customer support
                              telephonically or via email, such interactions may
                              be recorded and stored.
                            </li>
                            <li>
                              <u>Technical information</u>: We automatically
                              collect certain information from you when you use
                              the Platform such as authentication tokens.
                              Authentication tokens allows us to verify your
                              identity when you access our Platform in a secure
                              manner.
                            </li>
                            <li>
                              <u>Platform activity information</u>: When you
                              access and use our Platform, we collect details
                              related to the manner of your use of and
                              interaction with our Platform, third party sellers
                              and other Users such as the date and time during
                              which you accessed the Platform, location from
                              where you access the Platform, the amount of money
                              spent on the Platform, the products you purchase,
                              the duration for which you access the Platform,
                              the device you use to access the Platform.
                            </li>
                            <li>
                              <u>Information you upload on the Platform</u>:
                              When you upload any information on the Platform or
                              communicate with third-party sellers or other
                              Users on the Platform, we receive and collect such
                              information and messages you may upload or send or
                              receive on the Platform, and any other content you
                              may upload or share on the Platform.
                            </li>
                          </ul>
                        </li>
                        <br />
                        <li>
                          The information that we require you to provide on
                          first accessing or registering on the Platform is
                          mandatory and/or essential to the provision of our
                          services to you, and hence if you do not provide such
                          mandatory information, you may not be able to access
                          some of the features of the Platform.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>How we use your information</strong>
                      <br />
                      In addition to the uses of information we describe
                      elsewhere in this Privacy Policy, we use your information
                      to do the following:
                      <ol type='a'>
                        <li>enable your access and use of the Platform;</li>
                        <li>
                          facilitate you to purchase products on the Platform;
                        </li>
                        <li>
                          send you service-related emails and communications,
                          including notifications about changes to our policies
                          or services;
                        </li>
                        <li>handle support requests;</li>
                        <li>
                          perform internal operations such as troubleshooting,
                          data analysis, testing, research, statistical,
                          internal training, and quality management purposes
                          aimed at improving the Platform, or enhancing future
                          products and services that we may intend to offer.
                          Such analysis is aggregated and does not pertain to
                          any specific User.{' '}
                        </li>
                        <li>verification of users and their accounts;</li>
                        <li>
                          measure and understand the effectiveness of the
                          promotional offers we may serve to you;
                        </li>
                        <li>
                          personalise the Platform for you, including the
                          promotional offers, Products;{' '}
                        </li>
                        <li>provide you with user support; and</li>
                        <li>enforce our Platform policies.</li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>How we store your information</strong>
                      <br />
                      <ol type='A'>
                        <li>
                          The information collected by us from users of the
                          Platform is securely stored and processed on servers
                          located in India.
                        </li>
                        <li>
                          Some of our Affiliates and/or Service Providers may
                          store or process information in jurisdictions other
                          than India.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>How we share your information</strong>
                      <br />
                      <ol type='A'>
                        <li>
                          We may share your information with various third
                          parties who assist us in providing you with our
                          services and Platform, including the following:
                          <ul>
                            <li>
                              Service providers, such as information technology
                              and payment service providers, whose services form
                              a part of or are necessary for the provision of
                              our Services;
                            </li>
                            <li>
                              Cloud storage providers to store the information
                              you provide and for disaster recovery services, as
                              well as for the performance of any contract we
                              enter into with you;
                            </li>
                            <li>
                              Analytics and search engine providers that assist
                              us in the optimisation and improvement of the
                              Platform; and
                            </li>
                            <li>
                              Entities that provide us with marketing
                              assistance;
                            </li>
                            <li>
                              Entities that provide us with customer support
                              services.
                            </li>
                          </ul>
                        </li>
                        <br />
                        <li>
                          We may share your information with group companies of
                          Engage that aid us in the provision of our services
                          and the functioning of the Platform, and for our
                          internal business purposes.
                        </li>
                        <br />
                        <li>
                          We may share your information with law enforcement
                          agencies, public authorities, or other organizations
                          if legally required to do so, or if such use is
                          reasonably necessary to:
                          <ul>
                            <li>
                              comply with any legal obligation, process, or
                              request from government or judicial authorities;
                            </li>
                            <li>
                              enforce our Platform policies, including
                              investigation of any potential violation thereof;
                            </li>
                            <li>
                              detect, prevent, or otherwise address security,
                              fraud, or technical issues; or
                            </li>
                            <li>
                              protect the rights, property or safety of us, our
                              users, a third party or the public as required or
                              permitted by law (including exchanging information
                              with other companies and organisations for the
                              purposes of fraud protection).
                            </li>
                          </ul>
                        </li>
                        <br />
                        <li>
                          The service providers we engage are bound by data
                          processing agreements that require them to take
                          sufficient technical and organizational measures to
                          safeguard your information.
                        </li>
                        <br />
                        <li>
                          The service providers we engage are bound by data
                          processing agreements that require them to take
                          sufficient technical and organizational measures to
                          safeguard your information.
                        </li>
                        <br />
                        <li>
                          If we sell or otherwise transfer our business, or our
                          assets, in full or part, to another organization
                          (e.g., in the course of a transaction like a merger,
                          acquisition, bankruptcy, dissolution, or liquidation),
                          your information may be among the items sold or
                          transferred. The buyer or transferee would be
                          obligated to honour the commitments made in this
                          Privacy Policy.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>How long we retain your information</strong>
                      <ol type='A'>
                        <li>
                          We retain your information for as long as is necessary
                          to provide you with our services and enable access to
                          our Platform.
                        </li>
                        <br />
                        <li>
                          We retain your information for such duration as may be
                          necessary to comply with our legal obligations,
                          resolve disputes and enforce our agreements, and to
                          protect our legal rights, subject to limitations under
                          applicable law.
                        </li>
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Your rights and preferences</strong>
                      <ol type='A'>
                        <li>
                          <u>Access and Updating</u>: You may access and update
                          the personal information that you have provided to us
                          by logging into your user account, and using the
                          options available therein, though this may be
                          restricted in instances where you are not able to
                          verify your identity or there is any suspicious
                          activity associated with your account.
                        </li>
                        <br />
                        <li>
                          <u>Withdrawal from providing information</u>: You have
                          the right to withdraw or decline your consent to the
                          collection of any information you provide us at any
                          time by writing to us at{' '}
                          <a href='mailto:info@engagemusic.io'>
                            info@engagemusic.io
                          </a>
                          . Please note, this may affect your usage and access
                          to our Platform.
                        </li>
                        <br />
                        <li>
                          <u>Communications Opt-Outs</u>: You can opt-out of
                          marketing or advertising emails by sending an email to
                          info@engagemusic.io.
                        </li>
                        <br />
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>
                        Security and protection of your information
                      </strong>
                      <ol type='A'>
                        <li>
                          We use industry standard and legally mandated
                          safeguards to keep the information collected by us
                          secure. These measures include periodic reviews of our
                          data collection practices, storage and processing
                          practices, cyber security measures such as encryption,
                          and physical security measures to guard against
                          unauthorized access to systems where we store Users’
                          personal information. We have a comprehensive
                          information security program and information
                          securitypolicies that contain managerial, technical,
                          operational and physical security control measures
                          adequate for the protection of personal information,
                          including sensitive personal information.
                        </li>
                        <br />
                        <li>
                          When you register with us, your account is protected
                          by means of a unique password, which is known to or
                          may be accessed only by you. You are responsible for
                          maintaining the secrecy of your unique password and
                          the user account information, and for controlling
                          access to communication between you and us, at all
                          times. Therefore, you should not provide your password
                          or login credentials to anyone whosoever: breach
                          hereof constitutes a violation of this Privacy Policy
                          and may result in termination of your user account in
                          certain cases. If you become aware of or reasonably
                          suspect any breach of security, including compromise
                          of your password, you must notify us immediately.
                        </li>
                        <br />
                      </ol>
                    </li>
                    <br />
                    <li>
                      <strong>Information relating to children</strong>
                      The Platform is not intended or directed at anyone under
                      the age of 18, and we do not knowingly collect or solicit
                      any information from, or allow the registration on the
                      Platform of, anyone under the age of 18. Upon learning
                      that we have collected personal information from a user
                      under the age of 18, we will delete that information as
                      quickly as possible. If you believe that we might have any
                      information from or about a child under age 18, please
                      contact our Grievance Officer in the manner provided in
                      Clause 10 below.
                    </li>
                    <br />
                    <li>
                      <strong>Third party platforms and services</strong>
                      The Platform may contain links to third-party websites or
                      services (“External Resource”). Your browsing on and
                      interaction with any External Resource, irrespective of
                      whether the link to such External Resource originated on
                      our Platform, is subject to the terms and privacy policies
                      of such External Resource. We are not responsible for the
                      functionality, privacy, or security measures of any other
                      entity and this Privacy Policy will not govern your use
                      and access of such website or service you are redirected
                      to.
                    </li>
                    <br />
                    <li>
                      <strong>How to contact us</strong>
                      If you have any thoughts or questions about this Privacy
                      Policy or the Platform, or any concerns or grievances
                      about the processing of your personal information by us,
                      please contact our Grievance Officer at: Name: Himay
                      Kumbhani Email address:{' '}
                      <a href='mailto:info@engagemusic.io'>
                        info@engagemusic.io
                      </a>
                    </li>
                    <br />
                    <li>
                      <strong>Changes to this Privacy Policy</strong>
                      Engage may modify or update this Privacy Policy from time
                      to time, including the type of information collected from
                      you and we will notify you, via email or in-app/website
                      notifications of any such changes. However, you are
                      advised to review this Privacy Policy periodically, as
                      your continued use of our services or access to the
                      Platform after any modification to this Privacy Policy
                      will constitute your acceptance of such modification.
                    </li>
                  </ol>
                  
                  <p>Last Updated February 21, 2023</p>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default AboutusPage;
