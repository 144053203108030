import {
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Skeleton,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
//@ts-ignore
// import audioFile from 'assets/'
import { intervalToDuration } from "date-fns";
//@ts-ignore
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor";
import { GetAccessTokenKey } from "utility/AccessTokenManagement";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PauseOutlinedIcon from "@mui/icons-material/PauseOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import SkipPreviousOutlinedIcon from "@mui/icons-material/SkipPreviousOutlined";
import SkipNextOutlinedIcon from "@mui/icons-material/SkipNextOutlined";
import {
  DarkButtonWithCustomIcon,
  LightBlurButtonWithCustomIcon,
} from "components/controls/Buttons";
import PlayForWorkOutlinedIcon from "@mui/icons-material/PlayForWorkOutlined";
import { ReactComponent as Seperator } from "assets/svg/Seperator.svg";
import { MusicPlayerContext } from "context/MusicPlayerContext";
import { playerSongType } from "model/playerSongType";
import PopperComponents from "components/customisedComponents/PopperComponents";
import appConfig from "appConfig";
import { MusicStoreContext } from "context/MusicStoreContext";
import HttpTransferService from "services/httptransfer";
import { TrackUploadContext } from "context/TrackUploadContext";
import { AuthContext } from "context/AuthContext";

type Props = {};
const httptransfer = new HttpTransferService();

const WaveSurferWrapper = (props: Props) => {
  // console.log(audioFile,'audioFile')
  const containerRef = React.useRef<any>(null);
  // const musicPlayerState?.WavesurferRef = React.useRef<any>(null);

  const [openVolumePopperFlag, setOpenVolumePopperFlag] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const [isPlaying, musicPlayerState?.SetIsPlaying] = React.useState(false);
  const [isMuted, setisMuted] = React.useState(false);
  const [volume, setvolume] = React.useState(1);
  const [loadingPercentage, setloadingPercentage] = React.useState({
    percentage: 0,
    loading: false,
  });

  const musicPlayerState = React.useContext(MusicPlayerContext);
  const authcontext = React.useContext(AuthContext)!;

  const musicStoreCtx = React.useContext(MusicStoreContext);

  const [playingSongProgress, setplayingSongProgress] = React.useState(0);

  const [currentTrack, setCurrentTrack] = React.useState<playerSongType>({
    id: "",
    albumCredits: "",
    albumName: "",
    albumPrice: 0,
    artistName: "",
    genres: [],
    Label: "",
    price: 0,
    trackAudioUrl:
      "https://i.scdn.co/audio/ab67616d0000b273d0f1f9b0b0f9f3b8b8f9f3b8",
    trackName: "null",
    trackImageUrl: "",
  });

  const userInfoString = localStorage.getItem("userInfo");
  const userInfo = userInfoString ? JSON.parse(userInfoString) : null;

  const isLaptopBreakpoint = useMediaQuery((theme: any) =>
    theme.breakpoints.up("lg")
  );

  const isTabletOrMobileBreakpoint = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );

  const getAdditionalInfoJsx = (item: any) => {
    let arrayOfInfo = [];
    if (item["label"].length > 0) {
      arrayOfInfo.push(...item["label"].split(","));
    } else if (item["credits"].length > 0) {
      arrayOfInfo.push(...item["credits"]);
    }

    return (
      <React.Fragment key={3232}>
        <Typography sx={{ fontSize: ["12px", "16px"] }}>
          {arrayOfInfo[0]}
        </Typography>
        {/* {index !== arr.length - 1 && <Seperator />} */}
      </React.Fragment>
    );
  };

  const formateDuration = (seconds: number) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    // { minutes: 30, seconds: 7 }

    const zeroPad = (num: any) => String(num).padStart(2, "0");

    const formatted = `${zeroPad(duration.minutes)}:${zeroPad(
      duration.seconds
    )}`;
    // 30:07

    return formatted;
  };
  const play = () => {
    musicPlayerState?.WavesurferRef.current.play();
  };
  const pause = () => {
    musicPlayerState?.WavesurferRef.current.pause();
  };
  const togglePlay = () => {
    if (musicPlayerState?.WavesurferRef.current.isPlaying()) {
      pause();
    } else {
      play();
    }
  };

  const skipToNextSong = () => {
    setvolume(1);
    const currentIndex = musicPlayerState?.playlists.findIndex(
      (track: any) => track._id === musicPlayerState?.currentTrack?.id
    );

    if (
      currentIndex !== undefined &&
      musicPlayerState?.playlists &&
      currentIndex < musicPlayerState?.playlists?.length - 1
    ) {
      let previousTrack = musicPlayerState?.playlists[currentIndex + 1];

      // Fetch the URL of the previous track
      console.log("prev track", previousTrack);
      const successCallback = (res: any) => {
        const trackToGiveToPlayer = {
          id: previousTrack?._id,
          trackAudioUrl:
            res.data.musics[0].music_mp3_audio_file.file_download_url,
          trackImageUrl: previousTrack?.music_cover_image?.file_download_url,
          trackName: previousTrack?.name,
        };

        musicPlayerState?.openPlayer(trackToGiveToPlayer, []);
      };

      const errorCallback = (err: any) => {
        console.log("err", err);
      };

      if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
        const inputJson = {
          user_id: authcontext?.users
            ? [authcontext?.users._id]
            : [userInfo._id],
          mp3_play: true,
          music_id: [previousTrack?._id], // Use the ID of the previous track
        };

        httptransfer.musicQuery(inputJson, successCallback, errorCallback);
      }
    }
  };

  const skipToPreviousSong = () => {
    setvolume(1);
    const currentIndex = musicPlayerState?.playlists.findIndex(
      (track: any) => track._id === musicPlayerState?.currentTrack?.id
    );

    if (currentIndex !== undefined && currentIndex > 0) {
      let previousTrack = musicPlayerState?.playlists[currentIndex - 1];

      // Fetch the URL of the previous track
      const successCallback = (res: any) => {
        const trackToGiveToPlayer = {
          id: previousTrack?._id,
          trackAudioUrl:
            res.data.musics[0].music_mp3_audio_file.file_download_url,
          trackImageUrl: previousTrack?.music_cover_image?.file_download_url,
          trackName: previousTrack?.name,
        };

        musicPlayerState?.openPlayer(trackToGiveToPlayer, []);
      };

      const errorCallback = (err: any) => {
        console.log("err", err);
      };

      if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
        const inputJson = {
          user_id: authcontext?.users
            ? [authcontext?.users._id]
            : [userInfo._id],
          mp3_play: true,
          music_id: [previousTrack?._id],
        };

        httptransfer.musicQuery(inputJson, successCallback, errorCallback);
      }
    }
  };

  const mutePlayer = () => {
    musicPlayerState?.WavesurferRef.current.toggleMute();
  };

  const handleChangeVolume = (event: any, newValue: number | number[]) => {
    setvolume(newValue as number);
    musicPlayerState?.WavesurferRef.current.setVolume(newValue as number);
  };

  const updateAudioDuration = () => {
    // if (waveSurfer.isPlaying()) {
    var totalTime = musicPlayerState?.WavesurferRef.current.getDuration(),
      currentTime = musicPlayerState?.WavesurferRef.current.getCurrentTime();
    // remainingTime = totalTime - currentTime;

    if (isTabletOrMobileBreakpoint) {
      setplayingSongProgress((currentTime / totalTime) * 100);
    }

    const totalTimeVal = formateDuration(totalTime);

    document!.getElementById("time-total")!.innerText = `${
      isTabletOrMobileBreakpoint ? "" : "/"
    }${totalTimeVal}`;

    document!.getElementById("time-current")!.innerText =
      formateDuration(currentTime);
    // }
  };

  const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl == event.currentTarget) {
      setOpenVolumePopperFlag(!openVolumePopperFlag);
    } else {
      setAnchorEl(event.currentTarget);
      setOpenVolumePopperFlag(true);
    }
  };

  const canBeOpen = Boolean(anchorEl);
  const volumeButtonId = canBeOpen
    ? "transition-popper-volume-button"
    : undefined;

  const volumeControlJsx = (
    <React.Fragment>
      <Grid
        item
        xs={3}
        aria-describedby={volumeButtonId}
        container
        justifyContent="center"
        onClick={handleOpenPopper}
      >
        {volume == 0 ? (
          <VolumeOffOutlinedIcon sx={{ color: "#636B82" }} />
        ) : (
          <VolumeUpOutlinedIcon />
        )}
      </Grid>
    </React.Fragment>
  );

  const playerControlButtonsJsx = (
    <React.Fragment>
      {!isTabletOrMobileBreakpoint && volumeControlJsx}
      <Grid item xs={3} container justifyContent="center">
        <SkipPreviousOutlinedIcon onClick={() => skipToPreviousSong()} />
      </Grid>
      <Grid item xs={3} container justifyContent="center">
        {musicPlayerState?.IsPlaying ? (
          <PauseOutlinedIcon onClick={togglePlay} />
        ) : (
          <PlayArrowOutlinedIcon onClick={togglePlay} />
        )}
      </Grid>
      <Grid item xs={3} container justifyContent="center">
        <SkipNextOutlinedIcon onClick={skipToNextSong} />
      </Grid>
    </React.Fragment>
  );

  const totalTimeJsx = loadingPercentage.loading ? (
    <></>
  ) : (
    <span
      style={{
        fontSize: isTabletOrMobileBreakpoint ? "10px" : "16px",
      }}
      id="time-total"
    ></span>
  );

  const currentTimeJsx = loadingPercentage.loading ? (
    <></>
  ) : (
    <span
      style={{
        fontSize: isTabletOrMobileBreakpoint ? "10px" : "16px",
      }}
      id="time-current"
    ></span>
  );

  useEffect(() => {
    if (!containerRef.current) return;
    if (!musicPlayerState?.openPlayerFlag) return;
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      cursorWidth: appConfig.hideWaveInPlayer ? 4 : 2,
      barWidth: 0,
      barGap: 0,
      barRadius: 0,
      barHeight: appConfig.hideWaveInPlayer ? 0.1 : 1,
      cursorColor: "#FB6B04",
      progressColor: appConfig.hideWaveInPlayer ? "#08080D" : "#3276FB",
      waveColor: appConfig.hideWaveInPlayer ? "#08080D" : "#636B82",
      backgroundColor: "#08080D",
      height: appConfig.hideWaveInPlayer ? 10 : 80,
      scrollParent: appConfig.hideWaveInPlayer ? false : true,
      // maxCanvasWidth:2,
      autoCenter: appConfig.hideWaveInPlayer ? false : true,
      backend: "MediaElement",
      autoCenterImmediately: false,
      plugins: [],
      xhr: {
        cache: "default",
        // mode: "cors",
        method: "GET",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "client",
        requestHeaders: [{ key: "x-auth-token", value: GetAccessTokenKey()! }],
      },
    });
    waveSurfer.load(currentTrack?.trackAudioUrl!);

    waveSurfer.on("ready", () => {
      musicPlayerState!.WavesurferRef!.current! = waveSurfer;
      waveSurfer.play();
    });

    waveSurfer.on("loading", (progress: any) => {
      if (progress === 100) {
        setloadingPercentage({ percentage: progress, loading: false });
      } else {
        setloadingPercentage({ percentage: progress, loading: false });
      }
    });

    waveSurfer.on("audioprocess", () => updateAudioDuration());

    waveSurfer.on("play", () => {
      musicPlayerState?.SetIsPlaying(true);
    });
    waveSurfer.on("pause", () => {
      musicPlayerState?.SetIsPlaying(false);
    });

    waveSurfer.on("mute", state => {
      setisMuted(state);
    });

    waveSurfer.on("seek", (e, waveform) => {
      console.log(e, waveform);
      updateAudioDuration();
    });

    waveSurfer.on("finish", () => {
      setAnchorEl(null);
      setOpenVolumePopperFlag(false);
      setvolume(1);
      const currentIndex = musicPlayerState?.playlists.findIndex(
        (track: any) => track._id === musicPlayerState?.currentTrack?.id
      );

      if (
        currentIndex !== undefined &&
        musicPlayerState?.playlists &&
        currentIndex < musicPlayerState?.playlists?.length - 1
      ) {
        let previousTrack = musicPlayerState?.playlists[currentIndex + 1];

        // Fetch the URL of the previous track
        const successCallback = (res: any) => {
          const trackToGiveToPlayer = {
            id: previousTrack?._id,
            trackAudioUrl:
              res.data.musics[0].music_mp3_audio_file.file_download_url,
            trackImageUrl: previousTrack?.music_cover_image?.file_download_url,
            trackName: previousTrack?.name,
          };

          musicPlayerState?.openPlayer(trackToGiveToPlayer, []);
        };

        const errorCallback = (err: any) => {
          console.log("err", err);
        };

        if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
          const inputJson = {
            user_id: authcontext?.users
              ? [authcontext?.users._id]
              : [userInfo._id],
            mp3_play: true,
            music_id: [previousTrack?._id],
          };

          httptransfer.musicQuery(inputJson, successCallback, errorCallback);
        }
      } else {
        musicPlayerState?.SetIsPlaying(false);
        waveSurfer.stop();
        musicPlayerState?.closePlayer();
      }
    });

    waveSurfer.on("prev", () => {
      setvolume(1);
      const currentIndex = musicPlayerState?.playlists.findIndex(
        (track: any) => track._id === musicPlayerState?.currentTrack?.id
      );

      if (currentIndex !== undefined && currentIndex > 0) {
        let previousTrack = musicPlayerState?.playlists[currentIndex - 1];

        // Fetch the URL of the previous track
        console.log("prev track", previousTrack);
        const successCallback = (res: any) => {
          const trackToGiveToPlayer = {
            id: previousTrack?._id,
            trackAudioUrl:
              res.data.musics[0].music_mp3_audio_file.file_download_url,
            trackImageUrl: previousTrack?.music_cover_image?.file_download_url,
            trackName: previousTrack?.name,
          };

          musicPlayerState?.openPlayer(trackToGiveToPlayer, []);
        };

        const errorCallback = (err: any) => {
          console.log("err", err);
        };

        if (localStorage.getItem("APP_GATEWAY_ACCESS_TOKEN")) {
          const inputJson = {
            user_id: authcontext?.users
              ? [authcontext?.users._id]
              : [userInfo._id],
            mp3_play: true,
            music_id: [previousTrack?._id],
          };

          httptransfer.musicQuery(inputJson, successCallback, errorCallback);
        }
      }
    });

    return () => {
      setAnchorEl(null);
      setOpenVolumePopperFlag(false);
      waveSurfer.destroy();
    };
  }, [
    currentTrack,
    // isTabletOrMobileBreakpoint,
    musicPlayerState?.openPlayerFlag,
  ]);

  React.useEffect(() => {
    if (musicPlayerState?.currentTrack) {
      setCurrentTrack(musicPlayerState?.currentTrack!);
      setvolume(1);
    }
  }, [musicPlayerState?.currentTrack, musicPlayerState?.playlists]);

  return (
    <>
      <Grid
        container
        sx={{
          width: "100%",
          position: "fixed",
          bottom: "0",
          left: "0",
          p: isTabletOrMobileBreakpoint ? 1 : 2,
          pt: isTabletOrMobileBreakpoint ? 2 : "",
          backgroundColor: "#1E2332",
          background: isTabletOrMobileBreakpoint
            ? "linear-gradient(180deg, transparent 0%, rgba(30,35,50,1) 13%)"
            : "",
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        alignItems="center"
        justifyContent={"center"}
      >
        <Grid
          item
          xs={3}
          container
          flexWrap={"nowrap"}
          gap={["0", 1, "1.5rem", "2.5rem"]}
          alignItems={"center"}
        >
          <Grid
            item
            xs={"auto"}
            container
            justifyContent="flex-start"
            alignItems={"center"}
            color="white"
          >
            <Avatar
              src={currentTrack?.trackImageUrl}
              sx={{
                display: ["none", "flex", "flex", "flex"],
                width: "40px",
                height: "40px",
                borderRadius: "4px",
                // border: "1px solid #636B82",
              }}
            ></Avatar>

            <Grid
              item
              container
              xs="auto"
              direction={"column"}
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{ ml: "10px" }}
              flexWrap="nowrap"
            >
              <Grid item xs={12} container gap="0.1rem" alignItems="center">
                <Typography
                  sx={{
                    fontSize: ["12px", "16px"],
                    fontWeight: "700",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "7rem",
                  }}
                >
                  {currentTrack.trackName}
                </Typography>
              </Grid>
              <Grid item xs={12} container gap="0.1rem" alignItems="center">
                <Typography
                  noWrap
                  sx={{
                    fontSize: ["12px", "16px"],
                    fontWeight: "500",
                    maxWidth: ["80px", "100px"],
                  }}
                >
                  {currentTrack.albumCredits}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={"auto"}
            sx={{
              color: "white",
              "& #time-current": {
                color: theme => theme.palette.customTheme.main,
              },
            }}
          >
            {!isTabletOrMobileBreakpoint && (
              <>
                {currentTimeJsx}
                {totalTimeJsx}
              </>
            )}
          </Grid>
        </Grid>
        <Grid
          xs={9}
          sm={6}
          item
          sx={{
            borderRadius: isTabletOrMobileBreakpoint ? "none" : "18px",
            overflow: "hidden",
          }}
          color="white"
        >
          {loadingPercentage.loading && (
            <LinearProgress
              sx={{
                width: "100%",
                height: appConfig.hideWaveInPlayer ? "10px" : "80px",
                borderRadius: "4px",
                background: "#08090C",
                "& .MuiLinearProgress-determinate": { background: "#FA6B05" },
                "& .MuiLinearProgress-barColorPrimary": {
                  background: "#FA6B05",
                },
              }}
              variant="determinate"
              value={loadingPercentage.percentage}
            />
          )}
          <Box
            sx={{
              display: loadingPercentage.loading ? "none" : "block",
              borderRadius: "18px",
            }}
            ref={containerRef}
          />
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems={"center"}
            mt={isTabletOrMobileBreakpoint ? "0.5rem" : "0"}
          >
            {isTabletOrMobileBreakpoint && currentTimeJsx}
            {isTabletOrMobileBreakpoint && playerControlButtonsJsx}
            {isTabletOrMobileBreakpoint && totalTimeJsx}
          </Grid>
        </Grid>
        <Grid
          xs={3}
          item
          container
          flexWrap={"nowrap"}
          alignItems="center"
          color={"white"}
          justifyContent={"flex-end"}
          display={["none", "none", "flex", "flex"]}
        >
          {!isTabletOrMobileBreakpoint
            ? playerControlButtonsJsx
            : volumeControlJsx}
        </Grid>
      </Grid>

      <PopperComponents
        anchorEl={anchorEl}
        placement="bottom-end"
        open={openVolumePopperFlag}
        setOpen={setOpenVolumePopperFlag}
        id={volumeButtonId}
        popperProps={{
          placement: "top",
        }}
        sx={{
          "& .MuiBox-root": {
            p: "0",
            py: "1rem",
            display: "flex",
          },
        }}
      >
        <Slider
          sx={{ minHeight: "100px" }}
          orientation="vertical"
          aria-label="Volume"
          min={0}
          max={1}
          step={0.01}
          value={volume}
          onChange={handleChangeVolume}
          valueLabelDisplay="auto"
          valueLabelFormat={value => Math.round(value * 100)}
        />
      </PopperComponents>
    </>
  );
};

export default WaveSurferWrapper;
