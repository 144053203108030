import appConfig from "appConfig";

const accessTokenName = appConfig.accessTokenName;

export const setKeyInLocalStorage = (key: string, value: string) => {
    localStorage.setItem(key, value);
    // @ts-ignore
    window[key] = value;
};


export const SetAccessTokenKey = (accessToken: string) => {
    localStorage.setItem(accessTokenName, accessToken);
    window.AccessToken = accessToken;

};

export const RemoveAccessTokenKey = () => {
    localStorage.removeItem(accessTokenName);
    window.AccessToken = null;

};

export const GetAccessTokenKey = () => {
    let accessToken = window.AccessToken;
    if(accessToken == null)
    {
       accessToken = localStorage.getItem(accessTokenName);  
       window.AccessToken = accessToken; 
    }

    return accessToken;
};
