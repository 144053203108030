import { playerSongType } from "model/playerSongType";
import React, { useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js/src/wavesurfer";

interface IMusicPlayerContext {
  currentTrack: any;
  setCurrentTrack: (data: playerSongType) => void;
  playlists: any;
  setPlaylists: (data: playerSongType[]) => void;
  addTrackToPlaylist: (data: playerSongType) => void;
  removeTrackFromPlaylist: (data: playerSongType) => void;
  clearPlaylist: () => void;
  openPlayerFlag: boolean;
  openPlayer: (data: any, allMusics: any) => void;
  closePlayer: () => void;
  IsPlaying: boolean;
  SetIsPlaying: (data: boolean) => void;
  WavesurferRef: React.MutableRefObject<any>;
  TogglePlay: () => void;
  Play: () => void;
  Pause: () => void;
  wholeAlbumInPlaylist: boolean;
}

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

export const MusicPlayerContext =
  React.createContext<IMusicPlayerContext | null>(null);

const MusicPlayerContextProvider: React.FC<Props> = (props) => {
  const [currentTrack, setCurrentTrack] = useState<playerSongType | null>(null);
  const [playlists, setPlaylists] = useState<playerSongType[]>([]);
  const [openPlayerFlag, setOpenPlayerFlag] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [wholeAlbumInPlaylist, setWholeAlbumInPlaylist] =
    useState<boolean>(false);

  const wavesurferRef = useRef<WaveSurfer>(null);

  const Play = () => {
    setIsPlaying(true);
    if (wavesurferRef.current) {
      wavesurferRef.current.play();
    }
  };

  const Pause = () => {
    setIsPlaying(false);
    if (wavesurferRef.current) {
      wavesurferRef.current.pause();
    }
  };

  const TogglePlay = () => {
    if (isPlaying) {
      Pause();
    } else {
      Play();
    }
  };

  const addTrackToPlaylist = (data: playerSongType) => {
    setPlaylists((prev) => [...prev, data]);
  };

  const removeTrackFromPlaylist = (data: playerSongType) => {
    setPlaylists((prev) => prev.filter((item) => item.id !== data.id));
  };

  const clearPlaylist = () => {
    setPlaylists([]);
  };

  const openPlayer = (data: any, allMusics: any) => {
    setCurrentTrack(data);
    setPlaylists((prevPlaylists) => [...prevPlaylists, ...allMusics]);
    setOpenPlayerFlag(true);
  };

  const closePlayer = () => {
    setOpenPlayerFlag(false);
  };

  React.useEffect(() => {
    console.log("playlists", playlists);
  }, [playlists, currentTrack]);

  React.useEffect(() => {
    if (playlists.length > 1) {
      setWholeAlbumInPlaylist(true);
    }
  }, [playlists]);

  return (
    <MusicPlayerContext.Provider
      value={{
        currentTrack: currentTrack,
        setCurrentTrack: setCurrentTrack,
        playlists: playlists,
        setPlaylists: setPlaylists,
        addTrackToPlaylist: addTrackToPlaylist,
        removeTrackFromPlaylist: removeTrackFromPlaylist,
        clearPlaylist: clearPlaylist,
        openPlayerFlag: openPlayerFlag,
        openPlayer: openPlayer,
        closePlayer: closePlayer,
        IsPlaying: isPlaying,
        SetIsPlaying: setIsPlaying,
        WavesurferRef: wavesurferRef,
        Pause: Pause,
        Play: Play,
        TogglePlay: TogglePlay,
        wholeAlbumInPlaylist: wholeAlbumInPlaylist,
      }}
    >
      {props.children}
    </MusicPlayerContext.Provider>
  );
};

export default MusicPlayerContextProvider;
