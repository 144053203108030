import { Grid, Typography } from "@mui/material";
import { DarkGlassRoundedWapper } from "components/uiCard/BoxWrapperCard";
import React, { ReactNode, useState } from "react";

type Props = {
  tabs: { viewName: string; view: ReactNode | ReactNode[] }[];
};

const ProfileListWrapper = ({ tabs }: Props) => {
  const [selectedView, setselectedView] = useState(tabs[0].viewName);

  return (
    <React.Fragment>
      <DarkGlassRoundedWapper
        width={"100%"}
        padding={"0px 0px 0px 0px !important"}
        overflow="hidden"
        borderRadius={"16px"}
      >
        <Grid container>
          {tabs.map((tab, index) => (
            <Grid
              key={index}
              p="12px 0px 10px 0px"
              textAlign={"center"}
              onClick={() => setselectedView(tab.viewName)}
              item
              xs={12/tabs.length}
              sx={
                selectedView == tab.viewName
                  ? { borderBottom: "2px solid #FA6B05", transition: "unset" }
                  : { color: "#636B82", transition: "unset" }
              }
            >
              <Typography>{tab.viewName}</Typography>
            </Grid>
          ))}
          <Grid item xs={12} overflow={'scroll'}>
            {tabs[tabs.findIndex((tab) => tab.viewName == selectedView)].view}
          </Grid>
        </Grid>
      </DarkGlassRoundedWapper>
    </React.Fragment>
  );
};

export default ProfileListWrapper;
