import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface DarkSelectProps {
  imageSrc: string;
  label: string;
  selected: boolean;
  onclick: any;
  imageWidth: string;
  imageHeight: string;
}

const TypeSelect = styled(({ imageSrc, label, selected, onclick, imageWidth, imageHeight, ...rest }: DarkSelectProps) => {

  return (
    <div
      onClick={onclick}
      style={{
        background: "rgba(255, 255, 255, 0.12)",
        borderRadius: "8px",
        color: "#fff",
        cursor: "pointer",
        width: "300px",
        padding: "0.5rem", 
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      {...rest}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <img src={imageSrc} alt="Image" style={{ width: imageWidth, height: imageHeight }}/>
        <span>{label}</span>
      </div>
      {selected && <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '1.5rem' }} />}
    </div>
  );
})(({ theme }) => ({
    background: "rgba(255, 255, 255, 0.12)",
    backdropFilter: "blur(100px)",
   // padding: "0.5rem 6rem 0.5rem 0",
    borderRadius: "8px",
    color: "#fff",
    "& .MuiOutlinedInput-root": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-notchedOutline": { display: "none" },
}));

export default TypeSelect;
