import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import { WaveRightBox } from "components/layout/WaveBox";
import React from "react";
import { ReactComponent as PlayArrow } from "assets/svg/play.svg";
import { ReactComponent as MusicFilter } from "assets/svg/music-filter.svg";
import SearchResultTable from "components/other/SearchResultTable";
import { SearchContext } from "context/SearchContext";
// import { PlayArrow } from "@mui/icons-material";

type Props = {};

const SearchListing = (props: Props) => {
  const search = React.useContext(SearchContext);
  return (
    <React.Fragment>
      <WaveRightBox
      //  sx={{bottom:'-230%',top:'unset'}}
      />
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box
          p={["1rem", "2rem", "4rem", "5rem"]}
          pt={["7rem", "6rem", null, null, null]}
          // zIndex={(theme)=>(theme.zIndex.drawer) as number}
          position={'relative'}
        >
          <Grid container spacing={2} alignItems="center" color={'white'}>
            <Grid container item xs={12} mb={'2rem'} gap='2rem' alignItems={'center'} mt='2rem'>
            <Grid container item xs={12} sm={'auto'} gap='1rem' alignItems={'center'}>
              <Typography sx={{
                fontSize: [ '2rem', '2.5rem', '3rem'],
                fontFamily: "'Darker Grotesque', sans-serif",

              }} fontWeight="bold">
                {
                  search?.query
                    ? `Search results for "${search?.query}"`
                    : `All ${search?.type}`
                }
              </Typography>
              </Grid>
              <Grid container item xs={12} sm={'auto'} gap='1rem' alignItems={'center'}>
              {/* <IconButton> */}
                  <PlayArrow/>
                {/* </IconButton> */}
                {/* <IconButton> */}
                  <MusicFilter />
                  </Grid>
                {/* </IconButton> */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
          <SearchResultTable/>
          </Grid>
        </Box>
      </WaveBackgroundLayout>
    </React.Fragment>
  );
};

export default SearchListing;
