import { Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as Facebook } from "assets/svg/facebook.svg";
import { ReactComponent as Whatsapp } from "assets/svg/whatsapp.svg";
import { ReactComponent as YouTube } from "assets/svg/youtube.svg";
import { ReactComponent as Apple } from "assets/svg/apple.svg";
import { ReactComponent as GooglePlay } from "assets/svg/google-play.svg";
import { DarkGlassRoundedWapper } from 'components/uiCard/BoxWrapperCard';
import { DarkInput } from 'components/controls/inputFields';
import { DarkButtonWithIcon } from 'components/controls/Buttons';
//import { ContactUsApi } from 'services/Apirepository';
import { severityType } from 'model/errorSeverityTypeEnum';
import { AlertContext } from 'context/AlertContext';
import { Instagram, Twitter } from "@mui/icons-material";

type Props = {}

const ContactUsHero = (props: Props) => {
  
  const [contactUsInfo, setcontactUsInfo] = React.useState({
    email: '',
    mobileNumber: '',
    reason:'',
    message: '',
  })

  const alertContext = React.useContext(AlertContext);


  const contactUsQueryHandler = ()=>{
    console.log("contactUsQueryHandler")

    const successHandler = (response: any) => {
      console.log("successHandler", response)
      alertContext?.OpenError({ title: 'Contact Us', message: response.data.msg, severity: severityType.success })
    }

    const errorHandler = (error: any) => {
      console.log("errorHandler", error)
      alertContext?.OpenError({ title: 'Contact Us', message: error.response.data.message, severity: severityType.error })
    }

    const data = {
      email: contactUsInfo.email,
      mobileNumber: contactUsInfo.mobileNumber,
      reason: contactUsInfo.reason,
      message: contactUsInfo.message,
    }
    //ContactUsApi(data, successHandler, errorHandler)
  }

    const ContactUsIntroJs = (<React.Fragment>
        <Grid container direction='row' color='white'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
						fontWeight={700}
						sx={{
							fontSize: "3rem",
							// fontSize: ["1.5rem","1.5rem", "3rem", "3rem", "4rem"],
							fontFamily: "'Darker Grotesque', sans-serif",
							// mb: "1.5rem",
						}}
					>CONTACT{" "}
						<span style={{color: "#FA6B05",fontFamily: "inherit"}} >
							US
						</span>
					</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
            <Typography variant="body1" fontSize="1.2rem" mb={1}>
            Engage Music Private Limited
          </Typography>
          <Typography variant="body1" fontSize="1.2rem">
            Contact No.: 9820396177
          </Typography>
          <Typography variant="body1" fontSize="1.2rem">
            Address: 314, Unique Industrial Estate,
          </Typography>
          <Typography variant="body1" fontSize="1.2rem">
            Twin Tower Lane, Prabhadevi,
          </Typography>
          <Typography variant="body1" fontSize="1.2rem">
            Mumbai 400025.
          </Typography>
          <Typography variant="body1" fontSize="1.2rem">
            Email: info@engagemusic.io
          </Typography>
        </Grid>

            {/* <Grid item xs={10} sm={10} md={9} lg={9} xl={7}>
            <Typography variant="subtitle2" textTransform={"uppercase"}>
            We would love to hear from you! Get in touch with us and our represetatives will get in touch with you shortly          </Typography>
            </Grid> */}
            <Grid item container mt={2} xs={6} sm={6} md={6} lg={6} justifyContent='flex-start'>
              <Grid item xs={2} container alignItems={'center'}><Facebook /></Grid>
              <Grid item xs={2} container alignItems={'center'}><Twitter /></Grid>
              <Grid item xs={2} container alignItems={'center'}><Instagram /></Grid>
            </Grid>
        </Grid>
    </React.Fragment>)

    const disabledStatus = contactUsInfo.email.length === 0 || contactUsInfo.reason.length === 0 || contactUsInfo.message.length === 0

    const ContactUsFormJsx = (<React.Fragment>
                <DarkGlassRoundedWapper maxWidth={'700px'}>
            <Grid container>
              <Grid item xs={12}>
                <DarkInput
                  placeholder={"Enter Email id"}
                  value={contactUsInfo.email}
                  onChange={(e: any) =>
                    setcontactUsInfo({ ...contactUsInfo, email: e.target.value })
                  }
                  type={"email"}
                  sx={{
                    width: "100%",
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <DarkInput
                  value={contactUsInfo.mobileNumber}
                  onChange={(e: any) =>
                    setcontactUsInfo({ ...contactUsInfo, mobileNumber: e.target.value })
                  }
                  placeholder={"Enter Mobile No."}
                  type={"tel"}
                  sx={{
                    mt: "2rem",
                    width: "100%",
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <DarkInput
                  value={contactUsInfo.reason}
                  onChange={(e: any) =>
                    setcontactUsInfo({ ...contactUsInfo, reason: e.target.value })
                  }
                  placeholder={"Enter Reason"}
                  sx={{
                    mt: "2rem",
                    width: "100%",
                  }}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                {/* textField */}
                <DarkInput
                  placeholder={"Enter Message"}
                  sx={{
                    mt: "2rem",
                    width: "100%",
                  }}
                  variant="outlined"
                  fullWidth
                  multiline={true}
                  size="small"
                  minRows={4}
                  label={''}
                  value={contactUsInfo.message}
                  onChange={(e) => setcontactUsInfo({ ...contactUsInfo, message: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} mt={"2rem"} alignItems='center' justifyContent={'center'} container>
                <Grid item xs={'auto'} textAlign={"right"}>
                  <DarkButtonWithIcon onClick={()=>contactUsQueryHandler()} disabled={disabledStatus}>
                    SEND
                  </DarkButtonWithIcon>
                </Grid>
              </Grid>
            </Grid>
          </DarkGlassRoundedWapper>
      </React.Fragment>)
  return (
    <React.Fragment>
        <Grid position={'relative'} zIndex='2' container gap={[4,4,0,0]} direction={['column','column','row','row']} justifyContent='space-between' color='white'>
            <Grid item xs={12}sm={12} md={6} lg={6}>{ContactUsIntroJs}</Grid>
            <Grid item xs={12}sm={12} md={6} lg={6} container justifyContent={'center'}>{ContactUsFormJsx}</Grid>
        </Grid>
    </React.Fragment>
  )
}

export default ContactUsHero