import { Avatar, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
// import JimCapaldi from "assets/images/JimCapaldi.png";
// import JimMorrison from "assets/images/JimMorrison.png";
// import Ray from "assets/images/Ray.png";
// import Roby from "assets/images/Roby.png";
import Bhish from "assets/images/Bhish.jpg";
import Himay from "assets/images/Himay.jpg";
//import Shobz from "assets/images/Shobz.jpg";
import Alessandro from "assets/images/Alessandro.png";
import Madhav from "assets/images/Madhav.jpg";
import Sushil from "assets/images/Sushil.jpg";
type Props = {}

const MeetTheTeam = (props: Props) => {

	const foundersData = [
		{ name: 'HIMAY KUMBHANI', src: Himay, position: 'Co-Founder', bio: "Music producer, DJ, Designer and serial Entrepreneur. He was the Co-founder at two creative startups, a shoe customization studio, Toe 2 Head and Nineteen51, an E-commence fashion brand." },
		{ name: 'BHISHMA SAGAR', src: Bhish, position: 'Co-Founder', bio: "Entrepreneur by nature. 10 years in the music industry as a DJ and Producer, Founder of Regenerate Music, an artist management agency and Far Out Left, one of India’s finest underground music festivals." },
	];

	const staticData = [
		// { name: 'ANIKET SANGHVI', src: JimMorrison, position: 'CEO' },
		//{ name: 'SHOBHAN VIJAY', src: Shobz, position: 'Tech Advisor', bio: "Web Entrepreneur who has spent the past 10 years building impactful products. Ex VP of Products at Marrow. He is also the founder of poolo.io, a crypto based fund raising platform." },
		{ name: 'ALESSANDRO BONI', position: 'Advisor', src: Alessandro, bio: "Based between Berlin and London, Alessandro is a DJ, Music Producer and Label owner. He is one half of Tapefeed. Co-Founder at Home Of Sound, they host Music Masterclasses. He also manages bookings at Club Renate and Else in Berlin." },
		{ name: 'MADHAV SHOREY', position: 'Advisor', src: Madhav, bio: "Music Industry veteran with over two decades of experience, Kohra is a Producer, DJ and label head at Qilla Records. He has performed at some of the best festivals and clubs in India and Internationally as well as multiple releases on international music labels." },
		{ name: 'SUSHIL CHHUGANI', position: 'Advisor', src: Sushil, bio: "Founder of Stubborn Company, a music services company and creative arts incubator. He has also worked at Sony Music and Viacom in areas of Music/ Artist management Intellectual Property, A&R, Marketing, Brand Solutions and Live Entertainment." },
	]

	const responsiveImageSize = [150, 100, 150, 200, 250]

	return (
		<Grid container color='white' gap={['2rem', '4rem', '4rem', '4rem', '5rem']}>
			{/* heading */}
			<Grid item container xs={12} alignItems='flex-start' justifyContent={'flex-start'} direction='column'>
				<Typography
					sx={{
						"@media (min-width: 1200px)": {},
						fontSize: ["2rem", "2.5rem", "3.5rem", "4rem", "5.2rem"],
						fontFamily: "'Darker Grotesque', sans-serif",
						fontWeight: '700',
						letterSpacing: '-0.03em',
						// mb: "1.5rem",
						color: "#FA6B05",
					}}
				>TEAM</Typography>
				{/* <Typography variant='subtitle2'>Our leaders who made all this possible.</Typography> */}
			</Grid>
			{/* pic wrapper component */}
			<Grid item container xs={12} spacing={4} justifyContent='space-evenly'>
				{foundersData.map((item, i) => <React.Fragment key={i}>
					<Grid item xs={6} sm={3} container direction='column' alignItems={'center'}>
						<Avatar src={item.src} sx={{ mb: '24px', width: responsiveImageSize, height: responsiveImageSize }}></Avatar>
						<Typography fontWeight={'bold'} mb={'8px'} fontFamily="'Darker Grotesque', sans-serif" fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem"]}>{item.name}</Typography>
						<Typography mb={'8px'} fontWeight={'bold'} fontSize={'15px'} variant='caption'>{item.position}</Typography>
						<Typography variant='body2' textAlign={'center'}>{item.bio}</Typography>
					</Grid>
				</React.Fragment>)}
			</Grid>
			<Grid item container xs={12} spacing={4} justifyContent='space-evenly'>
				{staticData.map((item, i) => <React.Fragment key={i}>
					<Grid item xs={6} sm={3} container direction='column' alignItems={'center'}>
						<Avatar src={item.src} sx={{ mb: '24px', width: responsiveImageSize, height: responsiveImageSize }}></Avatar>
						<Typography fontWeight={'bold'} mb={'8px'} fontFamily="'Darker Grotesque', sans-serif" fontSize={["1rem", "1rem", "1.5rem", "1.5rem", "2rem"]}>{item.name}</Typography>
						<Typography mb={'8px'} fontWeight={'bold'} fontSize={'15px'} variant='caption'>{item.position}</Typography>
						<Typography variant='body2' textAlign={'center'}>{item.bio}</Typography>
					</Grid>
				</React.Fragment>)}
			</Grid>
			
		</Grid>
	)
}

export default MeetTheTeam