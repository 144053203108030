import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  ListItem,
  MenuItem,
  Popper,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { HTMLAttributes } from 'react';
import { ReactComponent as AddSquare } from 'assets/svg/add-square.svg';
import { ReactComponent as UploadIcon } from 'assets/svg/uploadIcon.svg';
import { ReactComponent as BlueBurstPucker } from 'assets/svg/blueBurstPucker.svg';
import { AuthContext } from 'context/AuthContext';
import ImageTextFieldContainer from 'components/controls/ImageTextFieldContainer';
import { DarkInputWithLabel } from '../controls/inputFields';
import { Done, AttachMoney } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import {
  DarkButtonWithIcon,
  LightBlurButtonWithCustomIcon,
} from 'components/controls/Buttons';
import GenresSearchInput from 'components/controls/GenresSearchInput';
import MusicUploadTextFieldContainer from 'components/controls/MusicUploadTextFieldContainer';
import { formatBytes, getMatchingIds, getNamesNotInDatabase } from 'utility/Common';
import CheckedIcon from 'assets/Icons/CheckedIcon';
import { TrackUploadContext } from 'context/TrackUploadContext';
import { TrackModel, TrackPublishType } from 'model/musicModel';
import { AlbumModel, AlbumPublishType } from 'model/albumModel';
import DialogPopup from 'components/layout/DialogPopup';
import HttpTransferService from 'services/httptransfer';
import ProgressBarLoadingScreen from './ProgressBarLoadingScreen';
import { AlertContext } from 'context/AlertContext';
import { severityType } from 'model/errorSeverityTypeEnum';
import ApiReqAutoComplete from 'components/controls/ApiReqAutoComplete';
import ApiReqAutoCompleteInput from 'components/controls/AutoCompleteInputString';
import { userType } from 'model/userType';

type Props = {
  closeDialog: () => void;
  fromUploadMusic: boolean;
  albumId: any;
  labelData: any;
  setUnsavedChanges: (value: boolean) => void;
  setDisableIconButton: (value: boolean) => void;
};

const httptransfer = new HttpTransferService();

const UploadMusicForm = (props: Props) => {
  const [imagePreview, setImagePreview] = React.useState<string | null>(null);
  const [fileData, setFileData] = React.useState<any>({});
  const [file, setFile] = React.useState<any>({});
  const [showLoadingScreen, setShowLoadingScreen] =
    React.useState<boolean>(false); 
  const userInfo = React.useContext(AuthContext)!.User!;
  const [formData, setFormData] = React.useState<
    TrackModel & { isrc_code: string, release_date: string }
  >({
    name: '',
    price: '',
    visibility: TrackPublishType.PRIVATE,
    catalog_number: '',
    genres: [],
    isrc_code: '',
    release_date: ''
  });

  const [editSongIndex, setEditSongIndex] = React.useState<number | null>(null);
  const [artistNameData, setArtistNameData] = React.useState<any[]>([]);
  const [artistNames, setArtistNames] = React.useState<any[]>([]);
  const [labelIds, setLabelIds] = React.useState<any[]>([]);
  const [albumId, setAlbumId] = React.useState<any[]>([]);
  const [labelNames, setLabelNames] = React.useState<any[]>([]);
  const [musicFile, setMusicFile] = React.useState<File | null>(null);
  const [artistState, setArtistState] = React.useState<any[]>([]);
  const [genreData, setGenreData] = React.useState<any[]>([]);
  const [albumState, setAlbumState] = React.useState<any[]>([]);
  const [labelState, setLabelState] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [progressBar, setProgressBar] = React.useState<
    { percent: number; trackName: string; id: string }[]
  >([{ percent: 0, trackName: '', id: '' }]);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);

  const theme = useTheme();

  const tracksContext = React.useContext(TrackUploadContext);
  const alertContext = React.useContext(AlertContext);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'visibility') {
      const newVisibility = formData.visibility === TrackPublishType.PUBLIC
        ? TrackPublishType.PRIVATE
        : TrackPublishType.PUBLIC;
      setFormData((prev) => ({ ...prev, visibility: newVisibility }));
    } else if (name === 'release_date') {
      const targetDateString = e.target.value;
      const targetDate = new Date(targetDateString);
      const currentDate = new Date(1900, 0, 1);
      const timeDifference = targetDate.getTime() - currentDate.getTime();
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      setFormData((prev) => ({ ...prev, [name]: daysDifference }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    } 
    props.setUnsavedChanges(true);
  };

  const fileInfo = (file: File) => {
    const fileExtension = file.name.split('.').pop();
    const fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
    const file_path = {
      file_type: file.type.split('/')[0],
      file_extension: fileExtension,
      file_name: fileNameWithoutExtension,
    }
    return file_path
  }

  const musicErrorCallback = (error: any) => {
    setShowLoadingScreen(false);
    props.setDisableIconButton(false);
    props.setUnsavedChanges(false);
    setLoading(false);
    window.ShowInfoDialog(
      error.response.data.message
    );
  };

  const uploadMusicFailedError = () => {
    setShowLoadingScreen(false);
    setLoading(false);
    props.setDisableIconButton(false);
    window.ShowInfoDialog(
      "We couldn’t upload your song. Please try again"
    );
  };

  const createCommonMusicData = (music_audio_file: any) => {
    const commonData = {
      ...formData,
      music_audio_file,
      price: parseFloat(formData.price),
      artist_collaborators_id: getMatchingIds(artistNameData, artistNames),
      artist_collaborators_name: getNamesNotInDatabase(artistNameData, artistNames),
      label_collaborators_id: getMatchingIds(labelIds, labelNames),
      label_collaborators_name: getNamesNotInDatabase(labelIds, labelNames),
      ...(props.albumId || (albumId && albumId.length > 0)
        ? { album_id: props.albumId || albumId[0] }
        : {}),
    };

    if (commonData.release_date === '') {
      const { release_date, ...rest } = commonData; 
      return rest;
    }
  
    return commonData;
  };   

  const  uploadSong = async (music_audio_file:any, music_cover_image:any) => {
    const createSuccessCallback = async (createResponse: any) => {
      tracksContext?.GetAllAlbumsByUser();
      setShowLoadingScreen(false);
      setLoading(false)
      props.closeDialog();
   };
   
  const updatedSongData = {
    ...(music_cover_image.file_path
      ? { ...createCommonMusicData(music_audio_file), music_cover_image }
      : createCommonMusicData(music_audio_file)),
  };
    await httptransfer.uploadMusic(updatedSongData, createSuccessCallback, musicErrorCallback);
  }

  const handleMusicUpload = async () => {
    setLoading(true)
    setShowLoadingScreen(true);
    let music_audio_file={};
    const fileSuccessCallback = async (fileResponse: any) => {
      props.setDisableIconButton(true);
      const file_upload_url = fileResponse?.data?.responseJson?.file_upload_url;
      const musicDataInfo = musicFile!==null ? fileInfo(musicFile) : null;
      music_audio_file = {
        file_path: fileResponse?.data?.responseJson?.file_path,
        file_extension: musicDataInfo?.file_extension,
        file_name: musicDataInfo?.file_name
      } 
      await httptransfer.uploadFileOnAws(musicFile, file_upload_url, musicUploadSuccessCallback, uploadMusicFailedError, setUploadProgress);
    };
    const musicUploadSuccessCallback= async (response: any) => {
      props.setDisableIconButton(false);
      const file_data = file.name ? fileInfo(file) : null;
      if(file_data) {
      await httptransfer.uploadAlbumArt(file_data, imagefileSuccessCallback, musicErrorCallback);
      } else {
        uploadSong(music_audio_file, {})
      }
    };
    const putSuccessCallback= async (response: any) => {
      console.log(response);
    };
  
    const imagefileSuccessCallback = async (fileResponse: any) => {
      const file_upload_url = fileResponse?.data?.responseJson?.file_upload_url;
      const imageFileDataInfo = file!==null ? fileInfo(file) : null;
      let music_cover_image = {
        file_path: fileResponse?.data?.responseJson?.file_path,
        file_extension: imageFileDataInfo?.file_extension,
        file_name: imageFileDataInfo?.file_name
      }
  
      await httptransfer.putCallForFile(file, file_upload_url, putSuccessCallback, musicErrorCallback);
      uploadSong(music_audio_file, music_cover_image)
    };
  
    const file_info = musicFile !==null ? fileInfo(musicFile) : null;
    await httptransfer.uploadAlbumArt(file_info, fileSuccessCallback, musicErrorCallback);
  }
  React.useEffect(() => {
    setFormData((prev) => ({ ...prev, genres: genreData }));
  }, [genreData]);

  if (showLoadingScreen) {
    return (
      // liner progress bar mui loading screen
      <ProgressBarLoadingScreen
        waitingCondition={loading}
        progressBar={[
          {
            trackName: formData.name,
            percent: uploadProgress,
            id: 'music-upload-progress',
          },
        ]}
      />
    );
  }
  const disabledStatus =
  formData.price === '' ||
  formData.name === '' ||
  (props.fromUploadMusic && formData.release_date === '') ||
  formData.genres.length === 0 ||
  artistNameData.length === 0 ||
  musicFile === null

  const MaxFileNameLength = 25;

  return (
    <React.Fragment>
      <Grid container>
        {/* Form container */}
        <Grid container item xs={12} gap='20px' justifyContent={'center'}>
          <Grid
            item
            xs={12}
            sm={'auto'}
            md={'auto'}
            direction={['row', 'row', 'column']}
            gap={'20px'}
            container
            justifyContent={'center'}
            height='max-content'
          >   
          {props.fromUploadMusic && (
          <ImageTextFieldContainer
            setUnsavedChanges={props.setUnsavedChanges}
            data={[]}
            Datakey={'trackImageFile'}
            editIcon={false}
            fileData={fileData}
            file={file}
            imagePreview={imagePreview}
            setFileData={setFileData}
            setFile={setFile}
            setImagePreview={setImagePreview}
            placeholderCondition={!imagePreview}
            placeholderNode={
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: '#08090C',
                  borderRadius: '24px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <UploadIcon />
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: '#ffffff',
                    fontSize: '0.8rem',
                    fontWeight: 500,
                    marginLeft: '0.5rem',
                  }}
                >
                  UPLOAD SONG ART
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    color: '#A3A3A3',
                    fontSize: '10px',
                    fontWeight: 400,
                    marginLeft: '0.5rem',
                  }}
                >
                  1400X1400 pixels minimum.
                  <br />
                  .jpg .png only. Max 10Mb
                </Typography>
              </Box>
            }
          />)}        
            <MusicUploadTextFieldContainer
              setUnsavedChanges={props.setUnsavedChanges}
              Datakey={'trackAudioFile'}
              editIcon={false}
              formData={musicFile}
              setFormData={setMusicFile}
              placeholderCondition={musicFile === null}
              placeholderNode={
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#08090C',
                    borderRadius: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <UploadIcon />
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#ffffff',
                      fontSize: '0.8rem',
                      fontWeight: 500,
                      marginLeft: '0.5rem',
                    }}
                  >
                    UPLOAD MUSIC <span style={{ color: 'red', fontSize: '1rem' }}>*</span>
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      color: '#A3A3A3',
                      fontSize: '10px',
                      fontWeight: 500,
                      // marginLeft: "0.5rem",
                    }}
                  >
                    600Mb max per song, lossless .wav, .aif, .aiff, .flac
                  </Typography>
                </Box>
              }
              selectedFileNode={
                musicFile !== null ? (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: '#08090C',
                      borderRadius: '24px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <Done />
                    <Typography
                      sx={{
                        textAlign: 'center',
                        color: '#ffffff',
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        // marginLeft: "0.5rem",
                      }}
                    >
                      {musicFile.name.length > MaxFileNameLength
                         ? `${musicFile.name.substring(0, MaxFileNameLength)}...${musicFile.name.slice(-4)}` // Truncate file name and keep the extension
                         : musicFile.name}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: "#ffffff",
                        fontSize: "12px",
                        fontWeight: 400,
                        marginLeft: "0.5rem",
                      }}
                    >
                      File size is {formatBytes(musicFile!.size!)}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )
              }
            />

                <FormControlLabel
                  name='visibility'
                  onChange={handleChange}
                  control={
                    <Checkbox
                      icon={
                        <CheckedIcon
                          checked={
                            formData.visibility == TrackPublishType.PUBLIC
                          }
                        />
                      }
                      checkedIcon={
                        <CheckedIcon
                          checked={
                            formData.visibility == TrackPublishType.PUBLIC
                          }
                        />
                      }
                    />
                  }
                  label='Public'
                />
          </Grid>

          {/* Form fields container */}
          <Grid
            item
            container
            xs={'auto'}
            direction='column'
            width={['100%', '100%', 'unset']}
          >
            <DarkInputWithLabel
              label={<span>NAME <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              value={formData.name}
              name={'name'}
              type={'text'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />

            
            <DarkInputWithLabel
              label={<span>PRICE <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              value={formData.price}
              placeholder={'0.00'}
              onWheel={(e) => (e.target as any).blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Icon sx={{ color: '#C5CFDB', height: '1.05em' }}>
                      <AttachMoney />
                    </Icon>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                min: 0,
              }}
              name={'price'}
              type={'number'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />
            {props.fromUploadMusic && (
              <DarkInputWithLabel
                label={'CATALOG NUMBER'}
                value={formData.catalog_number}
                name={'catalog_number'}
                type={'text'}
                onChange={handleChange}
                sx={{ marginBottom: '1.25rem' }}
              />)}

          <ApiReqAutoCompleteInput
              setUnsavedChanges={props.setUnsavedChanges}
              multipleValue={true}
              key={'genre'}
              addNewOption={true}
              inputName={'genre'}
              setState={setGenreData}
              state={genreData}
              label={ <span>EDIT GENRE / MOOD <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              type={"GENRE"}
          />

            
          </Grid>
          <Grid
            item
            container
            xs={'auto'}
            direction='column'
            width={['100%', '100%', 'unset']}
          >
            {/* <Grid item container> */}
             {props.fromUploadMusic && (
             <ApiReqAutoComplete
              setUnsavedChanges={props.setUnsavedChanges}
              multipleValue={true}
              key={'label'}
              addNewOption={true}
              inputName={'label_collaborators_name'}
              setSelectedIds={setLabelIds}
              selectedIds={labelIds}
              setNames={setLabelNames}
              names={labelNames}
              state={labelState}
              setState={setLabelState}
              label={"LABEL"}
              type={"LABEL"}
            />)}

           {props.fromUploadMusic && (<div>
            <style>
              {`
                input[type="date"]::-webkit-calendar-picker-indicator {
                  filter: invert(1);
                }
              `}
            </style>
           <DarkInputWithLabel
            label={<span>RELEASE DATE <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
            type={'date'}
            name={'release_date'}
            sx={{ marginBottom: '1.25rem' }}
            onChange={handleChange}
          /></div>)}
            
            <DarkInputWithLabel
              label={'ISRC CODE'}
              value={formData.isrc_code}
              name={'isrc_code'}
              type={'text'}
              onChange={handleChange}
              sx={{ marginBottom: '1.25rem' }}
            />

            <ApiReqAutoComplete
              setUnsavedChanges={props.setUnsavedChanges}
              multipleValue={true}
              key={'artist_collaborators_id'}
              addNewOption={true}
              inputName={'artist_collaborators_id'}
              setSelectedIds={setArtistNameData}
              selectedIds={artistNameData}
              setNames={setArtistNames}
              names={artistNames}
              state={artistState}
              setState={setArtistState}
              label={<span>ARTISTS <span style={{ color: 'red', fontSize: '1rem' }}>*</span></span>}
              type={"ARTIST"}
            /> 
            {/* </Grid> */}
          </Grid>
        </Grid>

        {/* Save button container */}
        <Grid
          item
          container
          justifyContent={'space-between'}
          xs={12}
          gap={['20px', '20px', '0px']}
          flexWrap='nowrap'
          direction={['column', 'column', 'row']}
        >
          <Grid item container>
            <Typography
              sx={{
                textAlign: 'center',
                color: '#FFBA35',
                fontSize: '12px',
              }}
            >
              Note: 600Mb max per song, lossless .wav, .aif, .aiff, .flac
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent={'flex-end'}
            gap={['20px', '20px', '44px']}
            flexWrap='nowrap'
            direction={['column', 'column', 'row']}
          >
            
          
            <DarkButtonWithIcon
              disabled={disabledStatus}
              sx={{
                width: 'max-content',
              }}
              onClick={handleMusicUpload}
            >
             {loading ? <CircularProgress size={15} style={{ marginRight: '8px' }} color="secondary" /> : null}UPLOAD
            </DarkButtonWithIcon>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default UploadMusicForm;
