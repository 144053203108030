import { Box, Grid, Typography } from "@mui/material";
import WaveBackgroundLayout from "components/layout/WaveBackgroundLayout";
import React from "react";

type Props = {
  status: boolean;
};

const CheckoutStatus = ({ status }: Props) => {
  const successJSX = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">Thank you for your order!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">
          Your order number is #2001539. We have emailed your order
          confirmation, and will send you an update when your order has shipped.
        </Typography>
      </Grid>
    </Grid>
  );

  const failureJSX = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h1">
          Sorry, your order was not successful
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h2">
          Please try again or contact us if you need assistance.
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <WaveBackgroundLayout oneWave={true} twoWave={false}>
        <Box
          p={["1rem", "2rem", "4rem", "5rem"]}
          pt={["7rem", "6rem", null, null, null]}
        >
          {status ? successJSX : failureJSX}
        </Box>
      </WaveBackgroundLayout>
    </>
  );
};

export default CheckoutStatus;
