import { SvgIcon } from '@mui/material'
import React from 'react'
// import EllipseChecked from "assets/svg/EllipseChecked.svg";
type Props = {
	checked: boolean
}

const CheckedIcon = (props: Props) => {
	return (
		// <SvgIcon sx={{fontSize:'1.25rem'}}>
			props.checked ? <svg fontSize='1.25rem' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white" fillOpacity="0.24" stroke="#636B82" />
				<circle cx="10" cy="10" r="5" fill="#FA6B05" />
			</svg> :
				<svg fontSize='1.25rem' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="0.5" y="0.5" width="19" height="19" rx="5.5" fill="white" fillOpacity="0.24" stroke="#636B82" />
				</svg>
			
		// </SvgIcon>
	)
}

export default CheckedIcon