import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import constant from "constants/constant";
import App from "./App";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline, Theme } from "@mui/material";
import AuthContextProvider from "context/AuthContext";
import AlertContextProvider from "context/AlertContext";
import TrackUploadContextProvider from "context/TrackUploadContext";
import PlaylistContextProvider from "context/PlaylistContext";
import MusicPlayerContextProvider from "context/MusicPlayerContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MessageDialog from "components/layout/MessageDialog";
import MusicStoreContextProvider, {
  MusicStoreContext,
} from "context/MusicStoreContext";
import SearchContextProvider from "context/SearchContext";
import defaultPp from "assets/images/defaultPp.png";

declare module "@mui/material/styles" {
  interface Palette {
    customTheme: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    customTheme?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
// declare module '@mui/material/Button' {
//   interface ButtonPropsColorOverrides {
//     customTheme: true;
//   }
// }

let theme: Theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&:hover": {
            background: "none",
          },
        },
      },
      defaultProps: {
        disableElevation: true,
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiAvatar:{
      defaultProps:{
        children: <img style={{width:"100%",height:"100%"}} src={defaultPp} alt="profile" />
      }
    },
    MuiAlert: {
      styleOverrides: {},
      variants: [
        {
          props: { severity: "error" },
          style: {
            background: "linear-gradient(180deg, #EE183F 0%, #08090c 100%)",
          },
        },
        {
          props: { severity: "success" },
          style: {
            background: "linear-gradient(180deg, #18D363 0%, #08090c 100%);",
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: "Manrope",
  },
  palette: {
    customTheme: {
      main: "#FA6B05",
    },
  },
});

const ResponsiveTheme = responsiveFontSizes(theme, {
  breakpoints: ["sm", "md", "lg", "xl"],
  disableAlign: false,
  factor: 2,
  variants: [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "subtitle1",
    "subtitle2",
    "body1",
    "body2",
    "caption",
    "button",
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={constant.GOOGLE_CLIENT_ID}>
                <AlertContextProvider>
        <AuthContextProvider>
          <MusicPlayerContextProvider>
            <SearchContextProvider>
              <TrackUploadContextProvider>
              <PlaylistContextProvider>
                  <MusicStoreContextProvider>
                    <ThemeProvider theme={ResponsiveTheme}>
                      <CssBaseline />
                      <App />
                      <MessageDialog />
                    </ThemeProvider>
                  </MusicStoreContextProvider>
                  </PlaylistContextProvider>
              </TrackUploadContextProvider>
            </SearchContextProvider>
          </MusicPlayerContextProvider>
        </AuthContextProvider>
                </AlertContextProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);